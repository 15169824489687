const { APP_PLATFORMS } = require('../../app-platforms')

export const LOCALE = 'locale'

export const SHOW_IMPORT_INSTANCE_BUTTON_FOR_SERVER =
  process.env.VUE_APP_IMPORT_INSTANCE_BUTTON_FOR_SERVER === 'true'

export const HIDE_ONBOARDING_BOOKING_STEP =
  process.env.VUE_APP_HIDE_ONBOARDING_BOOKING_STEP === 'true'

export const VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_JIRA =
  process.env.VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_JIRA === 'true'

export const VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_WEB_APP =
  process.env.VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_WEB_APP === 'true'

export const getAppType = ({ appPlatform = APP_PLATFORMS.JIRA } = {}) => {
  return {
    isJiraApp: appPlatform === APP_PLATFORMS.JIRA,
    isWebApp: appPlatform === APP_PLATFORMS.WEB_APP,
    isCrossPlatformApp: appPlatform === APP_PLATFORMS.CROSS_PLATFORM
  }
}
