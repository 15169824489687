import i18n from '@/i18n'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { isMacOs } from '@/utils/general'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'

export const BLACK = '#000'
export const WHITE = '#fff'
export const MORE_FORMATS_ACTIONS = {
  UNDERLINE: 'Underline',
  STRIKETHROUGH: 'Strikethrough',
  SUBSCRIPT: 'Subscript',
  SUPERSCRIPT: 'Superscript',
  STRIKE: 'Strike',
  CLEAR: 'Clear'
}
export const COLORS = [
  'rgb(23, 43, 77)',
  'rgb(7, 71, 166)',
  'rgb(0, 141, 166)',
  'rgb(0, 102, 68)',
  'rgb(255, 153, 31)',
  'rgb(191, 38, 0)',
  'rgb(64, 50, 148)',
  'rgb(151, 160, 175)',
  'rgb(76, 154, 255)',
  'rgb(0, 184, 217)',
  'rgb(54, 179, 126)',
  'rgb(255, 196, 3)',
  'rgb(255, 86, 48)',
  'rgb(101, 85, 192)',
  'rgb(255, 255, 255)',
  'rgb(178, 212, 255)',
  'rgb(180, 245, 255)',
  'rgb(171, 245, 209)',
  'rgb(255, 241, 179)',
  'rgb(255, 190, 173)',
  'rgb(234, 231, 255)'
]

export const ICON_COLORS = COLORS.reduce((acc, val) => {
  const [, r, g, b] = val.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  return { ...acc, [val]: hsp > 127.5 ? BLACK : WHITE }
}, {})

export const TEXT_GROUP = 'text'
export const SMALL_TEXT_GROUP = 'small-text'
export const YOUTUBE_VIDEO_WIDTH = 510
export const YOUTUBE_VIDEO_HEIGHT = 260

export const getCurrentTip = feature => {
  const system = isMacOs() ? MAC_OS : OTHER
  return SHORTCUT_TIPS[feature][system]
}

const CMD = '⌘'
const OPTION = '⌥'
const ALT = 'Alt'
const SHIFT = '⇧'
// const ENTER = '↵'
const CONTROL = 'Ctrl'
const MAC_OS = 'MAC_OS'
const OTHER = 'OTHER'
export const TIPTAP_EXTENSION_NAMES = {
  HEADING_1: 'heading1',
  HEADING_2: 'heading2',
  HEADING_3: 'heading3',
  HEADING_4: 'heading4',
  HEADING_5: 'heading5',
  HEADING_6: 'heading6',
  ORDER_LIST: 'orderList',
  BULLET_LIST: 'bulletList',
  NUMBERED_LIST: 'numberedList',
  TASK_LIST: 'taskList',
  BLOCKQUOTE: 'blockquote',
  CODE_BLOCK: 'codeBlock',
  TABLE: 'table',
  YOUTUBE: 'youtube',
  GIFS: 'gifs',
  IMAGE: 'image',
  IMAGE_BLOCK: 'imageBlock',
  IMAGE_UPLOAD: 'imageUpload',
  EMOJI: 'emoji',
  BOLD: 'bold',
  ITALICIZE: 'italicize',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  HIGHLIGHT: 'highlight',
  CODE: 'code',
  NORMAL_TEXT: 'normalText',
  LEFT_ALIGN: 'leftAlign',
  CENTER_ALIGN: 'centerAlign',
  RIGHT_ALIGN: 'rightAlign',
  JUSTIFY: 'justify',
  SUBSCRIPT: 'subscript',
  SUPERSCRIPT: 'superscript',
  HORIZONTAL_RULE: 'horizontalRule'
}

export const SHORTCUT_TIPS = {
  [TIPTAP_EXTENSION_NAMES.BOLD]: {
    [MAC_OS]: [CMD, 'B'],
    [OTHER]: [CONTROL, 'B']
  },
  [TIPTAP_EXTENSION_NAMES.ITALICIZE]: {
    [MAC_OS]: [CMD, 'I'],
    [OTHER]: [CONTROL, 'I']
  },
  [TIPTAP_EXTENSION_NAMES.UNDERLINE]: {
    [MAC_OS]: [CMD, 'U'],
    [OTHER]: [CONTROL, 'U']
  },
  [TIPTAP_EXTENSION_NAMES.STRIKETHROUGH]: {
    [MAC_OS]: [CMD, SHIFT, 'S'],
    [OTHER]: [CONTROL, SHIFT, 'S']
  },
  [TIPTAP_EXTENSION_NAMES.HIGHLIGHT]: {
    [MAC_OS]: [CMD, SHIFT, 'H'],
    [OTHER]: [CONTROL, SHIFT, 'H']
  },
  [TIPTAP_EXTENSION_NAMES.CODE]: {
    [MAC_OS]: [CMD, 'E'],
    [OTHER]: [CONTROL, 'E']
  },
  [TIPTAP_EXTENSION_NAMES.NORMAL_TEXT]: {
    [MAC_OS]: [CMD, OPTION, '0'],
    [OTHER]: [CONTROL, ALT, '0']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_1]: {
    [MAC_OS]: [CMD, OPTION, '1'],
    [OTHER]: [CONTROL, ALT, '1']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_2]: {
    [MAC_OS]: [CMD, OPTION, '2'],
    [OTHER]: [CONTROL, ALT, '2']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_3]: {
    [MAC_OS]: [CMD, OPTION, '3'],
    [OTHER]: [CONTROL, ALT, '3']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_4]: {
    [MAC_OS]: [CMD, OPTION, '4'],
    [OTHER]: [CONTROL, ALT, '4']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_5]: {
    [MAC_OS]: [CMD, OPTION, '5'],
    [OTHER]: [CONTROL, ALT, '5']
  },
  [TIPTAP_EXTENSION_NAMES.HEADING_6]: {
    [MAC_OS]: [CMD, OPTION, '6'],
    [OTHER]: [CONTROL, ALT, '6']
  },
  [TIPTAP_EXTENSION_NAMES.ORDER_LIST]: {
    [MAC_OS]: [CMD, SHIFT, '7'],
    [OTHER]: [CONTROL, SHIFT, '7']
  },
  [TIPTAP_EXTENSION_NAMES.BULLET_LIST]: {
    [MAC_OS]: [CMD, SHIFT, '8'],
    [OTHER]: [CONTROL, SHIFT, '8']
  },
  [TIPTAP_EXTENSION_NAMES.TASK_LIST]: {
    [MAC_OS]: [CMD, SHIFT, '9'],
    [OTHER]: [CONTROL, SHIFT, '9']
  },
  [TIPTAP_EXTENSION_NAMES.BLOCKQUOTE]: {
    [MAC_OS]: [CMD, SHIFT, 'B'],
    [OTHER]: [CONTROL, SHIFT, 'B']
  },
  [TIPTAP_EXTENSION_NAMES.LEFT_ALIGN]: {
    [MAC_OS]: [CMD, SHIFT, 'L'],
    [OTHER]: [CONTROL, SHIFT, 'L']
  },
  [TIPTAP_EXTENSION_NAMES.CENTER_ALIGN]: {
    [MAC_OS]: [CMD, SHIFT, 'E'],
    [OTHER]: [CONTROL, SHIFT, 'E']
  },
  [TIPTAP_EXTENSION_NAMES.RIGHT_ALIGN]: {
    [MAC_OS]: [CMD, SHIFT, 'R'],
    [OTHER]: [CONTROL, SHIFT, 'R']
  },
  [TIPTAP_EXTENSION_NAMES.JUSTIFY]: {
    [MAC_OS]: [CMD, SHIFT, 'J'],
    [OTHER]: [CONTROL, SHIFT, 'J']
  },
  [TIPTAP_EXTENSION_NAMES.CODE_BLOCK]: {
    [MAC_OS]: [CMD, OPTION, 'C'],
    [OTHER]: [CONTROL, ALT, 'C']
  },
  [TIPTAP_EXTENSION_NAMES.SUBSCRIPT]: {
    [MAC_OS]: [CMD, ','],
    [OTHER]: [CONTROL, ',']
  },
  [TIPTAP_EXTENSION_NAMES.SUPERSCRIPT]: {
    [MAC_OS]: [CMD, ';'],
    [OTHER]: [CONTROL, ';']
  },
  [TIPTAP_EXTENSION_NAMES.HORIZONTAL_RULE]: {
    [MAC_OS]: [CMD, SHIFT, 'P'],
    [OTHER]: [CONTROL, SHIFT, 'P']
  },
  [TIPTAP_EXTENSION_NAMES.TABLE]: {
    [MAC_OS]: [CMD, OPTION, 'T'],
    [OTHER]: [CONTROL, ALT, 'T']
  },
  [TIPTAP_EXTENSION_NAMES.IMAGE_UPLOAD]: {
    [MAC_OS]: [CMD, OPTION, 'K'],
    [OTHER]: [CONTROL, ALT, 'K']
  }
}

export const HEADING_LEVELS = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5,
  LEVEL_6: 6
}

export const TEXT_FORMATS = [
  {
    label: i18n.global.t('description.normal_text'),
    value: 'paragraph',
    featureName: TIPTAP_EXTENSION_NAMES.NORMAL_TEXT
  },
  {
    label: i18n.global.t('description.heading_1'),
    value: HEADING_LEVELS.LEVEL_1,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_1
  },
  {
    label: i18n.global.t('description.heading_2'),
    value: HEADING_LEVELS.LEVEL_2,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_2
  },
  {
    label: i18n.global.t('description.heading_3'),
    value: HEADING_LEVELS.LEVEL_3,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_3
  },
  {
    label: i18n.global.t('description.heading_4'),
    value: HEADING_LEVELS.LEVEL_4,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_4
  },
  {
    label: i18n.global.t('description.heading_5'),
    value: HEADING_LEVELS.LEVEL_5,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_5
  },
  {
    label: i18n.global.t('description.heading_6'),
    value: HEADING_LEVELS.LEVEL_6,
    featureName: TIPTAP_EXTENSION_NAMES.HEADING_6
  }
].map(item => ({ ...item, shortcut: getCurrentTip(item.featureName) }))

export const getSimpleViewList = ({ simpleView, editor, showUrlModal, hiddenFeatures = [] }) => {
  return [
    {
      title: i18n.global.t('action.table'),
      action: 'insertTable',
      disabled: editor.isActive('table'),
      icon: 'table-icon',
      type: 'default',
      showInSimpleView: true,
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.TABLE)
    },
    {
      title: i18n.global.t('action.link'),
      action: 'insertLink',
      isActive: showUrlModal || editor.isActive('link'),
      icon: 'link',
      type: 'default',
      showInSimpleView: true
    },
    {
      title: i18n.global.t('action.youtube'),
      action: 'addYouTubeVideo',
      icon: 'video-play',
      type: 'default'
    },
    {
      title: i18n.global.t('action.image'),
      action: 'setImageUpload',
      icon: 'picture',
      type: 'default',
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.IMAGE_UPLOAD)
    },
    {
      title: i18n.global.t('action.divider'),
      action: 'addDivider',
      icon: 'divider',
      type: 'default',
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.HORIZONTAL_RULE)
    },
    {
      title: i18n.global.t('action.task'),
      action: 'addTaskList',
      isActive: editor.isActive('taskList'),
      icon: 'check-outline',
      type: 'default',
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.TASK_LIST)
    },
    {
      title: i18n.global.t('action.code_snippet'),
      action: 'insertCodeSnippet',
      isActive: editor.isActive('code'),
      icon: 'code',
      type: 'default',
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.CODE_BLOCK)
    },
    {
      title: i18n.global.t('action.mention'),
      action: 'insertMention',
      isActive: editor.isActive('mention'),
      icon: 'mention',
      type: 'default'
    },
    {
      title: i18n.global.t('action.quote'),
      action: 'insertQuote',
      isActive: editor.isActive('blockquote'),
      icon: 'quote',
      type: 'default',
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.BLOCKQUOTE)
    },
    {
      title: i18n.global.t('action.emoji'),
      action: 'showEmojiPicker',
      icon: 'emoji',
      type: 'default',
      showInSimpleView: true,
      key: TIPTAP_EXTENSION_NAMES.EMOJI
    }
  ]
    .filter(item => (simpleView ? true : !item.showInSimpleView))
    .filter(item => !hiddenFeatures.includes(item.key))
}

export const getMoreFormats = ({ editor }) => {
  return [
    {
      label: 'description.underline',
      tag: 'u',
      value: MORE_FORMATS_ACTIONS.UNDERLINE,
      highlightOption: editor.isActive('underline'),
      group: TEXT_GROUP,
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.UNDERLINE)
    },
    {
      label: 'description.strikethrough',
      tag: 's',
      value: MORE_FORMATS_ACTIONS.STRIKE,
      highlightOption: editor.isActive('strike'),
      group: TEXT_GROUP,
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.STRIKETHROUGH)
    },
    {
      label: 'description.position',
      labelDescription: 'description.subscript',
      tag: 'sub',
      value: MORE_FORMATS_ACTIONS.SUBSCRIPT,
      highlightOption: editor.isActive('subscript'),
      group: SMALL_TEXT_GROUP,
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.SUBSCRIPT)
    },
    {
      label: 'description.position',
      labelDescription: 'description.superscript',
      tag: 'sup',
      value: MORE_FORMATS_ACTIONS.SUPERSCRIPT,
      highlightOption: editor.isActive('superscript'),
      group: SMALL_TEXT_GROUP,
      shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.SUPERSCRIPT)
    },
    {
      label: 'description.clear_formatting',
      value: MORE_FORMATS_ACTIONS.CLEAR,
      group: MENU_ITEMS_GROUPS.REMOVING
    }
  ]
}

export const removeSlashCommandShortcut = editor => {
  const { view, state } = editor
  const { $head, $from } = view.state.selection

  const end = $from.pos
  const from = $head?.nodeBefore
    ? end - ($head.nodeBefore.text?.substring($head.nodeBefore.text?.indexOf('/')).length ?? 0)
    : $from.start()

  const tr = state.tr.deleteRange(from, end)
  view.dispatch(tr)
}

export const insertEmptySpaces = editor => {
  editor.commands.enter()
  editor.commands.enter()
  editor.commands.selectNodeBackward()
}

export const tiptapChainFocusFnc = editor => {
  return editor.chain().focus()
}
export const MENU_LIST = [
  {
    name: 'format',
    title: i18n.global.t('wysiwyg.menu_list.format'),
    commands: [
      {
        id: 1,
        name: TIPTAP_EXTENSION_NAMES.HEADING_1,
        label: i18n.global.t('wysiwyg.menu_list.header'),
        iconName: 'heading-1',
        styles: '--font-weight: 600; --font-size: 24px;',
        aliases: ['h1'],
        action: editor => {
          tiptapChainFocusFnc(editor).setHeading({ level: 1 }).run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.HEADING_1)
      },
      {
        id: 2,
        name: TIPTAP_EXTENSION_NAMES.HEADING_2,
        label: i18n.global.t('wysiwyg.menu_list.subheader'),
        iconName: 'heading-2',
        styles: '--font-weight: 600; --font-size: 20px;',
        aliases: ['h2'],
        action: editor => {
          tiptapChainFocusFnc(editor).setHeading({ level: 2 }).run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.HEADING_2)
      },
      {
        id: 3,
        name: TIPTAP_EXTENSION_NAMES.BULLET_LIST,
        label: i18n.global.t('wysiwyg.menu_list.bullet_list'),
        iconName: 'list_bulleted',
        aliases: ['ul'],
        action: editor => {
          tiptapChainFocusFnc(editor).toggleBulletList().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.BULLET_LIST)
      },
      {
        id: 4,
        name: TIPTAP_EXTENSION_NAMES.NUMBERED_LIST,
        label: i18n.global.t('wysiwyg.menu_list.numbered_list'),
        iconName: 'list_numbered',
        aliases: ['ol'],
        action: editor => {
          tiptapChainFocusFnc(editor).toggleOrderedList().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.ORDER_LIST)
      },
      {
        id: 5,
        name: TIPTAP_EXTENSION_NAMES.TASK_LIST,
        label: i18n.global.t('wysiwyg.menu_list.task_list'),
        iconName: 'check-outline',
        aliases: ['todo'],
        action: editor => {
          tiptapChainFocusFnc(editor).toggleTaskList().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.TASK_LIST)
      },
      {
        id: 6,
        name: TIPTAP_EXTENSION_NAMES.BLOCKQUOTE,
        label: i18n.global.t('wysiwyg.menu_list.blockquote'),
        iconName: 'quote',
        action: editor => {
          tiptapChainFocusFnc(editor).setBlockquote().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.BLOCKQUOTE)
      },
      {
        id: 7,
        name: TIPTAP_EXTENSION_NAMES.CODE_BLOCK,
        label: i18n.global.t('wysiwyg.menu_list.code_snippet'),
        iconName: 'code',
        action: editor => {
          tiptapChainFocusFnc(editor).setCodeBlock().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.CODE_BLOCK)
      }
    ]
  },
  {
    name: 'insert',
    title: i18n.global.t('wysiwyg.menu_list.insert'),
    commands: [
      {
        id: 8,
        name: TIPTAP_EXTENSION_NAMES.TABLE,
        label: i18n.global.t('wysiwyg.menu_list.table'),
        iconName: 'table-icon',
        action: editor => {
          insertEmptySpaces(editor)
          insertTable(editor)
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.TABLE)
      },
      {
        id: 9,
        name: TIPTAP_EXTENSION_NAMES.YOUTUBE,
        label: 'Youtube',
        iconName: 'video-play',
        aliases: ['yt'],
        action: () => {
          showNotify({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Ooops',
            content:
              'The action is not provided, we are aware of it and will fix it in the near future'
          })
        }
      },
      {
        id: 10,
        name: TIPTAP_EXTENSION_NAMES.GIFS,
        label: 'GIF',
        iconName: 'gif',
        aliases: ['gif'],
        action: () => {
          showNotify({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Ooops',
            content:
              'The action is not provided, we are aware of it and will fix it in the near future'
          })
        }
      },
      {
        id: 11,
        name: TIPTAP_EXTENSION_NAMES.IMAGE,
        label: i18n.global.t('wysiwyg.menu_list.image'),
        iconName: 'picture',
        aliases: ['image'],
        action: editor => {
          insertEmptySpaces(editor)
          tiptapChainFocusFnc(editor).setImageUpload().run()
        },
        shortcut: getCurrentTip(TIPTAP_EXTENSION_NAMES.IMAGE_UPLOAD)
      }
    ]
  }
]

export const insertTable = editor => {
  tiptapChainFocusFnc(editor).insertTable({ rows: 3, cols: 3 }).run()
}

export const DESCRIPTION_FIELD_PLACEMENTS = 'DESCRIPTION_FIELD_PLACEMENTS'
export const COMMENT_FIELD_PLACEMENTS = 'COMMENT_FIELD_PLACEMENTS'

export const GIFS_PLACEMENTS_API_KEYS = {
  [DESCRIPTION_FIELD_PLACEMENTS]: process.env.VUE_APP_API_GIFS_DESCRIPTION,
  [COMMENT_FIELD_PLACEMENTS]: process.env.VUE_APP_API_GIF_COMMENTS
}

export const convertShortcutToText = (options = []) => {
  return options.join(' + ')
}
