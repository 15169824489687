<template>
  <div class="btl-Loader">
    <div v-for="row in ROWS" :key="row.id" class="btl-Loader_Row">
      <div class="btl-Cell">
        <SkeletonItem :appearance="SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" border-radius="6px" />
      </div>

      <div class="btl-Cell">
        <SkeletonItem :appearance="CIRCLE" :size="SM" />
      </div>

      <div class="btl-Cell">
        <SkeletonItem :size="XS" width="175px" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BinTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { SM, XS } = SKELETON_SIZES

const { SQUARE, CIRCLE } = SKELETON_APPEARANCES

const ROWS = [
  {
    id: 0,
    name: '90px'
  },
  {
    id: 1,
    name: '13px'
  },
  {
    id: 2,
    name: '50px'
  },
  {
    id: 3,
    name: '192px'
  },
  {
    id: 4,
    name: '16px'
  },
  {
    id: 5,
    name: '20px'
  },
  {
    id: 6,
    name: '47px'
  },
  {
    id: 7,
    name: '185px'
  },
  {
    id: 8,
    name: '210px'
  },
  {
    id: 9,
    name: '82px'
  }
]
</script>

<style lang="scss" scoped>
.btl-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.btl-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    gap: 8px;
    flex: 1 0 auto;
    padding-left: 32px;
  }

  &:nth-child(2) {
    width: 204px;
    display: flex;
    justify-content: center;
  }

  &:nth-child(3) {
    width: 272px;
  }
}
</style>
