<template>
  <portal to="modal-windows">
    <AppDialog :show="show" :title="$t('action.share')" hide-hero @on-close="close">
      {{ $t('common.copy_link_description') }}

      <FormFieldNext
        v-if="link"
        :label="$t('common.direct_link')"
        class="cslm-LinkWrapper"
        @click="onCopyLink(true)"
      >
        <div :class="{ 'cslm-Link-copied': isCopied }" class="cslm-Link oboard-truncated-text">
          {{ link }}
        </div>
      </FormFieldNext>

      <template #footer-actions>
        <AppButton
          :disable="!link"
          height="24"
          icon="link"
          type="primary-next"
          width="24"
          @click="onCopyLink(false)"
        >
          {{ $t('breadcrumb.copy_link') }}
        </AppButton>
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { Portal } from 'portal-vue'
import { inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ROUTE_PATHS } from '@/routes/route-helpers'
import { getAppType } from '@/util'
import { getHashFromLocation } from '@/utils/external-link-helper'
import { copyToClipboard } from '@/utils/general'
import { appPlatformInjectionKey } from '@/utils/injection-keys'
import { showNotify } from '@/utils/notify'
import { getAtlassianBaseUrl, JIRA_CLOUD_API, JIRA_SERVER_API } from '@jira/util'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'CopySharedLinkModal'
})

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },

  token: {
    type: String,
    default: ''
  }
})

watch(
  () => props.show,
  newValue => {
    if (newValue) {
      createLink()
    } else {
      link.value = ''
      isCopied.value = false
    }
  }
)

const emit = defineEmits({ 'update:show': null })

const isCopied = ref(false)
const appPlatform = inject(appPlatformInjectionKey)

const link = ref('')

const createLink = () => {
  const sharePostfix = `/${ROUTE_PATHS.SHARE}/${props.token}`

  const hash = getHashFromLocation() && `/${getHashFromLocation()}`

  const DEFAULT_HREF = `${location.origin}${hash}${sharePostfix}`

  const { isJiraApp, isWebApp, isCrossPlatformApp } = getAppType({ appPlatform })

  if (isWebApp) {
    link.value = `${location.origin}${hash}${sharePostfix}`
  } else if (isCrossPlatformApp) {
    link.value = `${location.origin}${hash}${sharePostfix}`
  } else if (isJiraApp) {
    if (JIRA_CLOUD_API) {
      JIRA_CLOUD_API.getLocation(location => {
        // location is full link including hash(vue route)
        // vue route path is in hash independent of router mode, it's
        // characteristic of jira cloud
        const url = new URL(location)
        const [hashDivider] = url.hash.split('/')
        const resolvedDivider = hashDivider === '#!' ? '#!' : '#'
        link.value = url.href.replace(url.hash, `${resolvedDivider}${sharePostfix}`)
      })
    } else if (JIRA_SERVER_API) {
      const url = new URL(getAtlassianBaseUrl())

      link.value = `${url.href}${sharePostfix}`
    } else {
      link.value = DEFAULT_HREF
    }
  } else {
    link.value = DEFAULT_HREF
  }
}

const close = () => {
  emit('update:show', false)
}
const { t } = useI18n()

const onCopyLink = (isFieldClicked = false) => {
  // save selection and restore it after copying
  const selection = window.getSelection()
  const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null

  copyToClipboard(link.value)
  isCopied.value = true
  showNotify({ title: t('common.link_copied') })

  if (range && range.commonAncestorContainer.textContent === link.value && isFieldClicked) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}
</script>

<style lang="scss" scoped>
.cslm-Link {
  border-radius: $border-radius-sm-next;
  padding: 10px;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  color: $dark-1;
  cursor: pointer;

  &:not(&-copied) {
    background-color: $grey-3-next;
  }

  &-copied {
    background-color: rgba($grade-high-color-next, 0.1);
  }
}

.cslm-LinkWrapper {
  margin-top: 20px;
}
</style>
