<template>
  <Modal
    :data-testid="$attrs['data-testid'] || null"
    :scrollable-wrapper="true"
    :show="show"
    class="mggm-Modal"
    manual-close
    size="lg-next"
    sticky-header
    @close="onClose"
  >
    <template #header>
      <div class="mggm-HeaderGroup">
        <GroupIcon
          :clickable="userHasUpdateAccess"
          :color="group.color || groupForManage.color"
          :icon-name="group.icon || groupForManage.icon"
          data-testid="modal-group-icon"
          @click="openSettings"
        />
        <TitleWithAction
          v-tippy="{
            ...TOOLTIP_CONFIGURATION,
            content: group.name || groupForManage.name,
            placement: 'top-start'
          }"
          class="oboard-truncated-text"
          data-testid="modal-title"
        >
          {{ group.name || groupForManage.name }}
        </TitleWithAction>
      </div>
    </template>

    <template #before-close>
      <div class="mggm-BeforeClose">
        <SavingIndicator ref="savingIndicator" />

        <DropdownMenu
          v-if="!hideModalActions"
          :items="modalActions"
          :offset="[0, 0]"
          :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
          data-testid="modal-actions"
          preferred-position="bottom-end"
          @item-click="onMenuActionsClick"
        >
          <template #activator>
            <AppButton icon="more-next" size="sm" type="subtle" />
          </template>
        </DropdownMenu>
      </div>
    </template>

    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>

    <template #modal-body>
      <div v-if="userHasUpdateAccess" class="mggm-TaNav">
        <AppRadioGroup
          :model-value="currentTab"
          :options="Object.values(TABS)"
          data-testid="manage-group-tabs"
          name="manage-group-tabs"
          type="tab-like"
          @update:model-value="onTabChange"
        />
      </div>

      <template v-if="isUsersTabActive">
        <WorkspacesAffectMessage :workspaces="group.workspaces" class="mggm-AffectMessage" />
        <div v-if="!isNoUsers" class="mggm-Toolbar" data-testid="modal-toolbar">
          <!--          <OkrFilterSelect
            :bottom-fixed-items="
              getFilterBottomItems({
                filtersValues: filtersValues,
                filterKey: ACCOUNT_IDS
              })
            "
            :dropdown-min-width="200"
            :loading="isGroupLoading && !isUsersFilterLoaded"
            :model-value="filtersValues[ACCOUNT_IDS]"
            :options="usersFilterOptions"
            :search-function="getUsersForFilter"
            data-testid="users-filter-select"
            has-only-this-button
            item-value="accountId"
            n-selected-label="common.users"
            prepend-icon="user-next"
            @update:model-value="onSelectFilterValue($event, ACCOUNT_IDS)"
            @update:options="usersFilterOptions = $event"
          >
            <template #option-label="{ option }">
              <OwnerFieldOption :option="option" label-key="name" />
            </template>
            <template #bottom-fixed-items="{ bottomFixedItems }">
              <div v-for="item in bottomFixedItems" :key="item.id">
                <BottomFixedSelectItem
                  v-if="isClearSelectionAction(item.action)"
                  @click="clearSelection(ACCOUNT_IDS)"
                >
                  {{ $t(item.text) }}
                </BottomFixedSelectItem>
              </div>
            </template>
          </OkrFilterSelect>-->

          <AppSearch
            v-model="filtersValues[REQUEST_ENTITY_KEYS.SEARCH_STRING]"
            :placeholder="t('placeholder.search_user')"
            :width="160"
            data-auto-testid="users-search"
            data-testid="users-search"
            @update:model-value="onSearchStringChange"
          />

          <AppButton
            v-tippy="{
              ...actionsTooltipOptions
            }"
            :disable="!userHasUpdateAccess"
            data-testid="toolbar-add-users-button"
            height="24"
            icon="plus-next"
            type="primary-next"
            width="24"
            @click="showAddUsersToGroupModal"
          >
            {{ $t('users.create') }}
          </AppButton>
        </div>

        <div class="mggm-TableWrapper">
          <SettingsPagesEmptyState
            v-if="isNoUsers"
            data-testid="empty-state"
            hero-height="128"
            hero-icon="no-users-hero"
            hero-width="142"
          >
            <template #title> {{ $t('global_groups.no_users.title') }} </template>
            <template #subtitle> {{ $t('global_groups.no_users.subtitle') }} </template>

            <template #action>
              <AppButton
                v-tippy="{
                  ...actionsTooltipOptions,
                  placement: 'top'
                }"
                :disable="!userHasUpdateAccess"
                data-testid="empty-state-add-users-button"
                height="24"
                icon="plus-next"
                type="primary-next"
                width="24"
                @click="showAddUsersToGroupModal"
              >
                {{ $t('users.create') }}
              </AppButton>
            </template>
          </SettingsPagesEmptyState>
          <AppTable
            v-else
            :columns="COLUMNS"
            :data="categories"
            :disable-user-select="false"
            :loading="isGroupLoading"
            data-testid="users-table"
            no-border
            offset-left="var(--page-left-padding-next)"
            offset-right="var(--page-right-padding-next)"
            sticky-header
            type="primary-next"
          >
            <template #header-cell="{ column }">
              <div
                v-if="column.title"
                :class="{
                  [`mggm-HeadCell-${column.key}`]: true
                }"
                class="mggm-HeadCell"
              >
                <template v-if="column.key === TABLE_COLUMNS_KEYS.NAME">
                  <div class="mggm-CheckboxWrapper">
                    <AppCheckbox
                      v-model="allUsers"
                      :disabled="
                        isGroupLoading ||
                        isEmpty(usersAvailableToAdd) ||
                        isRemoveUsersLoading ||
                        isDeleteUserLoading
                      "
                      full-height
                      full-width
                    />
                  </div>

                  {{ $t(column.title) }}
                </template>
                <template v-else-if="column.key === TABLE_COLUMNS_KEYS.LINKED_GROUPS">
                  {{ $t(column.title, { platform: $t('app.platform.jira') }) }}

                  <AppIcon
                    v-tippy="{
                      content: $t('workspaces.groups_tab.linked_groups.tooltip'),
                      placement: 'top'
                    }"
                    height="24"
                    icon-name="info-next"
                    width="24"
                  />
                </template>

                <template v-else>
                  {{ $t(column.title) }}
                </template>
              </div>
            </template>

            <template #row="{ columns, columnStyles, rowStyles, row }">
              <div
                :class="{
                  'mggm-CategoryRow-hovered': tableHoverRow === row.id
                }"
                :data-testid="`row-category-${row.id}`"
                :style="rowStyles"
                class="mggm-CategoryRow"
              >
                <div
                  v-for="column in columns"
                  :key="column.key"
                  :class="{
                    [`mggm-CategoryRow_Cell-${column.key}`]: true
                  }"
                  :style="columnStyles[column.key]"
                  class="mggm-CategoryRow_Cell"
                >
                  <GroupNameCell
                    v-if="column.key === TABLE_COLUMNS_KEYS.NAME"
                    :hide-count="isFiltersUsed"
                    hide-icon
                    style="--label-color: var(--dark-3)"
                  >
                    {{ row[column.key] }}

                    <template #count>
                      {{ row.count }}
                    </template>
                  </GroupNameCell>

                  <template v-if="column.key === TABLE_COLUMNS_KEYS.SYNC && row.withSyncIcon">
                    <AppIcon height="24" icon-name="replace_jira" width="24" />
                  </template>

                  <template
                    v-if="column.key === TABLE_COLUMNS_KEYS.LINKED_GROUPS && row.linkedGroups"
                  >
                    <CellSelectItemsList
                      v-if="!isEmpty(row.linkedGroups)"
                      :items="row.linkedGroups"
                      item-value="name"
                      user-has-read-access
                      user-has-update-access
                    />
                  </template>

                  <template
                    v-if="
                      column.key === TABLE_COLUMNS_KEYS.ACTIONS &&
                      row.id === DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id
                    "
                  >
                    <DropdownMenu
                      v-if="isShowSyncedUsersCategoryActions"
                      :items="PLATFORM_GROUPS_MENU_ITEMS"
                      :offset="[0, 0]"
                      :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                      data-testid="category-row-dropdown-menu"
                      preferred-position="bottom-end"
                      @close="tableHoverRow = null"
                      @open="tableHoverRow = row.id"
                      @item-click="onMenuActionsClick($event)"
                    >
                      <template #activator>
                        <AppButton icon="more-next" size="sm" type="tertiary-next" />
                      </template>
                    </DropdownMenu>

                    <AppButton
                      v-else
                      v-tippy="{
                        ...syncedUsersActionsTooltipOptions
                      }"
                      disable
                      icon="more-next"
                      size="sm"
                      type="tertiary-next"
                    />
                  </template>
                </div>
              </div>

              <div
                v-for="user in listState[row.id].users"
                :key="user.accountId"
                :style="rowStyles"
                class="mggm-UserRow"
              >
                <div
                  v-for="column in columns"
                  :key="column.key"
                  :class="{
                    [`mggm-UserRow_Cell-${column.key}`]: true
                  }"
                  :style="columnStyles[column.key]"
                  class="mggm-UserRow_Cell"
                >
                  <template v-if="column.key === TABLE_COLUMNS_KEYS.NAME">
                    <div class="mggm-CheckboxWrapper">
                      <AppCheckbox
                        v-model="selectedUsers"
                        :data-testid="`user-bulk-checkbox-${row.id}`"
                        :disabled="isCheckboxDisabled(row.id)"
                        :value="user.accountId"
                        full-height
                        full-width
                      />
                    </div>

                    <UserNameCell :item="user" item-label="name" name-as-link />
                  </template>

                  <template v-if="column.key === TABLE_COLUMNS_KEYS.SYNC && row.withSyncIcon">
                    <AppIcon height="24" icon-name="replace_jira" width="24" />
                  </template>

                  <template
                    v-if="
                      column.key === TABLE_COLUMNS_KEYS.LINKED_GROUPS &&
                      row.id === DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id
                    "
                  >
                    {{ $t('workspaces.linked_user_message') }}
                  </template>

                  <template v-if="column.key === TABLE_COLUMNS_KEYS.ACTIONS">
                    <AppButton
                      v-if="row.id === DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id"
                      v-tippy="{
                        theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.TEXT_CENTER}`,
                        content: $t('workspaces.linked_user_message'),
                        placement: 'top-start'
                      }"
                      data-testid="synced-user-delete-button"
                      disable
                      icon="delete-next"
                      size="sm"
                      type="tertiary-next"
                    />

                    <AppButton
                      v-else
                      v-tippy="{
                        ...actionsTooltipOptions
                      }"
                      :disable="!userHasUpdateAccess"
                      :icon="isDeleteUserLoading ? null : 'delete-next'"
                      :loading="isDeleteUserLoading"
                      data-testid="not-synced-user-delete-button"
                      size="sm"
                      type="tertiary-next"
                      @click="onDeleteUserClick(user)"
                    />
                  </template>
                </div>
              </div>

              <WorkspaceGroupsNestedUsersLoader
                v-if="listState[row.id].isLoading"
                style="--offset: 32px"
                with-checkbox
              />

              <AppLoadMoreButton
                v-if="isShowLoadMoreButton(row.id)"
                class="mggm-LoadMoreButton"
                @click="loadMoreUsers(row.id)"
              />
            </template>
            <template #loading>
              <ManageUsersTableLoader />
            </template>

            <template #footer>
              <NoSearchResults
                v-if="isNoUsersWithFilters"
                data-testid="no-search-results"
                offset-left="var(--page-left-padding-next)"
                offset-right="var(--page-right-padding-next)"
              >
                {{ t('global_groups.no_users_found') }}
              </NoSearchResults>
              <AppTableCreateButton
                v-if="!isGroupLoading"
                v-tippy="{
                  ...actionsTooltipOptions,
                  offset: [40, 8]
                }"
                :disable="!userHasUpdateAccess"
                data-testid="table-add-users-button"
                full-width
                icon-name="plus-next"
                style="--padding-left: var(--page-left-padding-next)"
                @click="showAddUsersToGroupModal"
              >
                {{ $t('users.create') }}
              </AppTableCreateButton>

              <AppSnackbar
                :actions="snackbarActions"
                :count="selectedUsers.length"
                data-testid="snackbar"
                inline
                sticky
                style="
                  --padding-left: calc(var(--page-left-padding-next) + 4px);
                  --padding-right: var(--page-right-padding-next);
                  --text-color: var(--dark-2);
                "
                @close="clearCheckboxes"
                @action-click="onSnackbarActionClick"
              />
            </template>
          </AppTable>
        </div>
      </template>

      <template v-else>
        <ManageGlobalGroupSettingsTab
          v-if="userHasUpdateAccess"
          :group="group"
          data-testid="group-settings-tab"
          @group-updated="replaceGroupData"
          @saving-started="onSavingStarted"
          @saving-finished="onSavingFinished"
          @close-modal="onClose"
        />
      </template>
    </template>
  </Modal>

  <GroupsModalsHub
    ref="modalsHubReference"
    :groups="[group]"
    :link-group-tracking-source="EVENT_SOURCES.GROUP_USER_TAB"
    :unlink-group-tracking-source="EVENT_SOURCES.GROUP_USER_TAB"
    @reload-group="reloadGroup"
    @group-deleted="onGroupDeleted"
    @user-deleted="onUserDeleted"
    @child-modal-opened="emit('child-modal-opened')"
    @child-modal-closed="emit('child-modal-closed')"
  />

  <portal to="modal-windows">
    <UserDeleteModal
      :loading="isRemoveUsersLoading"
      :show="showUserBulkDeleteModal"
      :title="bulkDeleteUsersModalTitle"
      data-testid="bulk-user-delete-modal"
      @on-close="closeUserBulkDeleteModal"
      @on-confirm="onBulkDeleteUsers"
    >
      {{ t('confirm.user.message', selectedUsers.length) }}
    </UserDeleteModal>
  </portal>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual, uniqBy } from 'lodash'
import { computed, nextTick, ref, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import UsersApiHandler from '@/api/users'
// import { getFilterBottomItems, isClearSelectionAction } from '@/composables/bottom-fixed-items'
import { useGetDeleteUserName } from '@/composables/plugin-users'
import {
  // EVENT_SECTIONS,
  EVENT_SOURCES,
  // FILTER_LABELS_FOR_TRACKING,
  MODE_NAMES_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  // trackApplySettingsPagesFilterEvent,
  trackForceSyncJiraGroups,
  TRACKING_UNKNOWN,
  trackManageGlobalGroupModalOpenedEvent,
  trackOpenAddUserModalEvent,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { useFilterByAccessLevel } from '@/utils/access-level-filter'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { ENTITIES_ACCESS_LEVELS } from '@/utils/entities-access-levels'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { deferExecutionByCondition, isStringEmpty } from '@/utils/general'
import { userCanDeleteGroup, userCanUpdateGroup } from '@/utils/global-groups'
import { isCrossPlatformAppInjectionKey, isWebAppInjectionKey } from '@/utils/injection-keys'
import { memoizeGetGlobalGroupActionsTooltipOptions } from '@/utils/memoizations'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { objectOrNullProp } from '@/utils/prop-validators'
import {
  //getNewSelectWithSelectAllValue,
  getSelectWithSelectAllApiParameter
} from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'
import { ACCOUNT_ID, DEFAULT_USERS_LIMIT } from '@/utils/workspace-configuration-modal-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

// import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import ManageGlobalGroupSettingsTab from '@/components/global-groups/ManageGlobalGroupSettingsTab'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import GroupNameCell from '@/components/GroupNameCell'
import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import TitleWithAction from '@/components/objectives/TitleWithAction'
// import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
// import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppLoadMoreButton from '@/components/ui/AppLoadMoreButton/AppLoadMoreButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import Modal from '@/components/ui/Modal/Modal'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import AppSearch from '@/components/ui/Search/Search'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import ManageUsersTableLoader from '@/components/ui/SkeletonLoaders/ManageUsersTableLoader'
import WorkspaceGroupsNestedUsersLoader from '@/components/ui/SkeletonLoaders/WorkspaceGroupsNestedUsersLoader'
import AppTable from '@/components/ui/Table/AppTable'
import GroupsModalsHub from '@/components/workspaces/configuration-modal/GroupsModalsHub'
import UserDeleteModal from '@/components/workspaces/UserDeleteModal'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

defineOptions({
  name: 'ManageGlobalGroupModal'
})

const { ACCOUNT_IDS } = USERS_QUERY_KEYS

const { t } = useI18n()

const props = defineProps({
  show: {
    type: Boolean
  },

  groupForManage: {
    required: true,
    validator: v => objectOrNullProp(v)
  },

  hideModalActions: {
    type: Boolean
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const isWebApp = inject(isWebAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const TABS = {
  USERS: {
    value: 'users',
    label: t('common.users')
  },

  SETTINGS: {
    value: 'settings',
    label: t('menu.settings')
  }
}

const currentTab = ref(TABS.USERS.value)

const isUsersTabActive = computed(() => currentTab.value === TABS.USERS.value)

// const currentGroupName = ref(null)

// const groupInfoReference = ref(null)

// useIntersectionObserver(
//   groupInfoReference,
//   ([{ isIntersecting }]) => {
//     currentGroupName.value = isIntersecting ? null : group.value.name
//   },
//   {
//     threshold: 0.99
//   }
// )

const emit = defineEmits({
  'on-close': null,
  'child-modal-opened': null,
  'child-modal-closed': null
})

const DEFAULT_CATEGORIES = {
  USERS: {
    id: 'not-synced-users',
    name: t('common.users')
  },

  USERS_FROM_PLATFORM: {
    id: 'synced-users',
    name: t('workspaces.users_from_platform', { platform: t('app.platform.jira') })
  }
}

const DEFAULT_CATEGORY_STATE = {
  users: [],
  startAt: 0,
  isLoading: false
}

const DEFAULT_LISTS_STATE = {
  ...Object.values(DEFAULT_CATEGORIES).reduce((acc, val) => {
    const { id } = val
    return {
      ...acc,
      [id]: cloneDeep(DEFAULT_CATEGORY_STATE)
    }
  }, {})
}
const listState = ref(cloneDeep(DEFAULT_LISTS_STATE))

const getInitialData = async () => {
  await getGroupInfo()

  await nextTick()

  const usersRequests = categories.value.map(({ id }) => getUsers(id))

  const result = await Promise.all([getUsersForFilter(), ...usersRequests])

  const [usersForFilter] = result

  usersFilterOptions.value = [
    {
      ...ALL_USERS_OPTION
    },
    ...usersForFilter
  ]
}

const isFiltersUsed = computed(() => {
  return (
    !isEqual(filtersValues.value[ACCOUNT_IDS], DEFAULT_VALUE_FOR_FILTER) ||
    filtersValues.value[REQUEST_ENTITY_KEYS.SEARCH_STRING].trim() !== ''
  )
})

const getUsers = async (categoryId, isReload = false) => {
  if (!categoryId) {
    return
  }

  const synced = categoryId === DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id

  const api = new GlobalGroupsApiHandler()
  try {
    const { startAt } = listState.value[categoryId]
    listState.value[categoryId].isLoading = true

    const searchString = filtersValues.value[REQUEST_ENTITY_KEYS.SEARCH_STRING]

    const accountIds = isStringEmpty(searchString)
      ? getSelectWithSelectAllApiParameter([...filtersValues.value[ACCOUNT_IDS]])
      : getSelectWithSelectAllApiParameter(DEFAULT_VALUE_FOR_FILTER)

    const payload = {
      groupId: props.groupForManage.id,
      synced,
      startAt,
      limit: DEFAULT_USERS_LIMIT,
      accountIds,
      searchString: searchString?.trim() || null
    }

    if (isReload) {
      payload.limit = startAt || DEFAULT_USERS_LIMIT
    }

    if (isFiltersUsed.value) {
      payload.limit = 1000
    }

    if (isReload || isFiltersUsed.value) {
      payload.startAt = 0
      listState.value[categoryId].startAt = 0
      listState.value[categoryId].users = []
    }

    const result = await api.getUsersFromGlobalGroup({
      ...payload
    })

    const { total, users } = result

    if (synced) {
      group.value.syncedUserCount = total
    } else {
      group.value.notSyncedUserCount = total
    }

    const enrichedUsers = users.map(user => ({
      ...user,
      currentGroupId: payload.groupId
    }))

    listState.value[categoryId].users = uniqBy(
      [...listState.value[categoryId].users, ...enrichedUsers],
      ACCOUNT_ID
    )

    if (isFiltersUsed.value) {
      listState.value[categoryId].startAt = 0
    } else {
      if (listState.value[categoryId].users.length <= total) {
        listState.value[categoryId].startAt += payload.limit
      }
    }
  } catch (error) {
    handleError({ error })
  } finally {
    listState.value[categoryId].isLoading = false
  }
}

const categories = computed(() => {
  if ((isEmptyUsersLists.value && !isUsersLoading.value) || isGroupLoading.value) {
    return []
  }
  const { associatedPlatformGroups, notSyncedUserCount, syncedUserCount } = group.value
  const result = []
  if (notSyncedUserCount) {
    result.push({
      ...DEFAULT_CATEGORIES.USERS,
      count: notSyncedUserCount
    })
  }

  if (syncedUserCount) {
    result.push({
      ...DEFAULT_CATEGORIES.USERS_FROM_PLATFORM,
      withSyncIcon: !isEmpty(associatedPlatformGroups),
      count: syncedUserCount,
      linkedGroups: associatedPlatformGroups
    })
  }
  return result
})

const tableHoverRow = ref(null)

const store = useStore()

const isJiraConnected = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
)

const INITIAL_COLUMNS = computed(() => {
  return [
    {
      title: 'users.table_header_name',
      key: TABLE_COLUMNS_KEYS.NAME,
      width: 'auto',
      accessLevel:
        ENTITIES_ACCESS_LEVELS.JIRA_APP |
        ENTITIES_ACCESS_LEVELS.WEB_APP |
        ENTITIES_ACCESS_LEVELS.CROSS_PLATFORM_APP
    },
    {
      key: TABLE_COLUMNS_KEYS.SYNC,
      width: 32,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP
    },
    {
      title: 'workspaces.groups_tab.linked_platform_groups',
      key: TABLE_COLUMNS_KEYS.LINKED_GROUPS,
      width: 400,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP
    },
    {
      key: TABLE_COLUMNS_KEYS.ACTIONS,
      width: 24,
      accessLevel:
        ENTITIES_ACCESS_LEVELS.JIRA_APP |
        ENTITIES_ACCESS_LEVELS.WEB_APP |
        ENTITIES_ACCESS_LEVELS.CROSS_PLATFORM_APP
    }
  ].map(column => {
    const accessLevel =
      (isWebApp || isCrossPlatformApp) && isJiraConnected.value
        ? ENTITIES_ACCESS_LEVELS.JIRA_APP |
          ENTITIES_ACCESS_LEVELS.WEB_APP |
          ENTITIES_ACCESS_LEVELS.CROSS_PLATFORM_APP
        : column.accessLevel

    return {
      ...column,
      accessLevel
    }
  })
})

const { getEntitiesForPlatform } = useFilterByAccessLevel()

const COLUMNS = getEntitiesForPlatform({ entities: INITIAL_COLUMNS.value })

const group = ref({})
const isGroupLoading = ref(false)

const getGroupInfo = async () => {
  const api = new GlobalGroupsApiHandler()
  isGroupLoading.value = true
  try {
    const result = await api.getGlobalGroupById({
      groupId: props.groupForManage.id
    })

    group.value = {
      ...result,
      linkedGroups: result.associatedPlatformGroups
    }
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupLoading.value = false
  }
}

const modalActions = computed(() => {
  if (props.hideModalActions) {
    return []
  }
  return [
    {
      name: ACTIONS_KEYS.DELETE,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)',
      group: MENU_ITEMS_GROUPS.REMOVING,
      disabled: !userCanDeleteGroup(group.value),
      tooltipContent:
        (!userCanDeleteGroup(group.value) && t('global_groups.unavailable_group')) || null
    }
  ]
})

const PLATFORM_GROUPS_MENU_ITEMS = [
  {
    name: ACTIONS_KEYS.SYNC_NOW,
    title: 'dropdown.sync_now',
    icon: 'sync-next',
    group: MENU_ITEMS_GROUPS.ACTIONS
  },
  {
    name: ACTIONS_KEYS.LINK_NEW_PLATFORM_GROUP,
    label: t('dropdown.link_new_platform_groups', { platform: t('app.platform.jira') }),
    icon: 'link-task',
    group: MENU_ITEMS_GROUPS.ACTIONS
  },
  {
    name: ACTIONS_KEYS.UNLINK_PLATFORM_GROUP,
    label: t('dropdown.unlink_platform_groups', { platform: t('app.platform.jira') }),
    icon: 'unlink-next',
    color: 'var(--grade-low-color-next)',
    group: MENU_ITEMS_GROUPS.REMOVING
  }
]

const syncGroup = async () => {
  const api = new GlobalGroupsApiHandler()
  try {
    await api.syncGlobalGroup({
      groupId: props.groupForManage.id
    })

    trackForceSyncJiraGroups({
      source: EVENT_SOURCES.GROUP_USER_TAB,
      role: userRoleForTracking.value
    })

    await reloadGroupData()
  } catch (error) {
    handleError({ error })
  }
}

const onMenuActionsClick = async action => {
  if (action === ACTIONS_KEYS.DELETE) {
    modalsHubReference.value.showDeleteGlobalGroupModal({
      group: group.value
    })
  }

  if (action === ACTIONS_KEYS.SYNC_NOW) {
    await syncGroup()
  }

  if (action === ACTIONS_KEYS.LINK_NEW_PLATFORM_GROUP) {
    modalsHubReference.value.showLinkPlatformGroupsModal({
      group: group.value
    })
  }

  if (action === ACTIONS_KEYS.UNLINK_PLATFORM_GROUP) {
    modalsHubReference.value.showUnlinkPlatformGroupsModal({
      group: group.value
    })
  }
}

const isDeleteUserLoading = ref(false)

const onDeleteUserClick = async user => {
  if (isEmpty(user) || !userHasUpdateAccess.value) {
    return
  }

  modalsHubReference.value.showUserDeleteModal({
    user
  })
}

const onUserDeleted = ({ accountId, callBack }) => {
  trackRemoveUserEvent({
    mode: MODE_NAMES_FOR_TRACKING.GROUP,
    source: EVENT_SOURCES.GROUP_USER_TAB
  })
  selectedUsers.value = selectedUsers.value.filter(userAccountId => userAccountId !== accountId)
  Promise.all([getGroupInfo(), getUsers(DEFAULT_CATEGORIES.USERS.id, true)])
  callBack()
}

const loadMoreUsers = async categoryId => {
  await getUsers(categoryId)
}

const isShowLoadMoreButton = categoryId => {
  if (isFiltersUsed.value) {
    return false
  }
  const { users, isLoading } = listState.value[categoryId]

  const { count } = categories.value.find(category => category.id === categoryId)

  return users.length < count && !isLoading
}

const resetState = () => {
  listState.value = cloneDeep(DEFAULT_LISTS_STATE)
  group.value = {}
  selectedUsers.value = []
  currentTab.value = TABS.USERS.value
  usersFilterOptions.value = [
    {
      ...ALL_USERS_OPTION
    }
  ]
  filtersValues.value = {
    [ACCOUNT_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER),
    [REQUEST_ENTITY_KEYS.SEARCH_STRING]: ''
  }
  isUsersFilterLoaded.value = false
}

const reloadGroupData = () => {
  clearCheckboxes()
  const requests = [getGroupInfo(), reloadUsers()]

  Promise.all(requests)
}

const isUsersLoading = computed(() => {
  return Object.values(listState.value).some(list => list.isLoading)
})

const reloadUsers = () => {
  const requests = [
    getUsers(DEFAULT_CATEGORIES.USERS.id, true),
    getUsers(DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id, true)
  ]

  Promise.all(requests)
}

const onClose = () => {
  if (!isSaving.value) {
    emit('on-close', group.value.id)
  }
}

const selectedUsers = ref([])

const isCheckboxDisabled = categoryId => {
  if (categoryId === DEFAULT_CATEGORIES.USERS_FROM_PLATFORM.id) {
    return true
  } else {
    return isGroupLoading.value || listState.value[categoryId].isLoading
  }
}

const clearCheckboxes = () => {
  selectedUsers.value = []
}

const usersAvailableToAdd = computed(() => {
  const { users } = listState.value[DEFAULT_CATEGORIES.USERS.id]

  return users.map(user => user.accountId)
})

const allUsers = computed({
  get: () => {
    if (isEmpty(usersAvailableToAdd.value)) {
      return false
    }
    return isEqual(selectedUsers.value.toSorted(), usersAvailableToAdd.value.toSorted())
  },
  set: value => {
    if (value) {
      selectedUsers.value = [...usersAvailableToAdd.value]
    } else {
      selectedUsers.value = []
    }
  }
})

const showUserBulkDeleteModal = ref(false)

const bulkDeleteUsersModalTitle = computed(() => {
  if (!showUserBulkDeleteModal.value) {
    return ''
  }

  const selectedUsersCount = selectedUsers.value.length

  const deleteUserConfig = {
    name: t('common.users')
  }

  return t(
    'delete_user.title',
    {
      userName: useGetDeleteUserName(
        selectedUsers.value,
        listState.value[DEFAULT_CATEGORIES.USERS.id].users,
        deleteUserConfig
      )
    },
    selectedUsersCount
  )
})
const closeUserBulkDeleteModal = () => {
  showUserBulkDeleteModal.value = false
}
const onSnackbarActionClick = action => {
  if (action === ACTIONS_KEYS.DELETE && userHasUpdateAccess.value) {
    showUserBulkDeleteModal.value = true
  }
}

const isRemoveUsersLoading = ref(false)

const onBulkDeleteUsers = async () => {
  if (isRemoveUsersLoading.value) {
    return
  }
  const api = new GlobalGroupsApiHandler()
  try {
    isRemoveUsersLoading.value = true
    await api.removeUsersFromGlobalGroup({
      groupId: props.groupForManage.id,
      accountIds: selectedUsers.value
    })

    trackRemoveUserEvent({
      mode: MODE_NAMES_FOR_TRACKING.GROUP,
      source: EVENT_SOURCES.GROUP_USER_TAB
    })

    clearCheckboxes()
    Promise.all([getGroupInfo(), getUsers(DEFAULT_CATEGORIES.USERS.id, true)])
    closeUserBulkDeleteModal()
  } catch (error) {
    handleError({ error })
  } finally {
    isRemoveUsersLoading.value = false
  }
}

const snackbarActions = computed(() => {
  return [
    {
      name: ACTIONS_KEYS.DELETE,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)',
      loading: isRemoveUsersLoading.value,
      disable: !userHasUpdateAccess.value,
      tooltipContent: actionsTooltipOptions.value.content
    }
  ]
})

const isNoUsers = computed(() => {
  return isNoUsersWithFilters.value && !isFiltersUsed.value
})

const isNoUsersWithFilters = computed(() => {
  if (isGroupLoading.value || isUsersLoading.value) {
    return false
  }
  return isEmpty(categories.value)
})

const isEmptyUsersLists = computed(() => {
  const isNoUsers = Object.values(listState.value).every(list => {
    return isEmpty(list.users)
  })

  return isNoUsers && isFiltersUsed.value
})

const filtersValues = ref({
  [ACCOUNT_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER),
  [REQUEST_ENTITY_KEYS.SEARCH_STRING]: ''
})

const onSearchStringChange = async () => {
  await reloadUsers()
}

const ALL_USERS_OPTION = {
  name: t('filter.all_users'),
  accountId: SELECT_ALL_VALUE
}

const usersFilterOptions = ref([
  {
    ...ALL_USERS_OPTION
  }
])

// const clearSelection = async filterKey => {
//   if (filterKey) {
//     filtersValues.value[filterKey] = cloneDeep(DEFAULT_VALUE_FOR_FILTER)
//   }
//
//   await reloadUsers()
// }
// const onSelectFilterValue = async (newValue, filterKey) => {
//   if (filterKey) {
//     filtersValues.value[filterKey] = getNewSelectWithSelectAllValue(
//       newValue,
//       filtersValues.value[filterKey]
//     )
//
//     trackApplySettingsPagesFilterEvent({
//       section: EVENT_SECTIONS.GROUP_MODAL,
//       tab: TAB_NAMES_FOR_TRACKING.USERS,
//       label: FILTER_LABELS_FOR_TRACKING[filterKey]
//     })
//
//     await reloadUsers()
//   }
// }

const isUsersFilterLoaded = ref(false)
const getUsersForFilter = async (searchString = null) => {
  const api = new UsersApiHandler()

  try {
    const users = await api.getUserFilter({
      searchString
    })

    if (!isUsersFilterLoaded.value) {
      isUsersFilterLoaded.value = true
    }

    return users
  } catch (error) {
    handleError({ error })
  }
}

const userHasUpdateAccess = computed(() => {
  return userCanUpdateGroup(group.value)
})

const actionsTooltipOptions = computed(() => {
  return memoizeGetGlobalGroupActionsTooltipOptions({
    group: group.value,
    t
  })
})

const modalsHubReference = ref(null)

const showAddUsersToGroupModal = () => {
  if (userHasUpdateAccess.value) {
    modalsHubReference.value.showAddUsersToGroupModal({
      group: group.value
    })

    trackOpenAddUserModalEvent({
      role: userRoleForTracking.value,
      source: EVENT_SOURCES.GROUP_USER_TAB,
      mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
      tab: TAB_NAMES_FOR_TRACKING.SINGLE
    })
  }
}

const reloadGroup = ({ callBack }) => {
  reloadGroupData()
  callBack()
}

const replaceGroupData = newData => {
  const { name, icon, workspaces, parentId, permissions, hidden, color } = newData

  group.value = {
    ...group.value,
    name,
    icon,
    workspaces,
    parentId,
    permissions,
    hidden,
    color
  }
}

const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)
const isSaving = ref(false)

const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
}

const onGroupDeleted = async ({ callBack }) => {
  callBack()
  onClose()
}

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

watch(
  () => props.show,
  async newValue => {
    if (newValue) {
      await getInitialData()
      await nextTick()
      trackManageGlobalGroupModalOpenedEvent({
        source: props.trackingSource,
        tab: TABS.USERS.value,
        role: userRoleForTracking.value,
        workspaces: group.value.workspaces.length
      })
    } else {
      resetState()
    }
  }
)

const onTabChange = async tab => {
  await deferExecutionByCondition({
    condition: () => isSaving.value
  })
  currentTab.value = tab
  trackManageGlobalGroupModalOpenedEvent({
    source: EVENT_SOURCES.GROUP_TAB_MENU,
    tab,
    role: userRoleForTracking.value,
    workspaces: group.value.workspaces.length
  })
}

const openSettings = () => {
  if (userHasUpdateAccess.value) {
    onTabChange(TABS.SETTINGS.value)
  }
}

const isShowSyncedUsersCategoryActions = computed(() => {
  if (isWebApp || isCrossPlatformApp) {
    return false
  }
  return userHasUpdateAccess.value
})

const syncedUsersActionsTooltipOptions = computed(() => {
  if (isWebApp || isCrossPlatformApp) {
    return {
      ...actionsTooltipOptions.value,
      content: t('global_groups.available_only_in_jira')
    }
  }

  return actionsTooltipOptions.value
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.mggm-Modal {
  --modal-header-padding: 32px 40px 10px;
  &:deep(.o-modal-body) {
    padding: 0;
  }

  :deep(.o-modal-content) {
    height: auto;
  }
}

.mggm-HeaderGroup {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  font-family: $system-ui;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$modal-header-height: 82px; // size that rendered in DOM

$modal-body-toolbar-height: 60px; // size that rendered in DOM

// .mggm-GroupInfo {
//   display: grid;
//   gap: 12px;
//   padding: 0 $page-right-padding-next 16px $page-left-padding-next;
// }

.mggm-Toolbar {
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;
  padding: 16px $page-right-padding-next 12px $page-left-padding-next;
  display: flex;
  align-items: center;
  position: sticky;
  justify-content: space-between;
  z-index: 5;
  top: $modal-header-height;
  background-color: $white;
}

.mggm-TableWrapper {
  --sticky-top: calc(#{$modal-header-height} + #{$modal-body-toolbar-height});
  padding: 0 $page-right-padding-next 0 $page-left-padding-next;
  --head-padding-top: 12px;
  --head-padding-bottom: 4px;

  --expand-collapse-cell-width: calc(
    var(--okr-table-row-gap, 8px) + var(--okr-table-row-controls-button-width, 24px)
  );

  :deep(.tb-Table-primary-next.tb-Table-with-offset .tb-RowWrapper) {
    padding-bottom: 0;
  }

  :deep(.tb-RowWrapper) {
    &:hover {
      background-color: initial;
    }

    padding-bottom: 0;
    &:after {
      display: none;
    }
  }

  :deep(.tb-RowWrapper-hovered) {
    background-color: initial;
  }
}

.mggm-HeadCell {
  min-height: 24px;
  display: flex;
  align-items: center;

  &-name {
    gap: 8px;
  }
}

.mggm-CategoryRow_Cell,
.mggm-UserRow_Cell {
  overflow: hidden;
}

.mggm-CategoryRow {
  background: $white;
  position: sticky;
  z-index: 1;
  top: calc(#{$modal-header-height} + #{$modal-body-toolbar-height} + 40px);
  height: 46px;
  padding-bottom: 2px;

  &:after {
    height: 2px;

    @include tableLinesGradient(
      $white,
      $grey-2-next,
      calc(#{$page-left-padding-next}),
      $page-right-padding-next
    );
  }
}

.mggm-UserRow {
  position: relative;
  height: 45px;
  padding-bottom: 1px;

  &:after {
    height: 1px;

    @include tableLinesGradient(
      $white,
      $grey-2-next,
      calc(#{$page-left-padding-next} + var(--expand-collapse-cell-width)),
      $page-right-padding-next
    );
  }
}

.mggm-CategoryRow_Cell-sync,
.mggm-UserRow_Cell-sync {
  display: flex;
  align-items: center;
}
.mggm-CategoryRow,
.mggm-UserRow {
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &:hover,
  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-hovered {
    background-color: $grey-3-next;
  }
}

.mggm-LoadMoreButton {
  min-height: 44px;
  margin-left: $page-left-padding-next;
  width: calc(100% - #{$page-right-padding-next} - #{$page-left-padding-next});
  justify-content: flex-start;
  border-bottom: 1px solid $grey-2-next;
  border-radius: 0;
}

.mggm-UserRow_Cell-name {
  display: flex;
  align-items: center;
  padding-right: 4px;
  gap: 8px;
}

.mggm-CheckboxWrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  --label-justify-content: center;
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
%hidden-items {
  .mggm-UserRow &,
  .mggm-CategoryRow & {
    visibility: hidden;
  }

  .mggm-UserRow:hover &,
  .mggm-UserRow-hovered &,
  .mggm-CategoryRow:hover &,
  .mggm-CategoryRow-hovered & {
    visibility: visible;
  }
}

.mggm-UserRow_Cell-linkedGroups {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;

  color: $dark-3;
}

.mggm-CategoryRow_Cell-linkedGroups {
  display: flex;
  padding-right: 8px;
}

.mggm-UserRow_Cell-sync,
.mggm-UserRow_Cell-linkedGroups,
.mggm-CategoryRow_Cell-actions,
.mggm-UserRow_Cell-actions {
  @extend %hidden-items;
}

.mggm-TaNav {
  padding: 16px $page-right-padding-next 8px $page-left-padding-next;
}

.mggm-AffectMessage {
  padding: 8px $page-right-padding-next 8px $page-left-padding-next;
}

.mggm-BeforeClose {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
