<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :size="tab === ADD_USER_TAB ? 500 : 680"
    :title="t('users.add_to_group', usersToAdd.length)"
    class="autgm-Modal"
    hide-hero
    @on-close="onClose(false)"
  >
    <AppRadioGroup
      v-if="needShowSwitcher"
      v-model="tab"
      :options="TAB_OPTIONS"
      class="autgm-RadioGroup"
      name="add-users-tab"
      style="--option-padding: 0 8px 0 0"
      type="primary-next"
      @update:model-value="onTabChange"
    >
      <template #item-label="{ item }">
        <AppRadioGroupNextOptionWithIcon :option="item" />
      </template>
    </AppRadioGroup>

    <div v-show="tab === ADD_USER_TAB" class="autgm-Head">
      <div class="autgm-Head_Cell autgm-Head_CellWs">
        {{ userSelectLabel }}
      </div>
      <div class="autgm-Head_Cell">
        {{ $t('objective.select_group') }}
      </div>
    </div>
    <div ref="fieldSetsReference" class="autgm-ModalBody">
      <div v-show="tab === ADD_USER_TAB" class="autgm-Fields">
        <AddUsersAndGroupFieldGroup
          v-for="item in usersToAdd"
          :key="item.id"
          v-model:users-to-add="usersToAdd"
          :item="item"
          :show-clear-button="isShowClearButton"
          @remove-item="onRemoveItem"
        />
      </div>
      <div v-show="tab === BULK_ADD_TAB" class="autgm-Fields">
        <AddGlobalGroupsFieldGroup v-model:groups-to-add="groupsToAdd" />
      </div>
      <AppTableCreateButton
        v-if="isShowAddButton"
        class="autgm-AddAnother"
        data-testid="add-another-user"
        icon-name="plus-next"
        no-hover
        no-padding
        @click="onAddItemClick"
      >
        {{ $t('users.add_another') }}
      </AppTableCreateButton>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose(false)">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton :disable="isLoading" :loading="isLoading" type="primary-next" @click="save">
        {{ $t('action.add_user_qty', usersToAdd.length) }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { scrollSmooth } from '@/composables/visualEffects'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent,
  trackAddUserEventBulk,
  trackOpenAddUserModalEvent
} from '@/tracking/amplitude-helpers'
import {
  ADD_USER_TAB,
  BULK_ADD_TAB,
  useBulkOperationSwitcher
} from '@/utils/bulk-operation-switcher'
import { REQUEST_ENTITY_KEYS as RQUEST_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { uid } from '@/utils/uid'
import {
  createPayloadForAccountIdsAndEmails,
  unifyEmailsPayloadForGroup,
  useEmailsInSelect,
  USER_IDENTIFIER_VALID_KEY
} from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AddGlobalGroupsFieldGroup from '@/views/workspaces/settings/plugin-users/AddGlobalGroupsFieldGroup'
import AddUsersAndGroupFieldGroup from '@/views/workspaces/settings/plugin-users/AddUsersAndGroupFieldGroup'

defineOptions({
  name: 'AddUsersToGroupModal'
})

const { userSelectLabel } = useEmailsInSelect()

const DEFAULT_GROUPS_TO_ADD = {
  keepSynced: null,
  sourcePlatformGroupIds: [],
  targetPluginGroupId: null,
  workspaceId: null,
  groupValid: true,
  sourcePlatformGroupsValid: true,
  targetPluginGroupsValid: true,
  keepSyncedValid: true
}

const areDataChanged = computed(() => {
  return (
    usersToAdd.value.length > 1 ||
    usersToAdd.value[0][REQUEST_ENTITY_KEYS.IDENTIFIER] !== null ||
    groupsToAdd.value.keepSynced !== null ||
    groupsToAdd.value.sourcePlatformGroupIds.length > 0 ||
    groupsToAdd.value.targetPluginGroupId
  )
})

const groupsToAdd = ref({
  ...DEFAULT_GROUPS_TO_ADD
})

const show = ref(false)

const DEFAULT_USER_ITEM = {
  [RQUEST_ENTITY_KEYS.IDENTIFIER]: null,
  [USER_IDENTIFIER_VALID_KEY]: true
}
const DEFAULT_GROUP_ITEM = {
  groupIds: [],
  groupValid: true
}
const usersToAdd = ref([
  {
    id: uid(),
    ...DEFAULT_USER_ITEM,
    ...DEFAULT_GROUP_ITEM
  }
])

const emit = defineEmits(['update:show', 'reload-data'])

const onClose = (reloadData = false) => {
  usersToAdd.value = [
    {
      id: uid(),
      ...DEFAULT_USER_ITEM,
      ...DEFAULT_GROUP_ITEM
    }
  ]
  groupsToAdd.value = {
    ...DEFAULT_GROUPS_TO_ADD
  }
  tab.value = ADD_USER_TAB
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const { t } = useI18n()

const tab = ref(ADD_USER_TAB)

const { resolvedOptions: TAB_OPTIONS, needShowSwitcher } = useBulkOperationSwitcher()

const MAX_COUNT = 10
const MIN_COUNT = 1

const isShowClearButton = computed(() => {
  return usersToAdd.value.length > MIN_COUNT
})

const isShowAddButton = computed(() => {
  return usersToAdd.value.length < MAX_COUNT && tab.value === ADD_USER_TAB
})

const fieldSetsReference = ref(null)

const onAddItemClick = () => {
  usersToAdd.value.push({
    id: uid(),
    ...DEFAULT_USER_ITEM,
    ...DEFAULT_GROUP_ITEM
  })

  scrollSmooth(fieldSetsReference)
}

const onRemoveItem = itemId => {
  usersToAdd.value = usersToAdd.value.filter(item => item.id !== itemId)
}

const isLoading = ref(false)

const save = async () => {
  let isValid = true
  if (tab.value === ADD_USER_TAB) {
    usersToAdd.value.forEach(item => {
      item[USER_IDENTIFIER_VALID_KEY] = !!item[REQUEST_ENTITY_KEYS.IDENTIFIER]
      item.groupValid = !!item.groupIds.length

      if (!item[USER_IDENTIFIER_VALID_KEY] || !item.groupValid) {
        isValid = false
      }
    })

    if (isValid) {
      isLoading.value = true

      const { emails, accountIds } = createPayloadForAccountIdsAndEmails({
        selectedItems: usersToAdd.value
      })

      const unifiedEmailsPayload = unifyEmailsPayloadForGroup({ emails })

      const items = [...unifiedEmailsPayload, ...accountIds].map(item => {
        return {
          [REQUEST_ENTITY_KEYS.IDENTIFIER]: item[RQUEST_ENTITY_KEYS.IDENTIFIER],
          [REQUEST_ENTITY_KEYS.GROUP_IDS]: item[REQUEST_ENTITY_KEYS.GROUP_IDS],
          [REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]: item[REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]
        }
      })

      try {
        const api = new GlobalGroupsApiHandler()

        await api.addUsersToGlobalGroups({ items })

        trackAddUserEvent({
          selectedUsers: usersToAdd.value,
          source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB
        })

        onClose(true)
      } catch (error) {
        handleError({ error })
      } finally {
        isLoading.value = false
      }
    }
  } else {
    groupsToAdd.value.sourcePlatformGroupsValid = !!groupsToAdd.value.sourcePlatformGroupIds.length
    groupsToAdd.value.targetPluginGroupsValid = !!groupsToAdd.value.targetPluginGroupId
    groupsToAdd.value.keepSyncedValid = groupsToAdd.value.keepSynced !== null

    if (
      groupsToAdd.value.sourcePlatformGroupsValid &&
      groupsToAdd.value.targetPluginGroupsValid &&
      groupsToAdd.value.keepSyncedValid
    ) {
      isLoading.value = true

      const payload = {
        synced: groupsToAdd.value.keepSynced,
        platformGroupIds: groupsToAdd.value.sourcePlatformGroupIds,
        id: groupsToAdd.value.targetPluginGroupId
      }

      try {
        const api = new GlobalGroupsApiHandler()

        await api.updateGlobalGroup(payload)

        trackAddUserEventBulk({
          synced: groupsToAdd.value.keepSynced,
          source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB
        })

        onClose(true)
      } catch (error) {
        handleError({ error })
      } finally {
        isLoading.value = false
      }
    }
  }
}

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const onTabChange = value => {
  const tab = TAB_OPTIONS.find(item => item.value === value).trackingName
  trackOpenAddUserModalEvent({
    tab,
    source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
    role: userRoleForTracking.value
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.autgm-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px - 32px - 20px
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height; 32px is radioGroup height
  padding: 0 40px;
  @include styled-native-scrollbar();
}

.autgm-Head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 40px 6px;
}

.autgm-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  &.autgm-Head_CellWs {
    width: 200px;
  }
}
.autgm-Fields {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 0 4px 0;
}
.autgm-RadioGroup {
  margin: 0 40px 20px;
}
.autgm-AddAnother {
  margin: 20px 0 0 0;
}
</style>

<style lang="scss">
.autgm-Modal {
  .ad-Content_Title {
    padding: 0 40px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }
  // --footer-padding-top: 0;
  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
</style>
