<template>
  <AppDialog
    :disable-confirm-button="isLoading"
    :show="showConfirmDeleteDialog"
    :title="$t('action.remove')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirmDeleteDashboard"
    @on-close="closeConfirmDeleteDialog"
  >
    <span class="rcd-Description">
      {{
        $t('action.remove_from_dashboard', {
          elementName: elementForDelete.name,
          customDashboardName
        })
      }}
    </span>
    <template #confirm-btn-text>
      {{ $t('action.delete') }}
    </template>
  </AppDialog>
</template>

<script setup>
import { ref } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'

const props = defineProps({
  showConfirmDeleteDialog: {
    type: Boolean
  },

  elementForDelete: {
    type: Object,
    default: () => ({})
  },

  customDashboardName: {
    type: String,
    default: ''
  }
})
const isLoading = ref(false)

const emit = defineEmits(['close', 'confirm', 'on-success'])

const closeConfirmDeleteDialog = () => {
  emit('close')
}
const onConfirmDeleteDashboard = async () => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    const { id, dashboardId } = props.elementForDelete
    isLoading.value = true
    await customDashboardApi.removeDashboardElement({
      elementId: id,
      dashboardId
    })
    closeConfirmDeleteDialog()
    emit('on-success')
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.rcd-Description {
  word-break: break-word;
}
</style>
