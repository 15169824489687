<template>
  <div class="dm-Module">
    <FormFieldNext v-if="isWebApp || isCrossPlatformApp" :label="t('connections.connected_with')">
      <div
        :class="{
          'dm-InstanceInfo-error': isConnectionLost
        }"
        class="dm-InstanceInfo"
      >
        <AppIcon class="dm-InstanceInfo_Icon" height="24" icon-name="platform-jira" width="24" />
        <span class="oboard-truncated-text">{{ connectedInstanceName }}</span>
        <AppIcon
          v-if="isConnectionLost"
          class="dm-InstanceInfo_Icon dm-InstanceInfo_Icon-append"
          height="24"
          icon-name="info-error"
          width="24"
        />
      </div>

      <AppFieldError v-if="isConnectionLost" :show="isConnectionLost" class="dm-FieldError">
        {{ t('integration_settings.connection_lost') }}
      </AppFieldError>
    </FormFieldNext>

    <div v-if="isJiraApp" class="dm-Module_message">
      {{ t('integration_settings.jira.description') }}
    </div>

    <AppButton
      :loading="isLoading"
      class="dm-Button"
      icon="close-common"
      type="danger"
      @click="onDisconnect"
    >
      {{ t('action.disconnect') }}
    </AppButton>
  </div>

  <portal to="modal-windows">
    <AppDialog
      :loading="isDisconnectLoading"
      :show="showDisconnectModal"
      :title="t('disconnect_modal.title')"
      :type="DIALOG_TYPES.NOT_ALLOWED"
      @on-confirm="onConfirm"
      @on-close="onClose"
    >
      <div class="dm-DisconnectMessage">
        <p class="dm-DisconnectMessage_Title">{{ t('disconnect_modal.description') }}:</p>
        <ul class="dm-DisconnectMessage_List">
          <li v-for="(n, index) in 3" :key="n" class="dm-DisconnectMessageListItem">
            {{ t(`disconnect_modal_events_list[${index}]`) }}
          </li>
        </ul>
      </div>
      <KeywordInput ref="keywordInputReference" :keyword="KEYWORD" />

      <template #confirm-btn-text> {{ t('action.disconnect') }}</template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

defineOptions({
  name: 'DisconnectModule'
})

defineProps({
  isLoading: {
    type: Boolean
  },

  isConnectionLost: {
    type: Boolean
  }
})

const { t } = useI18n()

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const showDisconnectModal = ref(false)

const KEYWORD = 'DISCONNECT'

const isDisconnectLoading = ref(false)

const onClose = () => {
  showDisconnectModal.value = false
}

const emit = defineEmits({
  'on-disconnect-confirm': null
})

const keywordInputReference = ref(null)

const onConfirm = async () => {
  const isKeywordValid = keywordInputReference.value.validate()

  if (!isKeywordValid) {
    return
  }

  emit('on-disconnect-confirm')
  onClose()
}

const onDisconnect = () => {
  showDisconnectModal.value = true
}

const store = useStore()
const connectedInstanceName = computed(() => {
  return store.getters['pluginOptions/getAtlassianInstanceName']
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/integrations-steps';

.dm-Module {
  max-width: 100%;
  overflow: hidden;
  --skeleton-width: 100%;
}

.dm-Module_message {
  @extend %step-description-styles;
}

.dm-InstanceInfo {
  height: 40px;
  width: 100%;
  border-radius: $border-radius-sm-next;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  color: $dark-1;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;

  &:not(&-error) {
    background-color: $grey-3-next;
  }

  &-error {
    background-color: rgba($grade-low-color-next, 0.15);
  }
}

.dm-InstanceInfo_Icon {
  flex-shrink: 0;

  &-append {
    margin-left: auto;
  }
}

.dm-Button {
  margin-top: 20px;
  align-self: flex-start;
}

.dm-FieldError {
  line-height: 20px;
}

.dm-DisconnectMessage {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
}

.dm-DisconnectMessage_Title {
  margin-bottom: 0;
}

.dm-DisconnectMessage_List {
  font-weight: fw('semi-bold');
  list-style: none;
  margin-left: 10px;
  margin-bottom: 20px;
}

.dm-DisconnectMessageListItem {
  padding-left: 13px;
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
