<template>
  <AppDialog
    :disable-confirm-button="!name.trim()"
    :loading-confirm-button="isLoading"
    :show="show"
    :title="$t('dashboard.duplicate_dashboard')"
    class="dcd-Modal"
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    <template #confirm-btn-text>
      {{ $t('dropdown.copy') }}
    </template>
    <div class="dcd-ContentWrapper">
      <FormFieldNext :label="$t('dashboard.dashboard_name')">
        <AppInput
          v-model="name"
          :max-length="CUSTOM_DASHBOARD_NAME_MAX_LENGTH"
          :placeholder="$t('dashboard.custom_dashboard_name_placeholder')"
          size="xlg"
          style-type="primary"
        />
      </FormFieldNext>
      <AppCheckbox v-model="includeShare" value="sortChildren">
        {{ $t('dashboard.share_with_same_people') }}
      </AppCheckbox>
    </div>
  </AppDialog>
</template>

<script setup>
import { ref, watch } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { CUSTOM_DASHBOARD_NAME_MAX_LENGTH } from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppInput from '@/components/ui/AppInput/AppInput'

const props = defineProps({
  show: {
    type: Boolean
  },
  dashboardToDuplicate: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['update:show', 'confirm'])

const name = ref('')
const includeShare = ref(false)
const onClose = () => {
  emit('update:show', false)
}

watch(
  () => props.show,
  value => {
    if (!value) {
      name.value = ''
      includeShare.value = false
    }
  }
)

const isLoading = ref(false)
const onConfirm = async () => {
  try {
    isLoading.value = true
    const customDashboardApi = new CustomDashboardApiHandler()
    const { id } = props.dashboardToDuplicate

    await customDashboardApi.duplicateDashboard({
      dashboardId: id,
      newName: name.value,
      includeShare: includeShare.value
    })

    const mode = includeShare.value ? 'shared' : 'private'

    tracker.logEvent('custom dashboard duplicated', {
      category: EVENT_CATEGORIES.DASHBOARD,
      id,
      mode
    })

    emit('update:show', false)
    emit('confirm')
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.dcd-Modal {
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
.dcd-ContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
