import dayjs from 'dayjs'
import { ref } from 'vue'

import AuditLogsApiHandler from '@/api/audit-logs'
import { dateFormat } from '@/utils/date'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { DEFAULT_LIMIT } from '@/utils/global-groups'

export const AUDIT_LOG_TYPE_IDS = {
  GROUPS: 'groups',
  ELEMENTS: 'elements'
}

export const formatDateForAuditLog = date => {
  return dayjs(date).utc().format(`HH:mm (UTC), ${dateFormat}`)
}

export const useAuditLogs = ({ filtersValues = {} } = {}) => {
  const DEFAULT_LIST_STATE = {
    isLoading: false,
    [REQUEST_ENTITY_KEYS.START_AT]: 0,
    isAllItemsLoaded: false
  }

  const listState = ref({
    ...DEFAULT_LIST_STATE,
    filtersValues: {
      ...filtersValues
    }
  })

  const auditLogData = ref([])

  const resetListState = () => {
    listState.value.isAllItemsLoaded = false
    listState.value[REQUEST_ENTITY_KEYS.START_AT] = 0
  }

  const fetchAuditLog = async ({
    filtersValues = {},
    limit = DEFAULT_LIMIT,
    auditLogTypeId = null
  } = {}) => {
    if (!auditLogTypeId || !Object.values(AUDIT_LOG_TYPE_IDS).includes(auditLogTypeId)) {
      throw new Error('auditLogTypeId missing or invalid')
    }

    if (listState.value.isLoading || listState.value.isAllItemsLoaded) {
      return
    }

    const api = new AuditLogsApiHandler()

    listState.value.isLoading = true

    const requestMethodsByAuditLogType = {
      [AUDIT_LOG_TYPE_IDS.GROUPS]: payload => api.getGroupsAuditLog(payload),
      [AUDIT_LOG_TYPE_IDS.ELEMENTS]: payload => api.getElementsAuditLog(payload)
    }

    try {
      const result = await requestMethodsByAuditLogType[auditLogTypeId]({
        [REQUEST_ENTITY_KEYS.START_AT]: listState.value[REQUEST_ENTITY_KEYS.START_AT],
        ...filtersValues
      })

      if (result.length < limit) {
        listState.value.isAllItemsLoaded = true
      }

      listState.value[REQUEST_ENTITY_KEYS.START_AT] += limit

      auditLogData.value = [...auditLogData.value, ...result]
    } catch (error) {
      handleError({ error })
    } finally {
      listState.value.isLoading = false
    }
  }

  return {
    listState,
    resetListState,
    auditLogData,
    fetchAuditLog
  }
}

export const ELEMENTS_AUDIT_LOG_HISTORY_TYPE_IDS = {
  OBJECTIVE_CREATED: 1,
  KR_CREATED: 14,
  TASK_CREATED: 28,
  OBJECTIVE_REMOVED: 13,
  KR_REMOVED: 27,
  TASK_REMOVED: 41,
  ELEMENT_RESTORED: 82
}
