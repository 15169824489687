<template>
  <div
    :class="{
      'ts-Table': true,
      [`ts-Table-${uid}`]: true,
      'ts-Table-empty': data.length === 0,
      'ts-Table-sticky-header': stickyHeader,
      'ts-Table-dragging': isDragging
    }"
    :style="tableStyles"
  >
    <div :style="headerStyles" class="ts-Header">
      <div
        v-for="column in columns"
        :key="column.key"
        :class="[`ts-HeaderCell-${column.key}`]"
        :style="columnStyles[column.key]"
        class="ts-HeaderCell"
      >
        <slot :column="column" name="header-cell">
          {{ column.title }}
        </slot>
      </div>
    </div>

    <SlickList
      :append-to="`.ts-Table-${uid}`"
      :list="data"
      :lock-offset="['0%', '0%']"
      :transition-duration="200"
      helper-class="ts-RowWrapper-moving"
      lock-axis="y"
      lock-to-container-edges
      use-drag-handle
      @update:list="$emit('update:data', $event)"
      @sort-start="isDragging = true"
      @sort-cancel="isDragging = false"
      @sort-end="onDrop"
    >
      <SlickItem
        v-for="(row, index) in data"
        :key="index"
        :class="{ 'ts-RowWrapper-hovered': hoverRow === index }"
        :index="index"
        class="ts-RowWrapper"
      >
        <slot
          :column-styles="columnStyles"
          :columns="columns"
          :index="index"
          :on-cell-click-self="onCellClickSelf"
          :on-row-click-self="onRowClickSelf"
          :row="row"
          name="row"
        >
          <div
            :data-testid="rowTestId"
            :style="rowStyles"
            class="ts-Row"
            @click.self="onRowClickSelf(row, $event)"
          >
            <div v-handle class="ts-Drag">
              <AppIcon height="24" icon-name="drag-next" width="24" />
            </div>
            <div
              v-for="column in columns"
              :key="column.key"
              :class="[`ts-Cell-${column.key}`, [`ts-Cell-${column.key}-${index}`]]"
              :style="columnStyles[column.key]"
              class="ts-Cell"
              @click.self="onCellClickSelf(row, column, $event)"
            >
              <slot :column-key="column.key" :index="index" :item="row" name="cell">
                <template v-if="isShowCellValue(row[column.key])">
                  {{ row[column.key] }}
                </template>
              </slot>
            </div>
          </div>
        </slot>
      </SlickItem>
    </SlickList>

    <template v-if="loading && !infiniteLoading">
      <slot name="loading">
        <div class="ts-Loading">
          <LoadingCircle size="small" />
        </div>
      </slot>
    </template>
    <InfiniteScrollLoader
      v-if="infiniteLoading"
      :identifier="infiniteId"
      @infinite="$emit('infinite-load', $event)"
    >
      <template #loader>
        <slot name="loading">
          <div class="o-infinite-loading">
            <LoadingCircle size="small" />
          </div>
        </slot>
      </template>

      <template #no-results>
        <slot name="no-results">{{ $t('table.no_items') }}</slot>
      </template>
    </InfiniteScrollLoader>

    <slot name="footer" />

    <div class="ts-EndBlock" />
  </div>
</template>

<script>
import { isBoolean, isNull, isUndefined } from 'lodash'
import { defineComponent } from 'vue'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'

import { handleOrder } from '@/utils/drag-n-drop'
import { CELL_TEXT_ALIGNS } from '@/utils/table-columns'
import { uid } from '@/utils/uid'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import InfiniteScrollLoader from '@/components/ui/InfiniteScrollLoader/InfiniteScrollLoader'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

export default defineComponent({
  name: 'TableSortable',

  components: {
    SlickList,
    SlickItem,
    InfiniteScrollLoader,
    LoadingCircle,
    AppIcon
  },

  directives: {
    handle: HandleDirective
  },

  props: {
    columns: {
      type: Array,
      required: true
    },

    data: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    hoverRow: {
      type: Number,
      default: -1
    },

    infiniteLoading: {
      type: Boolean,
      default: false
    },

    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    stickyHeader: {
      type: Boolean
    },

    rowTestId: {
      type: String,
      default: null
    }
  },

  emits: {
    'update:data': null,
    'infinite-load': null,
    'row-click-self': null,
    'cell-click-self': null,
    drop: null
  },

  data() {
    return {
      uid: uid(),
      infiniteId: +new Date(),
      isDragging: false
    }
  },

  computed: {
    columnStyles() {
      const result = {}
      this.columns.forEach(column => {
        const columnStyles = {}
        if (!column.width || column.width === 'auto') {
          columnStyles.flex = '1 1 auto'
          columnStyles['min-width'] = '0'
        } else {
          columnStyles.flex = `0 0 ${column.width}px`
        }
        const availableTextAligns = Object.values(CELL_TEXT_ALIGNS)

        if (column.textAlign && availableTextAligns.includes(column.textAlign)) {
          columnStyles.textAlign = column.textAlign
        }

        result[column.key] = columnStyles
      })
      return result
    },

    tableStyles() {
      return {
        // negate value(also variable):
        // https://github.com/css-modules/postcss-icss-values/issues/64#issuecomment-241285628
        'margin-left': `calc(${this.offsetLeft} * -1)`,
        'margin-right': `calc(${this.offsetRight} * -1)`
      }
    },

    headerStyles() {
      return {
        'padding-left': this.offsetLeft,
        'padding-right': this.offsetRight,
        '--padding-left': this.offsetLeft,
        '--padding-right': this.offsetRight
      }
    },

    rowStyles() {
      return {
        'padding-left': this.offsetLeft,
        'padding-right': this.offsetRight,
        '--padding-left': this.offsetLeft,
        '--padding-right': this.offsetRight
      }
    }
  },

  methods: {
    isShowCellValue(value) {
      if (isUndefined(value) || isNull(value)) return false

      if (isBoolean(value)) {
        return !!value
      }

      return true
    },

    onRowClickSelf(row) {
      this.$emit('row-click-self', row)
    },

    onCellClickSelf(row, column) {
      this.$emit('cell-click-self', { row, column })
    },

    /** @public */
    updateItems() {
      this.infiniteId += 1
    },

    async onDrop(dropResult) {
      this.isDragging = false
      const { oldIndex, newIndex } = dropResult
      if (newIndex === null || oldIndex === newIndex) {
        return
      }
      const { result, itemToAdd } = this.applyDrag(this.data, oldIndex, newIndex)
      this.$emit('update:data', result)

      // wait for value update
      await this.$nextTick()
      this.onMoved(itemToAdd, oldIndex, newIndex)
    },

    applyDrag(arr, oldIndex, newIndex) {
      if (oldIndex === null && newIndex === null) return arr

      const result = [...arr]
      let itemToAdd = []

      if (oldIndex !== null) {
        // eslint-disable-next-line prefer-destructuring
        itemToAdd = result.splice(oldIndex, 1)[0]
      }

      if (newIndex !== null) {
        result.splice(newIndex, 0, itemToAdd)
      }

      return { result, itemToAdd }
    },

    async onMoved(item, oldIndex, newIndex) {
      const { orderValue, newRealIndex } = handleOrder({
        oldIndex,
        newIndex,
        data: this.data
      })

      this.$emit('drop', { orderValue, item, newIndex, oldIndex, newRealIndex })
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/dnd';

.ts-Table-dragging {
  user-select: none;
}

.ts-Header {
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
  position: relative;
  padding-top: var(--head-padding-top, 0);

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background: $grey-2-next;
    bottom: 0;
    width: calc(100% - var(--padding-left, 0px) - var(--padding-right, 0px));
    left: var(--padding-left, 0);
  }

  .ts-Table-sticky-header & {
    position: sticky;
    top: var(--sticky-top, 0);
    background: $white;
    z-index: var(--sticky-z-index, 1);
  }

  .ts-HeaderCell {
    font-family: $system-ui;
    font-style: normal;
    font-weight: fw('bold');
    font-size: $fs-12;
    line-height: 16px;
    color: $dark-3;
    position: relative;
    z-index: 6;
  }
}

.ts-Row {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ts-Cell {
  flex-shrink: 0;
  min-width: 0;
  color: $dark-1;
}

.ts-RowWrapper {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background: $grey-2-next;
    bottom: 0;
    width: calc(100% - v-bind(offsetLeft) - v-bind(offsetRight));
    left: v-bind(offsetLeft);
  }

  &:hover,
  &-hovered {
    background-color: $grey-3-next;

    .ts-Drag {
      opacity: 1;
    }
  }
}

:deep(.ts-RowWrapper-moving) {
  @extend %grabbing-item-common-styles;
  @extend %grabbing-item-shadow;
  @include grabbingItemBackdrop();

  .ts-Drag {
    opacity: 1;
    cursor: grabbing;
  }
}

.ts-Drag {
  cursor: grab;
  position: absolute;
  width: 24px;
  height: 100%;
  top: 0;
  left: 4px;
  display: flex;
  align-items: center;
  z-index: 10;
  opacity: 0;
  transition: opacity $transition-fast ease;
}

.ts-Loading {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 49px;
  border-bottom: 1px solid $grey-medium;
  margin: 0 40px;
}
</style>
