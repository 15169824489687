import { createI18n } from 'vue-i18n'

import deMessages from '@/locales/de.json'
import enMessages from '@/locales/en.json'
import ptMessages from '@/locales/pt.json'
import ukMessages from '@/locales/uk.json'
import zhMessages from '@/locales/zh.json'

export const FALLBACK_LOCALE = 'en'

export default createI18n({
  legacy: false,
  locale: 'en',
  // flatJson: true, // necessary to support linked messages in  our (non-nested) JSON
  fallbackLocale: FALLBACK_LOCALE,
  messages: {
    en: enMessages,
    zh_CN: zhMessages,
    pt_BR: ptMessages,
    de_DE: deMessages,
    uk: ukMessages
  }
})
