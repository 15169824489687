<template>
  <div :ref="templateRef" class="ni-Item">
    <div class="ni-Item_Head">
      <AppAvatar :avatar-url="displayItems.avatar" no-margins />
      <div class="ni-Item_Action">
        <i18n-t :keypath="titleKeyPath" class="ni-Item_Title" scope="global" tag="span">
          <template #author>
            <span class="ni-ItemTitleAuthor">
              {{ displayItems.author }}
            </span>
          </template>
          <template #element>
            {{ displayItems.element }}
          </template>
        </i18n-t>
        <div v-if="showStatusChanges" class="ni-StatusChanges">
          <ObjectiveStatus
            :status="getStatusNumber(displayItems.statusBefore)"
            class="ni-ObjectiveStatus"
          >
            <template v-if="displayItems[STATUS_BEFORE_LABEL]">
              {{ displayItems[STATUS_BEFORE_LABEL] }}
            </template>
          </ObjectiveStatus>
          <AppIcon
            class="ni-StatusChanges_Arrow"
            height="24"
            icon-name="arrow-right-next"
            width="24"
          />
          <ObjectiveStatus
            :status="getStatusNumber(displayItems.statusAfter)"
            class="ni-ObjectiveStatus"
          >
            <template v-if="displayItems[STATUS_AFTER_LABEL]">
              {{ displayItems[STATUS_AFTER_LABEL] }}
            </template>
          </ObjectiveStatus>
        </div>
        <span v-if="item.timing" class="ni-Item_Ago" data-testid="notification-timing">
          {{ item.timing }}
        </span>
      </div>
      <AppBadge
        v-if="!item.isRead || !isChildRead"
        :half-filled="onlyChildUnread"
        class="ni-Item_Marker"
        data-testid="notification-unread-marker"
        inline
      />
    </div>
    <div v-if="showOkrIcon" class="ni-Item_ElementInfo">
      <OkrIcon :objective="displayItems.okrElement" :size="MD" class="ni-ElementInfo_OkrElement" />

      <ObjectiveId
        :value="displayItems.displayId"
        size="xs"
        style="--id-font-weight: 700"
        @click="onIdClick"
      />

      <div class="ni-Item_Name">
        {{ displayItems.title }}
      </div>
      <!--      <span v-if="getStatusObject" class="ni-StatusObject">-->
      <!--        • {{ $t(getStatusObject.label) }}-->
      <!--      </span>-->
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="showTextBody && displayItems.commentText"
      class="ni-Item_Body"
      v-html="displayItems.commentText"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script setup>
import { isUndefined } from 'lodash'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { SIZES } from '@/utils/components-configurations/okr-icon'
import { STATUS_NAMES_ENTITY_KEYS } from '@/utils/entity-keys'
import { useExternalLinkHelper } from '@/utils/external-link-composables'
import { EXTERNAL_LINK_HANDLER_SOURCES } from '@/utils/external-link-helper'
import { NOTIFICATION_ATTRIBUTES } from '@/utils/in-app-notifications'
import { appPlatformInjectionKey, isCrossPlatformAppInjectionKey } from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { OKR_STATUSES } from '@/utils/objectives'
import { ALL_STATUS_NAMES } from '@/utils/status-names-factory'
import { getFirstName } from '@/utils/users-helpers'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import ObjectiveId from '@/components/objectives/ObjectiveId'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import { useMarkAsRead } from '@/components/ui/InAppNotifications/in-app-notifications-utils'

defineOptions({
  name: 'NotificationEntity'
})

const { MD } = SIZES

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  titleKeyPath: {
    type: String,
    default: 'in_app_notifications.notification_title'
  },

  showOkrIcon: {
    type: Boolean,
    default: true
  },

  showTextBody: {
    type: Boolean,
    default: true
  },

  showStatusChanges: {
    type: Boolean,
    default: false
  },

  isChildRead: {
    type: Boolean
  }
})

const {
  AUTHOR_NAME,
  AUTHOR_AVATAR,
  ELEMENT_TYPE_ID,
  ELEMENT_LEVEL_PREFIX,
  ELEMENT_LEVEL_COLOR,
  ELEMENT_TITLE,
  WORKSPACE_ID,
  ELEMENT_ID,
  ELEMENT_DISPLAY_ID,
  COMMENT_TEXT,
  OBJ_STATUS_BEFORE,
  OBJ_STATUS_AFTER,
  CURRENT_CONFIDENCE_LEVEL,
  CONFIDENCE_LEVEL_CUSTOM_NAME_OLD,
  CONFIDENCE_LEVEL_CUSTOM_NAME_NEW
} = NOTIFICATION_ATTRIBUTES

const STATUS_BEFORE_LABEL = 'statusBeforeLabel'
const STATUS_AFTER_LABEL = 'statusAfterLabel'

const { t } = useI18n()

const isKrComment = computed(
  () => props.item.normalizedAttributes[ELEMENT_TYPE_ID] === OBJECTIVE_TYPES.KR
)

const displayItems = computed(() => {
  const { normalizedAttributes } = props.item

  const author = getFirstName(normalizedAttributes[AUTHOR_NAME])

  return {
    avatar: normalizedAttributes[AUTHOR_AVATAR],
    author,
    element: isKrComment.value ? t('okr_elements.kr') : t('okr_elements.objective'),
    okrElement: {
      typeId: normalizedAttributes[ELEMENT_TYPE_ID],
      levelPrefix: normalizedAttributes[ELEMENT_LEVEL_PREFIX],
      levelColor: normalizedAttributes[ELEMENT_LEVEL_COLOR],
      workspaceId: normalizedAttributes[WORKSPACE_ID],
      id: normalizedAttributes[ELEMENT_ID]
    },
    displayId: normalizedAttributes[ELEMENT_DISPLAY_ID],
    title: normalizedAttributes[ELEMENT_TITLE],
    commentText: normalizedAttributes[COMMENT_TEXT],
    statusBefore: normalizedAttributes[OBJ_STATUS_BEFORE],
    statusAfter: normalizedAttributes[OBJ_STATUS_AFTER],
    statusObject: normalizedAttributes[CURRENT_CONFIDENCE_LEVEL],
    ...getStatusNames()
  }
})

const getStatusNames = () => {
  const { showStatusChanges, item } = props
  const { normalizedAttributes } = item
  const statusLabels = {
    [STATUS_BEFORE_LABEL]: undefined,
    [STATUS_AFTER_LABEL]: undefined
  }

  if (showStatusChanges) {
    const statusBefore = getStatusNumber(normalizedAttributes[OBJ_STATUS_BEFORE])
    const statusBeforeLabel = normalizedAttributes[CONFIDENCE_LEVEL_CUSTOM_NAME_OLD]

    const statusAfter = getStatusNumber(normalizedAttributes[OBJ_STATUS_AFTER])
    const statusAfterLabel = normalizedAttributes[CONFIDENCE_LEVEL_CUSTOM_NAME_NEW]

    const setDefaultLabel = ({ status, statusLabel, labelKey }) => {
      const statusProperties = ALL_STATUS_NAMES.statusProperties

      if (!isUndefined(status) && statusProperties[status]) {
        statusLabels[labelKey] =
          statusLabel || statusProperties[status][STATUS_NAMES_ENTITY_KEYS.DEFAULT_NAME]
      }
    }

    setDefaultLabel({
      status: statusBefore,
      statusLabel: statusBeforeLabel,
      labelKey: STATUS_BEFORE_LABEL
    })
    setDefaultLabel({
      status: statusAfter,
      statusLabel: statusAfterLabel,
      labelKey: STATUS_AFTER_LABEL
    })
  }

  return statusLabels
}

const emit = defineEmits(['mark-as-read', 'update-notifications-statuses'])

const appPlatform = inject(appPlatformInjectionKey)

const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const { handleExternalLink } = useExternalLinkHelper()

const store = useStore()
const route = useRoute()

const OKR_ELEMENT_VIEW_ROUTE_NAMES = [
  ROUTE_NAMES.OKR_ELEMENTS_EXPLORER,
  ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE,
  ROUTE_NAMES.OKR_ELEMENTS_ROADMAP,
  ROUTE_NAMES.OKR_ELEMENTS_MIND_MAP
]

const onIdClick = () => {
  if (isCrossPlatformApp && OKR_ELEMENT_VIEW_ROUTE_NAMES.includes(route.name)) {
    store.dispatch('objectives/setElementForEdit', {
      ...displayItems.value.okrElement
    })
  } else {
    handleExternalLink({
      ...displayItems.value.okrElement,
      source: EXTERNAL_LINK_HANDLER_SOURCES.COMMENT,
      appPlatform,
      navigateByRouter: isCrossPlatformApp,
      saveWorkspaceIdToStore: isCrossPlatformApp
    })
  }

  emit('update-notifications-statuses')
}

const markAsRead = () => {
  emit('mark-as-read', props.item.id)
}

const templateRef = 'wrapperReference'

useMarkAsRead({
  item: props.item,
  callback: markAsRead,
  templateRef
})

const onlyChildUnread = computed(() => props.item.isRead && !props.isChildRead)
// const getStatusObject = computed(() => {
//   return OBJECTIVE_STATUS_ALL_OPTIONS.find(
//     option => +option.value === +displayItems.value.statusObject
//   )
// })

const getStatusNumber = value => (!isNaN(value) ? Number(value) : OKR_STATUSES.AUTO)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_notification-center.scss';
.ni-Item {
  border-radius: $border-radius-sm-next;
  width: 100%;
  position: relative;
  gap: 4px;
  padding: 10px;
  display: grid;
  transition: background-color $transition-fast ease-in-out;

  @media (any-hover: hover) {
    &:hover {
      background-color: $grey-3-next;
    }
  }
}

.ni-Item_Head {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: $notification-head-width;
}

.ni-Item_Body {
  width: 100%;
  max-width: $notification-max-width;
  margin-left: auto;
  padding: 10px;
  background-color: $white;
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-md-next;
}

.ni-Item_Action {
  font-size: $fs-14;
  font-family: $system-ui;
  line-height: 20px;
  width: 100%;
  padding: 2px 0 0 0;
}

.ni-Item_Ago {
  flex: 1 1 auto;
  color: $dark-3;
}

.ni-Item_Title {
  font-style: normal;
  font-weight: fw('regular');
  color: $dark-1;
  margin-right: 8px;
}

.ni-ItemTitleAuthor {
  font-weight: fw('semi-bold');
  color: $dark-2;
  overflow-wrap: break-word;
}

.ni-Item_Marker {
  margin-left: auto;
  --backdrop-size: 24px;
}

.ni-Item_ElementInfo {
  font-family: $system-ui;
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 16px;
  --id-font-weight: 600;
  width: 100%;
  max-width: $notification-max-width;
  margin-left: auto;
}
.ni-ElementInfo_OkrElement {
  margin-right: 4px;
}

.ni-Item_Name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-2;
}
.ni-StatusChanges {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  color: $dark-2;
  gap: 8px;
  // max-width: 200px;
}
//.ni-StatusObject {
//  font-size: $fs-12;
//  font-style: normal;
//  font-weight: fw('regular');
//  line-height: 16px;
//  color: $dark-3;
//  min-width: fit-content;
//}

.ni-StatusChanges_Arrow {
  flex-shrink: 0;
}

.ni-ObjectiveStatus {
  line-height: 16px;
}
</style>
<style lang="scss">
.ni-Item_Body {
  p {
    margin: 0;
  }
  .mention {
    color: $primary-color-next;
    font-size: $fs-14;
    font-weight: fw('semi-bold');
    line-height: 20px;
  }
  ul,
  ol {
    padding: 0 0 0 20px;
    margin: 0;
  }
}
</style>
