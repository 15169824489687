<template>
  <Component
    :is="FILTERS_COMPONENTS_BY_TYPE_ID[filter.typeId]"
    v-for="filter in currentWorkspaceCustomFields"
    :key="`ws-${workspaceId}—f-${filter.id}`"
    :field-id="filter.id"
    :filter-value="getModelValue(filter)"
    :workspace-id="props.workspaceId"
    @update-filter-value="
      onUpdateFilterValue({ ...$event, typeId: filter.typeId, filterName: filter.name })
    "
    @initial-data-loaded="onInitialDataLoaded(filter.id)"
  />
</template>

<script setup>
import { has, isEqual, isUndefined } from 'lodash'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import {
  calculateSelectedFiltersCount,
  CUSTOM_FIELD_FILTER_DEFAULT_VALUES,
  DEFAULT_SELECTED_FILTERS_COUNT,
  VALUES_DATA_TYPES
} from '@/utils/okr-elements/filters'
import { getNumberFilterOptions } from '@/utils/okr-elements/number-filter'
import { RELATIVE_VALUES_FILTER_KEYS } from '@/utils/okr-elements/relative-values-filter'
import { handleSelectInputWithSelectAll, selectAllIsSelected } from '@/utils/select'

import AssigneeFilter from '@/components/custom-fields/okr-elements-filters/AssigneeFilter'
import CustomFieldDateFilter from '@/components/custom-fields/okr-elements-filters/CustomFieldDateFilter'
import MoneyFieldFilter from '@/components/custom-fields/okr-elements-filters/MoneyFieldFilter'
import MultiSelectFilter from '@/components/custom-fields/okr-elements-filters/MultiSelectFilter'
import NumberFieldFilter from '@/components/custom-fields/okr-elements-filters/NumberFieldFilter'
import SingleSelectFilter from '@/components/custom-fields/okr-elements-filters/SingleSelectFilter'

defineOptions({
  name: 'OkrElementsFiltersCustomFieldsHub',
  inheritAttrs: false
})

const emit = defineEmits({
  'update-filter-value': null,
  'initial-data-loaded': null,
  'update:custom-field-filters-state': null
})

const props = defineProps({
  workspaceId: {
    type: [String, Number],
    required: true
  },

  filtersValues: {
    type: Object,
    required: true
  }
})

const FILTERS_COMPONENTS_BY_TYPE_ID = {
  [ALL_CUSTOM_FIELDS.getTypeIds().MULTI_SELECT]: MultiSelectFilter,
  [ALL_CUSTOM_FIELDS.getTypeIds().SINGLE_SELECT]: SingleSelectFilter,
  [ALL_CUSTOM_FIELDS.getTypeIds().DATE]: CustomFieldDateFilter,
  [ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]: AssigneeFilter,
  [ALL_CUSTOM_FIELDS.getTypeIds().NUMBER]: NumberFieldFilter,
  [ALL_CUSTOM_FIELDS.getTypeIds().MONEY]: MoneyFieldFilter
}

const store = useStore()

const currentWorkspaceCustomFields = computed(() => {
  return store.getters['customFields/columnsByWorkspaceId'](props.workspaceId)
})

const getModelValue = ({ id, typeId }) => {
  const defaultValueForFilter = CUSTOM_FIELD_FILTER_DEFAULT_VALUES[typeId]

  return isUndefined(props.filtersValues[id]) ? defaultValueForFilter : props.filtersValues[id]
}

const onUpdateFilterValue = ({ fieldId, value, typeId, filterName }) => {
  if (typeId === ALL_CUSTOM_FIELDS.getTypeIds().DATE) {
    emit('update-filter-value', { fieldId, value, filterName })
    return
  }

  if (
    typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY ||
    typeId === ALL_CUSTOM_FIELDS.getTypeIds().NUMBER
  ) {
    emit('update-filter-value', { fieldId, value, filterName })
    return
  }

  const defaultValueForFilter = CUSTOM_FIELD_FILTER_DEFAULT_VALUES[typeId]

  const oldValue = isUndefined(props.filtersValues[fieldId])
    ? defaultValueForFilter
    : props.filtersValues[fieldId]

  let { valueChanged, result } = handleSelectInputWithSelectAll(value, oldValue)
  valueChanged = valueChanged || !isEqual(oldValue, result)

  if (valueChanged) {
    emit('update-filter-value', { fieldId, value: result, filterName })
  }
}

const initialDataLoadedStates = ref(undefined)
const areFiltersValidated = ref(false)
const setupInitialDataLoaded = () => {
  initialDataLoadedStates.value = currentWorkspaceCustomFields.value.reduce((acc, filter) => {
    const { id, typeId } = filter
    // skip date filter cause it already validated
    if (typeId === ALL_CUSTOM_FIELDS.getTypeIds().DATE) {
      return acc
    }
    return {
      ...acc,
      [id]: false
    }
  }, {})
}

const onInitialDataLoaded = filterId => {
  if (has(initialDataLoadedStates.value, filterId) && !initialDataLoadedStates.value[filterId]) {
    initialDataLoadedStates.value[filterId] = true
  }
}

watch(
  initialDataLoadedStates,
  newValue => {
    if (isUndefined(newValue)) return

    const isAllInitialDataLoaded = Object.values(newValue).every(val => val)

    if (isAllInitialDataLoaded && !areFiltersValidated.value) {
      areFiltersValidated.value = true
      emit('initial-data-loaded')
    }
  },
  { deep: true }
)

onMounted(() => {
  setupInitialDataLoaded()
})

const resetState = () => {
  initialDataLoadedStates.value = undefined
  areFiltersValidated.value = false
}
const renewFilters = () => {
  resetState()
  nextTick(() => {
    setupInitialDataLoaded()
  })
}

defineExpose({
  renewFilters
})

onBeforeUnmount(() => {
  resetState()
})

watch(
  () => props.filtersValues,
  () => {
    const { selectedFiltersCount, isAllFiltersInDefaultValues } =
      currentWorkspaceCustomFields.value.reduce(
        (acc, filter) => {
          const { id, typeId } = filter

          const filterValue = props.filtersValues[id]

          if (isUndefined(filterValue)) {
            return acc
          }

          if (
            typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY ||
            typeId === ALL_CUSTOM_FIELDS.getTypeIds().NUMBER
          ) {
            const [operator] = filterValue

            let count = 1
            if (
              operator === getNumberFilterOptions().SHOW_ALL[RELATIVE_VALUES_FILTER_KEYS.OPERATOR]
            ) {
              count = 0
            }

            return {
              selectedFiltersCount: acc.selectedFiltersCount + count,
              isAllFiltersInDefaultValues: acc.isAllFiltersInDefaultValues && count === 0
            }
          }

          if (typeId === ALL_CUSTOM_FIELDS.getTypeIds().DATE) {
            const count = calculateSelectedFiltersCount({
              filterValue,
              filterValueType: VALUES_DATA_TYPES.DATES
            })

            return {
              selectedFiltersCount: acc.selectedFiltersCount + count,
              isAllFiltersInDefaultValues:
                acc.isAllFiltersInDefaultValues &&
                filterValue === CUSTOM_FIELD_FILTER_DEFAULT_VALUES[typeId]
            }
          }

          const isAllSelected = selectAllIsSelected(filterValue)
          const count = calculateSelectedFiltersCount({
            filterValue,
            filterValueType: VALUES_DATA_TYPES.ARRAY
          })

          return {
            selectedFiltersCount: acc.selectedFiltersCount + count,
            isAllFiltersInDefaultValues: acc.isAllFiltersInDefaultValues && isAllSelected
          }
        },
        {
          selectedFiltersCount: DEFAULT_SELECTED_FILTERS_COUNT,
          isAllFiltersInDefaultValues: true
        }
      )

    emit('update:custom-field-filters-state', {
      selectedFiltersCount,
      isAllFiltersInDefaultValues
    })
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss" scoped></style>
