<template>
  <component
    :is="config.tag"
    :class="{ 'alr-Resolver-hover-underline': hoverUnderline }"
    class="alr-Resolver"
    v-bind="config.attrs"
  >
    <slot />
  </component>
</template>

<script setup>
import { computed, inject, useAttrs } from 'vue'

import { isCrossPlatformAppInjectionKey } from '@/utils/injection-keys'

defineOptions({
  name: 'AppLinkResolver',
  inheritAttrs: false
})

defineProps({
  hoverUnderline: {
    type: Boolean
  }
})

const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const attrs = useAttrs()

const config = computed(() => {
  return {
    attrs: {
      ...attrs,
      href: isCrossPlatformApp ? null : attrs.href
    },
    tag: isCrossPlatformApp ? 'span' : 'a'
  }
})
</script>

<style lang="scss" scoped>
.alr-Resolver {
  cursor: pointer;

  &-hover-underline {
    @media (any-hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
