<template>
  <portal to="modal-windows">
    <AppDialog
      :disable-confirm-button="!localFormModel.name"
      :loading="isLoading"
      :show="showModal"
      :title="$t('action.create_dashboard')"
      :type="DIALOG_TYPES.INFO"
      hide-hero
      @on-confirm="createDashboard"
      @on-close="closeModal"
    >
      <template #confirm-btn-text>
        {{ $t('action.save') }}
      </template>
      <div>
        <FormFieldNext :label="$t('dashboard.dashboard_name')">
          <AppInput
            v-model="localFormModel.name"
            :max-length="CUSTOM_DASHBOARD_NAME_MAX_LENGTH"
            :placeholder="$t('dashboard.custom_dashboard_name_placeholder')"
            size="xlg"
            style-type="primary"
          />
        </FormFieldNext>
      </div>
    </AppDialog>
  </portal>
</template>

<script setup>
import { ref, watch } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { CUSTOM_DASHBOARD_NAME_MAX_LENGTH } from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'CreateCustomDashboardModal'
})

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },

  formModel: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  'update:show-modal': null,
  'update:form-model': null,
  created: null
})
const closeModal = () => {
  emit('update:show-modal', false)
}

const isLoading = ref(false)
const createDashboard = async () => {
  try {
    const customDashboardApi = new CustomDashboardApiHandler()
    isLoading.value = true
    const { name, backgroundId } = localFormModel.value

    const data = await customDashboardApi.createDashboard({
      name,
      backgroundId
    })
    emit('update:form-model', data)
    emit('created', data.id)
    closeModal()

    tracker.logEvent('custom dashboard created', {
      category: EVENT_CATEGORIES.DASHBOARD
    })

    gtmTracker.logEvent(EVENT_NAMES.DASHBOARD_CREATED)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const localFormModel = ref({ ...props.formModel })

watch(
  () => props.formModel,
  val => {
    localFormModel.value = { ...val }
  },
  { deep: true }
)
</script>

<style lang="scss" scoped></style>
