<template>
  <AppDialog
    v-if="currentRole"
    :closeable="false"
    :hide-footer="hideFooter"
    :show="isOnboardingShow"
    class="oh-Dialog"
    hide-hero
    size="full-page"
  >
    <StepBullets
      :current-step="availableSteps.indexOf(currentStep) + 1"
      :total-count="availableSteps.length"
    />
    <Transition :name="currentTransition" mode="out-in">
      <Component
        :is="STEPS[currentStep][COMPONENT]"
        ref="stepsReference"
        v-model:is-video-shows="isTutorialStepVideoShows"
        v-model:subscription="subscription"
        :is-rerun-onboarding="isRerunOnboarding"
        :role="currentRole"
        :setup-name-step-need="setupNameStepNeed"
        class="oh-Step"
        @continue="onStepAction(STEP_ACTIONS.NEXT)"
      >
        <!--        <template v-if="STEPS[currentStep].hero" #step-hero>
          <AppIcon :icon-name="getStepHero" height="80" width="80" />
        </template>-->
        <template v-if="userData" #step-title>
          <TitleWithAction class="oh-Title">
            <i18n-t :keypath="getTitleKeyPath" scope="global">
              <template #userName>
                {{ userData.userName }}
              </template>

              <template #break>
                <br />
              </template>
            </i18n-t>
          </TitleWithAction>
        </template>

        <template v-if="STEPS[currentStep][MESSAGE]" #step-message>
          <AppInfoMessage>
            <i18n-t :keypath="STEPS[currentStep][MESSAGE]">
              <template #break>
                <br />
              </template>
            </i18n-t>
          </AppInfoMessage>
        </template>
      </Component>
    </Transition>

    <AppDivider
      v-if="!isEmpty(getResolvedActions(STEPS[currentStep][ACTIONS], currentStep))"
      class="oh-Divider"
      no-margin
    />

    <template #footer>
      <div class="oh-Footer">
        <!--
        <div v-if="!isWelcomeStep && !isSetupNameStep" class="oh-Footer_StepCounter">
          {{ $t('onboarding.step') }} {{ availableSteps.indexOf(currentStep) + 1 }}
          {{ $t('pagination.of') }} {{ availableSteps.length }}
        </div>
        -->
        <div class="oh-Footer_Actions">
          <AppButton
            v-for="action in getResolvedActions(STEPS[currentStep][ACTIONS], currentStep)"
            :key="action[EVENT]"
            :class="{
              'oh-Action': true,
              [`oh-Action-${action[EVENT]}`]: true
            }"
            :icon-after="action[ICON_AFTER] || ''"
            :type="STEP_ACTIONS_TYPES[action[EVENT]]"
            @click="onStepAction(action[EVENT])"
          >
            {{ action[LABEL][currentRole] || action[DEFAULT_LABEL] }}
          </AppButton>
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script setup>
import { has, isEmpty, lowerCase } from 'lodash'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
// import { HIDE_ONBOARDING_BOOKING_STEP } from '@/util'
import { ONBOARDING_ROLES } from '@/utils/components-configurations/onboarding-hub'
import { ONBOARDING_HUB, USER_SETTINGS_MAPPER } from '@/utils/user-settings'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AddAdministratorsStep from '@/components/ui/onboarding/AddAdministratorsStep'
import BookingStep from '@/components/ui/onboarding/BookingStep'
import InviteUsersStep from '@/components/ui/onboarding/InviteUsersStep'
import WelcomeSetupNameStep from '@/components/ui/onboarding/WelcomeSetupNameStep'
import StepBullets from '@/components/ui/StepBullets/StepBullets'
import GroupsStep from '@/components/ui/WorkspaceSetupSteps/GroupsStep'
import IntervalsStep from '@/components/ui/WorkspaceSetupSteps/IntervalsStep'
import OkrLevelsStep from '@/components/ui/WorkspaceSetupSteps/OkrLevelsStep'
import WorkspaceStep from '@/components/ui/WorkspaceSetupSteps/WorkspaceStep'

defineOptions({
  name: 'OnboardingHub'
})

// used local var instead of env till added to jenkins
const HIDE_ONBOARDING_BOOKING_STEP = false

const store = useStore()

const userData = computed(() => store.state.system.userData)

const isOnboardingPassed = ref(false)
const onboardingHubState = computed(() => store.getters['system/onboardingHubState'])
const isOnboardingShow = ref(true)

const isRerunOnboarding = computed(() => {
  const { userOnboardingPassed, adminOnboardingPassed } = onboardingHubState.value

  return userOnboardingPassed && !adminOnboardingPassed
})

const setupNameStepNeed = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.SETUP_NAME])

const workspaceSetupStepsNeed = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.ONBOARDING] && !isRerunOnboarding.value
})

// must be here for tracking
const subscription = ref(true)

const STEP_ACTIONS = {
  NEXT: 'next',
  PREVIOUS: 'previous',
  SKIP: 'skip'
}

const STEP_ACTIONS_TYPES = {
  [STEP_ACTIONS.NEXT]: 'primary-next',
  [STEP_ACTIONS.PREVIOUS]: 'ghost-next',
  [STEP_ACTIONS.SKIP]: 'ghost-next'
}

const { t } = useI18n()

const ACTIONS_KEYS = {
  EVENT: 'event',
  LABEL: 'label',
  ICON_AFTER: 'iconAfter',
  DEFAULT_LABEL: 'defaultLabel'
}

const { EVENT, LABEL, ICON_AFTER, DEFAULT_LABEL } = ACTIONS_KEYS

const SKIP_ACTION = {
  [EVENT]: STEP_ACTIONS.SKIP,
  [LABEL]: {},
  [DEFAULT_LABEL]: t('action.skip')
}

const NEXT_STEP_ACTION = {
  [EVENT]: STEP_ACTIONS.NEXT,
  [LABEL]: {},
  [DEFAULT_LABEL]: t('action.continue'),
  [ICON_AFTER]: 'chevron-right-next'
}

const STEP_OPTIONS_KEYS = {
  NAME: 'name',
  ACCESS: 'access',
  COMPONENT: 'component',
  TITLE: 'title',
  MESSAGE: 'message',
  TRACKING_STEP_NAME: 'trackingStepName',
  ACTIONS: 'actions',
  HERO: 'hero',
  RERUN_HERO: 'rerunHero',
  RERUN_TITLE: 'rerunTitle',
  SETUP_NAME_TITLE: 'setupNameTitle'
}

const {
  NAME,
  ACCESS,
  COMPONENT,
  TITLE,
  MESSAGE,
  TRACKING_STEP_NAME,
  ACTIONS,
  RERUN_TITLE,
  SETUP_NAME_TITLE
} = STEP_OPTIONS_KEYS

const USER_MANAGEMENT_STEP_WITH_EMAIL_INVITATION = {
  [COMPONENT]: InviteUsersStep,
  [TITLE]: 'organization.invite_users.title',
  //  [HERO]: 'invite-users-hero',
  [TRACKING_STEP_NAME]: 'invite users'
}

const JIRA_APP_USER_MANAGEMENT_STEP = {
  [COMPONENT]: AddAdministratorsStep,
  [TITLE]: 'onboarding.admins.title',
  // [HERO] 'onboarding-admins',
  [TRACKING_STEP_NAME]: 'add admin'
}

const isWebApp = computed(() => store.state.appContext.isWebApp)
const isCrossPlatformApp = computed(() => store.state.appContext.isCrossPlatformApp)

const userManagementStep = computed(() => {
  return isWebApp.value || isCrossPlatformApp.value
    ? USER_MANAGEMENT_STEP_WITH_EMAIL_INVITATION
    : JIRA_APP_USER_MANAGEMENT_STEP
})

const SETUP_NAME = 'SETUP_NAME'
const WELCOME = 'WELCOME'
const OKR_LEVELS = 'OKR_LEVELS'
const WORKSPACE = 'WORKSPACE'
const INTERVALS = 'INTERVALS'
const GROUPS = 'GROUPS'
const BOOKING = 'BOOKING'
const TUTORIAL = 'TUTORIAL'
const USER_MANAGEMENT = 'USER_MANAGEMENT'

const STEPS = {
  // [SETUP_NAME]: {
  //   [NAME]: SETUP_NAME,
  //   [ACCESS]: setupNameStepNeed.value ? Object.values(ONBOARDING_ROLES) : [],
  //   [COMPONENT]: SetupNameStep,
  //   [TITLE]: 'onboarding.setup_name.title',
  //   // [HERO]: 'setup-organization-hero',
  //   [TRACKING_STEP_NAME]: 'setup name',
  //   [ACTIONS]: []
  // },
  [WELCOME]: {
    [NAME]: WELCOME,
    [ACCESS]: Object.values(ONBOARDING_ROLES),
    [COMPONENT]: WelcomeSetupNameStep,
    [TITLE]: 'onboarding.welcome.title',
    [RERUN_TITLE]: 'onboarding.welcome.rerun_title',
    [SETUP_NAME_TITLE]: 'onboarding.setup_name.title',
    // [HERO]: 'onboarding-heart',
    // [RERUN_HERO]: 'onboarding-cog',
    [TRACKING_STEP_NAME]: 'email subscription',
    [ACTIONS]: setupNameStepNeed.value
      ? []
      : [
          {
            ...NEXT_STEP_ACTION,
            [DEFAULT_LABEL]: t('action.lets_start')
          }
        ]
  },
  // [WELCOME]: {
  //   [NAME]: WELCOME,
  //   [ACCESS]: Object.values(ONBOARDING_ROLES),
  //   [COMPONENT]: WelcomeStep,
  //   [TITLE]: 'onboarding.welcome.title',
  //   [RERUN_TITLE]: 'onboarding.welcome.rerun_title',
  //   // [HERO]: 'onboarding-heart',
  //   // [RERUN_HERO]: 'onboarding-cog',
  //   [TRACKING_STEP_NAME]: 'email subscription',
  //   [ACTIONS]: [
  //     {
  //       ...NEXT_STEP_ACTION,
  //       [DEFAULT_LABEL]: t('action.lets_start')
  //     }
  //   ]
  // },
  [OKR_LEVELS]: {
    [NAME]: OKR_LEVELS,
    [ACCESS]: workspaceSetupStepsNeed.value ? [ONBOARDING_ROLES.PLUGIN_ADMIN] : [],
    [COMPONENT]: OkrLevelsStep,
    [TITLE]: 'setup_okr_levels.title',
    [MESSAGE]: 'edit_later.message',
    [TRACKING_STEP_NAME]: 'okr levels',
    [ACTIONS]: [SKIP_ACTION, NEXT_STEP_ACTION]
  },
  [WORKSPACE]: {
    [NAME]: WORKSPACE,
    [ACCESS]: workspaceSetupStepsNeed.value ? [ONBOARDING_ROLES.PLUGIN_ADMIN] : [],
    [COMPONENT]: WorkspaceStep,
    [TITLE]: 'setup_workspace.title',
    [MESSAGE]: 'setup_workspace.message',
    [TRACKING_STEP_NAME]: 'workspace step',
    [ACTIONS]: [SKIP_ACTION, NEXT_STEP_ACTION]
  },
  [INTERVALS]: {
    [NAME]: INTERVALS,
    [ACCESS]: workspaceSetupStepsNeed.value ? [ONBOARDING_ROLES.PLUGIN_ADMIN] : [],
    [COMPONENT]: IntervalsStep,
    [TITLE]: 'setup_intervals.title',
    [MESSAGE]: 'edit_later.message',
    [TRACKING_STEP_NAME]: 'intervals step',
    [ACTIONS]: [SKIP_ACTION, NEXT_STEP_ACTION]
  },
  [GROUPS]: {
    [NAME]: GROUPS,
    [ACCESS]: workspaceSetupStepsNeed.value ? [ONBOARDING_ROLES.PLUGIN_ADMIN] : [],
    [COMPONENT]: GroupsStep,
    [TITLE]: 'setup_groups.title',
    [MESSAGE]: 'setup_groups.message',
    [TRACKING_STEP_NAME]: 'groups step',
    [ACTIONS]: [SKIP_ACTION, NEXT_STEP_ACTION]
  },
  [USER_MANAGEMENT]: {
    [NAME]: USER_MANAGEMENT,
    [ACCESS]: [ONBOARDING_ROLES.PLUGIN_ADMIN],
    ...userManagementStep.value,
    [ACTIONS]: [
      // {
      //   [EVENT]: STEP_ACTIONS.PREVIOUS,
      //   [LABEL]: {},
      //   [DEFAULT_LABEL]: t('action.back')
      // },
      SKIP_ACTION,
      NEXT_STEP_ACTION
      // {
      //   ...NEXT_STEP_ACTION,
      //   [LABEL]: {
      //     [ONBOARDING_ROLES.PLUGIN_ADMIN]: t('action.get_started')
      //   }
      // }
    ]
  },
  [BOOKING]: {
    [NAME]: BOOKING,
    [ACCESS]: HIDE_ONBOARDING_BOOKING_STEP
      ? []
      : [ONBOARDING_ROLES.PLUGIN_ADMIN, ONBOARDING_ROLES.WORKSPACE_ADMIN],
    [COMPONENT]: BookingStep,
    [TITLE]: 'onboarding.demo.title',
    [TRACKING_STEP_NAME]: 'demo',
    [ACTIONS]: [
      {
        ...NEXT_STEP_ACTION,
        [DEFAULT_LABEL]: t('action.get_started')
      }
    ]
  }
  //[TUTORIAL]: {
  //  [NAME]: TUTORIAL,
  //  [ACCESS]: Object.values(ONBOARDING_ROLES),
  //  [COMPONENT]: TutorialStep,
  //  [TITLE]: 'onboarding.tutorial.title',
  //  // [HERO]: 'onboarding-check',
  //  [TRACKING_STEP_NAME]: 'video',
  //  [ACTIONS]: [
  //    {
  //      ...SKIP_ACTION,
  //      [ACCESS]: [ONBOARDING_ROLES.PLUGIN_ADMIN, ONBOARDING_ROLES.WORKSPACE_ADMIN]
  //    },
  //    // {
  //    //   event: STEP_ACTIONS.PREVIOUS,
  //    //   label: {},
  //    //   [DEFAULT_LABEL]: t('action.back'),
  //    //   access: [ONBOARDING_ROLES.PLUGIN_ADMIN, ONBOARDING_ROLES.WORKSPACE_ADMIN]
  //    // },
  //    {
  //      ...NEXT_STEP_ACTION,
  //      [LABEL]: {
  //        [ONBOARDING_ROLES.PLUGIN_ADMIN]: t('action.continue')
  //      },
  //      [DEFAULT_LABEL]: t('action.get_started')
  //    }
  //  ]
  //},
}

const stepsByRole = computed(() => {
  const getStepsByRole = role => {
    const steps = Object.values(STEPS).filter(step => step[ACCESS].includes(role))
    return steps.map(step => step[NAME])
  }

  return {
    [ONBOARDING_ROLES.PLUGIN_ADMIN]: getStepsByRole(ONBOARDING_ROLES.PLUGIN_ADMIN),
    [ONBOARDING_ROLES.WORKSPACE_ADMIN]: getStepsByRole(ONBOARDING_ROLES.WORKSPACE_ADMIN),
    [ONBOARDING_ROLES.USER]: getStepsByRole(ONBOARDING_ROLES.USER)
  }
})

const getTitleKeyPath = computed(() => {
  const step = STEPS[currentStep.value]

  if (isRerunOnboarding.value && step[RERUN_TITLE]) {
    return step[RERUN_TITLE]
  }

  if (setupNameStepNeed.value && step[SETUP_NAME_TITLE]) {
    return step[SETUP_NAME_TITLE]
  }

  return step[TITLE]
})

// const getStepHero = computed(() => {
//   const step = STEPS[currentStep.value]
//
//   if (isRerunOnboarding.value && step[RERUN_HERO) {
//     return step[RERUN_HERO]
//   }
//
//   return step[HERO]
// })

const getResolvedActions = (actions, step) => {
  return actions.filter(action => {
    if (isCurrentStepLast.value && action[EVENT] === STEP_ACTIONS.SKIP) {
      return false
    }
    if (has(action, ACCESS)) {
      const isActionAvailable = action[ACCESS].includes(currentRole.value)

      // hide 'back' action on tutorial step if we hide BOOKING step
      // but keep it if we show some VIDEO on tutorial step
      if (
        step === TUTORIAL &&
        HIDE_ONBOARDING_BOOKING_STEP &&
        action[EVENT] === STEP_ACTIONS.PREVIOUS
      ) {
        return isActionAvailable && isTutorialStepVideoShows.value
      }

      return isActionAvailable
    }

    return true
  })
}

const currentStep = ref(null)
const currentRole = ref(null)

const availableSteps = computed(() => {
  return stepsByRole.value[currentRole.value]
})

const TRANSITIONS = {
  NEXT: 'slide-next',
  PREV: 'slide-prev'
}

const emit = defineEmits({
  'close-onboarding': null
})

const isCurrentStepLast = computed(() => {
  if (!currentRole.value) {
    return false
  }
  const currentStepIndex = availableSteps.value.indexOf(currentStep.value)
  return currentStepIndex === availableSteps.value.length - 1
})

const handleFirstStepLeaving = () => {
  if (!isOnboardingPassed.value) {
    let newHubState = {
      ...onboardingHubState.value
    }

    if (
      [ONBOARDING_ROLES.PLUGIN_ADMIN, ONBOARDING_ROLES.WORKSPACE_ADMIN].includes(currentRole.value)
    ) {
      newHubState = {
        adminOnboardingPassed: true,
        userOnboardingPassed: true
      }
    } else {
      newHubState = {
        ...newHubState,
        userOnboardingPassed: true
      }
    }

    store.dispatch('system/updateUserSettings', {
      [USER_SETTINGS_MAPPER[ONBOARDING_HUB]]: newHubState
    })

    isOnboardingPassed.value = true
  }
}

const currentTransition = ref(TRANSITIONS.NEXT)
const stepsReference = ref(null)

const closeOnboarding = () => {
  isOnboardingShow.value = false
  // wait modal animation
  setTimeout(() => {
    emit('close-onboarding')
  }, 200)
}

const goToNextStep = async currentStepIndex => {
  currentTransition.value = TRANSITIONS.NEXT

  await nextTick()

  const trackingPayload = {
    category: EVENT_CATEGORIES.ONBOARDING,
    step: STEPS[currentStep.value][TRACKING_STEP_NAME],
    role: lowerCase(currentRole.value)
  }

  if (currentStep.value === WELCOME) {
    handleFirstStepLeaving()
    trackingPayload.value = subscription.value ? 'subscribed' : 'not subscribed'

    if (isRerunOnboarding.value) {
      trackingPayload.value = 'unknown, user just became plugin admin'
    }
  }

  tracker.logEvent('view onboarding', {
    ...trackingPayload
  })

  if (isCurrentStepLast.value) {
    closeOnboarding()
  }

  const isNextStepAvailable = currentStepIndex + 1 < availableSteps.value.length

  if (isNextStepAvailable) {
    currentStep.value = availableSteps.value[currentStepIndex + 1]
  }
}

const goToPrevStep = async currentStepIndex => {
  if (isTutorialStep.value && isTutorialStepVideoShows.value) {
    stepsReference.value.backToNav()
  } else {
    currentTransition.value = TRANSITIONS.PREV

    await nextTick()

    const isPreviousStepAvailable = currentStepIndex > 0
    if (isPreviousStepAvailable) {
      currentStep.value = availableSteps.value[currentStepIndex - 1]
    }
  }
}
const onStepAction = action => {
  const currentStepIndex = availableSteps.value.indexOf(currentStep.value)

  if (action === STEP_ACTIONS.SKIP) {
    closeOnboarding()
  }

  if (action === STEP_ACTIONS.NEXT) {
    goToNextStep(currentStepIndex)
  }

  if (action === STEP_ACTIONS.PREVIOUS) {
    goToPrevStep(currentStepIndex)
  }
}
const isWelcomeStep = computed(() => currentStep.value === WELCOME)
const isSetupNameStep = computed(() => currentStep.value === SETUP_NAME)
const isTutorialStep = computed(() => currentStep.value === TUTORIAL)
const isTutorialStepVideoShows = ref(false)

const hideFooter = computed(() => {
  return isSetupNameStep.value || (isWelcomeStep.value && setupNameStepNeed.value)
})

const isWorkspaceAdmin = computed(
  () => store.state.system.userData.hasAccessToWorkspaceSettingsPage
)
const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

const setInitialValues = () => {
  const [role] = [
    isPluginAdmin.value && ONBOARDING_ROLES.PLUGIN_ADMIN,
    isWorkspaceAdmin.value && ONBOARDING_ROLES.WORKSPACE_ADMIN,
    ONBOARDING_ROLES.USER
  ].filter(Boolean)

  currentRole.value = role
  currentStep.value = availableSteps.value[0]
}

onMounted(() => {
  setInitialValues()
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.oh-Dialog {
  --dialog-content-padding-top: 80px;
  --footer-padding-bottom: 80px;
  --step-max-width: #{$step-max-width};
  --bulltes-width: var(--step-max-width);
}

.oh-Step {
  padding-top: 60px;
  margin-inline: auto;
}

.slide-next-enter-active,
.slide-next-leave-active {
  transition: all 0.25s ease-out;
}

.slide-next-enter-from {
  opacity: 0;
  transform: translateX(30px);
  filter: blur(5px);
}

.slide-next-leave-to {
  opacity: 0;
  transform: translateX(-30px);
  filter: blur(5px);
}

.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: all 0.25s ease-out;
}

.slide-prev-enter-from {
  opacity: 0;
  transform: translateX(-30px);
  filter: blur(5px);
}

.slide-prev-leave-to {
  opacity: 0;
  transform: translateX(30px);
  filter: blur(5px);
}

.oh-Footer {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  max-width: var(--step-max-width);
  margin-inline: auto;
  justify-content: flex-end;

  // &:not(&-centered) {
  //   justify-content: space-between;
  // }

  // &-centered {
  //   justify-content: center;
  // }
}

.oh-Footer_Actions {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.oh-Action {
  line-height: 20px;

  &-skip {
    --button-high-padding: 0;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  &-next {
    --button-low-padding: 4px;
    margin-left: auto;
  }
}

// .oh-Footer_StepCounter {
//   color: $grey-1-next;
//   font-size: $fs-14;
//   line-height: 20px;
//   font-weight: fw('regular');
// }

.oh-Divider {
  max-width: var(--step-max-width);
  margin: 20px auto 0;
}

.oh-Title {
  width: 100%;
}
</style>
