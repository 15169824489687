import { cloneDeep, isNull, isUndefined } from 'lodash'

import { FALLBACK_LOCALE } from '@/i18n'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { HOMEPAGE_OKR_SEARCH_TYPE_IDS } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import { readFromStorageByKey } from '@/utils/persist'
import { ROADMAP_KEY } from '@/utils/roadmap'

export const BANNER = 'BANNER'
export const OKR_ELEMENTS_TABLE_ENABLED_COLUMNS = 'OKR_ELEMENTS_TABLE_ENABLED_COLUMNS'
export const OKR_ELEMENTS_TABLE_COLUMNS_WIDTH = 'OKR_ELEMENTS_TABLE_COLUMNS_WIDTH'

export const getOkrElementsTableSettingsKeys = tablePlacement => {
  const resolvedPlacement = tablePlacement ? `${tablePlacement}_` : ''
  const enabledColumns = `${resolvedPlacement}${OKR_ELEMENTS_TABLE_ENABLED_COLUMNS}`.trim()
  const columnsWidth = `${resolvedPlacement}${OKR_ELEMENTS_TABLE_COLUMNS_WIDTH}`.trim()
  return {
    enabledColumns,
    columnsWidth
  }
}

export const ROADMAP_TABLE_ENABLED_COLUMNS =
  getOkrElementsTableSettingsKeys(ROADMAP_KEY).enabledColumns

export const ROADMAP_TABLE_COLUMN_WIDTH = getOkrElementsTableSettingsKeys(ROADMAP_KEY).columnsWidth

export const OKR_EXPLORER_SHOW_KRS = 'OKR_EXPLORER_SHOW_KRS'

export const ROADMAP_TABLE_COLLAPSED_STATE = 'ROADMAP_TABLE_COLLAPSED_STATE'
export const ROADMAP_TABLE_SIDE_WIDTH = 'ROADMAP_TABLE_SIDE_WIDTH'

export const ROADMAP_ACTIVE_VIEW = 'ROADMAP_ACTIVE_VIEW'

export const ROADMAP_VIEW_SCALE = 'ROADMAP_VIEW_SCALE'

export const HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP = 'HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP'
export const HOMEPAGE_SEARCH_TYPE_ID = 'HOMEPAGE_SEARCH_TYPE_ID'

export const RELEASE_UPDATES_MODAL_ID = 'RELEASE_UPDATES_MODAL_ID'

export const PERFORMANCE_REPORT_FILTERS = 'PERFORMANCE_REPORT_FILTERS'
export const CUSTOM_DASHBOARD_FILTERS = 'CUSTOM_DASHBOARD_FILTERS'
export const APP_LOCALE = 'APP_LOCALE'
export const ONBOARDING_HUB = 'ONBOARDING_HUB'
export const APP_OPENED_COUNT = 'APP_OPENED_COUNT'
export const FEEDBACK_MODAL_ID = 'FEEDBACK_MODAL_ID'
export const DASHBOARD_GENERAL_FILTERS = 'DASHBOARD_GENERAL_FILTERS'

export const SETTINGS_REFERER = 'SETTINGS_REFERER'
export const STORYLANE = 'STORYLANE'
export const OKR_ELEMENT_MODAL_FULL_SCREEN_STATE = 'OKR_ELEMENT_MODAL_FULL_SCREEN_STATE'

export const USER_SETTINGS_MAPPER = {
  [BANNER]: 1,
  [OKR_ELEMENTS_TABLE_ENABLED_COLUMNS]: 2,
  [OKR_ELEMENTS_TABLE_COLUMNS_WIDTH]: 3,
  [ROADMAP_TABLE_ENABLED_COLUMNS]: 4,
  [ROADMAP_TABLE_COLUMN_WIDTH]: 5,
  [OKR_EXPLORER_SHOW_KRS]: 6,
  [ROADMAP_TABLE_COLLAPSED_STATE]: 7,
  [ROADMAP_TABLE_SIDE_WIDTH]: 8,
  [ROADMAP_ACTIVE_VIEW]: 9,
  [ROADMAP_VIEW_SCALE]: 10,
  [HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP]: 11,
  [HOMEPAGE_SEARCH_TYPE_ID]: 12,
  [RELEASE_UPDATES_MODAL_ID]: 15,
  [PERFORMANCE_REPORT_FILTERS]: 16,
  [CUSTOM_DASHBOARD_FILTERS]: 17,
  [ONBOARDING_HUB]: 18,
  [APP_LOCALE]: 19,
  [SETTINGS_REFERER]: 20,
  [APP_OPENED_COUNT]: 21,
  [FEEDBACK_MODAL_ID]: 22,
  [STORYLANE]: 23,
  [DASHBOARD_GENERAL_FILTERS]: 24,
  [OKR_ELEMENT_MODAL_FULL_SCREEN_STATE]: 25
}

export const DASHBOARD_FILTERS_KEYS = {
  INTERVAL_IDS: 'iids'
}

export const CONFERENCE_BANNER = 'CONFERENCE_BANNER'
export const SURVEY_BANNER = 'SURVEY_BANNER'
export const CONFLUENCE_BANNER = 'CONFLUENCE_BANNER'
export const CONFLUENCE_SERVER_BANNER = 'CONFLUENCE_SERVER_BANNER'

export const USER_SETTINGS_DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_USERS_SETTINGS = {
  [SURVEY_BANNER]: {
    key: SURVEY_BANNER,
    id: 2, // Always increase, banners id should be unique
    visible: false,
    expirationDate: null // USER_SETTINGS_DATE_FORMAT
  },
  [CONFLUENCE_BANNER]: {
    key: CONFLUENCE_BANNER,
    id: 1, // Always increase, banners id should be unique
    visible: false,
    expirationDate: null // USER_SETTINGS_DATE_FORMAT
  },
  [CONFLUENCE_SERVER_BANNER]: {
    key: CONFLUENCE_SERVER_BANNER,
    id: 2, // Always increase, banners id should be unique
    visible: false,
    expirationDate: null // USER_SETTINGS_DATE_FORMAT
  },
  [CONFERENCE_BANNER]: {
    key: CONFERENCE_BANNER,
    id: 3, // Always increase, banners id should be unique
    visible: false,
    expirationDate: '01/11/2024' // USER_SETTINGS_DATE_FORMAT
  },
  [OKR_ELEMENTS_TABLE_ENABLED_COLUMNS]: null,
  [OKR_ELEMENTS_TABLE_COLUMNS_WIDTH]: null,
  [ROADMAP_TABLE_ENABLED_COLUMNS]: null,
  [ROADMAP_TABLE_COLUMN_WIDTH]: null,
  [OKR_EXPLORER_SHOW_KRS]: null,
  [ROADMAP_TABLE_COLLAPSED_STATE]: null,
  [ROADMAP_TABLE_SIDE_WIDTH]: null,
  [HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP]: true,
  [HOMEPAGE_SEARCH_TYPE_ID]: HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES,
  [RELEASE_UPDATES_MODAL_ID]: 10, // always increase
  [PERFORMANCE_REPORT_FILTERS]: [
    ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS,
    cloneDeep(DEFAULT_VALUE_FOR_FILTER),
    cloneDeep(DEFAULT_VALUE_FOR_FILTER)
  ],
  [CUSTOM_DASHBOARD_FILTERS]: {},
  [ONBOARDING_HUB]: {
    userOnboardingPassed: false,
    adminOnboardingPassed: false
  },
  [APP_LOCALE]: FALLBACK_LOCALE,
  [SETTINGS_REFERER]: null,
  [APP_OPENED_COUNT]: 0,
  [FEEDBACK_MODAL_ID]: 'eeasdasdasdaasdaasdsadddsddsdsdltfrmbi', // 'gzh9gw7uu' // change this value to another uid if you want to show feedback modal again
  [STORYLANE]: {
    hideOnboardingContent: false,
    completedIds: [],
    buttonId: 1 // Always increase, button id should be unique
  },
  [DASHBOARD_GENERAL_FILTERS]: {},
  [OKR_ELEMENT_MODAL_FULL_SCREEN_STATE]: false
}

export const parseUserSettings = settings => {
  return Object.entries(settings).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: JSON.parse(value)
    }
  }, {})
}

export const stringifyUserSettings = settings => {
  return Object.entries(settings).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: JSON.stringify(value)
    }
  }, {})
}

/**
 * The function to resolve restored **VALUE** from localStorage or user settings
 * need for backward compatibility after release user settings
 * for prevent lose user saved **VALUE**
 * @param {string|null} localStorageKey - Key for localStorage under which the value is stored
 * @param {any} valueFromSettings - User saved **VALUE** in user settings
 * @param {boolean} isBooleanOrNumberValueRestores - If **VALUE** is Boolean or Number adds
 * additional conditions to prevent wrong behavior with **false** and **0** values
 * @returns {any} -User saved **VALUE** in LS if no saved **VALUE** in user settings
 * or **null** if no saved **VALUE** in LS and user settings
 */
export const getResolvedRestoredValue = ({
  localStorageKey = null,
  valueFromSettings = null,
  isBooleanOrNumberValueRestores = false
}) => {
  if (isNull(localStorageKey)) {
    throw new Error('localStorageKey is required')
  }

  const restoredValueFromLs = readFromStorageByKey(localStorageKey)

  if (isBooleanOrNumberValueRestores) {
    let resolvedRestoredValue = null

    if (!isNull(valueFromSettings)) {
      resolvedRestoredValue = valueFromSettings
    }

    if (
      isNull(resolvedRestoredValue) &&
      !isNull(restoredValueFromLs) &&
      !isUndefined(restoredValueFromLs)
    ) {
      resolvedRestoredValue = restoredValueFromLs
    }

    return resolvedRestoredValue
  }

  return valueFromSettings || restoredValueFromLs
}

export const getResolvedRestoredValueWithQuery = ({
  valueFromSettings = null,
  valueFromQuery = null
}) => {
  if (valueFromQuery) {
    return valueFromQuery
  }

  return valueFromSettings
}
