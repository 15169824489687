const APP_MODULE_IDS = {
  BILLING: 1,
  SETTINGS_SYNCHRONIZATION: 2
}
/**
 * @param {number[]} disabledModules
 * @param {string|number} moduleId
 */
const isModuleDisabled = ({ disabledModules = [], moduleId = 'unknown' } = {}) =>
  disabledModules.includes(moduleId)

module.exports = {
  APP_MODULE_IDS,
  isModuleDisabled
}
