<template>
  <div class="up-UserPreferencesPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.FULL_NEXT"
      :title="t('user.personal_settings.title')"
      no-margin
    />

    <div class="up-MainContent">
      <div v-if="userData" class="up-UserData">
        <div class="up-UserData_Header">
          <AppAvatar
            :avatar-url="userData.userAvatarUrl || userData.avatarUrl"
            :size="AVATAR_SIZES.XL"
            no-margins
          />

          <AppTitle :level="3" disable-margin>
            {{ userData.userName }}
          </AppTitle>
        </div>

        <FormFieldNext :label="$t('user.preferences.user.name.label')" class="up-UserData_Field">
          <AppInput
            v-model="name"
            :is-error="!isNameValid"
            :max-length="50"
            :placeholder="$t('user.preferences.user.name.label')"
            :readonly="isJiraApp"
            blur-on-enter
            size="xlg"
            style-type="primary"
            @blur="onUpdateName"
            @focus="isNameValid = true"
          />
          <AppFieldError v-if="!isNameValid" :show="!isNameValid">
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>

        <FormFieldNext :label="$t('user.preferences.user.email.label')" class="up-UserData_Field">
          <AppInput
            v-model="userData.email"
            :is-error="false"
            :placeholder="$t('user.preferences.user.email.label')"
            readonly
            size="xlg"
            style-type="primary"
          />
        </FormFieldNext>
      </div>

      <AppDivider class="up-Divider" no-margin />

      <div class="up-UserLanguage">
        <AppTitle :level="4" disable-top-margin>
          {{ $t('user.preferences.change_language') }}
        </AppTitle>
        <AppSelect
          v-model="locale"
          :offset="[0, 0]"
          :options="localeOptions"
          :type="SELECT_TYPES.MODERN"
          class="up-SelectLang"
          @update:model-value="changedLocale"
        >
          <!-- <template #option-label="{ option }">
            <AppSelectOptionWithIcon v-if="option" :icon="option.icon">
              {{ option.label }}
            </AppSelectOptionWithIcon>
          </template>

          <template #button-content="{ option }">
            <AppSelectOptionWithIcon v-if="option" :icon="option.icon">
              {{ option.label }}
            </AppSelectOptionWithIcon>
          </template> -->
        </AppSelect>
      </div>

      <AppDivider class="up-Divider" no-margin />

      <UserPreferencesDefaultGroupsTab />
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { LOCALE } from '@/util'
import { AVATAR_SIZES } from '@/utils/components-configurations/app-avatar'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import { isJiraAppInjectionKey } from '@/utils/injection-keys'
import { updateStorageByKey } from '@/utils/persist'
import { APP_LOCALE, USER_SETTINGS_MAPPER } from '@/utils/user-settings'
import { useRenameUser } from '@/utils/web-app/composables'
import { transformLocaleToDayjsFormat } from '@jira/util'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppDivider from '@/components/ui/AppDivider'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import UserPreferencesDefaultGroupsTab from '@/views/workspaces/settings/UserPreferencesDefaultGroupsTab'

const store = useStore()

const { t, locale } = useI18n()

const isJiraApp = inject(isJiraAppInjectionKey, false)

const userData = computed(() => store.state.system.userData)

const localeOptions = computed(() => [
  {
    value: 'en',
    // label: 'English (US)',
    label: 'English',
    icon: 'flag-us'
  },
  {
    value: 'zh_CN',
    label: '中文 (简体)',
    icon: 'flag-cn'
  },
  {
    value: 'pt_BR',
    label: 'Português (Brasil)',
    icon: 'flag-pt'
  },
  {
    value: 'de_DE',
    label: 'Deutsch',
    icon: 'flag-de'
  },
  {
    value: 'uk',
    label: 'Українська',
    icon: 'flag-ua'
  }
])

const changedLocale = locale => {
  store.dispatch('system/updateUserSettings', {
    [USER_SETTINGS_MAPPER[APP_LOCALE]]: locale
  })
  updateStorageByKey(LOCALE, locale)
  dayjs.locale(transformLocaleToDayjsFormat(locale))

  tracker.logEvent('Set language', {
    category: EVENT_CATEGORIES.PERSONAL_SETTINGS,
    label: locale
  })
}

/*const LANGUAGE_TAB = 'LANGUAGE_TAB'
const DEFAULT_GROUPS_TAB = 'DEFAULT_GROUP_TAB'*/
/*const activeTab = ref(LANGUAGE_TAB)

const TABS = computed(() => [
  {
    value: LANGUAGE_TAB,
    label: t('user.preferences.change_language'),
    trackingTab: 'user.preferences.change_language'
  },
  {
    value: DEFAULT_GROUPS_TAB,
    label: t('user.preferences.default_group', 2),
    trackingTab: 'user.preferences.default_group'
  }
])*/

/*const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})*/
/*const onViewChange = view => {
  const page = TABS.value.find(page => page.value === view)

  if (page) {
    const trackerData = {
      category: EVENT_CATEGORIES.SETTINGS,
      subCategory: t('menu.personal_settings', 1, { locale: 'en' }),
      role: userRoleForTracking.value
    }

    if (page.trackingTab) {
      trackerData.tab = t(page.trackingTab, 1, { locale: 'en' })
    }

    tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
  }
}*/

const { onRenameUser, name, isNameValid } = useRenameUser({
  initialName: userData.value.userName
})

const onUpdateName = async () => {
  try {
    await onRenameUser()
  } catch (error) {
    handleError({ error })
  }
}
</script>

<style lang="scss" scoped>
.up-UserPreferencesPage {
  padding-bottom: $page-bottom-padding;
  max-width: $page-width-lg;
  width: 100%;
}

.up-MainContent {
  padding: 4px $page-right-padding 0 $page-left-padding;
  display: grid;
  gap: 40px;
}

.up-Divider {
  width: 100%;
}
.up-SelectLang {
  width: 400px;
}

.up-UserData {
  display: grid;
  gap: 20px;
}

.up-UserData_Header {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.up-UserData_Field {
  max-width: 400px;
}
</style>
