<template>
  <div v-if="isOwner && !isBillingModuleDisabled">
    <AppUnavailablePageLayout>
      <router-view v-if="ALLOWED_ROUTES.includes(route.name)" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
      <BillingSettings v-else />
    </AppUnavailablePageLayout>
  </div>
  <LicenseInactiveView v-else />
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { useDisabledModules } from '@/utils/cross-platform/disabled-modules'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import AppUnavailablePageLayout from '@shared-modules/components/AppUnavailablePageLayout'
import BillingSettings from '@shared-modules/views/workspaces/settings/billing/billing-settings'

import LicenseInactiveView from '@/views/LicenseInactiveView'

defineOptions({
  name: 'AppBillingMissing'
})

const store = useStore()
const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
const route = useRoute()

const { isBillingModuleDisabled } = useDisabledModules()

const ALLOWED_ROUTES = isBillingModuleDisabled.value
  ? []
  : [
      ROUTE_NAMES.SETTINGS_BILLING_SETTINGS,
      ROUTE_NAMES.SETTINGS_BILLING_INFORMATION,
      ROUTE_NAMES.SETTINGS_BILLING,
      ROUTE_NAMES.SETTINGS_BILLING_PROMO
    ]
</script>

<style lang="scss" scoped></style>
