<template>
  <div class="cdsv-Body">
    <!--   slides-per-view="auto" need for dialog when edit/create dashboard fro showing as much as possible elements     -->
    <swiper
      :breakpoints="swiperBreakpoints"
      :modules="[Navigation]"
      :navigation="navigationOptions"
      :space-between="20"
      class="cdsv-Slider"
      data-auto-testid="objective-box-wrapper"
      grab-cursor
      slides-per-view="auto"
      watch-slides-progress
      @init="initPresentationMode"
    >
      <AppButton
        class="swiper-button-next cdsv-NavigationButton"
        data-auto-testid="next-slide"
        data-export-ignore
        icon="chevron-right-next"
      />
      <AppButton
        class="swiper-button-prev cdsv-NavigationButton"
        data-auto-testid="prev-slide"
        data-export-ignore
        icon="chevron-left-next"
      />

      <swiper-slide v-if="showSliderAddElement" class="cdsv-SwiperSlide">
        <CustomDashboardListItem
          :title="$t('create.objective.btn.add')"
          class="cdsv-AddObjective-EmptyState"
          data-auto-testid="add-objective-button"
          size="grow"
          style="--border-style: dashed"
          @click="showAddObjectivesModal"
        />
      </swiper-slide>

      <swiper-slide
        v-for="(objective, objectiveIndex) in elements"
        :key="objective.id"
        class="cdsv-SwiperSlide"
      >
        <CustomDashboardListItemDetailed
          :autoplay="isRunCarousel"
          :is-cursor-outside="isCursorOutside"
          :objective="objective"
          :readonly="isReadonly"
          :show-caret="selectedItem?.id === objective.id"
          :style="{
            '--border-color': getBorderColor(objective),
            ...getGradeColorStyle(objective.gradeColor)
          }"
          data-auto-testid="objective-box-item"
          @click="selectItem(objective, objectiveIndex)"
          @remove-selected-objective="removeSelectedObjective"
          @open-objective="onOpenObjectiveDetails"
        />
      </swiper-slide>

      <template v-if="showSliderEmptyStates">
        <swiper-slide v-for="n in emptyStatesQty" :key="n" class="cdsv-SwiperSlide">
          <div class="cdsv-SwiperSlide-EmptyState" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script setup>
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { computed, onUnmounted, ref, watch } from 'vue'

import { MAX_OBJECTIVES, MIN_OBJECTIVES } from '@/utils/custom-dashboard-helper'
import { getGradeColorStyle } from '@/utils/okr-elements/grade'

import CustomDashboardListItem from '@/components/dashboard/custom-dashboard/CustomDashboardListItem'
import CustomDashboardListItemDetailed from '@/components/dashboard/custom-dashboard/CustomDashboardListItemDetailed'
import AppButton from '@/components/ui/AppButton/AppButton'

// eslint-disable-next-line import/extensions
import 'swiper/css'
// eslint-disable-next-line import/extensions
import 'swiper/css/navigation'

const navigationOptions = {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev'
}

defineOptions({
  name: 'CustomDashboardSliderView'
})

const props = defineProps({
  isReadonly: {
    type: Boolean
  },

  elements: {
    type: Array,
    required: true
  },

  isRunCarousel: {
    type: Boolean
  },

  isCursorOutside: {
    type: Boolean
  },

  selectedItem: {
    type: Object,
    default: () => ({})
  },

  slideIndex: {
    type: Number,
    required: true
  },

  timeout: {
    type: Number,
    required: true
  }
})

const elementsLength = computed(() => {
  return props.elements.length || 0
})

const swiperBreakpoints = computed(() => {
  return props.isReadonly
    ? {
        320: {
          slidesPerView: 3
        },
        1320: {
          slidesPerView: elementsLength.value < 3 ? 3 : 4
        },
        1600: {
          slidesPerView:
            elementsLength.value < 3 ? 3 : elementsLength.value < 5 ? elementsLength.value : 5
        }
      }
    : {}
})
const showSliderAddElement = computed(() => {
  return props.elements.length < MAX_OBJECTIVES && !props.isReadonly
})
const showSliderEmptyStates = computed(() => {
  return props.elements.length < MIN_OBJECTIVES && props.isReadonly
})

const emptyStatesQty = computed(() => {
  return MIN_OBJECTIVES - props.elements.length
})
const emit = defineEmits({
  'select-item': null,
  'remove-objective': null,
  'open-objective': null,
  'show-add-objectives-modal': null,
  'update:slideIndex': null,
  'update:selectedItem': null
})

const getBorderColor = objective => {
  const opacity = `${props.selectedItem?.id === objective.id ? 1 : 0.2}`
  return `rgba(${getGradeColorStyle(objective.gradeColor)['--rgb-color']}, ${opacity})`
}

const selectItem = (objective, objectiveIndex) => {
  emit('select-item', objective, objectiveIndex)
}

const removeSelectedObjective = payload => {
  emit('remove-objective', payload)
}

const onOpenObjectiveDetails = objective => {
  emit('open-objective', objective)
}
const showAddObjectivesModal = () => {
  emit('show-add-objectives-modal')
}

const timeoutId = ref(null)
const START_INDEX = 0
const swiperRef = ref(null)
const initPresentationMode = slider => {
  if (!swiperRef.value) {
    swiperRef.value = slider
  }

  if (!props.isReadonly || !props.isRunCarousel) return // если режим редактирования то не запускаем карусель

  emit('update:slideIndex', props.slideIndex + 1)
  emit('update:selectedItem', props.elements[props.slideIndex + 1]) // меняем выбранный элемент
  // если дошли до конца, то возвращаемся к началу
  if (props.slideIndex + 1 >= elementsLength.value) {
    emit('update:slideIndex', START_INDEX)
    emit('update:selectedItem', props.elements[START_INDEX]) // меняем выбранный элемент
  }

  const { visibleSlidesIndexes } = swiperRef.value

  if (!visibleSlidesIndexes.includes(props.slideIndex)) {
    swiperRef.value?.slideTo(props.slideIndex - START_INDEX) // переключаемся на слайд
  }

  timeoutId.value = setTimeout(initPresentationMode, props.timeout) // запускаем таймер
}

watch(
  () => props.isRunCarousel,
  value => {
    if (!value) {
      clearTimeout(timeoutId.value)
    } else {
      timeoutId.value = setTimeout(initPresentationMode, props.timeout)
    }
  }
)
watch(
  () => props.isCursorOutside,
  value => {
    if (!value) {
      clearTimeout(timeoutId.value)
    } else {
      timeoutId.value = setTimeout(initPresentationMode, props.timeout)
    }
  }
)
onUnmounted(() => {
  clearTimeout(timeoutId.value)
})
</script>

<style lang="scss" scoped>
.cdsv-Body {
  .cdsv-SwiperSlide {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    //width: 20%;
    //max-width: 20%;
    //max-width: 220px;
    min-width: 250px;
    min-height: 213px;
    height: auto;
  }
}
.cdsv-SwiperSlide-EmptyState {
  border: 2px solid $grey-3-next;
  border-radius: $border-radius-lg-next;
  width: 100%;
  height: calc(100% - 24px);
}
.cdsv-AddObjective-EmptyState {
  height: calc(100% - 24px);
  min-height: 213px;
  width: 100%;
}
.cdsv-NavigationButton {
  color: $white;
  --swiper-navigation-size: 48px;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  --swiper-navigation-top-offset: calc(
    50% - calc(var(--swiper-navigation-size) / 2) + 12px
  ); // 12px - half of height of caret under slide
  border-radius: 60px;
  background: $dark-2;
  padding: 0;

  &:after {
    display: none;
  }
  &:hover {
    background: darken($dark-2, 10%);
  }
  &:active {
    background: lighten($dark-2, 10%);
  }
}
.cdsv-Body {
  //padding-left: $page-left-padding;
  //padding-right: $page-right-padding;
}
</style>
<style lang="scss">
.cdsv-Body {
  .csi-List_Item-count {
    flex: 0 0 auto;
  }
  .swiper-button-disabled {
    display: none;
  }
}
</style>

<style lang="scss">
.cdc-Body {
  &.cdc-Body-readonly {
    .cdsv-SwiperSlide {
      //max-width: 20%;
      //max-width: calc(100% / v-bind(elementsLength));
      //min-width: 250px;
    }
  }
  &:not(.cdc-Body-readonly) {
    .cdsv-SwiperSlide {
      width: 250px;
    }
  }
}
</style>
