<template>
  <div class="cdl-Container">
    <div
      :class="{
        'cdl-Wrapper-active': showCaret,
        'cdl-Wrapper-play': autoplay && isCursorOutside,
        'cdl-Wrapper-paused': autoplay && !isCursorOutside
      }"
      class="cdl-Wrapper"
    >
      <span
        v-tippy="{
          content: userHasReadAccess ? `[${objective.displayId}] ${objective.name}` : '',
          placement: 'top-start',
          theme: DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES
        }"
        class="cdl-Title"
      >
        <OkrElementCipheredName
          :okr-element="objective"
          style="--text-color: var(--grade-default-color)"
        />
      </span>
      <AppButton
        v-if="readonly && userHasReadAccess"
        class="cdl-ButtonOpenObjective"
        icon="arrow-right-simple-next"
        size="sm"
        type="ghost-next"
        @click.stop="emit('open-objective', objective)"
      />
      <div class="cdl-Description">
        <span class="cdl-Description_Title">
          <AnimatedNumber
            v-slot="{ displayValue }"
            :end-amount="getSimplifiedNumber(objective.progress)[PLURALIZATION_KEYS.FLOAT_NUMBER]"
          >
            {{
              `${displayValue}${getSimplifiedNumber(objective.progress)[PLURALIZATION_KEYS.SYMBOL]}`
            }}
          </AnimatedNumber>
          <sub class="cdl-Title_SubText"> % </sub>
        </span>
        <span class="cdl-Granulation">
          <span class="cdl-Granulation_Text">{{ $t('dashboard.last_qty_days', { qty: 7 }) }}</span>
          <span class="cdl-Granulation_Text">
            {{ getLastWeekProgress }}
          </span>
        </span>
      </div>
      <IndicatorPanel
        :background-color="`rgba(var(--rgb-color), 0.2)`"
        :color="`var(--color)`"
        :indicator-width="objective.progress"
        style="--height: 6px"
      />
      <!--   modifier stop placed for prevent selecting element   -->
      <ClearButton
        v-if="!readonly"
        class="cdl-CloseButton"
        data-auto-testid="remove-objective-button"
        @click.stop="emit('remove-selected-objective', objective)"
      />
    </div>
    <div v-visible="showCaret" class="cdl-CaretDown">
      <AppIcon height="24" icon-name="arrow-down-simple-next" width="24" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { currentUserCanReadObjective } from '@/utils/objectives'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'

import AnimatedNumber from '@/components/ui/AnimatedNumber/AnimatedNumber'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import ClearButton from '@/components/ui/ClearButton/ClearButton'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'
import OkrElementCipheredName from '@/components/ui/OkrElementCipheredName'

defineOptions({
  name: 'CustomDashboardListItemDetailed'
})

const props = defineProps({
  readonly: {
    type: Boolean
  },
  showCaret: {
    type: Boolean
  },
  objective: {
    type: Object,
    default: () => ({})
  },

  autoplay: {
    type: Boolean
  },

  isCursorOutside: {
    type: Boolean
  }
})

const emit = defineEmits({
  'remove-selected-objective': null,
  'open-objective': null
})

const getLastWeekProgress = computed(() => {
  const lastWeekProgress = props.objective.lastWeekProgress
  const simplifiedNumber = getSimplifiedNumber(lastWeekProgress)[PLURALIZATION_KEYS.SIMPLIFIED]
  return `+${simplifiedNumber}%`
})

const userHasReadAccess = computed(() => {
  return currentUserCanReadObjective(props.objective)
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/custom-dashboard';

.cdl-Container {
  height: calc(100% - 24px);
  max-width: 100%;
  min-width: 200px;
  width: 100%;
  &:hover {
    .cdl-ButtonOpenObjective {
      display: flex;
    }
  }
}
.cdl-Wrapper {
  font-family: $system-ui;
  color: $dark-1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-height: 213px;
  border-radius: $border-radius-lg-next;
  padding: 20px;
  cursor: pointer;
  position: relative;
  container-type: inline-size;
  background: $white;
  overflow: hidden;

  > * {
    z-index: 1;
  }
  &:hover {
    background: $grey-3-next;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    border-radius: $border-radius-lg-next;
    height: 100%;
    border: 2px var(--border-style, solid) var(--border-color, $grey-3-next);
    pointer-events: none;
  }
  &-active {
    &:after {
      border-width: 4px;
    }
    &:before {
      position: absolute;
      content: '';
      left: 4px;
      top: 0;
      height: 100%;
      width: calc(100% - 4px);
      background: rgba(var(--rgb-color), 0.07);
      border-top-left-radius: $border-radius-lg-next;
      z-index: 0;
      transform: translate3d(-100%, 0, 0);
      pointer-events: none;
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-active#{&}-play {
    &:before {
      animation: carouselProgress var(--autoplay-duration);
    }
  }

  @keyframes carouselProgress {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @container (min-width: 240px) {
    //@extend %high-title; // ignored container width
    &:deep(.obn-ObjectiveName) {
      font-size: $fs-20;
      line-height: 28px;
    }
    .cdl-Title {
      font-size: $fs-20;
      line-height: 28px;
    }
    .cdl-Description_Title {
      font-size: $fs-32;
    }
    .cdl-Description {
      flex: 0 1 auto;
      margin: 0;
    }
    .cdl-Title_SubText {
      bottom: -0.2em;
    }
    .cdl-Granulation {
      justify-content: center;
      margin: 0;
    }
  }
}

.cdl-Title {
  font-family: $system-ui;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  margin: 0 0 15px 0;
  flex: 1 1 auto;
  max-width: calc(100% - 24px);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: normal;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.cdl-CloseButton {
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background-color: lighten($dark-grey, 60%);
  }
  &:active {
    background-color: lighten($dark-grey, 55%);
  }
}
.cdl-Description_Title {
  font-size: $fs-42;
  font-weight: fw('bold');
  line-height: 48px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.cdl-Title_SubText {
  font-size: $fs-20;
  line-height: 24px;
  font-weight: fw('semi-bold');
  bottom: -0.4em;
}
.cdl-Granulation {
  display: flex;
  flex-direction: column;
  font-weight: fw('regular');
  font-size: $fs-12;
  align-items: flex-end;
  text-align: end;
  color: $dark-3;
  height: 100%;
  justify-content: flex-end;
  line-height: 64px;
  margin-bottom: 14px;
  &_Text {
    line-height: 16px;
  }
}
.cdl-Description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
  width: 100%;
  color: var(--color);
  margin: 0 0 8px 0;
  gap: 10px;
}
.cdl-CaretDown {
  color: var(--color);
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}
.cdl-ButtonOpenObjective {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $primary-color-next;
  z-index: 2;
  display: none;
}
</style>
