<template>
  <AppDialog
    :disable-confirm-button="disableConfirmButton"
    :loading-confirm-button="loading"
    :show="show"
    :title="$t('action.restore')"
    hide-hero
    @on-close="onRestoreModalClose"
    @on-confirm="onConfirm"
  >
    <div class="trm-Subtitle">
      {{ $t('bin.restore_subtitle') }}
    </div>
    <WorkspaceIntervalParentSelector
      ref="WorkspaceIntervalParentSelectorRef"
      v-model="formModel"
      auto-set-parent-item
      class="smw-MainContent"
    />
  </AppDialog>
</template>

<script setup>
import { computed, ref, useTemplateRef } from 'vue'

import BinApiHandler from '@/api/bin'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'

import AppDialog from '@/components/AppDialog'
import { useWsWithParent } from '@/components/objectives/objective-move/utils/move-utils'
import WorkspaceIntervalParentSelector from '@/components/objectives/objective-move/WorkspaceIntervalParentSelector'

const props = defineProps({
  show: {
    type: Boolean
  },

  elementsIds: {
    type: Array,
    default: () => []
  }
})

const onRestoreModalClose = () => {
  emit('update:show', false)
}
const emit = defineEmits(['update:show', 'success'])

const { formModel } = useWsWithParent()

const loading = ref(false)

const onConfirm = async () => {
  try {
    loading.value = true
    const BinApi = new BinApiHandler()
    await BinApi.restoreDataFromBin({
      elementIds: props.elementsIds,
      intervalId: formModel.value.intervalId,
      parentId: formModel.value.parentId,
      workspaceId: formModel.value.workspaceId
    })

    emit('success')
    showNotify()
    onRestoreModalClose()
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}
const disableConfirmButton = computed(() => {
  return (
    Object.values(formModel.value).some(item => !item) ||
    loaders.value.intervalLoader ||
    loaders.value.parentLoader
  )
})

const WorkspaceIntervalParentSelectorRef = useTemplateRef('WorkspaceIntervalParentSelectorRef')
const loaders = computed(() => WorkspaceIntervalParentSelectorRef.value?.loaders || {})
</script>

<style lang="scss" scoped>
.trm-Subtitle {
  margin-bottom: 20px;
}
</style>
