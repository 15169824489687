import { useGtm } from '@gtm-support/vue-gtm'
import { has } from 'lodash'

import { EVENT_KEYS } from '@/tracking/gtm-helpers'
import { replaceJwt } from '@/utils/general'

class GtmTracker {
  constructor() {}

  init({
    instanceId = null,
    email = 'unknown',
    isPluginAdmin = false,
    isWorkspaceAdmin = false
  } = {}) {
    const [userRole] = [
      isPluginAdmin && 'pluginAdmin',
      isWorkspaceAdmin && 'workspaceAdmin',
      'user'
    ].filter(Boolean)

    this.gtm = useGtm()
    this.instanceId = instanceId
    this.email = email
    this.isInitialized = true
    this.userRole = userRole
  }

  logEvent(eventName, props = {}) {
    if (!this.isInitialized) return
    if (!eventName) return
    if (!this.gtm) return

    const payload = {
      event: eventName,
      instance_id: this.instanceId,
      email: this.email,
      user_role: this.userRole,
      ...props
    }

    if (has(payload, EVENT_KEYS.PAGE_URL)) {
      payload[EVENT_KEYS.PAGE_URL] = replaceJwt(payload[EVENT_KEYS.PAGE_URL])
    }

    this.gtm.trackEvent(payload)
  }
}

export const gtmTracker = new GtmTracker()
