<template>
  <div
    ref="customDashboardContentRef"
    :class="{ 'cdi-PageWrapper-is-server': isServer }"
    class="cdi-PageWrapper"
  >
    <div v-if="!loading" :class="{ [WRAPPER_CLASS]: true }">
      <div class="cdi-Header">
        <div>
          <AppButton
            class="cdi-Header-Button"
            data-export-ignore
            height="24"
            icon="arrow-left-next"
            remove-padding
            size="sm"
            type="ghost-next"
            width="24"
            @click="backToCustomDashboardList"
          />
        </div>
        <div class="cdi-Header_Right">
          <AppRadioGroupCommon
            :model-value="formModel.layoutTypeId"
            :options="getLayoutTypeId"
            data-auto-testid="layout-switch"
            data-testid="layout-switch"
            gap="0px"
            name="layoutOrientation"
            style="--option-padding: 0"
            @update:model-value="changeLayoutTypeId"
          />

          <AppButton
            v-tippy="{
              content: $t('objective.chart.fullscreen'),
              placement: 'bottom'
            }"
            :icon="fullscreenIcon"
            class="cdi-Header-Button"
            data-export-ignore
            remove-padding
            size="sm"
            type="ghost-next"
            @click="onFullscreenClick"
          />
          <AppDivider data-export-ignore vertical />
          <AppButton
            v-tippy="{
              content: $t(
                formModel.favorite ? 'action.remove_from_favorites' : 'action.add_to_favorites'
              ),
              placement: 'bottom',
              theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
            }"
            :class="{ 'cdi-ButtonFavorite-active': formModel.favorite }"
            :disable="isLoadingFavoriteToggle"
            :icon="formModel.favorite ? 'favorite-filled' : 'add-to-favorites-black'"
            class="cdi-Header-Button"
            data-export-ignore
            height="24"
            remove-padding
            size="sm"
            type="ghost-next"
            width="24"
            @click="toggleFavorite"
          />
          <AppButton
            v-tippy="{
              content: $t('action.share'),
              placement: 'bottom',
              theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
            }"
            class="cdi-Header-Button"
            data-export-ignore
            height="24"
            icon="share"
            remove-padding
            size="sm"
            type="ghost-next"
            width="24"
            @click="isShowSharing = true"
          />
          <AppAvatar
            v-tippy="{
              content: $t('dashboard.created_by', { userName: getOwner.ownerName }),
              placement: 'bottom',
              theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT
            }"
            :avatar-url="getOwner.ownerAvatarUrl"
            no-margins
          />
          <DropdownMenu
            :items="DROPDOWN_MENU_ACTIONS"
            :offset="[0, 0]"
            :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
            data-export-ignore
            preferred-position="bottom-end"
            @item-click="onMenuActionsClick"
          >
            <template #activator>
              <AppButton data-export-ignore icon="more-next" size="sm" type="subtle" />
            </template>
          </DropdownMenu>
          <AppExportLogo />
        </div>
      </div>

      <CustomDashboardContent
        :form-model="formModel"
        :is-export-proceed="isExportProceed"
        :is-run-carousel="isRunCarousel"
        is-readonly
        @update-data="getDashboard"
      />
    </div>
    <DashboardItemLoader v-else />
    <EditCustomDashboard
      v-model:show-modal="showModal"
      :model-value="formModel"
      @open-add-objective-modal="showAddObjectiveModal = true"
      @update-list="getDashboard({ withLoader: false })"
    />
    <AddObjectiveModal
      :model-value="formModel"
      :show="showAddObjectiveModal"
      @on-close="showAddObjectiveModal = false"
      @on-success="onAddedObjectives"
    />

    <DuplicateCustomDashboard
      v-model:show="showDuplicateModal"
      :dashboard-to-duplicate="dashboardItem"
    />
    <RemoveCustomDashboard
      :dashboard-for-delete="formModel"
      :show-confirm-delete-dialog="showConfirmDeleteDialog"
      @close="showConfirmDeleteDialog = false"
      @on-success="onSuccessRemoveDashboard"
    />
    <ShareCustomDashboard
      v-model:show="isShowSharing"
      :can-edit="isOwner || isPluginAdmin"
      :dashboard-for-share="dashboardItem"
      @on-confirm="getDashboard"
    />
  </div>
</template>

<script setup>
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { isNull } from 'lodash'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SECTIONS } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import {
  getLayoutTypeId,
  HORIZONTAL,
  LAYOUT_NAMES_BY_TYPE_ID,
  LAYOUT_VIEW_TYPES,
  VERTICAL
} from '@/utils/custom-dashboard-helper'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import {
  addExtraSpaceForFirefox,
  addVisibilityForLogo,
  alignOkrIcons,
  fixIndicatorPanel,
  ignoreElementsForExport,
  replaceAvatars,
  replaceSvgIcons,
  replaceTaskIcons
} from '@/utils/html2canvas-helpers'
import { checkResponseStatus, RESPONSE_STATUSES } from '@/utils/response-statuses'

import AppExportLogo from '@/components/AppExportLogo'
import CustomDashboardContent from '@/components/dashboard/custom-dashboard/CustomDashboardContent'
import DashboardItemLoader from '@/components/dashboard/custom-dashboard/DashboardItemLoader'
import AddObjectiveModal from '@/components/dashboard/custom-dashboard/modals/AddObjectiveModal'
import DuplicateCustomDashboard from '@/components/dashboard/custom-dashboard/modals/DuplicateCustomDashboard'
import EditCustomDashboard from '@/components/dashboard/custom-dashboard/modals/EditCustomDashboard'
import RemoveCustomDashboard from '@/components/dashboard/custom-dashboard/modals/RemoveCustomDashboard'
import ShareCustomDashboard from '@/components/dashboard/custom-dashboard/modals/ShareCustomDashboard'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppRadioGroupCommon from '@/components/ui/AppRadioGroup/AppRadioGroupCommon'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'

defineOptions({
  name: 'CustomDashboardItem'
})

const store = useStore()
const router = useRouter()

const WRAPPER_CLASS = 'cdi-Wrapper'

const fullscreenIcon = computed(() => {
  return fullscreen.value ? 'fullscreen-off' : 'fullscreen-on'
})
const onFullscreenClick = () => {
  store.dispatch('system/toggleFullscreen')
}

const isServer = computed(() => store.getters['pluginOptions/isPluginServer'])
onBeforeUnmount(() => {
  if (fullscreen.value) {
    store.dispatch('system/toggleFullscreen')
  }
})

const isShowSharing = ref(false)
const showAddObjectiveModal = ref(false)

const backToCustomDashboardList = () => {
  router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST })
}
const fullscreen = computed(() => store.state.system.fullscreen)
const DEFAULT_FORM_MODEL = {
  name: '',
  backgroundId: 0,
  layoutTypeId: LAYOUT_VIEW_TYPES[HORIZONTAL]
}
const formModel = ref({ ...DEFAULT_FORM_MODEL })
const route = useRoute()

const dashboardItem = computed(() => store.state.dashboard.dashboardItem)

const setFormModel = value => {
  formModel.value = { ...value }

  if (isNull(value.layoutTypeId)) {
    formModel.value.layoutTypeId = LAYOUT_VIEW_TYPES[HORIZONTAL]
  }
}
watch(
  () => dashboardItem.value,
  val => {
    setFormModel(val)
  },
  { deep: true }
)

const loading = ref(true)
const getDashboard = async ({ withLoader = true } = {}) => {
  try {
    if (withLoader) {
      loading.value = true
    }
    const id = route.params.id
    await store.dispatch('dashboard/getDashboardItem', id)
    setFormModel(dashboardItem.value)
  } catch (error) {
    if (checkResponseStatus({ error, status: RESPONSE_STATUSES.FORBIDDEN })) {
      await router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_ACCESS_DENIED })
    } else {
      await router.push({ name: ROUTE_NAMES.MISSING_PAGE })
    }
    handleError({ error })
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await getDashboard()
})
watch(
  () => route.params.id,
  val => {
    if (val) {
      getDashboard()
    }
  }
)

const showModal = ref(false)

const isRunCarousel = ref(false)
const toggleCarousel = () => {
  isRunCarousel.value = !isRunCarousel.value
}
const onEdit = () => {
  isRunCarousel.value = false
  showModal.value = true
}
const customDashboardContentRef = ref(null)
const isExportProceed = ref(false)
const exportCustomDashboard = async () => {
  isRunCarousel.value = false
  isExportProceed.value = true
  await nextTick()
  const wrapper = customDashboardContentRef.value
  const { offsetWidth: width, offsetHeight: height } = wrapper

  const customWidth = width > 1200 ? 1200 : width

  await store.dispatch('system/toggleFullAppLoader', {
    flag: true,
    alpha: 1,
    backgroundColor: '#ffffff',
    circleColor: '#42526e'
  })
  await nextTick()

  // setTimeout is for avoid start of export before the loader is shown and for showing chart
  setTimeout(async () => {
    const canvas = await html2canvas(wrapper, {
      onclone: clonedNode => {
        clonedNode.querySelector(`.${WRAPPER_CLASS}`).style.maxWidth = customWidth + 'px'
        addVisibilityForLogo(clonedNode)
        const backgroundWrapper = clonedNode.querySelector('.cdi-PageWrapper')
        backgroundWrapper.style.background = 'none'
        const hiddenNames = clonedNode.querySelectorAll('.ocn-Name-hidden')
        hiddenNames.forEach(item => {
          item.style['background-color'] = 'transparent'
          item.style['background-image'] = 'none'
        })

        addExtraSpaceForFirefox(clonedNode)
        alignOkrIcons(clonedNode)
        replaceAvatars(clonedNode)
        replaceTaskIcons(clonedNode)
        replaceSvgIcons(clonedNode)
        fixIndicatorPanel(clonedNode)
      },
      scale: 1.75,
      useCORS: true, // necessary to display base64 images
      imageTimeout: 0,
      height,
      width: customWidth,
      ignoreElements: ignoreElementsForExport()
    })
    const dataUrl = canvas.toDataURL('image/png')

    const pdfFile = new jsPDF({
      orientation: customWidth > height ? 'landscape' : 'portrait',
      unit: 'px',
      format: [customWidth, wrapper.offsetHeight],
      compress: true
    })
    pdfFile.addImage(dataUrl, 'PNG', 0, 0, customWidth, wrapper.offsetHeight)
    const { name = '' } = formModel.value

    const format = name.includes('.') ? '.pdf' : ''

    pdfFile.save(`custom_dashboard_${name}${format}`)
    isExportProceed.value = false
    await store.dispatch('system/toggleFullAppLoader')
  })
}

const { REMOVE, EDIT, DUPLICATE, EXPORT, TOGGLE_PRESENTATION, ADD } = ACTIONS_KEYS

const isVerticalView = computed(() => {
  return formModel.value.layoutTypeId === LAYOUT_VIEW_TYPES[VERTICAL]
})

const DROPDOWN_MENU_ACTIONS = computed(() => {
  const { EDITING, REMOVING } = MENU_ITEMS_GROUPS
  const result = []

  if (isOwner.value || isPluginAdmin.value) {
    result.push({
      name: ADD,
      title: 'dashboard.add_objectives',
      icon: 'plus-next',
      group: EDITING
    })

    result.push({
      name: EDIT,
      title: 'action.edit',
      icon: 'edit-next',
      group: EDITING
    })
  }

  const togglePresentationItem = isVerticalView.value
    ? null
    : {
        name: TOGGLE_PRESENTATION,
        title: isRunCarousel.value ? 'dashboard.stop_presentation' : 'dashboard.start_presentation',
        icon: isRunCarousel.value ? 'pause' : 'play-next',
        group: EDITING
      }

  result.push(
    {
      name: DUPLICATE,
      title: 'dropdown.copy',
      icon: 'duplicate-next',
      group: EDITING
    },
    togglePresentationItem,
    {
      name: EXPORT,
      title: 'dashboard.export_as_pdf',
      icon: 'export-pdf',
      group: EDITING
    }
  )
  if (isOwner.value || isPluginAdmin.value) {
    result.push({
      name: REMOVE,
      title: 'action.delete',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: REMOVING
    })
  }

  return result.filter(Boolean)
})

const onDuplicate = () => {
  showDuplicateModal.value = true
}
const onRemove = () => {
  showConfirmDeleteDialog.value = true
}

const onMenuActionsClick = name => {
  if (name === EDIT) {
    onEdit()
  } else if (name === DUPLICATE) {
    onDuplicate()
  } else if (name === TOGGLE_PRESENTATION) {
    toggleCarousel()
  } else if (name === EXPORT) {
    exportCustomDashboard()
  } else if (name === REMOVE) {
    onRemove()
  } else if (name === ADD) {
    onAdd()
  }
}

const onAdd = () => {
  showAddObjectiveModal.value = true
}

const onAddedObjectives = () => {
  getDashboard()
}

const showDuplicateModal = ref(false)
const showConfirmDeleteDialog = ref(false)
const onSuccessRemoveDashboard = () => {
  router.push({ name: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST })
  if (formModel.value.favorite) {
    store.dispatch('dashboard/getFavoriteList')
  }
}

const getOwner = computed(() => {
  const { ownerAvatarUrl, ownerId, ownerName } = dashboardItem.value

  return {
    ownerAvatarUrl,
    ownerId,
    ownerName
  }
})

const isLoadingFavoriteToggle = ref(false)
const toggleFavorite = async () => {
  try {
    isLoadingFavoriteToggle.value = true
    if (formModel.value.favorite) {
      await store.dispatch('dashboard/toggleFavorite', formModel.value)
      formModel.value.favorite = false
    } else {
      await store.dispatch('dashboard/toggleFavorite', formModel.value)
      formModel.value.favorite = true
    }
  } catch (error) {
    handleError({ error })
  } finally {
    isLoadingFavoriteToggle.value = false
  }
}
const currentUser = computed(() => store.state.system.userData || {})
const isOwner = computed(() => {
  return getOwner.value.ownerId === currentUser.value.userAccountId
})
const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

const changeLayoutTypeId = async layoutTypeId => {
  try {
    const { id, name, backgroundId } = formModel.value
    const customDashboardApi = new CustomDashboardApiHandler()

    await customDashboardApi.updateDashboard({
      id,
      name,
      backgroundId,
      layoutTypeId,
      isPrivate: formModel.value.private
    })
    await getDashboard({ withLoader: false })
    await store.dispatch('dashboard/getDashboardList')
    await store.dispatch('dashboard/getFavoriteList')

    const mode = LAYOUT_NAMES_BY_TYPE_ID[layoutTypeId] || ''

    tracker.logEvent('custom dashboard updated', {
      category: EVENT_CATEGORIES.DASHBOARD,
      section: EVENT_SECTIONS.DASHBOARD,
      id,
      mode: mode.toLowerCase()
    })
  } catch (error) {
    handleError({ error })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_custom-dashboard-animations';

$customBackground: 'https://s3-oboard-public-static.s3.eu-central-1.amazonaws.com/img/dashboard_map.png';

.cdi-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.cdi-Header_Right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.cdi-Header-Button {
  color: $dark-2;
}
.cdi-PageWrapper {
  //padding-left: $page-left-padding;
  //padding-right: $page-right-padding;
  padding: calc(#{$page-top-padding} + 4px) 0 0 0;
  background: url($customBackground) top/100% no-repeat;
  &.cdi-PageWrapper-is-server {
    background-image: url('~assets/images/dashboard_map.png');
  }
}
.cdi-ButtonFavorite-active {
  @extend %energyPop;
}
</style>
<style lang="scss">
.cdi-PageWrapper {
  .cdi-Header {
    padding-left: $page-left-padding;
    padding-right: $page-right-padding;
  }
  .cdc-ModalContent {
    padding-left: $page-left-padding;
    padding-right: $page-right-padding;
  }
  .cdsv-Slider {
    padding-left: $page-left-padding;
    padding-right: $page-right-padding;
  }
}
</style>
