<template>
  <div
    :data-auto-test-inital-data-loaded="initialDataAreLoaded"
    class="krf-KeyResultForm"
    data-auto-testid="kr-form"
  >
    <portal :to="portalName">
      <OkrElementSelectItem
        v-if="getCurrentParentElement && disableOnEdit"
        :id-as-link="parenElementReadable && isEdit"
        :objective="getCurrentParentElement"
        rotate-toggle-sign
        @click-on-id="onParentObjectiveIdClick"
      />
      <AppSelect
        v-else
        ref="parentSelect"
        v-model="formModel.parentId"
        :bottom-fixed-items="getBottomFixedItems"
        :content-class="['kr-ParentObjective']"
        :disabled="disableOnEdit"
        :dropdown-search="true"
        :group-by="getParentObjectiveGroupName"
        :loading="isParentObjectivesLoading"
        :offset="[0, 0]"
        :options="getObjectivesAsOptions"
        :search-function="getParentObjectives"
        data-auto-testid="parent-field"
        data-testid="parent-field"
        item-value="id"
        @update:options="parentObjectives = $event"
        @update:model-value="onParentObjectiveUpdate"
      >
        <template #button="{ option, active }">
          <OkrElementSelectItem
            v-if="option"
            :active="active"
            :id-as-link="parenElementReadable && isEdit"
            :objective="option"
            data-auto-testid="parent-element"
            rotate-toggle-sign
            @click-on-id="onParentObjectiveIdClick"
          />
          <ImmediateParentLoader v-else />
        </template>

        <template #option-label="{ option }">
          <ObjectiveSelectItem v-if="option" :objective="option" :show-interval="true" />
        </template>

        <template #bottom-fixed-items="{ bottomFixedItems, searchString }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem v-if="!searchString" icon-name="info-next" no-hover>
              {{ item.text }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </AppSelect>

      <div class="krf-ContributeWrapper">
        <AppCheckbox
          v-model="formModel.contribute"
          :disabled="disableOnEdit"
          data-auto-testid="contribute-switch"
          @update:model-value="onContributeChange"
        >
          {{ $t('field.contribute.label') }}
        </AppCheckbox>
      </div>
    </portal>

    <SidebarLayout
      :update-scrollbar-triggers="updateScrollbarTriggers"
      scroll-to-top-on-scrollbar-update
    >
      <LockMessage
        v-if="lockedMessagesStates.isShowNoPermissionsMessage"
        :type="LOCK_MESSAGE_TYPES.WARNING"
        class="krf-NoPermissionsMessage"
      >
        {{ $t('lock_message', { element: $t('element.type.key_result') }) }}
      </LockMessage>

      <LockMessage
        v-if="lockedMessagesStates.isShowClosedElementMessage"
        :type="LOCK_MESSAGE_TYPES.INFO"
        class="krf-ClosedStatusMessage"
        icon-name="info-next"
      >
        <span>
          {{ $t('lock_message.grade_closed', { status: getOkrElementClosedStatusName }) }}
          <br />
          <span class="krf-ClosedStatusMessage_Description">
            {{ $t('lock_message.grade_closed.description') }}
          </span>
        </span>
      </LockMessage>

      <OkrTitleFiled
        ref="title"
        v-model="formModel.name"
        :is-error="isTitleEmpty"
        :placeholder="$t('create.keyResult')"
        :readonly="isFieldDisabled || isOkrElementClosed"
        class="krf-Title"
        data-auto-testid="name-field"
        data-testid="name-field"
        @blur="onNameUpdate"
        @update:model-value="isTitleEmpty = $event === ''"
      />

      <DescriptionField
        v-if="isOkrElementClosed ? formModel.description : true"
        ref="description"
        v-model="formModel.description"
        :actions="editable"
        :max-length="17500"
        :placeholder="$t('description.placeholder')"
        :readonly="disableOnEdit"
        :show-placeholder="!disableOnEdit"
        :workspace-id="formModel.workspaceId"
        data-auto-testid="description-field"
        data-testid="description-field"
        @cancel="cancelDescriptionEditing"
        @save="saveDescription"
      />

      <LockMessage
        v-if="lockedMessagesStates.isShowClosedParentMessage"
        :type="LOCK_MESSAGE_TYPES.WARNING"
        class="krf-LockMessage"
        icon-name="warning-icon"
      >
        <i18n-t keypath="lock_message.entity_parent_closed" scope="global">
          <template #parentOkr>
            {{ getParentOkrTypeName }}
          </template>
          <template #statusName> {{ getParentElementClosedStatusName }}</template>
        </i18n-t>
      </LockMessage>

      <div class="krf-ResultTypeWrapper">
        <ResultTypeSettings
          :count="resultTypeSettingsCount"
          :show-lock-message="
            isEdit && editable && !isOkrElementClosed && isResultTypeSettingsDisabled
          "
          :type="getResultTypeSettingsType"
        >
          <template #lock-message-description>
            <i18n-t
              class="krf-ResultTypeWrapper_LockMessageDescription"
              keypath="update.keyResult.result_type_lock_message_description"
              scope="global"
              tag="div"
            >
              <template #action>
                <template v-if="okrWeightsLimitations.isDisabled({ okrElement: modelValue })">
                  {{ $t('update.keyResult.result_type_lock_message_description_action') }}
                </template>
                <a v-else href="#" @click.prevent="$emit('open-custom-weights', modelValue)">
                  {{ $t('update.keyResult.result_type_lock_message_description_action') }}
                </a>
              </template>
            </i18n-t>
          </template>
          <template #left-side>
            <AppRadioGroup
              v-if="!disableOnEdit"
              :disabled="resultTypeSettingsDisabledStates.select"
              :model-value="formModel.fieldTypeId"
              :name="`result-type-${formModel.id || uid}`"
              :options="RESULT_TYPE_OPTIONS"
              data-auto-testid="value-type-field"
              data-testid="value-type-field"
              style="--option-padding: 0 8px 0 0"
              type="primary-next"
              @update:model-value="changeResultType"
            >
              <template #item-label="{ item }">
                <AppRadioGroupNextOptionWithIcon :option="item" />
              </template>
            </AppRadioGroup>
          </template>

          <template #right-side>
            <!-- key is necessary for automatically update masks on when values changed outside -->
            <ResultTypeSettingsDefaultRightSide
              :key="resultTypeSettingsUid"
              v-model:current-value="currentValue"
              v-model:original-value="originalValue"
              v-model:target-value="targetValue"
              :editable="editable && !isOkrElementClosed"
              :field-type-id="formModel.fieldTypeId"
              :form-model="resultTypeSettingsFormModel"
              :is-binary="isBinary"
              :is-binary-select-disabled="isBinarySelectDisabled"
              :is-edit="isEdit"
              :is-locked-on-percent="isLockedOnPercent"
              :values-are-readonly="valuesAreReadonly"
              data-testid="result-type-settings-controls"
              @update:binary-value="onBinaryValueUpdate"
              @blur:original-value="onOriginalValueUpdate"
              @blur:current-value="onCurrentValueUpdate"
              @blur:target-value="onTargetValueUpdate"
            />
          </template>
        </ResultTypeSettings>
      </div>

      <FormNestedItemsField
        v-if="isEdit"
        :is-expand-all-loading="isExpandAllLoading"
        :show-expand-collapse-button="someItemIsExpandable"
        :show-head="childKrs.length > 0"
        :title="$t('kr.nested_items')"
        @expand-all="expandAll"
        @collapse-all="collapseAll"
      >
        <template #actions>
          <WeightsModalTrigger
            v-if="showWeightsModalTrigger"
            v-tippy="{
              content: okrWeightsLimitations.getTooltip({ okrElement: modelValue }),
              placement: 'top',
              theme: `${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.PADDING_DEFAULT} ${DROP_LIST_THEMES.TEXT_CENTER} ${DROP_LIST_THEMES.WHITE_SPACE_PRE_LINE}`
            }"
            :disabled="okrWeightsLimitations.isDisabled({ okrElement: modelValue })"
            data-auto-testid="custom-weights-button"
            data-testid="custom-weights-button"
            @click="$emit('open-custom-weights', modelValue)"
          />

          <AddNestedItem
            v-if="userCanCreateObjectives && childKrsMenuItems.length"
            v-tippy="{
              placement: 'top',
              content: $t('kr.add_nested_item'),
              boundary: 'viewport'
            }"
            :dropdown-options="childKrsMenuItems"
            :follow-cursor="false"
            data-auto-testid="add-nested-element"
            simplify
            @on-option-click="onChildKrsMenuItemsClick"
          />
        </template>

        <OkrElementsList
          ref="childKRs"
          :allow-delete-action="!isOkrElementClosed"
          :allow-unlink-action="!isOkrElementClosed"
          :allowed-depth="1"
          :dim-nested-items-weights="dimNestedItemsWeights"
          :objectives="childKrs"
          :placement="LIST_ITEM_PLACEMENTS.OKR_ELEMENT_FORM"
          :some-item-is-expandable="someItemIsExpandable"
          always-show-weight
          fetch-child-objectives-on-expand
          item-type="primary-next"
          show-status
          type="default-next"
          @expand-item="expandItem"
          @update-elements="onChildUpdated"
          @okr-element-deleted="onChildUpdated"
          @link-jira-issue="$emit('link-jira-issue', getKrJiraIssuePayload($event))"
          @create-jira-issue="createJiraIssue($event)"
          @edit-element="$emit('edit-element', $event)"
        >
          <template v-if="!childKrs.length" #no-objectives>
            <AddNestedItem
              v-if="childKrsMenuItems.length"
              :disabled="!userCanCreateObjectives"
              :dropdown-options="childKrsMenuItems"
              :follow-cursor="true"
              data-auto-testid="add-nested-element"
              disable-margin
              @on-option-click="onChildKrsMenuItemsClick"
            >
              {{
                $t(
                  userCanCreateObjectives
                    ? 'kr.add_nested_item'
                    : 'objective.btn.no_nested_objectives'
                )
              }}
            </AddNestedItem>
          </template>
        </OkrElementsList>
      </FormNestedItemsField>

      <OkrElementsFormCustomFieldsHub
        v-model:loading="contentCustomFieldsLoading"
        :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
        :element-id="modelValue.id || null"
        :fields-values="customFieldsValues"
        :is-edit="isEdit"
        :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.KR.id"
        :user-has-update-access="userCanUpdateCustomFields"
        :workspace-id="workspaceId"
        @update-field-value="onUpdateCustomFieldValue"
        @update-activities="updateActivities"
      />

      <Activities v-if="isEdit" ref="activities" :model-value="modelValue" class="krf-Activities" />

      <template #sidebar>
        <div class="krf-Sidebar">
          <FormGradeAndStatus
            v-if="isEdit"
            :disabled="isFieldDisabled"
            :form-model="formModel"
            :grade="grade"
            :grade-color="modelValue.gradeColor"
            :predicted-score="showPredictedScore ? modelValue.predictedGrade : 0"
            :show-predicted-score="showPredictedScore"
            data-testid="form-grade-and-status"
            @update:confidence-level-id="onStatusUpdate"
          >
            <template #status-right>
              <StatusIndicator
                v-if="showPredictedScore && modelValue.timePassedPercent >= 0"
                :grade="modelValue.gradeToUse"
                :grade-color="modelValue.gradeColor"
                :okr-element="formModel"
                :passed="modelValue.timePassedPercent"
                :predicted-score="modelValue.predictedGrade"
                data-auto-testid="status-indicator"
                data-testid="status-indicator"
              />
            </template>
          </FormGradeAndStatus>

          <div
            v-if="showChart && modelValue.uniqueId"
            class="krf-Progress"
            data-auto-testid="progress-chart"
            data-testid="progress-chart"
          >
            <ObjectiveChart
              ref="objectiveChart"
              :element-id="modelValue.uniqueId"
              :grade-color="modelValue.gradeColor"
              :workspace-id="formModel.workspaceId"
              chart-type="simple-next"
              has-full-size-toggle
            />
          </div>

          <FormItemsGroup :disabled="disableOnEdit" data-auto-testid="general-fields-group">
            <AppSelect
              v-model="formModel.ownerId"
              :disabled="disableOnEdit"
              :loading="assigneesAreLoading"
              :offset="[0, 0]"
              :options="ownerFieldOptions"
              :search-function="getAssignees"
              data-auto-testid="owner-field"
              data-testid="owner-field"
              dropdown-search
              item-label="name"
              item-value="accountId"
              skeleton-loader
              @change="onOwnerSelect"
              @update:options="assignees = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.OWNER)"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.owner.placeholder')"
                  :opened="active"
                >
                  <OwnerFieldOption v-if="option" :option="option" />
                </OkrFormFieldSelectTriggerNext>
              </template>
              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
            </AppSelect>

            <AppSelect
              v-model="formModel.groups"
              :disabled="disableOnEdit"
              :loading="isTeamsLoading"
              :offset="[0, 0]"
              :options="groups"
              :search-function="getGroups"
              data-auto-testid="groups-field"
              data-testid="groups-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              inline-search
              item-label="name"
              item-value="id"
              multi
              show-selected-options-inside
              skeleton-loader
              @change="onGroupSelect"
              @update:options="groups = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.GROUP)"
            >
              <template #option-label="{ option }">
                <GlobalGroupsSelectOption v-if="option" :group="option" />
              </template>
              <template #button="{ fullDataOption, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.group.placeholder')"
                  :label="$t('field.groups.title')"
                  :opened="active"
                  :selected-options="fullDataOption"
                  style="--value-item-padding: 0"
                >
                  <template #value-item-content="{ item }">
                    <GroupSelectLabel v-if="item" :group="item" />
                  </template>
                </OkrFormFieldSelectTriggerNext>
              </template>
            </AppSelect>
          </FormItemsGroup>

          <FormItemsGroup :disabled="disableOnEdit" data-auto-testid="common-fields-group">
            <AppSelect
              :disabled="disableOnEdit"
              :hidden-items="archivedIntervals"
              :loading="isIntervalsLoading"
              :model-value="formModel.intervalId"
              :offset="[0, 0]"
              :options="getIntervalsAsOptions"
              data-auto-testid="interval-field"
              data-testid="interval-field"
              skeleton-loader
              @update:model-value="onUpdateInterval"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.quoter.placeholder')"
                  :label="$t('field.quoter.title')"
                  :opened="active"
                  :selected-options="option?.label || ''"
                  separated-label
                />
              </template>
            </AppSelect>

            <OkrFormFieldSelectTriggerNext
              :disabled="isFieldDisabled"
              :opened="showDatesDropdown"
              data-auto-testid="dates-field-wrapper"
              data-testid="dates-field-wrapper"
              @click="onDatesTriggerClick"
            >
              <template #label>
                <div class="krf-DatesLabel">
                  <span class="krf-DatesLabel_Text">
                    {{ $t('objectives.table_header_dates') }}
                  </span>
                  <AppIcon
                    :icon-name="iconName"
                    class="krf-DatesLabel_Icon"
                    height="24"
                    width="24"
                  />
                  <span class="krf-DatesLabel_Value">
                    <OkrDatesDropdownTrigger
                      :active="showDatesDropdown"
                      :form-model="formModel"
                      :is-auto-period-mode="isAutoPeriodMode"
                      multi-line
                      type="default-next"
                    />

                    <OkrDatesDropdown
                      v-if="!isFieldDisabled && initialDataAreLoaded"
                      :follow-cursor="false"
                      :is-auto-period-mode="isAutoPeriodMode"
                      :objective="formModel"
                      :offset="[0, 11]"
                      :show-dates-dropdown="showDatesDropdown"
                      :show-period-mode-title="false"
                      append-to="body"
                      data-auto-testid="dates-field"
                      data-testid="dates-field"
                      position="bottom"
                      show-period-mode-switch
                      split-update-date-events
                      @hide-dates-dropdown="showDatesDropdown = false"
                      @update-element-date="onUpdateElementDate"
                      @update-period-mode="updatePeriodMode"
                    />
                  </span>
                </div>
              </template>
            </OkrFormFieldSelectTriggerNext>

            <AppSelect
              v-if="showFormField(formModel.labelIds.length > 0)"
              ref="labelsSelect"
              v-model="formModel.labelIds"
              :allow-create-on-search="allowCreateOnSearch"
              :create-label="$t('labels.new_label')"
              :disabled="disableOnEdit"
              :loading="isLabelsLoading"
              :offset="[0, 0]"
              :options="labels"
              :search-function="getLabels"
              :search-max-length="50"
              data-auto-testid="labels-field"
              data-testid="labels-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="id"
              multi
              show-selected-options-inside
              skeleton-loader
              @create="createLabelOnSearch"
              @update:model-value="
                onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.LABELS)
              "
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.labels.placeholder')"
                  :label="$t('field.labels.title')"
                  :opened="active"
                  :selected-options="option"
                />
              </template>
            </AppSelect>

            <AppSelect
              v-if="showFormField(formModel.stakeholders.length > 0)"
              v-model="formModel.stakeholders"
              :disabled="disableOnEdit"
              :dropdown-search="true"
              :hidden-items="[null]"
              :loading="assigneesAreLoading"
              :offset="[0, 0]"
              :options="assignees"
              :search-function="getAssignees"
              data-auto-testid="stakeholders-field"
              data-testid="stakeholders-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="accountId"
              multi
              show-selected-options-inside
              skeleton-loader
              @update:options="assignees = $event"
              @update:model-value="
                onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.STAKEHOLDERS)
              "
            >
              <template #button="{ fullDataOption, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('filed.assignee.stakeholders.plug')"
                  :label="$t('field.assignee.stakeholders')"
                  :opened="active"
                  :selected-options="fullDataOption"
                  separated-label
                >
                  <template #values>
                    <SelectedUsersAvatarsList :users="fullDataOption" />
                  </template>
                </OkrFormFieldSelectTriggerNext>
              </template>

              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
            </AppSelect>
          </FormItemsGroup>

          <OkrElementsFormCustomFieldsHub
            v-model:loading="sidebarCustomFieldsLoading"
            :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
            :element-id="modelValue.id || null"
            :fields-values="customFieldsValues"
            :is-edit="isEdit"
            :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.KR.id"
            :placement="CUSTOM_FIELDS_PLACEMENTS.SIDEBAR"
            :user-has-update-access="userCanUpdateCustomFields"
            :workspace-id="workspaceId"
            @update-field-value="onUpdateCustomFieldValue"
            @update-activities="updateActivities"
          />
        </div>
      </template>

      <template #footer>
        <ModalFooterActions
          v-if="!isEdit"
          v-model="createAnother"
          :disabled="loading"
          :loading="loading"
          data-auto-testid="create-okr-footer"
          data-testid="create-okr-footer"
          @close="$emit('close')"
          @create="save"
        >
          <template #checkbox-label>
            {{ $t('create.okr_element.create_another') }}
          </template>

          <template #cancel-button-text>
            {{ $t('action.cancel') }}
          </template>

          <template #confirm-button-text> {{ $t('action.create') }}</template>
        </ModalFooterActions>
      </template>
    </SidebarLayout>

    <CreateJiraIssueForm
      ref="createJiraIssueForm"
      source="form"
      @save="onCreateJiraIssueFormSave"
    />
    <CloseObjectiveModal
      v-model:show-modal="showCloseModal"
      :objective="formModel"
      :temp-status="tempStatus"
      data-testid="close-okr-modal"
      @update-status="onUpdateStatus"
    />
  </div>

  <AppDialog
    :data-auto-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
    :data-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
    :show="isConfirmIntervalChangeShow"
    :title="$t('confirm_interval_change.title')"
    :type="DIALOG_TYPES.WARNING"
    @on-close="hideConfirmIntervalChange"
    @on-confirm="onConfirmIntervalChange"
  >
    {{ confirmDescription }}
  </AppDialog>
</template>

<script>
import { cloneDeep, isEmpty, isNull, isUndefined, uniqBy } from 'lodash'
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import GlobalGroupsApiHandler from '@/api/global-groups'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import LabelsApiHandler from '@/api/labels'
import ObjectivesApiHandler from '@/api/okr-elements'
import ObjectivesInfoApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import { BOTTOM_INFO_PANEL } from '@/composables/bottom-fixed-items'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  trackLabelCreatedEvent,
  trackManualUpdateGradeEvent
} from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { LOCK_MESSAGE_TYPES } from '@/utils/components-configurations/lock-message'
import { LIST_ITEM_PLACEMENTS } from '@/utils/components-configurations/okr-elements-list-item'
import { TYPES as RESULT_TYPE_SETTINGS_TYPES } from '@/utils/components-configurations/result-type-settings'
import { CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import {
  createInitialCustomFieldsValuesForOkrElement,
  CUSTOM_FIELDS_OKR_TYPE_IDS,
  DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT
} from '@/utils/custom-fields/helpers'
import { OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import {
  ASSIGNEE_ENTITY_KEYS,
  COMPANY_SETTINGS_ENTITY_KEYS,
  OKR_ELEMENT_ENTITY_KEYS,
  REQUEST_ENTITY_KEYS
} from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy } from '@/utils/general'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { getArchivedIntervals } from '@/utils/interval'
import { CHART_TYPES, isRefreshChartDataNeed } from '@/utils/objective-chart'
import { OBJECTIVE_TYPES, TYPE_ID_NAMES } from '@/utils/objective-types'
import {
  convertGroupsListToGroups,
  convertUsersListToOwnerAndStakeholders,
  createGroupsList,
  createUsersList,
  currentUserCanUpdateObjective,
  getDescriptionForIntervalChangeConfirmation,
  getShowChartStatus,
  KR_VALUE_TYPES,
  getNotSetOwnerOption,
  OBJECTIVE_SORT_OPTIONS,
  OKR_ELEMENT_FORM_MENU_ACTIONS,
  OKR_ELEMENT_PARAMETERS_SAVING_STATUSES,
  OKR_FORM_VIEWS,
  OKR_STATUSES,
  okrElementToLinkIssuePayload,
  currentUserCanReadObjective,
  isOkrElementClosed,
  ALL_STATUS_OPTIONS,
  isKR,
  currentUserCanCreateObjective,
  saveUpdatedChildParameters,
  getTaskMenuItems,
  getClosedStatusName
} from '@/utils/objectives'
import { getPortalName } from '@/utils/okr-breadcrumbs'
import {
  getMinMaxDates,
  getSelectedIntervalDates,
  isManualPeriodModeEnabled,
  OKR_DATES_SELECT_DATE_PROPS,
  PERIOD_MODES
} from '@/utils/okr-element-dates'
import {
  DEFAULT_ORIGINAL_VALUE,
  DEFAULT_RESULT_TYPE_OPTIONS,
  DEFAULT_RESULT_TYPE_VALUES,
  DEFAULT_TARGET_VALUE,
  getCurrentValue,
  getOriginalValue,
  getResultTypeSettingsDisabledStates,
  getResultTypeValues,
  getTargetValue,
  isBinarySelectDisabled,
  notEqualStringifiedValues
} from '@/utils/okr-element-values'
import {
  calculateGradeValue,
  getGradeColorStyle,
  getGradeColorVariable,
  getGradeDisplayValue
} from '@/utils/okr-elements/grade'
import {
  checkIsExpandableNestedItemsExist,
  createGetGroupsPayload,
  createGetUsersPayload,
  createPayloadForExpandAllNestedItems,
  getLockMessagesStates,
  isFormModelsEqual
} from '@/utils/okr-elements-forms-helpers'
import { isAllNestedItemsWeightsDimmed } from '@/utils/okr-weights-helpers'
import { getSimplifiedNumber, PLURALIZATION_KEYS } from '@/utils/pluralization'
import { scrollToCommentsSection } from '@/utils/scrollTo-utils'
import { uid } from '@/utils/uid'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import OkrElementsFormCustomFieldsHub from '@/components/custom-fields/okr-elements-form/OkrElementsFormCustomFieldsHub'
import FormGradeAndStatus from '@/components/form/FormGradeAndStatus'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import FormNestedItemsField from '@/components/form/FormNestedItemsField'
import { SETTINGS_COUNT } from '@/components/form/key-result/result-type-settings'
import ResultTypeSettings from '@/components/form/key-result/ResultTypeSettings'
import ResultTypeSettingsDefaultRightSide from '@/components/form/key-result/ResultTypeSettingsDefaultRightSide'
import LockMessage from '@/components/form/LockMessage'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import ObjectiveChart from '@/components/objectives/chart/ObjectiveChart'
import CloseObjectiveModal from '@/components/objectives/close-objective-modal/CloseObjectiveModal'
import AddNestedItem from '@/components/objectives/forms/AddNestedItem'
import DescriptionField from '@/components/objectives/forms/DescriptionField'
import { CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID } from '@/components/objectives/forms/jest-helpers'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import Activities from '@/components/objectives/forms/objective-form/Activities'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrElementSelectItem from '@/components/objectives/forms/OkrElementSelectItem'
import OkrTitleFiled from '@/components/objectives/forms/OkrTitleFiled'
import WeightsModalTrigger from '@/components/objectives/forms/WeightsModalTrigger'
import OkrElementsList from '@/components/objectives/okr-elements-list/OkrElementsList'
import OkrDatesDropdown from '@/components/objectives/OkrDatesDropdown'
import OkrDatesDropdownTrigger from '@/components/objectives/OkrDatesDropdownTrigger'
import StatusIndicator from '@/components/objectives/status-indicator/StatusIndicator'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'
import SidebarLayout from '@/components/ui/SidebarLayout/SidebarLayout'
import ImmediateParentLoader from '@/components/ui/SkeletonLoaders/ImmediateParentLoader'
import CreateJiraIssueForm from '@jira/components/objectives/forms/CreateJiraIssue'

/*
 * grade:
 *   for grade are responsible three values in form data: originalValue, targetValue and
 *   currentValue.
 *   - originalValue is set on KR creating and can't be changed anymore(and should not be sent in
 *     request),
 *   - if grade type is binary and on creating originalValue was set to 1, then currentValue is
 *     also 1 and cannot be returned to 0(originalValue === targetValue)
 */

const DEFAULT_FORM_MODEL = {
  name: '',
  ...DEFAULT_RESULT_TYPE_VALUES,
  description: '',
  parentId: null,
  labelIds: [],
  groups: [],
  ownerId: null,
  stakeholders: [],
  workspaceId: null,
  dueDate: null,
  elementStartDate: null,
  startDateManual: PERIOD_MODES.MANUAL.value,
  dueDateManual: PERIOD_MODES.MANUAL.value,
  automaticElementStartDate: null,
  automaticDueDate: null,
  confidenceLevelId: OKR_STATUSES.AUTO,
  automaticConfidenceLevelId: 0,
  intervalId: null,
  levelId: 0,
  contribute: true,
  private: false,
  updateStatusForNestedElements: false
}

const RESULT_TYPE_OPTIONS = [...DEFAULT_RESULT_TYPE_OPTIONS]

export default defineComponent({
  name: 'KeyResult',

  components: {
    OkrElementsFormCustomFieldsHub,
    CloseObjectiveModal,
    GlobalGroupsSelectOption,
    GroupSelectLabel,
    BottomFixedSelectItem,
    ResultTypeSettingsDefaultRightSide,
    AppDialog,
    AppCheckbox,
    WeightsModalTrigger,
    LockMessage,
    AddNestedItem,
    FormNestedItemsField,
    ModalFooterActions,
    OkrTitleFiled,
    AppRadioGroupNextOptionWithIcon,
    ImmediateParentLoader,
    SelectedUsersAvatarsList,
    ResultTypeSettings,
    FormGradeAndStatus,
    OwnerFieldOption,
    FormItemsGroup,
    OkrFormFieldSelectTriggerNext,
    AppRadioGroup,
    OkrDatesDropdown,
    OkrDatesDropdownTrigger,
    OkrElementSelectItem,
    OkrElementsList,
    ObjectiveChart,
    AppIcon,
    AppSelect,
    ObjectiveSelectItem,
    DescriptionField,
    Activities,
    SidebarLayout,
    CreateJiraIssueForm,
    StatusIndicator
  },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isCrossPlatformApp: {
      from: isCrossPlatformAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    // required parameters:
    // create: intervalId, levelId, workspaceId
    modelValue: {
      type: Object,
      default: null
    },

    source: {
      type: String,
      default: ''
    },

    navigationTab: {
      type: String,
      default: ''
    },

    childrenOrder: {
      type: Array,
      default: () => [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
    },

    okrPayload: {
      type: Object,
      default: () => ({})
    }
  },

  CHART_TYPES: { ...CHART_TYPES },

  OKR_DATES_SELECT_DATE_PROPS: { ...OKR_DATES_SELECT_DATE_PROPS },

  emits: {
    'open-custom-weights': null,
    'link-jira-issue': null,
    'edit-element': null,
    close: null,
    'saving-started': null,
    'saving-ended': null,
    'on-created': null,
    'update:model-value': null,
    update: null,
    'on-parent-objective-id-click': null,
    'open-child-objective': null,
    'open-kr': null,
    'set-child-modal-opened': null
  },

  setup() {
    const { okrWeightsLimitations } = usePlansLimitations()

    return {
      okrWeightsLimitations
    }
  },

  data() {
    return {
      uid: uid(),
      localFormModel: cloneDeep(DEFAULT_FORM_MODEL),
      formModel: cloneDeep(DEFAULT_FORM_MODEL),
      isTitleEmpty: false,
      loading: false,
      isIntervalsLoading: false,
      isLabelsLoading: false,
      areIntervalsLoaded: false,
      assigneesAreLoading: false,
      isTeamsLoading: false,
      isParentObjectivesLoading: false,
      focusedField: null,
      areAssigneesLoaded: false,
      areGroupsLoaded: false,
      areParentObjectivesLoaded: false,

      isGroupManuallyChanged: false,
      isOwnerManuallyChanged: false,
      assignees: [],
      parentObjectives: [],
      groups: [],
      childKrs: [],
      intervals: [],
      labels: [],
      createAnother: false,

      binaryValueIsSaving: false,
      originalValueIsSaving: false,
      currentValueIsSaving: false,
      targetValueIsSaving: false,

      ...Object.fromEntries(
        Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => [item, false])
      ),

      initialDataAreLoaded: false,
      showChart: false,

      isConfirmIntervalChangeShow: false,
      temporaryIntervalId: null,

      showDatesDropdown: false,

      weightIsManual: false,

      isExpandAllLoading: false,
      resultTypeSettingsUid: uid(),

      contentCustomFieldsLoading: false,
      sidebarCustomFieldsLoading: false,
      customFieldsValues: DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT,

      showCloseModal: false,
      tempStatus: null,

      descriptionWasChanged: false
    }
  },

  OKR_PARAMETERS_SAVING_STATUSES: { ...OKR_ELEMENT_PARAMETERS_SAVING_STATUSES },

  computed: {
    ...mapGetters('system', {
      allowLinkOkrElementsToKr: 'allowLinkOkrElementsToKr',
      okrElementModalFullscreenState: 'okrElementModalFullscreenState'
    }),

    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem'
    }),

    ...mapGetters('customFields', {
      fieldsByWorkspaceId: 'fieldsByWorkspaceId'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin,
      isJiraConnected: state => state[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    }),

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    LIST_ITEM_PLACEMENTS: () => LIST_ITEM_PLACEMENTS,

    LOCK_MESSAGE_TYPES: () => LOCK_MESSAGE_TYPES,

    OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID: () => OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID,

    CUSTOM_FIELDS_OKR_TYPE_IDS: () => CUSTOM_FIELDS_OKR_TYPE_IDS,

    CUSTOM_FIELDS_PLACEMENTS: () => CUSTOM_FIELDS_PLACEMENTS,

    OKR_STATUSES: () => OKR_STATUSES,

    RESULT_TYPE_OPTIONS: () => RESULT_TYPE_OPTIONS,

    DIALOG_TYPES: () => DIALOG_TYPES,

    CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID: () => CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID,

    PLURALIZATION_KEYS: () => PLURALIZATION_KEYS,

    updateScrollbarTriggers() {
      return [this.modelValue?.id].filter(Boolean)
    },

    getResultTypeSettingsType() {
      const { CENTERED, DEFAULT } = RESULT_TYPE_SETTINGS_TYPES
      return this.okrElementModalFullscreenState ? CENTERED : DEFAULT
    },

    currentWorkspaceCustomFields() {
      return this.fieldsByWorkspaceId(this.workspaceId)
    },

    selectedParentElement() {
      return this.parentObjectives.find(objective => objective.id === this.formModel.parentId)
    },

    getOkrElementClosedStatusName() {
      const statusName = getClosedStatusName({
        okrElement: this.modelValue
      })
      return this.$t(statusName).toLowerCase()
    },

    getParentElementClosedStatusName() {
      const statusName = getClosedStatusName({
        okrElement: this.selectedParentElement
      })
      return this.$t(statusName).toLowerCase()
    },

    parenElementReadable() {
      return currentUserCanReadObjective(this.selectedParentElement)
    },

    showWeightsModalTrigger() {
      // return this.isEdit && this.editable && this.childKrs.length > 1
      return this.isEdit && this.editable
    },

    ownerFieldOptions() {
      return uniqBy(
        [getNotSetOwnerOption(this.$t), ...this.assignees],
        ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID
      )
    },

    getBottomFixedItems() {
      return [
        {
          id: 0,
          text: this.$t('objectives.last_count_items_shown', {
            count: 10,
            entity: this.$t('objectives.okr', 2)
          }),

          action: BOTTOM_INFO_PANEL
        }
      ]
    },

    workspaceId() {
      if (this.modelValue && this.modelValue.workspaceId && this.modelValue.workspaceId !== -1) {
        return this.modelValue.workspaceId
      }
      return this.$route.params.workspaceId
    },

    portalName() {
      return getPortalName(OKR_FORM_VIEWS.KR, this.isEdit)
    },

    dimNestedItemsWeights() {
      return isAllNestedItemsWeightsDimmed({
        weightIsManual: this.weightIsManual,
        nestedItems: this.childKrs
      })
    },

    someItemIsExpandable() {
      return checkIsExpandableNestedItemsExist(this.childKrs)
    },

    resultTypeSettingsDisabledStates() {
      const { select, isSwitch, input } = getResultTypeSettingsDisabledStates({
        isEdit: this.isEdit,
        editable: this.editable,
        isLockedOnPercent: this.isLockedOnPercent,
        isBinarySelectDisabled: this.isBinarySelectDisabled,
        valuesAreReadonly: this.valuesAreReadonly
      })
      return {
        select,
        switch: isSwitch,
        input
      }
    },

    resultTypeSettingsCount() {
      if (this.isBinary) {
        return SETTINGS_COUNT[1]
      }

      if (this.isEdit && !this.isBinary) {
        return SETTINGS_COUNT[3]
      }

      return SETTINGS_COUNT[2]
    },

    isResultTypeSettingsDisabled() {
      return this.modelValue && this.modelValue.existContributeChildren
    },

    showPredictedScore() {
      return (
        this.formModel.confidenceLevelId === OKR_STATUSES.AUTO &&
        this.isEdit &&
        this.showChart &&
        Math.max(this.modelValue.timePassedPercent, 0) >= 0
      )
    },

    chartTriggers() {
      const { intervals, formModel } = this
      const { intervalId } = formModel
      return {
        intervals,
        intervalId
      }
    },

    ...mapState('system', {
      settings: state => state.settings,
      userData: state => state.userData || {}
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    isAutoPeriodMode() {
      return !isManualPeriodModeEnabled(
        this.formModel.startDateManual,
        this.formModel.dueDateManual
      )
    },

    automaticElementDatesForCreate() {
      const { intervalId } = this.formModel
      const currentInterval = this.intervals.find(item => item.id === intervalId)
      return {
        startDate: currentInterval?.startDate,
        dueDate: currentInterval?.endDate
      }
    },

    iconName() {
      return this.isAutoPeriodMode ? PERIOD_MODES.AUTO.icon : PERIOD_MODES.MANUAL.icon
    },

    isFieldDisabled() {
      return this.isEdit && !this.userCanUpdateObjective
    },

    confirmDescription() {
      return getDescriptionForIntervalChangeConfirmation({
        formModel: this.formModel,
        i18nInstance: this.$t
      })
    },

    selectedIntervalDates() {
      return getSelectedIntervalDates(this.modelValue)
    },

    minMaxDates() {
      return getMinMaxDates(
        this.selectedIntervalDates,
        this.formModel.elementStartDate,
        this.formModel.dueDate
      )
    },

    editable() {
      if (!this.isEdit) {
        return false
      }
      return currentUserCanUpdateObjective(this.modelValue)
    },

    isEdit() {
      return Boolean(this.modelValue && this.modelValue.id)
    },

    isLockedOnPercent() {
      return this.modelValue?.existContributeChildren
    },

    originalValue: {
      get() {
        return getOriginalValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberOriginalValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentOriginalValue = value
        }
      }
    },

    targetValue: {
      get() {
        return getTargetValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberTargetValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentTargetValue = value
        }
      }
    },

    currentValue: {
      get() {
        // need for display current grade in auto grade calculation mode in current value input
        if (this.isEdit) {
          return this.isResultTypeSettingsDisabled
            ? getSimplifiedNumber(getGradeDisplayValue(this.grade))[PLURALIZATION_KEYS.FLOAT_NUMBER]
            : getCurrentValue(this.formModel)
        }
        return getCurrentValue(this.formModel)
      },

      set(value) {
        if (this.formModel.fieldTypeId === KR_VALUE_TYPES.NUMBER) {
          this.formModel.numberCurrentValue = value
        } else if (this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT) {
          this.formModel.percentCurrentValue = value
        }
        // else {
        //   this.formModel.currentValue = value;
        // }
      }
    },

    resultTypeSettingsFormModel() {
      // need for display correct binary value in auto grade calculation  mode with binary option
      if (this.isEdit && this.isResultTypeSettingsDisabled && this.isBinary) {
        return {
          ...this.formModel,
          binaryValue: Number(
            getSimplifiedNumber(getGradeDisplayValue(this.grade))[
              PLURALIZATION_KEYS.DEFAULT_NUMBER
            ] >= 100
          )
        }
      }

      return this.formModel
    },

    getObjectivesAsOptions() {
      return this.parentObjectives.filter(o => {
        if (this.modelValue) {
          return o.id !== this.modelValue.id
        }
        return true
      })
    },

    getCurrentParentElement() {
      return this.getObjectivesAsOptions.find(objective => objective.id === this.formModel.parentId)
    },

    isBinary() {
      return this.formModel.fieldTypeId === KR_VALUE_TYPES.BINARY
    },

    isPercent() {
      return this.formModel.fieldTypeId === KR_VALUE_TYPES.PERCENT
    },

    isBinarySelectDisabled() {
      return isBinarySelectDisabled(this.modelValue)
    },

    skipDescriptionCheck() {
      // ↓ optional channing coz in case of closed okr elements we hide description field
      if (this.$refs.description?.active) {
        return false
      }
      return !this.descriptionWasChanged
    },

    areDataChanged() {
      let resultTypesValuesChanged = false

      if (this.isEdit) {
        resultTypesValuesChanged =
          Number(this.originalValue) !== this.modelValue.originalValue ||
          Number(getCurrentValue(this.formModel)) !== this.modelValue.currentValue ||
          Number(this.targetValue) !== this.modelValue.targetValue
      } else {
        resultTypesValuesChanged =
          Number(this.originalValue) !== DEFAULT_ORIGINAL_VALUE ||
          Number(this.targetValue) !== DEFAULT_TARGET_VALUE
      }

      return (
        !isFormModelsEqual({
          localFormModel: this.localFormModel,
          formModel: this.formModel,
          skipDescriptionCheck: this.skipDescriptionCheck
        }) || resultTypesValuesChanged
      )
    },

    // areDataChanged() {
    //   return (
    //     JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel) ||
    //     Number(this.originalValue) !== this.modelValue.originalValue ||
    //     Number(this.currentValue) !== this.modelValue.currentValue ||
    //     Number(this.targetValue) !== this.modelValue.targetValue
    //   )
    // },

    isDescriptionTooLong() {
      // in some cases(for example when linked jira issue is being edited)
      // description in formModel(store) can be undefined
      if (!this.formModel.description) {
        return false
      }
      return this.formModel.description.length > 18000
    },

    childKrsMenuItems() {
      const CREATE_OKR_ELEMENT_ACTIONS = [
        {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_OBJECTIVE,
          name: this.$t('action.create_objective'),
          icon: 'company-objective-next'
        },
        {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_KR,
          name: this.$t('action.create_keyResult'),
          icon: 'kr-objective-next'
        }
      ]

      const TASK_ACTIONS = getTaskMenuItems({
        isOkrElementClosed: this.isOkrElementClosed,
        isJiraApp: this.isJiraApp,
        isWebApp: this.isWebApp,
        isCrossPlatformApp: this.isCrossPlatformApp,
        isJiraConnected: this.isJiraConnected,
        hasAccessToJira: this.userData.hasAccessToJira
      })

      return this.allowLinkOkrElementsToKr
        ? [...CREATE_OKR_ELEMENT_ACTIONS, ...TASK_ACTIONS].filter(Boolean)
        : [...TASK_ACTIONS].filter(Boolean)
    },

    valuesAreReadonly() {
      return this.isLockedOnPercent || (this.modelValue && this.modelValue.existContributeChildren)
    },

    editDataAreSaving() {
      return (
        this.isEdit &&
        checkSomeValueIsTruthy(
          this.binaryValueIsSaving,
          this.originalValueIsSaving,
          this.currentValueIsSaving,
          this.targetValueIsSaving,
          ...Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => this[item]),
          this.contentCustomFieldsLoading,
          this.sidebarCustomFieldsLoading
        )
      )
    },

    grade() {
      if (this.isEdit && this.modelValue.existContributeChildren) {
        return this.modelValue.gradeToUse
      }

      return calculateGradeValue(this.targetValue, this.originalValue, this.currentValue)
    },

    getIntervalsAsOptions() {
      return this.intervals.map(i => ({
        label: i.name,
        value: i.id
      }))
    },

    // passing archived intervals as hidden-items because
    // OKR element can has archived interval as selected
    // so we must show it like selected but dont pass it to all options list
    archivedIntervals() {
      return getArchivedIntervals(this.intervals).map(item => item.id)
    },

    userCanUpdateObjective() {
      return this.modelValue && currentUserCanUpdateObjective(this.modelValue)
    },

    userCanUpdateCustomFields() {
      if (this.isEdit) {
        return this.userCanUpdateObjective && !this.isOkrElementClosed
      }

      return true
    },

    // userCanCreateObjectives() {
    //   return currentUserCanCreateObjective(this.selectedWorkspace)
    // },
    getParentOkrTypeName() {
      return isKR(this.selectedParentElement)
        ? this.$t('element.type.key_result')
        : this.$t('okr_elements.objective')
    },

    isOkrElementClosed() {
      return isOkrElementClosed(this.modelValue)
    },

    isClosedParent() {
      if (!this.formModel.parentId) return false
      return this.selectedParentElement && isOkrElementClosed(this.selectedParentElement)
    },

    disableOnEdit() {
      if (!this.initialDataAreLoaded) return true
      return (this.isEdit && !this.editable) || this.isOkrElementClosed
    },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    },

    allowCreateOnSearch() {
      return this.settings[COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_LABEL_CREATION]
    },

    lockedMessagesStates() {
      return getLockMessagesStates({
        isEdit: this.isEdit,
        modelValue: this.modelValue,
        isClosedParent: this.isClosedParent,
        contribute: this.formModel.contribute
      })
    }
  },

  watch: {
    showCloseModal(value) {
      this.$emit('set-child-modal-opened', value)
    },

    'modelValue.id': {
      async handler() {
        if (this.isEdit) {
          this.getChildKrs()
          this.parentObjectives = await this.getParentObjectives()
          this.resultTypeSettingsUid = uid()
        }
      }
    },

    automaticElementDatesForCreate: {
      handler(newValue) {
        if (newValue && !this.isEdit) {
          const { startDate, dueDate } = newValue
          this.formModel.automaticElementStartDate = startDate
          this.formModel.automaticDueDate = dueDate
        }
      },

      deep: true,
      immediate: true
    },

    'formModel.intervalId': {
      async handler() {
        if (this.areIntervalsLoaded && this.areParentObjectivesLoaded) {
          const promises = []

          const setParentObjectives = async () => {
            this.parentObjectives = await this.getParentObjectives()
          }

          if (this.isEdit) {
            promises.push(
              this.onParameterUpdate(this.$options.OKR_PARAMETERS_SAVING_STATUSES.INTERVAL)
            )
          }

          promises.push(setParentObjectives())

          await Promise.all(promises)
        }
      }
    },

    isLockedOnPercent: {
      handler(newValue) {
        if (newValue) {
          this.formModel.fieldTypeId = KR_VALUE_TYPES.PERCENT
        }
      },

      immediate: true
    },

    'formModel.ownerId': async function ownerIdChanged() {
      if (this.areAssigneesLoaded) {
        this.groups = await this.getGroups()
      }
    },

    async modelValue(newValue, oldValue) {
      if (newValue) {
        this.saveModelValueToFormModel()
        this.saveFormModelLocal()
        this.initCustomFieldsValues()
      }

      const { showChart } = this
      if (isRefreshChartDataNeed({ oldValue, newValue, showChart })) {
        await this.$nextTick()
        await this.$refs.objectiveChart?.refreshData()
      }
    },

    editDataAreSaving(newValue) {
      if (newValue) {
        this.$emit('saving-started')
      } else {
        this.$emit('saving-ended')
      }
    },

    chartTriggers: {
      handler(newValue) {
        const { isEdit } = this
        this.showChart = getShowChartStatus({ ...newValue, isEdit })
      },

      deep: true
    }
  },

  created() {
    this.saveModelValueToFormModel()
  },

  mounted() {
    this.fetchData().then(() => {
      this.$nextTick(() => {
        scrollToCommentsSection(this.okrPayload)
      })
    })
  },

  methods: {
    getGradeColorStyle,
    getGradeDisplayValue,
    getGradeColorVariable,

    async fetchData() {
      // enable loader for fields
      this.isParentObjectivesLoading = true
      this.assigneesAreLoading = true
      this.isTeamsLoading = true

      if (!this.isEdit) {
        this.setFocusOnTitle()
      }

      if (this.isEdit && !this.valuesAreReadonly && !this.isBinary && !this.isOkrElementClosed) {
        this.setFocusOnCurrent()
      }

      if (this.isEdit || this.createAnother) {
        this.isGroupManuallyChanged = true
        this.isOwnerManuallyChanged = true
      }

      const handleAssignees = async () => {
        this.assignees = await this.getAssignees(null, true)
      }

      const handleGroups = async () => {
        this.groups = await this.getGroups()
      }

      const handleParentObjectives = async () => {
        this.parentObjectives = await this.getParentObjectives()
      }

      const handleIntervals = async () => {
        this.intervals = await this.getIntervals()
      }

      const handleLabels = async () => {
        this.labels = await this.getLabels()
      }

      if (!this.isEdit) {
        await handleAssignees()
      }

      const promises = [handleParentObjectives(), handleIntervals(), handleLabels(), handleGroups()]

      if (this.isEdit) {
        promises.unshift(handleAssignees())
      }

      Promise.all(promises).then(() => {
        this.initCustomFieldsValues()
        this.saveFormModelLocal()
        this.initialDataAreLoaded = true
      })

      if (this.isEdit) {
        this.getChildKrs()
      }
    },

    async expandAll() {
      this.isExpandAllLoading = true
      const objectivesApi = new ObjectivesApiHandler()

      try {
        const payload = createPayloadForExpandAllNestedItems({
          modelValue: this.modelValue,
          childrenOrder: this.childrenOrder
        })
        this.childKrs = await objectivesApi.getObjectives(payload)

        this.$refs.childKRs.toggleExpandAll()
        this.isExpandAllLoading = false

        // this.$refs.childKRs.toggleExpandAll()
      } catch (error) {
        handleError({ error })
      }
    },

    collapseAll() {
      this.$refs.childKRs.toggleExpandAll(false)
    },

    showFormField(isValueExist = false) {
      if (this.isEdit) {
        return this.editable ? true : isValueExist
      }
      return true
    },

    onParentObjectiveIdClick(item) {
      this.$emit('on-parent-objective-id-click', item)
      this.$refs.parentSelect?.hideDropdown()
    },

    expandItem({ parentId, items }) {
      const target = this.childKrs.find(item => item.id === parentId)
      if (target) {
        target.childElements = items
      }
    },

    async changeResultType(resultType) {
      this.formModel.fieldTypeId = resultType
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.TYPE] = true
      await this.updateKeyResult()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.TYPE] = false
    },

    async onBinaryValueUpdate(value) {
      this.formModel.binaryValue = value
      this.binaryValueIsSaving = true
      await this.updateKeyResult()
      if (this.isEdit) {
        trackManualUpdateGradeEvent({
          confidenceLevel: this.formModel.confidenceLevelId,
          id: this.modelValue.id,
          issueType: TYPE_ID_NAMES[OBJECTIVE_TYPES.KR],
          source: EVENT_SOURCES.FORM,
          value: value * 100 // for convert boolean to percent
        })
      }
      this.binaryValueIsSaving = false
    },

    close({ checkDataChange = true, createdKR = null, updatedKR = null }) {
      this.$emit('close', { checkDataChange, createdKR, updatedKR })
    },

    getParentObjectiveGroupName(objective) {
      if (objective.id === 0) {
        return null
      }
      return `${objective.workspaceKey} ${objective.intervalName}`
    },

    validateName() {
      if (this.formModel.name === '') {
        this.isTitleEmpty = true
        this.setFocusOnTitle()
        return false
      }
      return true
    },

    updateChildrenParams(updatedElementParameters) {
      saveUpdatedChildParameters(this.childKrs, updatedElementParameters)
    },

    async save(close = true) {
      if (!this.validateName()) {
        return
      }
      if (this.isDescriptionTooLong) {
        return
      }

      let isSuccessful = true
      this.loading = true

      let currentValue = +this.currentValue
      if (!this.isBinary && !this.isEdit) {
        currentValue = +this.originalValue
      }

      const payload = {
        name: this.formModel.name,
        parentId: this.formModel.parentId,
        description: this.formModel.description,
        users: createUsersList(this.formModel),
        groups: createGroupsList(this.formModel.groups),
        fieldTypeId: this.formModel.fieldTypeId,
        originalValue: this.isBinary ? 0 : +this.originalValue,
        currentValue,
        targetValue: this.isBinary ? 1 : +this.targetValue,
        workspaceId: this.formModel.workspaceId,
        dueDate: this.formModel.dueDate,
        elementStartDate: this.formModel.elementStartDate,
        confidenceLevelId: this.formModel.confidenceLevelId,
        intervalId: this.formModel.intervalId,
        levelId: this.formModel.levelId,
        labelIds: this.formModel.labelIds,
        startDateManual: this.formModel.startDateManual,
        dueDateManual: this.formModel.dueDateManual,
        contribute: this.formModel.contribute,
        private: this.modelValue.private,
        updateStatusForNestedElements: this.formModel.updateStatusForNestedElements
      }

      if (!this.isEdit) {
        payload.customFields = this.customFieldsValues
      }

      let createdKeyResult = null
      let updateData = null
      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        if (!this.isEdit) {
          createdKeyResult = await objectivesApi.createOkrElement(payload)
          createdKeyResult = createdKeyResult.elements[0]
          tracker.logEvent('Created KR', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            contribution: createdKeyResult.contribute ? 'Yes' : 'No',
            ent_id: createdKeyResult.displayId,
            tab: this.navigationTab,
            source: this.source,
            initial_value: createdKeyResult.originalValue,
            target_value: createdKeyResult.targetValue,
            parent_objective: createdKeyResult.parentId !== null ? createdKeyResult.parentId : ''
          })

          gtmTracker.logEvent(EVENT_NAMES.KR_CREATED)
        } else {
          payload.elementId = this.modelValue.id
          if (this.isResultTypeSettingsDisabled) {
            // need to prevent rewriting current value by grade on chages
            delete payload.currentValue
          }
          updateData = await objectivesApi.updateOkrElement(payload)

          createdKeyResult = updateData.element
          const updatedElementParameters = updateData.updatedElementParameters

          const isParentUpdated = updatedElementParameters.some(
            ({ elementId }) => elementId === this.formModel.parentId
          )
          // this.updateChildrenParams(updatedElementParameters)
          if (isParentUpdated) {
            this.parentObjectives = await this.getParentObjectives()
          }
        }

        // this.formModel.updateStatusForNestedElements = false
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false

      // if (isSuccessful && !this.isEdit && !this.createAnother) {
      //   this.$emit('on-created', createdKeyResult)
      // }

      if (isSuccessful) {
        if (!this.isEdit) {
          this.$emit('on-created', createdKeyResult)
        }

        if (!this.isEdit && this.createAnother) {
          this.scheduleCreateAnother()
          this.setFocusOnTitle()
          // showNotify({
          //   title: this.$t('create.keyResult.success_message')
          // })
        } else if (close) {
          this.close({
            createdKR: !this.isEdit ? createdKeyResult : null,
            checkDataChange: false
          })
        } else {
          this.$emit('update:model-value', createdKeyResult)
        }
      }
      if (updateData && !currentUserCanReadObjective(updateData.element)) {
        this.close({ checkDataChange: false })
        throw new Error(this.$t('objective.you_have_no_rights'))
      }
    },

    async getAssignees(searchString = null, selectUser = false) {
      let result = []
      const api = new AssigneesInfoApiHandler()

      const { OWNER_ID } = OKR_ELEMENT_ENTITY_KEYS

      this.assigneesAreLoading = true
      const payload = createGetUsersPayload({
        workspaceId: this.workspaceId,
        searchString,
        userData: this.userData,
        isOwnerManuallyChanged: this.isOwnerManuallyChanged,
        isEdit: this.isEdit,
        formModel: this.formModel
      })

      try {
        const users = await api.getUsers(payload)

        result = [...users]

        if (
          !isEmpty(users) &&
          isNull(this.formModel[OWNER_ID]) &&
          selectUser &&
          !this.isOwnerManuallyChanged
        ) {
          this.formModel[OWNER_ID] = this.userData.userAccountId
        }

        if (!this.isEdit && isNull(this.formModel[OWNER_ID])) {
          this.formModel[OWNER_ID] = this.userData.userAccountId
        }

        this.$nextTick(() => {
          if (!this.areAssigneesLoaded) {
            this.localFormModel[OWNER_ID] = this.formModel[OWNER_ID]
          }
          this.areAssigneesLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.assigneesAreLoading = false
      }

      return result
    },

    async getGroups(searchString = null) {
      // if (this.isEdit) return;
      const api = new GlobalGroupsApiHandler()
      this.isTeamsLoading = true
      let result = []

      const { GROUP_IDS } = REQUEST_ENTITY_KEYS
      const { GROUPS } = OKR_ELEMENT_ENTITY_KEYS

      const { ownerId, groups: selectedGroups } = this.formModel

      try {
        const payload = createGetGroupsPayload({
          searchString,
          ownerId,
          selectedGroups,
          workspaceId: this.workspaceId,
          isGroupManuallyChanged: this.isGroupManuallyChanged,
          users: this.assignees
        })

        const groups = await api.getGroupsForFilter(payload)
        this.groups = groups
        this.isTeamsLoading = false

        if (!this.isEdit) {
          this.formModel[GROUPS] = payload[GROUP_IDS]
        }

        this.$nextTick(() => {
          if (!this.areGroupsLoaded) {
            this.localFormModel[GROUPS] = this.formModel[GROUPS]
          }
          this.areGroupsLoaded = true
        })

        result = groups
      } catch (error) {
        handleError({ error })
        this.isTeamsLoading = false
      }

      return result
    },

    async getLabels(searchString = null) {
      let result = []
      const api = new LabelsApiHandler()
      if (!searchString) {
        this.isLabelsLoading = true
      }
      try {
        result = await api.getLabels({ name: searchString })
      } catch (error) {
        handleError({ error })
      }
      this.isLabelsLoading = false
      return result
    },

    async createLabelOnSearch(searchString) {
      const api = new LabelsApiHandler()
      try {
        const label = await api.createLabel({ name: searchString })
        this.formModel.labelIds.push(label.id)
        this.labels.push(label)

        trackLabelCreatedEvent({
          source: EVENT_SOURCES.OKR_FORM,
          label: label.name
        })

        this.updateKeyResult()
        this.$refs.labelsSelect?.clearInput()
      } catch (error) {
        handleError({ error })
      }
    },

    async getParentObjectives(searchString = null) {
      const api = new ObjectivesInfoApiHandler()
      let result = []
      this.isParentObjectivesLoading = true

      const payload = {
        elementTypeId: OBJECTIVE_TYPES.KR,
        intervalId: this.formModel.intervalId,
        elementId: this.modelValue?.id || null,
        parentId: this.modelValue.parentId,
        searchString,
        workspaceId: this.formModel.workspaceId
      }

      try {
        const { currentIndex, elements } = await api.getParentObjectiveInfo(payload)
        result = elements

        if (searchString === null && currentIndex === 0 && !elements.length) {
          this.formModel.parentId = null
          return result
        }

        if (this.formModel.parentId === -1) {
          this.formModel.parentId = null
        }

        this.$nextTick(() => {
          if (!this.areParentObjectivesLoaded) {
            this.localFormModel.parentId = this.formModel.parentId
          }
          this.areParentObjectivesLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.isParentObjectivesLoading = false
      }

      return result
    },

    async getIntervals() {
      const api = new IntervalsInfoApiHandler()
      let result = []
      this.isIntervalsLoading = true
      try {
        const { currentIndex, intervals } = await api.getIntervalsInfo({
          workspaceId: this.formModel.workspaceId
        })
        result = intervals

        if (!this.formModel.intervalId) {
          this.formModel.intervalId = intervals[currentIndex].id
        }

        this.$nextTick(() => {
          this.areIntervalsLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.isIntervalsLoading = false
      }
      return result
    },

    setFocusedField(field) {
      this.focusedField = field
    },

    onGroupSelect() {
      this.isGroupManuallyChanged = true
    },

    onOwnerSelect() {
      this.isOwnerManuallyChanged = true
    },

    initCustomFieldsValues() {
      this.customFieldsValues = {
        ...createInitialCustomFieldsValuesForOkrElement({
          isEdit: this.isEdit,
          elementTypeId: OBJECTIVE_TYPES.KR,
          modelValueCustomFields: this.modelValue?.customFields,
          currentWorkspaceCustomFields: this.currentWorkspaceCustomFields
        })
      }
    },

    saveFormModelLocal() {
      this.localFormModel = cloneDeep(this.formModel)
    },

    scheduleCreateAnother() {
      this.resultTypeSettingsUid = uid()
      this.formModel.name = ''
      this.formModel.description = ''
      this.formModel.binaryValue = 0
      this.formModel.percentOriginalValue = '0'
      this.formModel.percentCurrentValue = '0'
      this.formModel.percentTargetValue = '100'
      this.formModel.numberOriginalValue = '0'
      this.formModel.numberCurrentValue = '0'
      this.formModel.numberTargetValue = '100'
      this.formModel.originalValue = '0'
      this.formModel.currentValue = '0'
      this.formModel.targetValue = '0'
    },

    setFocusOnTitle() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.title && this.$refs.title.focus()
        }, 200)
      })
    },

    setFocusOnCurrent() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.current) {
            this.$refs.current.selectAll()
          }
        }, 100)
      })
    },

    cancelDescriptionEditing() {
      if (this.isEdit) {
        this.formModel.description = this.modelValue.description
      }
    },

    async onContributeChange() {
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.CONTRIBUTE] = true
      await this.updateKeyResult()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.CONTRIBUTE] = false
    },

    async updateKeyResult() {
      if (this.initialDataAreLoaded && this.isEdit && this.areDataChanged) {
        // wait for refs after result type change
        try {
          await this.$nextTick()
          await this.save(false)
          this.saveFormModelLocal()
          this.updateActivities()
        } catch (error) {
          handleError({ error })
        }
      }
    },

    async onNameUpdate() {
      if (this.editable && this.validateName()) {
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.NAME] = true
        await this.updateKeyResult()
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.NAME] = false
      }
    },

    async onOriginalValueUpdate() {
      if (
        this.isEdit &&
        notEqualStringifiedValues(this.originalValue, this.modelValue.originalValue)
      ) {
        this.originalValueIsSaving = true
        await this.updateKeyResult()
        this.originalValueIsSaving = false
      }
    },

    async onCurrentValueUpdate() {
      if (
        this.isEdit &&
        notEqualStringifiedValues(this.currentValue, this.modelValue.currentValue)
      ) {
        this.currentValueIsSaving = true
        await this.updateKeyResult()
        trackManualUpdateGradeEvent({
          confidenceLevel: this.formModel.confidenceLevelId,
          id: this.modelValue.id,
          issueType: TYPE_ID_NAMES[OBJECTIVE_TYPES.KR],
          source: EVENT_SOURCES.FORM,
          value: this.currentValue
        })
        this.currentValueIsSaving = false
      }
    },

    async onTargetValueUpdate() {
      if (this.isEdit && notEqualStringifiedValues(this.targetValue, this.modelValue.targetValue)) {
        this.targetValueIsSaving = true
        await this.updateKeyResult()
        this.targetValueIsSaving = false
      }
    },

    onUpdateElementDate(params) {
      const { val, dateProp, savingStatus } = params

      this.formModel[dateProp] = val

      this.onParameterUpdate(savingStatus)
    },

    updatePeriodMode(mode) {
      this.formModel.startDateManual = mode
      this.formModel.dueDateManual = mode
      if (mode) {
        this.formModel.elementStartDate = this.formModel.automaticElementStartDate
        this.formModel.dueDate = this.formModel.automaticDueDate
      } else {
        this.formModel.elementStartDate = null
        this.formModel.dueDate = null
      }

      this.onParameterUpdate(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PERIOD_MODE)
    },

    async onUpdateStatus({ updateStatus, updateStatusForNestedElements, reset = false }) {
      if (!reset) {
        this.formModel.updateStatusForNestedElements = updateStatusForNestedElements
        await this.updateStatus(updateStatus)
      }
      this.tempStatus = null
    },

    async updateStatus(value) {
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.STATUS] = true
      this.formModel.confidenceLevelId = value
      await this.updateKeyResult()
      const statusValue = ALL_STATUS_OPTIONS.map(option => ({
        ...option,
        label: this.$t(option.label)
      })).find(item => item.value === this.formModel.confidenceLevelId)
      tracker.logEvent('Changed okr status', {
        category: EVENT_CATEGORIES.OKR_MANAGEMENT,
        label: 'kr',
        source: EVENT_SOURCES.FORM,
        value: this.formModel.confidenceLevelId ? statusValue.label : 'auto'
      })
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.STATUS] = false
    },

    onStatusUpdate(value) {
      if (isOkrElementClosed({ confidenceLevelId: value })) {
        this.showCloseModal = true
        this.tempStatus = value
        return
      }
      this.updateStatus(value)
    },

    async onParentObjectiveUpdate(value) {
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = true
      await this.updateKeyResult()
      this.localFormModel.parentId = value
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = false
    },

    onUpdateInterval(intervalId) {
      if (this.formModel.elementStartDate || this.formModel.dueDate) {
        this.temporaryIntervalId = intervalId
        this.isConfirmIntervalChangeShow = true
      } else {
        this.formModel.intervalId = intervalId
      }
    },

    hideConfirmIntervalChange() {
      this.isConfirmIntervalChangeShow = false
      this.temporaryIntervalId = null
    },

    onConfirmIntervalChange() {
      this.formModel.elementStartDate = null
      this.formModel.dueDate = null
      this.formModel.intervalId = this.temporaryIntervalId
      this.hideConfirmIntervalChange()
    },

    async saveDescription() {
      if (!this.descriptionWasChanged) {
        this.descriptionWasChanged = true
      }
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.DESCRIPTION] = true
      await this.updateKeyResult()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.DESCRIPTION] = false
    },

    onDatesTriggerClick() {
      if (!this.isFieldDisabled) {
        this.showDatesDropdown = !this.showDatesDropdown
      }
    },

    async onParameterUpdate(savingStatus) {
      this[savingStatus] = true
      await this.updateKeyResult()
      this[savingStatus] = false
    },

    async onUpdateCustomFieldValue({ fieldId, value }) {
      this.customFieldsValues[fieldId] = value
    },

    updateActivities() {
      this.$refs.activities?.refreshData()
    },

    async getChildKrs({ isRefetch = false, isUpdateHistory = false } = {}) {
      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        const { items, weightManual } = await objectivesApi.getChildObjectives({
          parentId: this.modelValue.id,
          workspaceId: this.modelValue.workspaceId,
          // intervalIds: [this.modelValue.intervalId],
          searchType: SEARCH_TYPES.OKR_EXPLORER,
          order: this.childrenOrder,
          childOrder: this.childrenOrder,
          offset: 0,
          limit: 1000
        })
        this.childKrs = items
        this.weightIsManual = weightManual
        if (isUpdateHistory) {
          this.updateActivities()
        }
        if (isRefetch) {
          this.$refs.childKRs.refetchExpandedElementsChildItems()
        }
      } catch (error) {
        handleError({ error })
      }
    },

    getKrJiraIssuePayload(kr) {
      return okrElementToLinkIssuePayload(kr)
    },

    onChildKrsMenuItemsClick(name) {
      const isCreateElementAction =
        [
          OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_KR,
          OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_OBJECTIVE
        ].includes(name) && this.allowLinkOkrElementsToKr

      if (isCreateElementAction) {
        const payload = {
          parentId: this.modelValue.id,
          workspaceId: this.workspaceId,
          intervalId: this.modelValue.intervalId
        }

        if (name === OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_OBJECTIVE) {
          const typeId = OBJECTIVE_TYPES.PERSONAL
          payload.levelId = this.getLevelItem('typeId', typeId).id

          this.$emit('open-child-objective', { ...payload })
          this.updateActivities()
        } else {
          this.$emit('open-kr', {
            ...payload,
            intervalStartDate: this.modelValue.intervalStartDate,
            intervalEndDate: this.modelValue.intervalEndDate
          })
        }
      } else if (name === OKR_ELEMENT_FORM_MENU_ACTIONS.LINK_TASK) {
        this.onLinkJiraIssueClick()
      } else {
        this.onCreateJiraIssueClick()
      }
    },

    onCreateJiraIssueFormSave() {
      this.getChildKrs(true)
      this.$emit('update')
      if (this.isEdit) {
        this.updateActivities()
      }
    },

    async onLinkJiraIssueClick() {
      this.$emit('link-jira-issue', okrElementToLinkIssuePayload(this.modelValue))
    },

    async onCreateJiraIssueClick() {
      const payload = {
        id: this.modelValue.id,
        parentId: this.modelValue.parentId,
        users: this.modelValue.users,
        groups: this.modelValue.groups,
        intervalId: this.modelValue.intervalId,
        workspaceId: this.modelValue.workspaceId,
        dueDate: this.modelValue.dueDate
      }

      this.createJiraIssue(payload)
    },

    createJiraIssue(payload) {
      this.$refs.createJiraIssueForm.create(payload)
    },

    onChildUpdated() {
      this.getChildKrs(true)
      this.$emit('update')
      if (this.isEdit) {
        this.updateActivities()
      }
    },

    saveModelValueToFormModel() {
      if (this.modelValue) {
        this.formModel = {
          ...this.formModel,
          ...this.modelValue,
          name: this.modelValue.name || DEFAULT_FORM_MODEL.name,
          fieldTypeId: this.modelValue.fieldTypeId || DEFAULT_FORM_MODEL.fieldTypeId,
          originalValue: this.modelValue.originalValue || DEFAULT_FORM_MODEL.originalValue,
          currentValue: this.modelValue.currentValue || DEFAULT_FORM_MODEL.currentValue,
          targetValue: this.modelValue.targetValue || DEFAULT_FORM_MODEL.targetValue,
          description: this.modelValue.description || DEFAULT_FORM_MODEL.description,
          parentId: this.modelValue.parentId || DEFAULT_FORM_MODEL.parentId,
          workspaceId: this.modelValue.workspaceId || DEFAULT_FORM_MODEL.workspaceId,
          automaticConfidenceLevelId:
            this.modelValue.automaticConfidenceLevelId ||
            DEFAULT_FORM_MODEL.automaticConfidenceLevelId,

          intervalId: this.modelValue.intervalId || DEFAULT_FORM_MODEL.intervalId,
          levelId: this.modelValue.levelId || DEFAULT_FORM_MODEL.levelId,

          ...convertUsersListToOwnerAndStakeholders(this.modelValue.users),
          groups: [...convertGroupsListToGroups(this.modelValue.groups)],
          labelIds: this.modelValue.labels?.map(label => label.id) || [],
          confidenceLevelId:
            this.modelValue.confidenceLevelId || DEFAULT_FORM_MODEL.confidenceLevelId,

          dueDate: this.modelValue.dueDate || null,
          elementStartDate: this.modelValue.elementStartDate || null,

          automaticElementStartDate: this.modelValue.automaticElementStartDate,

          automaticDueDate: this.modelValue.automaticDueDate,
          contribute: isUndefined(this.modelValue.contribute)
            ? DEFAULT_FORM_MODEL.contribute
            : this.modelValue.contribute,

          private: isUndefined(this.modelValue.private)
            ? DEFAULT_FORM_MODEL.private
            : this.modelValue.private
        }

        if (this.isEdit) {
          const values = getResultTypeValues(this.modelValue)
          this.formModel = {
            ...this.formModel,
            ...values
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.krf-KeyResultForm {
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  // margin-top: 14px;
  height: 100%;
}

.krf-Activities {
  margin-top: 28px;
}

.krf-Title {
  margin-bottom: 8px;
}

.krf-ResultTypeWrapper {
  margin-bottom: 8px;
}

.krf-ResultTypeWrapper_LockMessageDescription {
  font-weight: fw('regular');
}

.krf-Progress {
  // margin-bottom: 22px;
}

// .krf-ChildKeyResultsHeader {
//   margin-top: 28px;
//   // avoid 'jumping' when type of field content(empty & selected) is changed
//   min-height: 72px;
// }

// .krf-MenuName {
//   margin-left: 16px;
//   font-size: $fs-16;
// }

:deep(.kr-ObjectiveForm-NameField:first-child) {
  background-color: $white;
  border: 1px solid $azure-medium;
  border-radius: $border-radius-md;

  &:hover {
    background-color: $azure-medium;
  }
}

.krf-DatesLabel_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  align-self: flex-start;
}

.krf-DatesLabel_Icon {
  margin-right: auto;
  align-self: flex-start;
}

.krf-DatesLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.krf-DatesLabel_Value {
  width: auto;
  flex: 1;
  text-align: right;
  .is-safari & {
    // Fix for safari breaking wbr
    line-height: 20px;
  }
}

.krf-Sidebar {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.krf-ContributeWrapper {
  padding-top: 10px;
  padding-left: 14px;

  &:deep(.ac-Text) {
    line-height: normal;
  }
}

.krf-NoPermissionsMessage {
  margin-bottom: 26px;
  --icon-color: #{$grade-medium-color-next};
}

.krf-ClosedStatusMessage {
  margin-bottom: 26px;
}

.krf-ClosedStatusMessage_Description {
  font-weight: fw('regular');
}

.krf-LockMessage {
  margin: 0 0 8px 0;
}
</style>

<style lang="scss">
.krf-KeyResultForm {
  .o-confirm-actions {
    margin-top: 24px;
  }

  .fdf-Label {
    font-size: $fs-12;
    line-height: 14px;
    padding-bottom: 4px;
  }
}

.krf-ParentObjectiveWrapper {
  margin-bottom: 16px;

  .as-AppDroplistButton_Icon-prepend {
    margin-right: 0;
  }
}
</style>
