<template>
  <div :ref="templateRef" class="re-Item">
    <AppIcon class="re-Icon" height="24" icon-name="calendar-check" width="24" />
    <a class="re-Item_Title" href="#" @click.prevent="onReminderClick">
      {{ displayItems.scheduleName }} {{ $t('reminders.time_update_progress') }}
    </a>
    <span v-if="item.timing" class="re-Item_Ago" data-testid="notification-timing">
      {{ item.timing }}
    </span>
    <AppBadge
      v-if="!item.isRead"
      class="re-Item_Marker"
      data-testid="notification-unread-marker"
      inline
    />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { NOTIFICATION_ATTRIBUTES } from '@/utils/in-app-notifications'
import { inAppNotificationsStateInjectionKey } from '@/utils/injection-keys'

import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import { useMarkAsRead } from '@/components/ui/InAppNotifications/in-app-notifications-utils'

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['mark-as-read'])

const markAsRead = () => {
  emit('mark-as-read', props.item.id)
}

const templateRef = 'wrapperReference'

useMarkAsRead({ item: props.item, callback: markAsRead, templateRef })

const { SCHEDULE_NAME } = NOTIFICATION_ATTRIBUTES

const displayItems = computed(() => {
  const { normalizedAttributes } = props.item

  return {
    scheduleName: normalizedAttributes[SCHEDULE_NAME]
  }
})

const inAppNotificationsState = inject(inAppNotificationsStateInjectionKey)

const router = useRouter()

const onReminderClick = async () => {
  await router.push({ name: ROUTE_NAMES.CHECK_INS })
  inAppNotificationsState.closeModal()
}
</script>

<style lang="scss" scoped>
.re-Item {
  display: flex;
  padding: 10px;
  gap: 8px;
  align-items: center;
  font-weight: fw('semi-bold');
  color: $primary-color-next;
  background: rgba($primary-color-next, 0.1);
  border-radius: $border-radius-md-next;
  max-width: 580px;

  width: 100%;
  position: relative;
  transition: background-color $transition-fast ease-in-out;

  @media (any-hover: hover) {
    &:hover {
      background-color: $grey-3-next;
    }
  }
}

.re-Item_Title {
  word-break: break-word;
}

.re-Item_Ago {
  flex: 1 1 auto;
  color: $dark-3;
  font-weight: fw('regular');
  min-width: fit-content;
}

.re-Item_Marker {
  margin-left: auto;
  --backdrop-size: 24px;
}

.re-Icon {
  min-width: 24px;
  min-height: 24px;
  display: flex;
}
</style>
