<template>
  <div
    v-if="showPrivacyCell"
    v-tippy="{
      content: getTooltipTitle,
      placement: 'top',
      theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.WHITE_SPACE_PRE_LINE} ${DROP_LIST_THEMES.TEXT_CENTER}`
    }"
    class="pc-Wrapper"
  >
    <PrivacyAnimatedIcons
      :can-update-objective-privacy="canUpdateObjectivePrivacy"
      :is-animated="isAnimated"
      :is-loading="isLoading"
      :item="item"
      @update-privacy="onPrivacyUpdate"
    />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { STATUS_NAMES_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { currentUserCanUpdateObjective, isOkrElementClosed, OKR_STATUSES } from '@/utils/objectives'
import { ALL_STATUS_NAMES } from '@/utils/status-names-factory'

import PrivacyAnimatedIcons from '@/components/ui/PrivacyAnimatedIcons'

defineOptions({
  name: 'PrivacyCell'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  trackingSource: {
    type: String,
    default: EVENT_SOURCES.GRID
  }
})

const emit = defineEmits({
  'update-privacy': null
})

const isLoading = ref(false)

const isEmptyUsersList = computed(() => isEmpty(props.item.users))

const canUpdateObjectivePrivacy = computed(() => {
  return (
    !isEmptyUsersList.value &&
    currentUserCanUpdateObjective(props.item) &&
    !isOkrElementClosed(props.item)
  )
})
const onPrivacyUpdate = async value => {
  if (!canUpdateObjectivePrivacy.value) return
  const api = new ObjectivesInfoApiHandler()
  isLoading.value = true
  try {
    const result = await api.updateOkrElement({
      ...props.item,
      elementId: props.item.id,
      isPrivate: value
    })

    if (value) setAnimatedItem()

    emit('update-privacy', { ...props.item, ...result })

    const eventName = value ? 'set okr private' : 'set okr public'

    tracker.logEvent(eventName, {
      source: props.trackingSource,
      level: props.item.levelName,
      id: props.item.id,
      category: EVENT_CATEGORIES.OKR_MANAGEMENT
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const isAnimated = ref(false)
const setAnimatedItem = () => {
  isAnimated.value = true

  setTimeout(() => {
    isAnimated.value = false
  }, 1500)
}

const { t } = useI18n()
const store = useStore()
const isPluginAdmin = computed(() => {
  return store.state.pluginOptions.isPluginAdmin
})

const userCanUpdateObjective = computed(
  () =>
    currentUserCanUpdateObjective(props.item) &&
    isEmptyUsersList.value &&
    !isOkrElementClosed(props.item)
)

const getTooltipTitle = computed(() => {
  const canUserUpdate = userCanUpdateObjective.value
  const isEmptyList = isEmptyUsersList.value
  const isClosed = isOkrElementClosed(props.item)
  const canCurrentUserUpdate = currentUserCanUpdateObjective(props.item)
  const isAdmin = isPluginAdmin.value
  const canChangePrivacy = canUpdateObjectivePrivacy.value

  const availableTooltip = props.item.private
    ? t('objective.make_okr_public')
    : t('objective.make_okr_private')

  if (canUserUpdate || (isEmptyList && !isClosed)) {
    return t('objective.you_cant_make_private')
  }

  if (!canCurrentUserUpdate && !isAdmin) {
    return t('objective.you_dont_have_rights_to_edit')
  }

  if (canUserUpdate || canChangePrivacy) {
    return availableTooltip
  }

  if (isClosed) {
    const getStatusName = status => {
      const statusObj = ALL_STATUS_NAMES.statusProperties[status]
      const { CUSTOMER_NAME, DEFAULT_NAME } = STATUS_NAMES_ENTITY_KEYS
      return statusObj[CUSTOMER_NAME] || statusObj[DEFAULT_NAME]
    }

    return t('objective.privacy_cant_adjusted', {
      closedNameStatus: getStatusName(OKR_STATUSES.CLOSED),
      abandonedNameStatus: getStatusName(OKR_STATUSES.ABANDONED)
    })
  }

  return null
})

const showPrivacyCell = computed(() => {
  if (!props.item) return false

  const isPrivate = props.item.private
  const canChangePrivacy = canUpdateObjectivePrivacy.value
  const canUserUpdate = userCanUpdateObjective.value
  const isClosed = isOkrElementClosed(props.item)

  // Показываем ячейку, если:
  // - Цель приватная
  // - Можно менять приватность
  // - Пользователь может обновлять цель
  // - Цель закрыта
  return isPrivate || canChangePrivacy || canUserUpdate || isClosed
})
</script>

<style lang="scss" scoped>
.pc-Wrapper {
  display: flex;
  margin: 0 auto 0 0;
  max-width: 46px;
  width: 100%;
  text-align: center;
  justify-content: center;
}
</style>
