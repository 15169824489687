<template>
  <AppDialog
    :loading="isLoading"
    :show="show"
    :title="$t('schedule.delete_schedule.title')"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirm"
    @on-close="onClose"
  >
    <div class="cfd-ModalBody">
      <i18n-t
        v-if="show"
        keypath="schedule.delete_schedule.confirm_message"
        scope="global"
        tag="div"
      >
        <template #scheduleName>
          <span class="cfd-DangerText">
            {{ scheduleForDelete[SCHEDULE_ENTITY_KEYS.NAME] }}
          </span>
        </template>
      </i18n-t>

      <KeywordInput ref="keywordInputReference" :keyword="KEYWORD" />
    </div>
  </AppDialog>
</template>

<script setup>
import { ref, watch } from 'vue'

import SchedulesApiHandler from '@/api/schedules'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { SCHEDULE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { KEYWORD } from '@/utils/helpers'

import AppDialog from '@/components/AppDialog'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

defineOptions({
  name: 'ScheduleDeleteModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  scheduleForDelete: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits({
  close: null,
  'on-schedule-deleted': null
})

const isLoading = ref(false)

const keywordInputReference = ref(null)

const onConfirm = async () => {
  const isKeywordValid = keywordInputReference.value.validate()

  if (!isKeywordValid) {
    return
  }

  isLoading.value = true
  const api = new SchedulesApiHandler()

  try {
    const { id } = props.scheduleForDelete
    await api.deleteSchedule({
      id
    })
    emit('on-schedule-deleted')
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const onClose = () => {
  emit('close')
}

const keyword = ref('')
const isKeywordFieldValid = ref(true)

const setDefaultState = () => {
  keyword.value = ''
  isKeywordFieldValid.value = true
}

watch(
  () => props.show,
  async newValue => {
    if (!newValue) {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/modal-danger-text';

.cfd-DangerText {
  @extend %modal-danger-text;
}

.cfd-ModalBody {
  display: grid;
  gap: 20px;
}
</style>
