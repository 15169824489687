<template>
  <ul :class="classes">
    <slot name="list-prepend" />
    <li v-for="item in items" :key="item.id" :class="getItemClass(item)">
      <AppMenuNavItem
        :disabled="disabled"
        :item="item"
        :layout="layout"
        :list-children="listChildren"
        :list-type="listType"
        @item-click="$emit('item-click', $event)"
        @set-active-additional-menu="setActiveAdditionalMenu"
      >
        <template #objectives-item-append>
          <portal-target name="menu-objectives-link" />
        </template>

        <template #organization-item-label="{ keyLabel }">
          <i18n-t :keypath="keyLabel" scope="global">
            <template #break>
              <br />
            </template>
          </i18n-t>
        </template>

        <template #bin-item-label="{ label }">
          {{ label }}
          <AppBadge :content="$t('dashboard.new')" class="amnl-ListItemBadge" inline is-uppercase />
        </template>
      </AppMenuNavItem>
      <AppMenuNavList
        v-if="
          item.children &&
          !item.showChildrenLikeAdditionalMenu &&
          layout === APP_MENU_NAV_LAYOUTS.VERTICAL
        "
        :disabled="disabled"
        :items="item.children"
        :list-type="listType"
        list-children
        @item-click="$emit('item-click', $event)"
      />
      <template
        v-if="
          $slots[`${item.name}-item-after`] && item.children && !item.showChildrenLikeAdditionalMenu
        "
      >
        <slot :name="`${item.name}-item-after`" />
      </template>
    </li>
  </ul>
</template>

<script>
import { camelCase } from 'lodash'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { APP_MENU_NAV_LAYOUTS } from '@/utils/app-menu'

import AppMenuNavItem from '@/components/AppMenu/AppMenuNavItem'
import AppMenuNavList from '@/components/AppMenu/AppMenuNavList'
import AppBadge from '@/components/ui/AppBadge/AppBadge'

export default defineComponent({
  name: 'AppMenuNavList',

  components: { AppBadge, AppMenuNavItem, AppMenuNavList },

  props: {
    items: {
      type: Array,
      required: true
    },

    listChildren: {
      type: Boolean
    },

    listType: {
      type: String,
      default: 'default',
      validator: v => ['default', 'additionalMenu'].includes(v)
    },

    layout: {
      type: String,
      default: APP_MENU_NAV_LAYOUTS.VERTICAL,
      validator: v => Object.values(APP_MENU_NAV_LAYOUTS).includes(v)
    },

    disabled: {
      type: Boolean
    }
  },

  emits: { 'set-active-additional-menu': null, 'item-click': null },

  computed: {
    ...mapState('system', {
      menuShowed: state => state.appMenu.showed,
      menuPinned: state => state.appMenu.pinned
    }),

    // ...mapState('pluginOptions', {
    //   isPluginAdmin: state => state.isPluginAdmin
    // }),
    //
    // ...mapGetters('pluginOptions', {
    //   isPluginServer: 'isPluginServer'
    // }),

    APP_MENU_NAV_LAYOUTS: () => APP_MENU_NAV_LAYOUTS,

    classes() {
      const { listChildren, menuHidden, layout } = this
      return {
        'amnl-List': true,
        'amnl-List-children': listChildren,
        'amnl-List-hidden': menuHidden,
        [`amnl-List-${layout}`]: true
      }
    },

    menuHidden() {
      return !this.menuShowed && !this.menuPinned
    }
  },

  methods: {
    setActiveAdditionalMenu(menuItemName) {
      this.$emit('set-active-additional-menu', menuItemName)
    },

    getItemClass(item) {
      return {
        'amnl-List_Item': true,
        'amnl-List_Item-with-bottom-divider': item.hasBottomDivider,
        [`amnl-List_Item-${camelCase(item.name)}`]: true
      }
    }
  }
})
</script>

<style lang="scss" scoped>
$bottom-offset: 33px;

.amnl-List {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(&-children) {
    display: flex;
    gap: var(--gap, 8px);
  }

  &:not(&-horizontal) {
    flex-direction: column;
  }

  &-children {
    &:not(&:empty) {
      padding: 4px 4px 8px 4px;
    }
  }

  &-horizontal {
    align-items: center;
    justify-content: center;
  }
}

.amnl-List_Item {
  &-with-bottom-divider {
    position: relative;
    margin-bottom: $bottom-offset;

    &:after {
      content: '';
      position: absolute;
      bottom: calc(#{$bottom-offset / 2 - 0.5} * -1);
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background: $grey-2-next;
      pointer-events: none;
    }

    .amnl-List-hidden &:after {
      background: transparent;
    }
  }
}

.amnl-ListItemBadge {
  --color: var(--grade-low-color-next);
  --badge-size: 12px;
  --padding: 2px 4px;
  vertical-align: middle;
  margin-left: 4px;
  margin-bottom: 2px;
  z-index: 0;
}
</style>
