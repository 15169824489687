import { isNull } from 'lodash'

//
import { KR_VALUE_TYPES, objectiveIsJiraTask } from '@/utils/objectives'
import { GRADE_MODES } from '@/utils/okr-element-values'

export const currentTargetVisibility = objective => {
  const { fieldTypeId, typeId, gradeMode, existContributeChildren, targetValue, originalValue } =
    objective
  const isNotBinary = fieldTypeId !== KR_VALUE_TYPES.BINARY
  // const percentValueOriginalValue =
  //   objective.fieldTypeId === KR_VALUE_TYPES.PERCENT && objective.originalValue !== 0
  // const percentValueTargetValue =
  //   objective.fieldTypeId === KR_VALUE_TYPES.PERCENT && objective.targetValue !== 100
  // const numberValueOriginalValue =
  //   objective.fieldTypeId === KR_VALUE_TYPES.NUMBER && objective.originalValue !== 0
  // const numberValueTargetValue =
  //   objective.fieldTypeId === KR_VALUE_TYPES.NUMBER && objective.targetValue !== 100
  const isValidValues = !isNull(targetValue) && !isNull(originalValue)
  const isJiraIssueManualMode = objectiveIsJiraTask({ typeId }) && gradeMode === GRADE_MODES.MANUAL
  return (
    isNotBinary &&
    // (percentValueOriginalValue ||
    //   percentValueTargetValue ||
    //   numberValueOriginalValue ||
    //   numberValueTargetValue) &&
    isValidValues &&
    (!existContributeChildren || isJiraIssueManualMode)
  )
}

export const PLUG_ICONS = {
  ASSIGNEE: 'cell-plug-assignee',
  DATE: 'cell-plug-date',
  SELECT: 'cell-plug-select',
  COMMENT: 'cell-plug-comment',
  COMMON: 'cell-plug-common'
}
