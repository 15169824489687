import { inject } from 'vue'

import { ENTITIES_ACCESS_LEVELS } from '@/utils/entities-access-levels'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'

export const useFilterByAccessLevel = () => {
  const isJiraApp = inject(isJiraAppInjectionKey)
  const isWebApp = inject(isWebAppInjectionKey)
  const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

  const getEntitiesForPlatform = ({ entities = [] } = {}) => {
    return entities.filter(option => {
      const [condition] = [
        isJiraApp &&
          (option.accessLevel & ENTITIES_ACCESS_LEVELS.JIRA_APP) ===
            ENTITIES_ACCESS_LEVELS.JIRA_APP,
        isWebApp &&
          (option.accessLevel & ENTITIES_ACCESS_LEVELS.WEB_APP) === ENTITIES_ACCESS_LEVELS.WEB_APP,
        isCrossPlatformApp &&
          (option.accessLevel & ENTITIES_ACCESS_LEVELS.CROSS_PLATFORM_APP) ===
            ENTITIES_ACCESS_LEVELS.CROSS_PLATFORM_APP
      ].filter(Boolean)

      return condition || false
    })
  }

  return {
    getEntitiesForPlatform
  }
}
