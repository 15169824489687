<template>
  <div v-if="isVisible" class="slo-BackdropWrapper">
    <AppDroplist
      v-model="opened"
      :offset="[0, 8]"
      append-to="parent"
      dropdown-width="280px"
      position="top-end"
    >
      <template #button>
        <div class="slo-BoxShadowWrapper">
          <AppButton class="slo-ButtonWrapper" icon="hat-shape" type="success">
            <span class="slo-ButtonText">{{ $t('common.getting_started') }}</span>
            <div class="slo-CountProgress">{{ completedStories }}/{{ stories.length }}</div>
            <AppButton
              class="slo-CloseBtn"
              icon="close-common"
              remove-padding
              size="sm"
              type="secondary-inline"
              @click.stop="closeOnboardingBtn"
            />
          </AppButton>
        </div>
      </template>
      <div>
        <div class="slo-ContentWrapper">
          <div class="slo-ContentTitleWrapper">
            <AppTitle :level="3" class="slo-ContentTitle" disable-margin>
              {{ $t('common.getting_started') }}
            </AppTitle>
            <AppButton
              class="slo-CloseContentBtn"
              icon="close-next"
              remove-padding
              size="sm"
              type="secondary-inline"
              @click="closeOnboardingContent"
            />
          </div>
          <div class="slo-ProgressWrapper">
            <div class="slo-ProgressTitle">
              {{ $t('common.complete_progress', { start: completedStories, end: stories.length }) }}
            </div>
            <IndicatorPanel
              :indicator-width="indicatorWidth"
              background-color="rgba(var(--grade-high-color-rgb-next), 0.1)"
              color="var(--grade-high-color-next)"
              style="--height: 4px"
            />
          </div>
        </div>
        <ul class="slo-StoryWrapper">
          <li
            v-for="story in stories"
            :key="story.id"
            :class="{ 'slo-StoryItem-complete': story.complete }"
            class="slo-StoryItem"
            @click="runStorylane(story)"
          >
            <AppIcon
              :icon-name="story.complete ? 'check-active' : story.icon"
              height="24"
              width="24"
            />
            <span class="slo-StoryTitle">{{ story.title }}</span>
          </li>
        </ul>
      </div>
    </AppDroplist>
  </div>
</template>

<script setup>
import { watchOnce } from '@vueuse/core'
import { uniq } from 'lodash'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { DEFAULT_USERS_SETTINGS, STORYLANE, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import IndicatorPanel from '@/components/ui/IndicatorPanel/IndicatorPanel'

defineOptions({
  name: 'StoryLaneOnboarding'
})

const route = useRoute()
const ALLOWED_ROUTES = [
  ROUTE_NAMES.OBJECTIVES,
  ROUTE_NAMES.ALIGNMENT,
  ROUTE_NAMES.OKR_EXPLORER,
  ROUTE_NAMES.OKR_ELEMENTS_EXPLORER,
  ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE,
  ROUTE_NAMES.HOME_PAGE,
  ROUTE_NAMES.DASHBOARD,
  ROUTE_NAMES.DASHBOARD_GENERAL,
  ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT,
  ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS,
  ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE,
  ROUTE_NAMES.CUSTOM_DASHBOARD,
  ROUTE_NAMES.CUSTOM_DASHBOARD_LIST,
  ROUTE_NAMES.CUSTOM_DASHBOARD_ITEM
]

const opened = ref(false)

const store = useStore()
const storylaneSettings = computed(() => store.getters['system/storylaneSettings'])
const settingsAreLoaded = computed(() => store.state.system.settingsAreLoaded)

const isVisible = computed(() => {
  const isButtonIdGraterThanSaved =
    storylaneSettings.value.buttonId < DEFAULT_USERS_SETTINGS[STORYLANE].buttonId

  return settingsAreLoaded.value && isButtonIdGraterThanSaved && ALLOWED_ROUTES.includes(route.name)
})

const isComplete = id => {
  return storylaneSettings.value.completedIds.includes(id)
}

const stories = computed(() =>
  [
    {
      id: 'jmyhcn5ta',
      title: 'Structure & reporting overview',
      icon: 'org-chart',
      link: 'https://app.storylane.io/demo/le1d3q9avuc5'
    },
    {
      id: '2p6m4k8r6',
      title: 'Create your first okr',
      icon: 'plus-next',
      link: 'https://app.storylane.io/demo/g1xznplnezg5'
    },
    {
      id: 'xhqo0fz4y',
      title: 'Create custom dashboard',
      icon: 'menu-dashboard',
      link: 'https://app.storylane.io/demo/1nuwafaeket8'
    }
  ].map(item => ({ ...item, complete: isComplete(item.id) }))
)

const completedStories = computed(() => stories.value.filter(story => story.complete).length)
const indicatorWidth = computed(() =>
  Math.round((completedStories.value / stories.value.length) * 100)
)

watchOnce(
  () => storylaneSettings.value,
  newValue => {
    if (!newValue.hideOnboardingContent) {
      opened.value = true
    }
  }
)

const closeOnboardingContent = () => {
  opened.value = false
  store.dispatch('system/updateUserSettings', {
    [USER_SETTINGS_MAPPER[STORYLANE]]: {
      ...storylaneSettings.value,
      hideOnboardingContent: true
    }
  })
}

const closeOnboardingBtn = () => {
  store.dispatch('system/updateUserSettings', {
    [USER_SETTINGS_MAPPER[STORYLANE]]: {
      ...storylaneSettings.value,
      buttonId: DEFAULT_USERS_SETTINGS[STORYLANE].buttonId
    }
  })
}

const runStorylane = ({ link, id } = {}) => {
  if (window.Storylane) {
    const { completedIds } = storylaneSettings.value
    store.dispatch('system/updateUserSettings', {
      [USER_SETTINGS_MAPPER[STORYLANE]]: {
        ...storylaneSettings.value,
        completedIds: uniq([...completedIds, id])
      }
    })
    window.Storylane.Play({
      type: 'popup',
      demo_type: 'image',
      width: 1920,
      height: 878,
      scale: '0.95',
      demo_url: link,
      padding_bottom: 'calc(45.73% + 27px)'
    })
  }
}
</script>

<style lang="scss" scoped>
.slo-CountProgress {
  line-height: 1.5;
  padding: 2px 6px 2px 6px;
  border-radius: $border-radius-md-next;
  background-color: rgba(var(--white-rgb), 0.2);
}

.slo-BoxShadowWrapper {
  border: 4px solid var(--white-color);
  box-shadow: 0 2px 4px 0 rgba(var(--black-rgb), 0.15), 0 0 1px 0 rgba(var(--black-rgb), 0.15);
  border-radius: $border-radius-sm-next;

  &:hover {
    .slo-CloseBtn {
      visibility: visible;
    }
  }
}

.slo-ButtonWrapper {
  display: flex;
  padding: 4px 6px 4px 4px;
  border-radius: $border-radius-sm-next;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  gap: 4px;
  color: var(--white-color);
}

.slo-CloseBtn {
  position: absolute;
  right: 0;
  transform: translateX(100%);
  top: 8px;
  border-radius: $border-radius-md-next;
  visibility: hidden;
  cursor: pointer;
  color: $grey-1-next;
}

.slo-ContentWrapper {
  padding: 20px 20px 0 20px;
}

.slo-ContentTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin: 0 0 20px 0;
}

.slo-CloseContentBtn {
  cursor: pointer;
}

.slo-ContentTitle {
  font-weight: fw('semi-bold');
  color: $dark-2;
}

.slo-ProgressTitle {
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  margin-bottom: 6px;
}

.slo-ProgressWrapper {
  margin: 0 0 20px 0;
}

.slo-StoryWrapper {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.slo-StoryItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: $fs-14;
  line-height: 20px;
  color: $primary-color-next;
  padding: 10px 20px;
  cursor: pointer;

  &-complete {
    color: $grade-high-color-next;
  }
  &:hover {
    background: $grey-3-next;
  }
  &:last-child {
    margin-bottom: 10px;
  }
}

.slo-StoryTitle {
  font-size: $fs-14;
  line-height: 20px;
  cursor: pointer;
}

.slo-ButtonText {
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('bold');
}

.slo-BackdropWrapper {
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index: 10;
  display: flex;
  padding: 0 28px 0 0;
  justify-content: flex-end;
  align-items: center;
}
</style>
