import VueProgressBar from '@aacassandra/vue3-progressbar'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import Qs from 'qs'
import VueTippy from 'vue-tippy'
import VueClickAway from 'vue3-click-away'

import { visible } from '@/directives/visible'
import i18n from '@/i18n'
import { makeServer } from '@/mock-api-server'
import { tracker } from '@/tracking/amplitude'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { replaceJwt } from '@/utils/general'
import { setPluginOptions } from '@/utils/plugin-options'
import { IS_PRODUCTION } from '@root/app-modes'

/* eslint-disable import/no-extraneous-dependencies */
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/translucent.css'
/* eslint-enable import/no-extraneous-dependencies */
import '@/assets/styles/root.scss'
import '@/assets/styles/app.scss'

const PROGRESS_BAR_OPTIONS = {
  color: '#0052cc',
  failedColor: '#F64963',
  thickness: '3px',
  transition: {
    speed: '1s',
    termination: 300
  },
  autoRevert: false,
  inverse: false
}

export default function configure(
  app,
  rootElement,
  { apiBase, pluginType, iss, baseUrl, atlassianBaseUrl, production, organizationId = null } = {},
  router = null,
  initSentry = true
) {
  axios.defaults.baseURL = apiBase || window.origin
  axios.defaults.timeout = 50000
  axios.defaults.headers['Cache-Control'] = 'no-cache'
  axios.defaults.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains'
  axios.defaults.headers[
    'Content-Security-Policy'
  ] = `default-src: self; script-src 'self'; object-src: self; style-src: self; font-src: self; connect-src 'self'; img-src 'self'; base-uri '${baseUrl}'; form-action 'self'; reflected-xss;`
  axios.defaults.paramsSerializer = params =>
    Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })

  tracker.init(pluginType, iss)

  setPluginOptions({ baseUrl, atlassianBaseUrl, organizationId })

  /* vue-tippy is used in AppDropdown(that's means also in AppSelect and other related component) */
  app.use(VueTippy, {
    component: 'AppTippy',
    defaultProps: {
      placement: 'bottom',
      theme: DROP_LIST_THEMES.TRANSLUCENT_NEXT,
      arrow: false,
      offset: [0, 5]
    }
  })

  app.use(i18n)
  app.use(VueClickAway)

  app.use(VueProgressBar, PROGRESS_BAR_OPTIONS)

  app.directive('visible', visible)
  if (process.env.VUE_APP_ENABLE_MOCK_API) {
    makeServer({ environment: 'development' }, apiBase)
  }

  // clear all parameters from back in root element
  // it is important because there is also sensitive data such as token inside
  Object.keys(rootElement.dataset).forEach(dataKey => {
    delete rootElement.dataset[dataKey]
  })

  if (IS_PRODUCTION && !JSON.parse(process.env.VUE_APP_IS_SERVER_BUILD) && initSentry) {
    const browserTracingOptions = {}
    if (router) {
      browserTracingOptions.router = router
    }
    Sentry.init({
      app,
      dsn: 'https://e106f88c028b41f999cf34139ec087be@o1140695.ingest.sentry.io/6198081',
      release: process.env.VUE_APP_SENTRY_RELEASE,
      integrations: [Sentry.browserTracingIntegration(browserTracingOptions)],
      tracesSampleRate: 1.0,
      environment: production && JSON.parse(production) ? 'production' : 'staging',
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.data && breadcrumb.data.from) {
          breadcrumb.data.from = replaceJwt(breadcrumb.data.from)
        }
        if (breadcrumb.data && breadcrumb.data.to) {
          breadcrumb.data.to = replaceJwt(breadcrumb.data.to)
        }
        return breadcrumb
      },

      beforeSendTransaction(transaction) {
        if (transaction?.request?.url) {
          transaction.request.url = replaceJwt(transaction.request.url)
        }

        if (transaction?.contexts?.trace?.data) {
          for (const key in transaction.contexts.trace.data) {
            if (key.includes('jwt')) {
              transaction.contexts.trace.data[key] = replaceJwt('', false)
            }
          }
        }

        transaction.spans.forEach(span => {
          if (span.description) {
            span.description = replaceJwt(span.description)
          }
        })

        return transaction
      },
      ignoreErrors: [
        // Random plugins/extensions
        'Network Error',
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Sentry useless errors
        'ResizeObserver loop limit exceeded',
        'Non-Error promise rejection captured with value: Not implemented on this platform',
        'ResizeObserver loop completed with undelivered notifications',
        "Cannot read properties of undefined (reading 'facebook.json')"
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /chrome:\/\//i,
        /chrome-extension/i,
        /^chrome-extension\/\//i,
        // Google analytics
        /googleads\.g\.doubleclick\.net/i,
        /static\.doubleclick\.net/i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Jira URLs
        /atlassian\.com/i,
        /atl-paas\.com/i,
        /jira-frontend-static\.com/i
      ]
    })
  }
}
