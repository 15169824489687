import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { handleError } from '@/utils/error-handling'
import { OKR_ELEMENT_PERMISSIONS } from '@/utils/objectives'
import { INTERVAL_ID_KEY, WORKSPACE_ID_KEY } from '@/utils/query-parameters'

const PARENT_ID = 'parentId'

export const useWsWithParent = ({ autoSetParentItem = false, withNoParentOption = true } = {}) => {
  const store = useStore()
  const workspacesOptions = computed(() => store.state.workspaces.workspaces)
  const { t } = useI18n()

  const NO_PARENT_ELEMENT = {
    name: t('move.no_parent'),
    id: null,
    customIcon: 'unassigned-owner',
    permissions: [OKR_ELEMENT_PERMISSIONS.READ],
    displayId: ''
  }

  const formModel = ref({
    workspaceId: workspacesOptions.value[0].id,
    intervalId: null,
    parentId: null
  })

  const intervalOptions = ref([])

  const fetchIntervals = async workspaceId => {
    const intervalApi = new IntervalsInfoApiHandler()
    const { currentIndex, intervals } = await intervalApi.getIntervalsInfo({ workspaceId })

    const onlyOpenedIntervals = intervals.filter(interval => interval.open)

    const setIntervalId = id => {
      formModel.value.intervalId = id ?? null
    }

    if (isEmpty(onlyOpenedIntervals)) {
      setIntervalId(null)
    } else {
      const intervalByIndex = onlyOpenedIntervals[currentIndex]
      const validInterval =
        intervalByIndex && !intervalByIndex.backlog
          ? intervalByIndex
          : onlyOpenedIntervals.find(interval => !interval.backlog)

      setIntervalId(validInterval?.id || onlyOpenedIntervals[0].id)
    }

    intervalOptions.value = onlyOpenedIntervals
  }

  const updateModelValue = ({ value, key }) => (formModel.value[key] = value)

  const onUpdateWorkspace = async value => {
    updateModelValue({ value, key: WORKSPACE_ID_KEY })
    await fetchIntervals(value)
    await fetchParentObjectives({ workspaceId: value, intervalId: formModel.value.intervalId })
    if (!autoSetParentItem) {
      formModel.value.parentId = NO_PARENT_ELEMENT.id
    }
  }

  const onUpdateInterval = async intervalId => {
    updateModelValue({ value: intervalId, key: INTERVAL_ID_KEY })
    await fetchParentObjectives({ workspaceId: formModel.value.workspaceId, intervalId })
    if (!autoSetParentItem) {
      formModel.value.parentId = NO_PARENT_ELEMENT.id
    }
  }
  const loaders = ref({
    intervalLoader: false,
    parentLoader: false
  })

  const parentOptions = ref([])

  const parentOptionsList = computed(() => {
    const data = [...parentOptions.value]
    if (withNoParentOption) {
      data.unshift(NO_PARENT_ELEMENT)
    }

    return data
  })

  const fetchParentObjectives = async ({ workspaceId, intervalId, searchString = null }) => {
    const objectiveInfoApi = new ObjectivesInfoApiHandler()

    let data = []
    try {
      loaders.value.parentLoader = true
      const { elements } = await objectiveInfoApi.getParentObjectiveInfo({
        workspaceId,
        intervalId,
        searchString,
        strict: true
      })
      if (searchString === null) {
        parentOptions.value = elements
      }
      data = elements
      if (autoSetParentItem) {
        if (elements[0]) {
          updateModelValue({ value: elements[0].id, key: PARENT_ID })
        } else {
          updateModelValue({ value: NO_PARENT_ELEMENT.id, key: PARENT_ID })
        }
      }
      return data
    } catch (error) {
      handleError({ error })
    } finally {
      loaders.value.parentLoader = false
    }
  }

  const updateParentOptions = value => {
    parentOptions.value = value.filter(v => v.id)
  }
  return {
    fetchIntervals,
    updateParentOptions,
    onUpdateInterval,
    onUpdateWorkspace,
    formModel,
    workspacesOptions,
    loaders,
    intervalOptions,
    parentOptionsList,
    updateModelValue,
    fetchParentObjectives
  }
}
