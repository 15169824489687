<template>
  <ScrollTrigger />
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { h, ref, watch } from 'vue'

defineOptions({
  name: 'InfiniteScrollLoaderNext'
})

const props = defineProps({
  loading: {
    type: Boolean
  }
})

const emit = defineEmits({
  'load-more': null
})

const isLoaderVisible = ref(false)

const loaderReference = ref(null)

useIntersectionObserver(loaderReference, ([entry]) => {
  isLoaderVisible.value = entry?.isIntersecting || false
})

watch(
  () => [isLoaderVisible.value, props.loading],
  newValue => {
    if (newValue) {
      const [loaderVisible, loadingStatus] = newValue
      if (loaderVisible && !loadingStatus) {
        emit('load-more')
      }
    }
  },
  { immediate: true }
)

const ScrollTrigger = () => {
  return h('div', {
    ref: loaderReference,
    style: {
      width: '100%',
      pointerEvents: 'none'
    }
  })
}
</script>

<style lang="scss" scoped></style>
