<template>
  <div class="smw-SelectWrapper">
    <FormFieldNext :label="t('field.workspace.title')">
      <AppSelect
        v-model="formModel.workspaceId"
        :offset="[0, -40]"
        :options="workspacesOptions"
        :search-function="value => localSearch({ value, options: workspacesOptions })"
        class="smw-Select"
        dropdown-search
        item-label="name"
        item-value="id"
        @update:model-value="onUpdateWorkspace"
      >
        <template #button-content="{ option }">
          <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon />
        </template>
        <template #option-label="{ option }">
          <WorkspaceSelectOption v-if="option" :workspace="option" />
        </template>
      </AppSelect>
    </FormFieldNext>

    <FormFieldNext :label="t('field.quoter.title')">
      <AppSelect
        v-model="formModel.intervalId"
        :loading="loaders.intervalLoader"
        :offset="[0, -40]"
        :options="intervalOptions"
        :search-function="value => localSearch({ value, options: intervalOptions })"
        class="smw-Select"
        dropdown-search
        item-label="name"
        item-value="id"
        @update:model-value="onUpdateInterval"
      >
      </AppSelect>
    </FormFieldNext>

    <FormFieldNext :label="t('field.parent_element')">
      <AppSelect
        v-model="formModel.parentId"
        :loading="loaders.parentLoader"
        :offset="[0, -40]"
        :options="parentOptionsList"
        :search-function="
          searchString =>
            fetchParentObjectives({
              workspaceId: formModel.workspaceId,
              intervalId: formModel.intervalId,
              searchString
            })
        "
        class="smw-Select"
        dropdown-search
        item-label="name"
        item-value="id"
        @update:model-value="value => updateModelValue({ value, key: PARENT_ID })"
        @update:options="updateParentOptions"
      >
        <template #option-label="{ option }">
          <ObjectiveSelectItem v-if="option" :objective="option" show-interval show-workspace />
        </template>
        <template #button-content="{ option, isDropdownVisible }">
          <template v-if="option">
            <OkrIcon :objective="option" class="smw-Select_ObjectiveIcon" />
            <span
              :class="{ 'smw-Select_ObjectiveId-focused': isDropdownVisible }"
              class="smw-Select_ObjectiveId"
            >
              {{ option.displayId }}
            </span>
            <span
              :class="{ 'smw-Select_LabelName-focused': isDropdownVisible }"
              class="smw-Select_LabelName"
            >
              {{ option.name }}
            </span>
          </template>
        </template>
      </AppSelect>
    </FormFieldNext>
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { localSearch } from '@/utils/objectives'

import FormFieldNext from '@/components/form/FormFieldNext'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import { useWsWithParent } from '@/components/objectives/objective-move/utils/move-utils'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

const { t } = useI18n()
const PARENT_ID = 'parentId'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  },

  withNoParentOption: {
    type: Boolean
  },

  autoSetParentItem: {
    type: Boolean
  }
})

const emit = defineEmits(['update:modelValue'])

const {
  fetchIntervals,
  updateParentOptions,
  onUpdateInterval,
  onUpdateWorkspace,
  formModel,
  workspacesOptions,
  loaders,
  intervalOptions,
  updateModelValue,
  fetchParentObjectives,
  parentOptionsList
} = useWsWithParent({
  autoSetParentItem: props.autoSetParentItem,
  withNoParentOption: props.withNoParentOption
})

defineExpose({
  loaders
})

onMounted(async () => {
  formModel.value = props.modelValue
  await fetchIntervals(formModel.value.workspaceId)
  await fetchParentObjectives({
    workspaceId: formModel.value.workspaceId,
    intervalId: formModel.value.intervalId
  })
})

watch(
  () => formModel.value,
  newValue => {
    if (newValue) {
      emit('update:modelValue', newValue)
    }
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.smw-SelectWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.smw-Select_ObjectiveIcon {
  margin-right: 8px;
}
.smw-Select_ObjectiveId {
  margin-right: 4px;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $primary-color-next;
  min-width: max-content;
  &-focused {
    color: $white;
  }
}
.smw-Select_LabelName {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &-focused {
    color: $white;
  }
}
</style>
