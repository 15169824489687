// Objectives
export const OBJECTIVES_SET_LEVELS = 'OBJECTIVES_SET_LEVELS'
export const OBJECTIVES_SET_FULL_LEVELS_LIST = 'OBJECTIVES_SET_FULL_LEVELS_LIST'
export const OBJECTIVES_ADD_USERS = 'OBJECTIVES_ADD_USERS'
export const OBJECTIVES_SET_SORTING = 'OBJECTIVES_SET_SORTING'
export const SET_ELEMENT_FOR_EDIT = 'SET_ELEMENT_FOR_EDIT'

// Workspaces
export const SET_WORKSPACE_ID = 'SET_WORKSPACE_ID'
export const SET_WORKSPACES = 'SET_WORKSPACES'
export const SET_WORKSPACES_ARE_LOADED = 'SET_WORKSPACES_ARE_LOADED'
export const ADD_WORKSPACE = 'ADD_WORKSPACE'
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE'

// Plugin options
export const SET_PLUGIN_OPTIONS = 'SET_PLUGIN_OPTIONS'

// System
export const TOGGLE_APP_MENU_PINNED_STATUS = 'TOGGLE_APP_MENU_PINNED_STATUS'
export const TOGGLE_APP_MENU_SHOWED_STATUS = 'TOGGLE_APP_MENU_SHOWED_STATUS'
export const SET_SETTINGS_PARAMETER = 'SET_SETTINGS_PARAMETER'
export const SET_FULLSCREEN = 'SET_FULLSCREEN'
export const SET_ROADMAP_STICKY_STATUS = 'SET_ROADMAP_STICKY_STATUS'
export const SET_SETTINGS_ARE_LOADED = 'SET_SETTINGS_ARE_LOADED'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_DATA_BY_KEY = 'SET_USER_DATA_BY_KEY'
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const DELETE_USER_DATA_PARAMETER = 'DELETE_USER_DATA_PARAMETER'
export const SET_FULL_APP_LOADER_STATUS = 'SET_FULL_APP_LOADER_STATUS'
export const SET_RESTRICTIONS = 'SET_RESTRICTIONS'

export const SET_BILLING_PLAN_DETAILS = 'SET_BILLING_PLAN_DETAILS'
export const SET_BILLING_PLAN_DETAILS_LOADER = 'SET_BILLING_PLAN_DETAILS_LOADER'

// Toolbar
export const SET_TOOLBAR_HEIGHT = 'SET_TOOLBAR_HEIGHT'

// Page header
export const SET_PAGE_HEADER_HEIGHT = 'SET_PAGE_HEADER_HEIGHT'

// License banner
export const SET_APP_LICENSE_BANNER_HEIGHT = 'SET_APP_LICENSE_BANNER_HEIGHT'

// External Environment
export const SET_EXTERNAL_ENVIRONMENT_ELEMENTS_HEIGHT = 'SET_EXTERNAL_ENVIRONMENT_ELEMENTS_HEIGHT'

// OKR filters
export const SET_SAVED_FILTERS = 'SET_SAVED_FILTERS'
export const ADD_NEW_FILTER = 'ADD_NEW_FILTER'
export const DELETE_FILTER = 'DELETE_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const UPDATE_SAVED_FILTERS_LOADED_STATUS = 'UPDATE_SAVED_FILTERS_LOADED_STATUS'

// Dashboard

export const DASHBOARD_SET_DASHBOARD_LIST = 'DASHBOARD_SET_DASHBOARD_LIST'
export const DASHBOARD_SET_COUNT = 'DASHBOARD_SET_COUNT'
export const DASHBOARD_SET_FAVORITE_DASHBOARD_LIST = 'DASHBOARD_SET_FAVORITE_DASHBOARD_LIST'
export const DASHBOARD_SET_DASHBOARD_ITEM = 'DASHBOARD_SET_DASHBOARD_ITEM'
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE'
export const SET_FILTERS = 'SET_FILTERS'

// Custom fields

export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS'
export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD'
export const RESET_CUSTOM_FIELDS = 'RESET_CUSTOM_FIELDS'
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD'

// Web app
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const SET_URLS = 'SET_URLS'
export const SET_IS_WEB_APP = 'SET_IS_WEB_APP'
export const SET_IS_CONNECTION_INITIALIZED_BY_ME = 'SET_IS_CONNECTION_INITIALIZED_BY_ME'
export const SET_IS_DISCONNECTION_INITIALIZED_BY_ME = 'SET_IS_DISCONNECTION_INITIALIZED_BY_ME'
export const SET_DISABLED_MODULES = 'SET_DISABLED_MODULES'

// Cross-platform app
export const SET_IS_CROSS_PLATFORM_APP = 'SET_IS_CROSS_PLATFORM_APP'
