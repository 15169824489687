<template>
  <div class="eal-Wrapper">
    <div class="eal-Toolbar">
      <OkrFilterSelect
        :bottom-fixed-items="
          getFilterBottomItems({
            filtersValues: listState.filtersValues,
            filterKey: REQUEST_ENTITY_KEYS.TYPE_IDS
          })
        "
        :model-value="listState.filtersValues[REQUEST_ENTITY_KEYS.TYPE_IDS]"
        :options="okrTypes"
        :search="false"
        has-only-this-button
        n-selected-label="audit_logs.types"
        prepend-icon="okr-types"
        @update:model-value="onSelectFilterValue($event, REQUEST_ENTITY_KEYS.TYPE_IDS)"
      >
        <template #option-label="{ option }">
          <div v-if="option" class="eal-TypesOption">
            <AppIcon :icon-name="option.icon" class="eal-TypesOption_Icon" height="24" width="24" />
            <span class="eal-TypesOption_Label oboard-truncated-text">
              {{ option.name }}
            </span>
          </div>
        </template>
        <template #bottom-fixed-items="{ bottomFixedItems }">
          <div v-for="item in bottomFixedItems" :key="item.id">
            <BottomFixedSelectItem
              v-if="isClearSelectionAction(item.action)"
              @click="clearSelection(REQUEST_ENTITY_KEYS.TYPE_IDS)"
            >
              {{ $t(item.text) }}
            </BottomFixedSelectItem>
          </div>
        </template>
      </OkrFilterSelect>
    </div>
    <AppTable
      :columns="COLUMNS"
      :data="auditLogData"
      :disable-user-select="false"
      :loading="listState.isLoading"
      border-on-last-row
      class="gal-Table"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      sticky-header
      type="primary-next"
    >
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.DATE">
          {{ formatDateForAuditLog(item.date) }}
        </template>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.USER_NAME">
          <div class="oboard-truncated-text eal-NameCell">{{ item.userName }}</div>
        </template>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.WORKSPACE">
          <div class="oboard-truncated-text eal-WorkspaceNameCell">
            <b>[{{ item.workspaceKey }}]</b>
            {{ item.workspaceName }}
            ID{{ item.workspaceId }}
          </div>
        </template>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.ICON">
          <OkrIconAndId
            :clickable="false"
            :okr-element="{
              ...item,
              typeId: item.elementTypeId
            }"
            id-disabled-color="var(--dark-1)"
          />
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
          <div class="oboard-truncated-text eal-ElementNameCell">{{ item.name }}</div>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.TEXT">
          {{ ACTIONS[item.historyTypeId] || '—' }}
        </template>
      </template>

      <template #loading>
        <ElementsAuditLogLoader />
      </template>

      <template #footer>
        <InfiniteScrollLoaderNext
          v-if="!listState.isAllItemsLoaded"
          :loading="listState.isLoading"
          @load-more="onLoadMore"
        />
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import { cloneDeep, isNull } from 'lodash'
import { computed, inject, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { getFilterBottomItems, isClearSelectionAction } from '@/composables/bottom-fixed-items'
import {
  AUDIT_LOG_TYPE_IDS,
  ELEMENTS_AUDIT_LOG_HISTORY_TYPE_IDS,
  formatDateForAuditLog,
  useAuditLogs
} from '@/utils/audit-logs'
import { CUSTOM_FIELDS_OKR_TYPE_IDS } from '@/utils/custom-fields/helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { getNewSelectWithSelectAllValue, getSelectWithSelectAllApiParameter } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import OkrIconAndId from '@/components/form/OkrIconAndId'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import ElementsAuditLogLoader from '@/components/ui/SkeletonLoaders/ElementsAuditLogLoader'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'ElementsAuditLog'
})

const { t } = useI18n()

const deletedActionText = t('action.deleted')
const createdActionText = t('action.created')

const {
  OBJECTIVE_CREATED,
  OBJECTIVE_REMOVED,
  KR_REMOVED,
  KR_CREATED,
  TASK_REMOVED,
  TASK_CREATED,
  ELEMENT_RESTORED
} = ELEMENTS_AUDIT_LOG_HISTORY_TYPE_IDS

const ACTIONS = {
  [OBJECTIVE_CREATED]: createdActionText,
  [KR_CREATED]: createdActionText,
  [TASK_CREATED]: createdActionText,
  [OBJECTIVE_REMOVED]: deletedActionText,
  [KR_REMOVED]: deletedActionText,
  [TASK_REMOVED]: deletedActionText,
  [ELEMENT_RESTORED]: t('action.restored')
}

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.DATE,
    title: t('audit_log.date'),
    width: 180
  },
  {
    title: t('roles.user'),
    key: TABLE_COLUMNS_KEYS.USER_NAME,
    width: 150
  },
  {
    title: t('audit_log.workspace'),
    key: TABLE_COLUMNS_KEYS.WORKSPACE,
    width: 210
  },
  {
    title: t('audit_log.element_name'),
    key: TABLE_COLUMNS_KEYS.ICON,
    width: 130
  },
  {
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    title: t('workspaces.action'),
    key: TABLE_COLUMNS_KEYS.TEXT,
    width: 83
  }
]

const { listState, resetListState, auditLogData, fetchAuditLog } = useAuditLogs({
  filtersValues: {
    [REQUEST_ENTITY_KEYS.TYPE_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
  }
})

const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await getAuditLog()
  }
}

const getAuditLog = async () => {
  let typeIds = getSelectWithSelectAllApiParameter(
    listState.value.filtersValues[REQUEST_ENTITY_KEYS.TYPE_IDS]
  )

  if (!isNull(typeIds)) {
    typeIds = typeIds.flatMap(item => JSON.parse(item))
  }

  await fetchAuditLog({
    auditLogTypeId: AUDIT_LOG_TYPE_IDS.ELEMENTS,
    filtersValues: {
      typeIds
    }
  })
}

const onSelectFilterValue = async (newValue, filterKey) => {
  if (filterKey) {
    listState.value.filtersValues[filterKey] = getNewSelectWithSelectAllValue(
      newValue,
      listState.value.filtersValues[filterKey]
    )

    resetListState()
    auditLogData.value = []

    await getAuditLog()
  }
}

const clearSelection = filterKey => {
  if (filterKey) {
    onSelectFilterValue(DEFAULT_VALUE_FOR_FILTER, filterKey)
  }
}

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)
const store = useStore()

const isJiraConnected = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
)

const ALL_TYPES_OPTION = {
  id: SELECT_ALL_VALUE,
  name: t('audit_logs.all_types')
}

const ELEMENT_TYPES_VALUES_BY_ID = {
  [OBJECTIVE_TYPES.PERSONAL]: [OBJECTIVE_CREATED, OBJECTIVE_REMOVED],
  [OBJECTIVE_TYPES.KR]: [KR_CREATED, KR_REMOVED],
  [OBJECTIVE_TYPES.TASK]: [TASK_CREATED, TASK_REMOVED]
}

const okrTypes = computed(() => {
  return [
    {
      ...ALL_TYPES_OPTION
    },
    ...Object.values(CUSTOM_FIELDS_OKR_TYPE_IDS)
      .filter(item => {
        const isNeedTask =
          isJiraApp ||
          (isWebApp && isJiraConnected.value) ||
          (isCrossPlatformApp && isJiraConnected.value)
        return isNeedTask ? true : item.id !== OBJECTIVE_TYPES.TASK
      })
      .map(item => {
        return {
          ...item,
          id: JSON.stringify(ELEMENT_TYPES_VALUES_BY_ID[item.id] || null),
          name: t(item.name)
        }
      })
  ]
})

onMounted(() => {
  getAuditLog()
})
</script>

<style lang="scss" scoped>
.eal-Wrapper {
  color: $dark-1;
  --head-padding-bottom: 8px;
  --head-padding-top: 8px;
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  margin-top: 16px;

  &:deep(.tb-Row) {
    padding: 12px 0;
    align-items: flex-start;
  }
}

.eal-Toolbar {
  margin-bottom: 20px;
  display: flex;
}

.eal-TypesOption {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  width: 100%;
}

.eal-TypesOption_Icon {
  flex-shrink: 0;
}

.eal-TypesOption_Label {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
}

.eal-NameCell,
.eal-WorkspaceNameCell,
.eal-ElementNameCell,
.eal-NameCell {
  padding-right: 8px;
}

.eal-ElementNameCell,
.eal-WorkspaceNameCell {
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
