<template>
  <Modal
    :scrollable-content="false"
    :show="showModal"
    :size="APP_MODAL_SIZES.XL_NEXT"
    data-auto-testid="custom-dashboard-modal"
    data-testid="custom-dashboard-modal"
    manual-close
    style="--modal-body-padding: 0; --modal-header-padding: 40px 32px 12px"
    @close="closeModal"
  >
    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>
    <template #header>
      <div class="ecd-TitleWrapper">
        <SkeletonItem v-if="loading" height="28px" width="100%" />

        <template v-else>
          <AppInput
            v-model="formModel.name"
            :is-error="!isNameValid"
            :max-length="CUSTOM_DASHBOARD_NAME_MAX_LENGTH"
            :placeholder="$t('dashboard.custom_dashboard_name_placeholder')"
            class="ecd-Title"
            show-error-for-placeholder
            style-type="title"
            @blur="updateCustomDashboard"
            @focus="isNameValid = true"
          />
          <AppFieldError v-if="!isNameValid" :show="!isNameValid" class="ecd-TitleError">
            {{ $t('field.required') }}
          </AppFieldError>
        </template>
      </div>
    </template>
    <div class="ecd-BodyWrapper">
      <div class="ecd-Body">
        <SettingsPagesEmptyState
          v-if="isNoElements"
          hero-height="128"
          hero-icon="empty-state-hero"
          hero-width="142"
        >
          <template #title>
            {{ $t('dashboard.no_objectives_yet') }}
          </template>
          <template #subtitle> {{ $t('dashboard.add_first_objective') }}</template>

          <template #action>
            <AppButton
              height="24"
              icon="plus-next"
              type="primary-next"
              width="24"
              @click="openAddObjectives"
            >
              {{ $t('create.objective.btn.add') }}
            </AppButton>
          </template>
        </SettingsPagesEmptyState>
        <TableSortable
          v-if="!isNoElements"
          :columns="TABLE_COLUMNS"
          :data="formModel.elements"
          :loading="loading"
          offset-left="var(--page-left-padding-next)"
          offset-right="var(--page-right-padding-next)"
          sticky-header
          style="--head-padding-top: 16px; --sticky-z-index: 11"
          @drop="onTableRowDropped"
          @update:data="formModel.elements = $event"
        >
          <template #header-cell="{ column }">
            <template v-if="column.title">
              {{ $t(column.title) }}
            </template>
          </template>
          <template #cell="{ columnKey, item }">
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
              <div class="ecd-OkrName">
                <OkrIcon :objective="item" />
                <TitleItems
                  :id-as-link="false"
                  :objective="item"
                  id-size="small"
                  style="--id-font-weight: 600"
                  with-tooltip
                />
              </div>
            </template>
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.INTERVAL">
              <div
                v-tippy="{
                  content: item.intervalName,
                  placement: 'top-start'
                }"
                class="ecd-IntervalCell oboard-truncated-text"
              >
                {{ item.intervalName }}
              </div>
            </template>
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.OWNER">
              <div class="ecd-AvatarWrapper">
                <AppAvatar
                  v-tippy="{
                    placement: 'top',
                    content: item.owner?.displayName
                  }"
                  :avatar-url="item.owner?.avatarUrl"
                  no-margins
                />
              </div>
            </template>
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.GROUPS">
              <div class="ecd-GroupsList">
                <GroupsCell :item="item" />
              </div>
            </template>
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
              <div class="ecd-DeleteBtn">
                <AppButton
                  icon="delete-next"
                  size="sm"
                  type="tertiary-next"
                  @click="onRemoveClick(item)"
                />
              </div>
            </template>
          </template>
          <template #loading>
            <LevelsTableLoader />
          </template>
          <template #footer>
            <AppTableCreateButton full-width icon-name="plus-next" @click="openAddObjectives">
              {{ $t('dashboard.add_objectives') }}
            </AppTableCreateButton>
          </template>
        </TableSortable>
      </div>
      <div v-if="!isNoElements" class="ecd-ModalFooter">
        <AppButton class="ecd-ModalFooter_AddObj" type="primary-next" @click="openAddObjectives">
          {{ $t('dashboard.add_objectives') }}
        </AppButton>
      </div>
      <RemoveCustomDashboardElement
        :custom-dashboard-name="modelValue.name"
        :element-for-delete="removeItem"
        :show-confirm-delete-dialog="showRemoveItemModal"
        @close="showRemoveItemModal = false"
        @on-success="onSuccessRemoveElement"
      />
    </div>
  </Modal>
</template>

<script setup>
import { cloneDeep, isEmpty } from 'lodash'
import { computed, ref, watch } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { APP_MODAL_SIZES } from '@/utils/components-configurations/app-modal'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { CUSTOM_DASHBOARD_NAME_MAX_LENGTH } from '@/utils/custom-dashboard-helper'
import { isStringEmpty } from '@/utils/general'
import { CELL_TEXT_ALIGNS, TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import RemoveCustomDashboardElement from '@/components/dashboard/custom-dashboard/modals/RemoveCustomDashboardElement'
import AppFieldError from '@/components/form/AppFieldError'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItems from '@/components/objectives/items/TitleItems'
import GroupsCell from '@/components/objectives/table/cells/GroupsCell'
import SavingIndicator from '@/components/SavingIndicator'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import Modal from '@/components/ui/Modal/Modal'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import LevelsTableLoader from '@/components/ui/SkeletonLoaders/LevelsTableLoader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'
import TableSortable from '@/components/ui/Table/TableSortable/TableSortable'

const emit = defineEmits({
  'update:show-modal': null,
  'update-list': null,
  'open-add-objective-modal': null
})

const props = defineProps({
  showModal: {
    type: Boolean
  },

  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const closeModal = () => {
  emit('update:show-modal', false)
}

const savingIndicatorTop = ref(null)
const formModel = ref({})
const openAddObjectives = () => {
  emit('open-add-objective-modal')
}

watch(
  () => [props.showModal, props.modelValue],
  ([newValueShowModal, newValueModelValue]) => {
    if (newValueShowModal && newValueModelValue) {
      isNameValid.value = true
      formModel.value = cloneDeep(newValueModelValue)
    }
  },
  { deep: true }
)

const showRemoveItemModal = ref(false)
const removeItem = ref({})
const onRemoveClick = item => {
  removeItem.value = {
    ...item,
    dashboardId: props.modelValue.id
  }
  showRemoveItemModal.value = true
}

const TABLE_COLUMNS = [
  {
    title: 'users.table_header_name',
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    title: 'objectives.table_header_interval',
    key: TABLE_COLUMNS_KEYS.INTERVAL,
    width: 120
  },
  {
    title: 'objectives.table_header_assignee',
    key: TABLE_COLUMNS_KEYS.OWNER,
    width: 90,
    textAlign: CELL_TEXT_ALIGNS.CENTER
  },
  {
    title: 'objectives.table_header_groups',
    key: TABLE_COLUMNS_KEYS.GROUPS,
    width: 150
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 32
  }
]

const isNoElements = computed(() => isEmpty(props.modelValue.elements) && !loading.value)
const loading = ref(false)
const onTableRowDropped = async ({ item, orderValue }) => {
  const api = new CustomDashboardApiHandler()

  await api.setOrder({
    dashboardId: props.modelValue.id,
    elementId: item.id,
    orderValue
  })
  emit('update-list')
}

const onSuccessRemoveElement = () => {
  showRemoveItemModal.value = false
  emit('update-list')
}

const isNameValid = ref(true)
const updateCustomDashboard = async () => {
  if (isStringEmpty(formModel.value.name)) {
    isNameValid.value = false
    return
  }
  const api = new CustomDashboardApiHandler()

  await api.updateDashboard({
    ...props.modelValue,
    name: formModel.value.name
  })
  emit('update-list')
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.ecd-Body {
  padding: 0 40px;
  min-height: calc(100vh - 152px); // 80px for header, 72px for footer
}

.ecd-ModalFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  position: sticky;
  z-index: 10;
  bottom: 0;
  background: $white;
  padding: 20px 0;
  box-shadow: inset 0 1px 0 $grey-2-next;
}

.ecd-ModalFooter_AddObj {
  margin: 0 40px 0 0;
}

.ecd-OkrName {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
}

.ecd-GroupsList {
  padding-right: 12px;
}

.ecd-IntervalCell {
  padding-right: 8px;
}

.ecd-AvatarWrapper {
  display: flex;
  justify-content: center;
}

.ecd-DeleteBtn {
  @extend %sortable-table-hidden-items;
}

.ecd-TitleWrapper {
  width: 100%;
  z-index: 2;
}

.ecd-TitleError {
  position: absolute;
  pointer-events: none;
  padding: 5px 10px;
}

.ecd-Title {
  --input-font-size: #{$fs-24};
  --input-line-height: 28px;
  --input-wrapper-height: 28px;
  --input-wrapper-hover-background-color: transparent;
  --error-border-color: transparent;
}
</style>
