<template>
  <div class="dp-DebugPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.FULL_NEXT"
      :title="$t('debug.title')"
      no-margin
    />

    <div class="dp-VersionWrapper">
      {{ $t('support.plugin_version') }}
      {{ getVersion }}
      <AppButton
        class="sp-AboutList_Button"
        height="24"
        icon="copy-standard"
        remove-padding
        size="sm"
        type="ghost-next"
        width="24"
        @click="onCopyVersionClick(getVersion)"
      />
    </div>

    <div class="dp-MainContent">
      <div class="dp-Column">
        <div v-if="isServer" class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('debug.migrations') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_tasks_description') }}
            </span>
            <AppButton
              :disabled="loadingTasks"
              class="dp-Action"
              type="primary-next"
              @click="showMigrateTasksConfirmModal = true"
            >
              {{ $t('debug.migrate_tasks') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_attributes_description') }}
            </span>
            <AppButton
              :disabled="loadingAttributes"
              class="dp-Action"
              type="primary-next"
              @click="showMigrateAttributesConfirmModal = true"
            >
              {{ $t('debug.migrate_attributes') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_intervals_description') }}
            </span>
            <AppButton
              :disabled="loadingIntervals"
              class="dp-Action"
              type="primary-next"
              @click="showMigrateIntervalsConfirmModal = true"
            >
              {{ $t('debug.migrate_intervals') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_levels_description') }}
            </span>
            <AppButton
              :disabled="loadingLevels"
              class="dp-Action"
              type="primary-next"
              @click="showMigrateLevelsConfirmModal = true"
            >
              {{ $t('debug.migrate_levels') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_levels_description') }}
            </span>
            <AppButton
              :disabled="loadingLevelsInEmptyWorkspaces"
              class="dp-Action"
              type="primary-next"
              @click="showRestoreLevelsConfirmModal = true"
            >
              {{ $t('debug.restore_levels') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.clean_up_description') }}
            </span>
            <AppButton
              :disabled="loadingCorruptedLinks"
              class="dp-Action"
              type="primary-next"
              @click="showCleanUpLinksConfirmModal = true"
            >
              {{ $t('debug.clean_up') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_attributes_description') }}
            </span>
            <AppButton
              :disabled="loadingRestoreAttributes"
              class="dp-Action"
              type="primary-next"
              @click="showRestoreAttributesConfirmModal = true"
            >
              {{ $t('debug.restore_attributes') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.remove_duplicated_intervals') }}
            </span>
            <AppButton
              :disabled="removingDuplicateIntervals"
              class="dp-Action"
              type="primary-next"
              @click="showRemoveDuplicateIntervalsConfirmModal = true"
            >
              {{ $t('action.remove') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.remove_users_without_groups') }}
            </span>
            <AppButton
              :disabled="removingUsers"
              class="dp-Action"
              type="primary-next"
              @click="showRemoveUsersConfirmModal = true"
            >
              {{ $t('action.remove') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.remove_okr_board_global_permission') }}
            </span>
            <AppButton
              :disabled="removingPermissions"
              class="dp-Action"
              type="primary-next"
              @click="removeGlobalPermissionsFromGroups"
            >
              {{ $t('action.remove') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_interval_dates_for_elements') }}
            </span>
            <AppButton
              :disabled="restoringIntervalDates"
              class="dp-Action"
              type="primary-next"
              @click="restoreIntervalDates"
            >
              {{ $t('action.restore') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_users_and_groups_for_elements') }}
            </span>
            <AppButton
              :disabled="restoringUsersAndGroups"
              class="dp-Action"
              type="primary-next"
              @click="restoreUsersAndGroups"
            >
              {{ $t('action.restore') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_jira_details') }}
            </span>
            <AppButton
              :disabled="restoringJiraDetails"
              class="dp-Action"
              type="primary-next"
              @click="restoreJiraDetails"
            >
              {{ $t('action.restore') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.restore_task_chain') }}
            </span>
            <AppButton
              :disabled="restoringTaskChain"
              class="dp-Action"
              type="primary-next"
              @click="restoreTaskChain"
            >
              {{ $t('action.restore') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_labels_description') }}
            </span>
            <AppButton
              :disabled="loadingLabels"
              class="dp-Action"
              type="primary-next"
              @click="showMigrateLabelsConfirmModal = true"
            >
              {{ $t('debug.migrate_labels') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.migrate_email_description') }}
            </span>
            <AppButton
              :disabled="migratingEmail"
              class="dp-Action"
              type="primary-next"
              @click="migrateEmail"
            >
              {{ $t('debug.migrate_email') }}
            </AppButton>
          </div>

          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.update_users_description') }}
            </span>
            <AppButton
              :disabled="usersInfoUpdating"
              class="dp-Action"
              type="primary-next"
              @click="updateUsersInfo"
            >
              {{ $t('debug.update_users_info') }}
            </AppButton>
          </div>
        </div>

        <div class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('debug.grade_calculation') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.calculate_grades_description') }}
            </span>
            <AppButton
              :disabled="loadingGrades"
              class="dp-Action"
              type="primary-next"
              @click="updateGrades"
            >
              {{ $t('debug.calculate_grades') }}
            </AppButton>
          </div>
        </div>
        <div class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('common.users') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.delete_deactivated_users') }}
            </span>
            <AppButton
              :disabled="loadingDeleteDeactivatingUsers"
              class="dp-Action"
              type="primary-next"
              @click="deleteDeactivatedUsers"
            >
              {{ $t('action.proceed') }}
            </AppButton>
          </div>
        </div>

        <div v-if="isJiraApp" class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('debug.instance_migration') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.export_to_zip') }}
            </span>
            <AppButton
              :loading="importInstanceLoader"
              class="dp-Action"
              type="primary-next"
              @click="getInstanceMigration"
            >
              {{ $t('debug.download_logs') }}
            </AppButton>
          </div>
          <div v-if="isShowImportFromZipButton" class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.import_to_zip') }}
            </span>
            <AppButton
              :loading="loadingUploadInstanceFile"
              class="dp-Action"
              type="primary-next"
              @click="setInstanceMigration"
            >
              {{ $t('debug.upload_logs') }}
            </AppButton>
          </div>
          <div v-if="instanceErrorMsg" class="dp-OptionsWrapper">
            <div class="dp-MigrationErrorWrapper">
              <a
                class="dp-MigrationErrorWrapper_ErrorButton"
                href="#"
                @click="showImportInstanceErrorPopup = !showImportInstanceErrorPopup"
              >
                <AppIcon height="24" icon-name="error-next" width="24" />
                {{ $t('debug.import_error') }}
              </a>
              <div v-if="showImportInstanceErrorPopup" class="dp-MigrationErrorWrapper_ErrorPopup">
                <AppIcon height="24" icon-name="error-next" width="24" />
                <div class="dp-ErrorPopup_Content">
                  <span class="dp-MigrationErrorWrapper_Title">
                    {{ $t('debug.import_error') }}
                  </span>
                  <div class="dp-MigrationErrorWrapper_Message">
                    <span>{{ instanceErrorMsg }}</span>
                    <div
                      v-if="importErrorWorkspaces && importErrorWorkspaces.length"
                      class="dp-ImportInstanceError_Workspaces"
                    >
                      {{ $t('workspaces.workspaces') }}:
                      <br />
                      <b>{{ importErrorWorkspaces.join(', ') }}</b>
                    </div>
                  </div>
                </div>
                <AppButton
                  height="24"
                  icon="close-next"
                  remove-padding
                  size="sm"
                  type="subtle"
                  width="24"
                  @click="showImportInstanceErrorPopup = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dp-Column">
        <div class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('debug.debug_logs') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.download_info_description') }}
            </span>
            <AppButton
              :disabled="loadingDebugInfo"
              class="dp-Action"
              type="primary-next"
              @click="getDebugInformation"
            >
              {{ $t('debug.download_logs') }}
            </AppButton>
          </div>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.download_logs_description') }}
            </span>
            <AppButton
              :disabled="loadingDebugLogs"
              class="dp-Action"
              type="primary-next"
              @click="getDebugLogs"
            >
              {{ $t('debug.download_logs') }}
            </AppButton>
          </div>
        </div>

        <div v-if="isCloud" class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('debug.rights') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('debug.claim_admin-rights') }}
            </span>
            <AppButton
              :disabled="claimingAdminRights"
              class="dp-Action"
              type="primary-next"
              @click="claimAdminRights"
            >
              {{ $t('action.claim') }}
            </AppButton>
          </div>
        </div>

        <div v-if="!isBillingModuleDisabled" class="dp-OptionsWrapper">
          <AppTitle :level="3" class="dp-OptionsTitle" disable-margin>
            {{ $t('subscription.title') }}
          </AppTitle>
          <div class="dp-OptionWrapper">
            <span class="dp-Description">
              {{ $t('action.update_license_status') }}
            </span>
            <AppButton
              :loading="refreshSubscriptionStatusLoading"
              class="dp-Action"
              type="primary-next"
              @click="refreshSubscriptionStatus"
            >
              {{ $t('action.update') }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
    <MigrationConfirmModal
      :show="showMigrateTasksConfirmModal"
      @on-close="showMigrateTasksConfirmModal = false"
      @on-confirm="migrateTasks"
    />

    <MigrationConfirmModal
      :show="showMigrateAttributesConfirmModal"
      @on-close="showMigrateAttributesConfirmModal = false"
      @on-confirm="migrateAttributes"
    />

    <MigrationConfirmModal
      :show="showMigrateIntervalsConfirmModal"
      @on-close="showMigrateIntervalsConfirmModal = false"
      @on-confirm="migrateIntervals"
    />

    <MigrationConfirmModal
      :show="showMigrateLevelsConfirmModal"
      @on-close="showMigrateLevelsConfirmModal = false"
      @on-confirm="migrateLevels"
    />
    <MigrationConfirmModal
      :show="showMigrateLabelsConfirmModal"
      @on-close="showMigrateLabelsConfirmModal = false"
      @on-confirm="migrateLabels"
    />

    <MigrationConfirmModal
      :show="showRestoreLevelsConfirmModal"
      @on-close="showRestoreLevelsConfirmModal = false"
      @on-confirm="restoreLevels"
    />

    <MigrationConfirmModal
      :show="showCleanUpLinksConfirmModal"
      @on-close="showCleanUpLinksConfirmModal = false"
      @on-confirm="cleanUpCorruptedLinks"
    />

    <MigrationConfirmModal
      :show="showRestoreAttributesConfirmModal"
      @on-close="showRestoreAttributesConfirmModal = false"
      @on-confirm="restoreAttributes"
    />

    <MigrationConfirmModal
      :show="showRemoveDuplicateIntervalsConfirmModal"
      @on-close="showRemoveDuplicateIntervalsConfirmModal = false"
      @on-confirm="removeDuplicateIntervals"
    />

    <MigrationConfirmModal
      :show="showRemoveUsersConfirmModal"
      @on-close="showRemoveUsersConfirmModal = false"
      @on-confirm="removeUsersWithoutGroups"
    />
    <AppDialog
      :show="showImportInstanceConfirmModal"
      :title="$t('debug.warning')"
      @on-close="showImportInstanceConfirmModal = false"
      @on-confirm="openFileSelection"
    >
      <i18n-t keypath="debug.migration_description" scope="global" tag="span">
        <template #break>
          <br />
        </template>
      </i18n-t>
      <template #confirm-btn-text>
        {{ $t('debug.i_understand') }}
      </template>
    </AppDialog>
    <MigrationConfirmModal
      :show="showWarningModal"
      title="debug.warning"
      @on-close="showWarningModal = false"
      @on-confirm="showWarningModal = false"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="warningText" />
      <template #footer>
        <div class="dp-InstanceModalFooter">
          <AppButton @click="showWarningModal = false">
            {{ $t('debug.i_understand') }}
          </AppButton>
        </div>
      </template>
    </MigrationConfirmModal>
    <input ref="fileInput" accept=".zip" hidden type="file" @change="selectIFileInstance" />
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import BillingApiHandler from '@/api/billing'
import DebugApiHandler from '@/api/debug'
import { SHOW_IMPORT_INSTANCE_BUTTON_FOR_SERVER } from '@/util'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { useDisabledModules } from '@/utils/cross-platform/disabled-modules'
import { handleError } from '@/utils/error-handling'
import { copyToClipboard, getPluginVersion } from '@/utils/general'
import { isJiraAppInjectionKey } from '@/utils/injection-keys'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'

import AppDialog from '@/components/AppDialog'
import MigrationConfirmModal from '@/components/support/MigrationConfirmModal'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

const api = new DebugApiHandler()
const RESPONSE_STATUSES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  DONE: 'DONE'
}
export default defineComponent({
  name: 'DebugPage',

  components: {
    PageContentHeader,
    AppTitle,
    AppButton,
    MigrationConfirmModal,
    AppIcon,
    AppDialog
  },

  inject: {
    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  setup() {
    const { isBillingModuleDisabled } = useDisabledModules()

    return {
      isBillingModuleDisabled
    }
  },

  data() {
    return {
      loadingTasks: false,
      loadingAttributes: false,
      loadingIntervals: false,
      loadingGrades: false,
      loadingDebugInfo: false,
      loadingDebugLogs: false,
      loadingLevels: false,
      loadingLabels: false,
      loadingCorruptedLinks: false,
      loadingLevelsInEmptyWorkspaces: false,
      loadingRestoreAttributes: false,
      removingDuplicateIntervals: false,
      removingUsers: false,
      removingPermissions: false,
      showMigrateTasksConfirmModal: false,
      showMigrateAttributesConfirmModal: false,
      showMigrateLevelsConfirmModal: false,
      showMigrateIntervalsConfirmModal: false,
      showRestoreLevelsConfirmModal: false,
      showCleanUpLinksConfirmModal: false,
      showRestoreAttributesConfirmModal: false,
      showRemoveDuplicateIntervalsConfirmModal: false,
      showRemoveUsersConfirmModal: false,
      showMigrateLabelsConfirmModal: false,
      restoringIntervalDates: false,
      restoringUsersAndGroups: false,
      restoringJiraDetails: false,
      restoringTaskChain: false,
      claimingAdminRights: false,
      refreshSubscriptionStatusLoading: false,
      // numberOfGrades: 0,
      showImportInstanceConfirmModal: false,
      importInstanceLoader: false,
      instanceErrorMsg: '',
      loadingUploadInstanceFile: false,
      importErrorWorkspaces: [],
      showImportInstanceErrorPopup: false,
      showWarningModal: false,
      migratingEmail: false,
      usersInfoUpdating: false,
      warningText: '',
      loadingDeleteDeactivatingUsers: false
    }
  },

  computed: {
    ...mapGetters('pluginOptions', {
      isServer: 'isPluginServer',
      isCloud: 'isPluginCloud'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin,
      backendVersion: state => state.backendVersion
    }),

    HEADER_SIZES: () => HEADER_SIZES,

    getVersion() {
      return getPluginVersion(this.backendVersion)
    },

    isShowImportFromZipButton() {
      if (this.isServer) {
        return SHOW_IMPORT_INSTANCE_BUTTON_FOR_SERVER
      }

      return true
    }
  },

  created() {
    if (!this.isPluginAdmin) {
      this.$router.push('/')
    }
    this.checkImportStatus()
  },

  methods: {
    async deleteDeactivatedUsers() {
      this.loadingDeleteDeactivatingUsers = true
      try {
        await api.deleteDeactivatedUsers()
        showNotify()
      } catch (error) {
        handleError({ error })
      } finally {
        this.loadingDeleteDeactivatingUsers = false
      }
    },

    async refreshSubscriptionStatus() {
      this.refreshSubscriptionStatusLoading = true
      try {
        const BillingApi = new BillingApiHandler()
        await BillingApi.refreshSubscriptionStatus()
      } catch (error) {
        handleError({ error })
      } finally {
        this.refreshSubscriptionStatusLoading = false
      }
    },

    async claimAdminRights() {
      this.claimingAdminRights = true
      try {
        await api.claimAdminRights()
      } catch (error) {
        handleError({ error })
      }
      this.claimingAdminRights = false
    },

    async selectIFileInstance() {
      const file = this.$refs.fileInput.files[0]
      this.showImportInstanceConfirmModal = false
      if (!file) return
      if (Math.round(file.size / 1024) > 20480) {
        this.showWarningModal = true
        this.warningText = this.$t('debug.import_file_error')
        return
      }
      this.loadingUploadInstanceFile = true
      try {
        const formData = new FormData()
        formData.append('file', file)
        await api.importInstance({ formData })
        await this.checkImportStatus()
      } catch (error) {
        this.loadingUploadInstanceFile = false
        handleError({ error, isHtml: true })
      }
    },

    openFileSelection() {
      this.$refs.fileInput.value = '' // clear fileList
      this.$refs.fileInput.click()
    },

    async getInstanceMigration() {
      this.importInstanceLoader = true
      try {
        const { data } = await api.exportInstance()
        FileSaver.saveAs(data, 'instance.zip')
      } catch (error) {
        handleError({ error })
      } finally {
        this.importInstanceLoader = false
      }
    },

    checkResponseStatus(currentStatus, expectedStatus) {
      return currentStatus.toUpperCase() === expectedStatus
    },

    async checkImportStatus() {
      this.loadingUploadInstanceFile = true
      this.instanceErrorMsg = ''
      this.importErrorWorkspaces = []
      try {
        const { data } = await api.importStatus()
        if (this.checkResponseStatus(data.status, RESPONSE_STATUSES.ERROR)) {
          this.importErrorWorkspaces = data.data
          this.loadingUploadInstanceFile = false
          this.instanceErrorMsg = data.message
        } else if (this.checkResponseStatus(data.status, RESPONSE_STATUSES.SUCCESS)) {
          this.loadingUploadInstanceFile = false
          showNotify({ title: data.message, type: NOTIFICATION_TYPES.SUCCESS })
        } else if (this.checkResponseStatus(data.status, RESPONSE_STATUSES.DONE)) {
          this.loadingUploadInstanceFile = false
          showNotify({ title: data.message, type: NOTIFICATION_TYPES.SUCCESS })
        } else {
          showNotify({ title: data.message, type: NOTIFICATION_TYPES.WARNING })
          this.loadingUploadInstanceFile = true
          setTimeout(() => {
            this.checkImportStatus()
          }, 30000) // Договорились использовать такой таймаут
        }
      } catch (error) {
        this.loadingUploadInstanceFile = false
        handleError({ error })
      }
    },

    async setInstanceMigration() {
      this.showImportInstanceConfirmModal = true
    },

    async migrateTasks() {
      this.showMigrateTasksConfirmModal = false
      this.loadingTasks = true
      try {
        await api.migrateTasks()
      } catch (error) {
        handleError({ error })
      }
      this.loadingTasks = false
    },

    async migrateAttributes() {
      this.showMigrateAttributesConfirmModal = false
      this.loadingAttributes = true
      try {
        await api.migrateAttributes()
      } catch (error) {
        handleError({ error })
      }
      this.loadingAttributes = false
    },

    async migrateLevels() {
      this.showMigrateLevelsConfirmModal = false
      this.loadingLevels = true
      try {
        await api.migrateLevels()
      } catch (error) {
        handleError({ error })
      }
      this.loadingLevels = false
    },

    async migrateLabels() {
      this.showMigrateLabelsConfirmModal = false
      this.loadingLabels = true
      try {
        await api.migrateLabels()
      } catch (error) {
        handleError({ error })
      }
      this.loadingLabels = false
    },

    async removeGlobalPermissionsFromGroups() {
      this.removingPermissions = true
      try {
        await api.removeGlobalPermissionsFromGroups()
      } catch (error) {
        handleError({ error })
      }
      this.removingPermissions = false
    },

    async restoreIntervalDates() {
      this.restoringIntervalDates = true
      try {
        await api.restoreIntervalDatesForElements()
      } catch (error) {
        handleError({ error })
      }
      this.restoringIntervalDates = false
    },

    async restoreUsersAndGroups() {
      this.restoringUsersAndGroups = true
      try {
        await api.restoreUsersAndGroupsForElements()
      } catch (error) {
        handleError({ error })
      }
      this.restoringUsersAndGroups = false
    },

    async restoreJiraDetails() {
      this.restoringJiraDetails = true
      try {
        await api.restoreJiraDetails()
      } catch (error) {
        handleError({ error })
      }
      this.restoringJiraDetails = false
    },

    async restoreTaskChain() {
      this.restoringTaskChain = true
      try {
        await api.restoreTaskChain()
      } catch (error) {
        handleError({ error })
      }
      this.restoringTaskChain = false
    },

    async restoreLevels() {
      this.showRestoreLevelsConfirmModal = false
      this.loadingLevelsInEmptyWorkspaces = true
      try {
        await api.restoreLevels()
      } catch (error) {
        handleError({ error })
      }
      this.loadingLevelsInEmptyWorkspaces = false
    },

    async migrateIntervals() {
      this.showMigrateIntervalsConfirmModal = false
      this.loadingIntervals = true
      try {
        await api.migrateIntervals()
      } catch (error) {
        handleError({ error })
      }
      this.loadingIntervals = false
    },

    async cleanUpCorruptedLinks() {
      this.showCleanUpLinksConfirmModal = false
      this.loadingCorruptedLinks = true
      try {
        await api.cleanUpCorruptedLinks()
      } catch (error) {
        handleError({ error })
      }
      this.loadingCorruptedLinks = false
    },

    async updateGrades() {
      this.loadingGrades = true
      try {
        await api.updateGrades()
        showNotify({
          title: this.$t('debug.grades_updated')
        })
      } catch (error) {
        handleError({ error })
      }
      this.loadingGrades = false
    },

    async restoreAttributes() {
      this.showRestoreAttributesConfirmModal = false
      this.loadingRestoreAttributes = true
      try {
        await api.restoreElementDetails()
      } catch (error) {
        handleError({ error })
      }
      this.loadingRestoreAttributes = false
    },

    async removeDuplicateIntervals() {
      this.showRemoveDuplicateIntervalsConfirmModal = false
      this.removingDuplicateIntervals = true
      try {
        await api.fixIntervalDuplication()
      } catch (error) {
        handleError({ error })
      }
      this.removingDuplicateIntervals = false
    },

    async removeUsersWithoutGroups() {
      this.showRemoveUsersConfirmModal = false
      this.removingUsers = true
      try {
        await api.removeUsersWithoutGroups()
      } catch (error) {
        handleError({ error })
      }
      this.removingUsers = false
    },

    async getDebugInformation() {
      this.loadingDebugInfo = true
      try {
        const response = await api.getDebugInformation()
        FileSaver.saveAs(response.data, response.filename)
      } catch (error) {
        handleError({ error })
      }
      this.loadingDebugInfo = false
    },

    async getDebugLogs() {
      this.loadingDebugLogs = true
      try {
        const response = await api.getLogs()
        FileSaver.saveAs(response.data, response.filename)
      } catch (error) {
        handleError({ error })
      }
      this.loadingDebugLogs = false
    },

    async migrateEmail() {
      this.migratingEmail = true
      try {
        await api.migrateEmail()
      } catch (error) {
        handleError({ error })
      }
      this.migratingEmail = false
    },

    async updateUsersInfo() {
      this.usersInfoUpdating = true
      try {
        await api.updateUsersInfo()
      } catch (error) {
        handleError({ error })
      }
      this.usersInfoUpdating = false
    },

    onCopyVersionClick(text) {
      copyToClipboard(text)
      showNotify({ title: this.$t('notifications.plugin_version_copied') })
    }
  }
})
</script>

<style lang="scss" scoped>
.dp-InstanceModalFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.dp-DebugPage {
  width: 880px;
  font-family: $system-ui;
}
.dp-Column {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.dp-VersionWrapper {
  color: $dark-2;
  font-weight: fw('bold');
  font-size: $fs-14;
  line-height: 20px;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.dp-MainContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: flex-start;
  padding: 35px $page-right-padding 64px $page-left-padding;
}

.dp-OptionsWrapper {
  display: grid;
  gap: 8px;
}

.dp-OptionWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.dp-OptionsTitle {
  font-family: $system-ui;
}

.dp-Description {
  flex: 1 1 auto;
}

.dp-Action {
  width: 136px;
  flex-shrink: 0;
}
.dp-MigrationErrorWrapper {
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  &_ErrorButton {
    color: $grade-low-color-next;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: fw('regular');
    font-size: $fs-12;
    line-height: 16px;
    font-family: $system-ui;
  }
}
.dp-ImportInstanceError_Workspaces {
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-break: break-word;
}
.dp-MigrationErrorWrapper_ErrorPopup {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  display: flex;
  gap: 16px;
  width: 520px;
  padding: 20px;
  background: $white;
  box-shadow: $common-box-shadow;
  border-radius: $border-radius-sm-next;
}
.dp-ErrorPopup_Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 400px;
  font-family: $system-ui;
}
.dp-MigrationErrorWrapper_Title {
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
}
.dp-MigrationErrorWrapper_Message {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
</style>
