<template>
  <div v-if="isTypeIdsExist" class="cffc-Cell">
    <AppIcon v-for="icon in iconsList" :key="icon" :icon-name="icon" height="24" width="24" />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { CUSTOM_FIELDS_OKR_TYPE_IDS } from '@/utils/custom-fields/helpers'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'CustomFieldForCell'
})

const props = defineProps({
  typeIds: {
    type: Array,
    required: true
  }
})

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const store = useStore()

const isJiraConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
})

const typeIdsByAppPlatform = computed(() => {
  return props.typeIds.filter(id => {
    const isNeedTaskIcon =
      isJiraApp ||
      (isWebApp && isJiraConnected.value) ||
      (isCrossPlatformApp && isJiraConnected.value)
    return isNeedTaskIcon ? true : id !== OBJECTIVE_TYPES.TASK
  })
})

const isTypeIdsExist = computed(() => !isEmpty(typeIdsByAppPlatform.value))

const iconsList = computed(() => {
  if (!isTypeIdsExist.value) return []

  const ALL = Object.values(CUSTOM_FIELDS_OKR_TYPE_IDS).map(({ id }) => id)

  return [...typeIdsByAppPlatform.value]
    .toSorted()
    .filter(id => {
      return ALL.includes(id)
    })
    .map(id => {
      return Object.values(CUSTOM_FIELDS_OKR_TYPE_IDS).find(item => item.id === id).icon
    })
})
</script>

<style lang="scss" scoped>
.cffc-Cell {
  display: flex;
  gap: 4px;
}
</style>
