<template>
  <router-link v-slot="{ isActive, isExactActive }" :to="to" custom>
    <slot :href="href" :is-active="isActive" :is-exact-active="isExactActive" :on-click="onClick" />
  </router-link>
</template>

<script>
import { defineComponent } from 'vue'

import { isCrossPlatformAppInjectionKey, isWebAppInjectionKey } from '@/utils/injection-keys'
import { getAtlassianBaseUrl, JIRA_CLOUD_API, JIRA_SERVER_API } from '@jira/util'

export default defineComponent({
  name: 'AppLink',

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isCrossPlatformApp: {
      from: isCrossPlatformAppInjectionKey
    }
  },

  inheritAttrs: false,

  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },

  emits: { click: null },

  data() {
    return {
      href: ''
    }
  },

  computed: {
    resolvedTo() {
      return this.$router.resolve(this.to)
    }
  },

  watch: {
    resolvedTo: {
      handler(resolvedTo) {
        if (JIRA_CLOUD_API) {
          JIRA_CLOUD_API.getLocation(location => {
            // location is full link including hash(vue route)
            // vue route path is in hash independent of router mode, it's
            // characteristic of jira cloud
            const url = new URL(location)
            const [hashDivider] = url.hash.split('/')
            const resolvedDivider = hashDivider === '#!' ? '#!' : '#'
            this.href = url.href.replace(url.hash, `${resolvedDivider}${resolvedTo.fullPath}`)
          })
        } else if (JIRA_SERVER_API) {
          const url = new URL(getAtlassianBaseUrl())
          this.href = `${url.pathname}${resolvedTo.fullPath}`
        } else if (this.isWebApp) {
          this.href = resolvedTo.href
        } else if (this.isCrossPlatformApp) {
          this.href = resolvedTo.href
        } else {
          this.href = resolvedTo.fullPath
        }
      },

      immediate: true
    }
  },

  methods: {
    onClick() {
      const resolvedTo = this.$router.resolve(this.to)
      if (this.$route.fullPath === resolvedTo.fullPath) {
        return
      }
      this.$emit('click')
      this.$router.push(this.to)
    }
  }
})
</script>

<style></style>
