import { difference, isEmpty } from 'lodash'

export const ROUTE_NAMES = {
  MISSING_PAGE: 'MissingPage',

  ORGANIZATION: 'Organization',
  ORGANIZATION_USERS: 'Users',
  ORGANIZATION_ADMINISTRATORS: 'Administrators',
  ORGANIZATION_GLOBAL_GROUPS: 'GlobalGroups',
  ORGANIZATION_AUDIT_LOG: 'AuditLog',
  ORGANIZATION_USER_DETAILS: 'UserDetails',

  SETTINGS: 'Settings',
  SETTINGS_WORKSPACES: 'settings-workspaces',
  SETTINGS_INTEGRATIONS: 'Integrations',
  SETTINGS_INTEGRATIONS_CONNECTIONS_LIST: 'IntegrationsConnectionsList',
  SETTINGS_INTEGRATIONS_API_TOKENS: 'IntegrationsApiTokens',
  SETTINGS_INTEGRATIONS_OBOARD_CONNECTION: 'IntegrationsOboardConnection',
  SETTINGS_INTEGRATIONS_MESSENGER_CONNECTION: 'IntegrationsMessengerConnection',
  AUDIT_LOGS: 'AuditLogs',
  AUDIT_LOGS_GROUPS: 'AuditLogsGroups',
  AUDIT_LOGS_ELEMENTS: 'AuditLogsElements',

  SETTINGS_COMPANY: 'Company',
  SETTINGS_LABELS: 'Labels',
  SETTINGS_USER_PREFERENCES: 'UserPreferences',
  SETTINGS_LEVELS: 'Levels',
  SETTINGS_SYNCHRONIZATION: 'Synchronization',
  SETTINGS_CUSTOM_FIELDS: 'CustomFields',
  SETTINGS_CHECK_INS: 'CheckInsSettings',
  SETTINGS_STATUSES: 'Statuses',
  SETTINGS_NOTIFICATIONS: 'UserNotificationsSettings',
  SETTINGS_SECURITY: 'UserSecuritySettings',
  SETTINGS_BILLING: 'Billing',
  SETTINGS_BILLING_SETTINGS: 'BillingSettings',
  SETTINGS_BILLING_INFORMATION: 'BillingInformation',
  SETTINGS_BILLING_PROMO: 'BillingPromo',
  // SETTINGS_BRANDING: 'Branding',
  SHARE: 'Share',

  CHECK_INS: 'CheckIns',

  SUPPORT: 'Support',

  DEBUG: 'Debug',

  OBJECTIVES: 'Objectives',
  ALIGNMENT: 'Alignment',
  OKR_EXPLORER: 'OKR Explorer',
  OKR_ELEMENTS_MIND_MAP: 'AlignmentTree',
  OKR_ELEMENTS_HIERARCHY_TABLE: 'AlignmentTable',
  OKR_ELEMENTS_ROADMAP: 'AlignmentRoadmap',
  OKR_ELEMENTS_EXPLORER: 'OkrExplorerTable',

  HOME_PAGE: 'HomePage',

  WORKSPACE_ID: 'WorkspaceId',
  WORKSPACES: 'Workspaces',

  DASHBOARD: 'Dashboard',
  DASHBOARD_GENERAL: 'DashboardGeneral',
  DASHBOARD_PERFORMANCE_REPORT: 'PerformanceReport',
  DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS: 'DashboardGroups',
  DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE: 'DashboardPeople',
  CUSTOM_DASHBOARD: 'CustomDashboard',
  CUSTOM_DASHBOARD_LIST: 'CustomDashboardList',
  CUSTOM_DASHBOARD_ITEM: 'CustomDashboardItem',
  CUSTOM_DASHBOARD_ACCESS_DENIED: 'DashboardAccessDenied',
  BIN: 'Bin'
}

export const ROUTE_PATHS = {
  MISSING_PAGE: '/:pathMatch(.*)*',

  ORGANIZATION: 'organization',
  ORGANIZATION_USERS: 'users',
  ORGANIZATION_ADMINISTRATORS: 'administrators',
  ORGANIZATION_GLOBAL_GROUPS: 'global-groups',
  ORGANIZATION_AUDIT_LOG: 'audit-log',
  ORGANIZATION_USER_DETAILS: ':accountId',

  SETTINGS: 'settings',
  SETTINGS_WORKSPACES: 'workspaces',
  SETTINGS_INTEGRATIONS: 'integrations',
  SETTINGS_INTEGRATIONS_CONNECTIONS_LIST: 'connections-list',
  SETTINGS_INTEGRATIONS_API_TOKENS: 'api-tokens',
  SETTINGS_INTEGRATIONS_OBOARD_CONNECTION: 'oboard-connection',
  SETTINGS_INTEGRATIONS_MESSENGER_CONNECTION: 'messenger-connection',
  AUDIT_LOGS: 'audit-logs',
  AUDIT_LOGS_GROUPS: 'groups',
  AUDIT_LOGS_ELEMENTS: 'elements',
  SETTINGS_COMPANY: 'company',
  SETTINGS_LABELS: 'labels',
  SETTINGS_USER_PREFERENCES: 'user-preferences',
  SETTINGS_LEVELS: 'levels',
  SETTINGS_SYNCHRONIZATION: 'synchronization',
  SETTINGS_CUSTOM_FIELDS: 'custom-fields',
  SETTINGS_CHECK_INS: 'check-ins',
  SETTINGS_STATUSES: 'statuses',
  SETTINGS_NOTIFICATIONS: 'notifications',
  SETTINGS_SECURITY: 'security',
  SETTINGS_BILLING: 'billing',
  SETTINGS_BILLING_SETTINGS: 'billing-settings',
  SETTINGS_BILLING_INFORMATION: 'billing-information',
  SETTINGS_BILLING_PROMO: 'billing-settings/promo',
  SHARE: 'share',
  CHECK_INS: 'check-ins',
  // SETTINGS_BRANDING: 'branding',

  SUPPORT: 'support',

  DEBUG: 'debug',

  OBJECTIVES: 'objectives',
  ALIGNMENT: 'alignment',
  OKR_EXPLORER: 'okr-explorer',
  OKR_ELEMENTS_MIND_MAP: 'tree',
  OKR_ELEMENTS_HIERARCHY_TABLE: 'table',
  OKR_ELEMENTS_ROADMAP: 'roadmap',
  OKR_ELEMENTS_EXPLORER: 'table',

  HOME_PAGE: '',

  WORKSPACE_ID: ':workspaceId',
  WORKSPACES: '/workspaces/',

  DASHBOARD: 'dashboard',
  DASHBOARD_GENERAL: 'general',
  DASHBOARD_PERFORMANCE_REPORT: 'performance-report',
  DASHBOARD_PERFORMANCE_REPORT_BY_GROUPS: 'groups',
  DASHBOARD_PERFORMANCE_REPORT_BY_PEOPLE: 'people',
  CUSTOM_DASHBOARD: 'custom-dashboard',
  CUSTOM_DASHBOARD_LIST: 'list',
  CUSTOM_DASHBOARD_ITEM: 'item',
  CUSTOM_DASHBOARD_ACCESS_DENIED: 'access-denied',
  BIN: 'bin'
}

export const OUTDATED_ROUTE_PATHS = {
  MEMBERS: 'members',
  MEMBERS_USERS: 'users',
  MEMBERS_GROUPS: 'groups',
  ACCESS_MANAGEMENT: 'access-management',
  PLUGIN_USERS: 'plugin-users',
  INTERVALS: 'intervals',
  OKR_BOARD_COMPANY: '/okrboard/company',
  COMPANY: '/company',
  TEAM: '/team',
  PERSONAL: '/personal'
}

const getRouteNamesWithPaths = () => {
  const diff = difference(Object.keys(ROUTE_NAMES), Object.keys(ROUTE_PATHS))
  if (isEmpty(diff)) {
    return Object.keys(ROUTE_NAMES).reduce((acc, key) => {
      return {
        ...acc,
        [key]: {
          name: ROUTE_NAMES[key],
          path: ROUTE_PATHS[key]
        }
      }
    }, {})
  }

  throw new Error(`ROUTE_NAMES and ROUTE_PATHS are not equal. Missing keys: ${diff.join(', ')}`)
}

export const ROUTE_NAMES_WITH_PATHS = getRouteNamesWithPaths()
