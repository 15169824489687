<script setup>
import { convertShortcutToText } from '@/utils/tiptap-utils'

defineOptions({
  name: 'ShortcutItem'
})

defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
</script>

<template>
  <span class="si-ShortcutItem">
    {{ convertShortcutToText(item.shortcut) }}
  </span>
</template>

<style lang="scss" scoped>
.si-ShortcutItem {
  font-size: $fs-12;
  font-weight: fw('regular');
  flex: 1 0 auto;
  text-align: right;
  color: $dark-1;
  margin-left: 32px;
}
</style>
