import { createApp, h, resolveComponent } from 'vue'

import AppAuth from '@jira/layout/AppAuth'
import { APP_PLATFORMS } from '@root/app-platforms'
import { CREATE_AUTH_APP_PLUGIN_OPTIONS } from '@root/template-options-keys'

export const createAuthApp = (
  component,
  rootElement,
  additionalProps,
  appPlatform = APP_PLATFORMS.JIRA
) => {
  const { accessToken, refreshToken, pluginType, ...rest } = getPluginOptions(
    CREATE_AUTH_APP_PLUGIN_OPTIONS,
    rootElement
  )

  return createApp({
    name: 'AppAuthWrapper',
    components: {
      AppAuth,
      'main-component': component
    },

    // use AppAuth as layout(root component) for all entry-points
    render: () =>
      h(
        resolveComponent('AppAuth'),
        {
          accessToken,
          refreshToken,
          pluginType,
          pluginOptions: {
            pluginType,
            ...rest
          },
          appPlatform
        },
        {
          default: props => h(resolveComponent('main-component'), { ...props, ...additionalProps })
        }
      )
  })
}

export const getPluginOptions = (options, rootElement) => {
  const pluginOptions = { ...rootElement.dataset }
  return options.reduce((acc, val) => {
    acc[val] = pluginOptions[val]
    return acc
  }, {})
}
