<template>
  <OkrFilterRelativeValues
    :model-value="filterValue"
    :options="filterOptions"
    class="nf-FilterButton"
    @update:model-value="onUpdateModelValue"
  >
    <template #option-label="{ option }">
      <template v-if="option">
        {{ option[RELATIVE_VALUES_FILTER_KEYS.LABEL] || '' }}
      </template>
    </template>
    <template #button="{ active }">
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :opened="active"
        :selected-options="selectedOptions"
        class="nf-FilterButton"
        separated-label
        truncated-label
      />
    </template>
  </OkrFilterRelativeValues>
</template>

<script setup>
defineOptions({
  name: 'NumberFilter'
})

import { isEqual } from 'lodash'
import { computed, nextTick, onMounted } from 'vue'

import {
  NUMBER_FILTER_DEFAULT_VALUE,
  getNumberFilterOptions,
  restoreNumberFilter
} from '@/utils/okr-elements/number-filter'
import { RELATIVE_VALUES_FILTER_KEYS } from '@/utils/okr-elements/relative-values-filter'

import OkrFilterRelativeValues from '@/components/objectives/toolbar/OkrFilterRelativeValues'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

const props = defineProps({
  filterValue: {
    type: Array,
    required: true
  },

  selectedOptions: {
    type: String,
    required: true
  },

  emptyStateLabel: {
    type: String,
    required: true
  },

  fieldName: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'initial-data-loaded': null,
  'update-filter-value': null
})

const filterOptions = computed(() => Object.values(getNumberFilterOptions()))

const validateFilterValue = async () => {
  const validatedValue = restoreNumberFilter({
    restoredValue: props.filterValue,
    defaultValue: NUMBER_FILTER_DEFAULT_VALUE
  })

  if (
    !isEqual(validatedValue, props.filterValue) ||
    !isEqual(validatedValue, NUMBER_FILTER_DEFAULT_VALUE)
  ) {
    emit('update-filter-value', {
      value: validatedValue
    })
  }

  await nextTick()
  emit('initial-data-loaded')
}

onMounted(() => {
  validateFilterValue()
})

const onUpdateModelValue = value => {
  if (!isEqual(props.filterValue, value)) {
    emit('update-filter-value', {
      value
    })
  }
}
</script>

<style lang="scss" scoped>
.nf-FilterButton {
  width: 100%;
}
</style>
