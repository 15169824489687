import { isUndefined } from 'lodash'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { MAX_CUSTOM_FIELDS_COUNT } from '@/utils/custom-fields/helpers'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { isOkrElementClosed, objectiveIsJiraTask, OKR_STATUSES } from '@/utils/objectives'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

export const SUBSCRIPTION_PLANS = {
  ESSENTIAL: '1',
  PROFESSIONAL: '2',
  ENTERPRISE: '3'
}

export const MAX_OKR_LEVELS_COUNT = Infinity
export const MAX_PUBLIC_CUSTOM_DASHBOARDS_COUNT = Infinity

export const RESTRICTIONS_KEYS = {
  OKR_LEVELS: 'okrLevels',
  CUSTOM_FIELDS: 'customField',
  OKR_WEIGHTS: 'weight',
  CUSTOM_DASHBOARD_PUBLIC: 'customDashboardPublic'
}

export const RESTRICTIONS_IDS = {
  ID_1: 1,
  ID_2: 2,
  ID_3: 3
}

const { ID_1, ID_2, ID_3 } = RESTRICTIONS_IDS

export const LIMITATIONS = {
  OKR_LEVELS: {
    [ID_1]: 3,
    [ID_2]: 7,
    [ID_3]: MAX_OKR_LEVELS_COUNT
  },
  CUSTOM_FIELDS: {
    [ID_1]: 3,
    [ID_2]: 10,
    [ID_3]: MAX_CUSTOM_FIELDS_COUNT
  },
  CUSTOM_DASHBOARD_PUBLIC: {
    [ID_1]: 3,
    [ID_2]: 20,
    [ID_3]: MAX_PUBLIC_CUSTOM_DASHBOARDS_COUNT
  }
}

const TOOLTIPS_BY_RESTRICTION_ID = {
  CUSTOM_FIELDS: {
    [ID_1]: 'limitations.create_button.tooltip',
    [ID_2]: 'limitations.create_button.tooltip',
    [ID_3]: 'custom_fields.disable_create_button_tooltip'
  }
}

export const usePlansLimitations = () => {
  const isWebApp = inject(isWebAppInjectionKey)
  const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)
  const isJiraApp = inject(isJiraAppInjectionKey)
  const store = useStore()
  const { t } = useI18n()

  const UNAVAILABLE_BY_PLAN_TOOLTIP = t('weights.objective.cant_change_weight_by_plan')

  const isJiraConnected = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
  )

  const restrictions = computed(() => store.state.system.restrictions || {})

  const isLimitExceeded = ({ count, limit }) => count >= limit

  const getRestrictionId = ({ key }) => restrictions.value[key]

  const checkIsNeedLimitation = ({ restrictionId }) => !isUndefined(restrictionId)

  const customFieldsLimitations = computed(() => {
    const restrictionId = getRestrictionId({ key: RESTRICTIONS_KEYS.CUSTOM_FIELDS })
    const isNeedLimitation = checkIsNeedLimitation({ restrictionId })

    const limit = isNeedLimitation
      ? LIMITATIONS.CUSTOM_FIELDS[restrictionId]
      : MAX_CUSTOM_FIELDS_COUNT

    const getDisabledButtonTooltip = () => {
      if (isNeedLimitation) {
        return t(TOOLTIPS_BY_RESTRICTION_ID.CUSTOM_FIELDS[restrictionId], {
          limit,
          entity: t('custom_fields.title')
        })
      } else {
        return t('custom_fields.disable_create_button_tooltip', { limit })
      }
    }

    return {
      limit,
      getDisabledButtonTooltip,
      isLimitExceeded: ({ count }) => isLimitExceeded({ count, limit })
    }
  })

  const okrLevelsLimitations = computed(() => {
    const restrictionId = getRestrictionId({ key: RESTRICTIONS_KEYS.OKR_LEVELS })
    const isNeedLimitation = checkIsNeedLimitation({ restrictionId })

    const limit = isNeedLimitation ? LIMITATIONS.OKR_LEVELS[restrictionId] : MAX_OKR_LEVELS_COUNT

    const getDisabledButtonTooltip = () => {
      if (isNeedLimitation && Number.isFinite(limit)) {
        return t('limitations.create_button.tooltip', { limit, entity: t('levels.header_title') })
      } else {
        return null
      }
    }

    return {
      limit,
      getDisabledButtonTooltip,
      isLimitExceeded: ({ count }) => isLimitExceeded({ count, limit })
    }
  })

  const okrWeightsLimitations = computed(() => {
    const restrictionId = getRestrictionId({ key: RESTRICTIONS_KEYS.OKR_WEIGHTS })

    const UNAVAILABLE_RESTRICTION_ID = RESTRICTIONS_IDS.ID_1
    const isUnavailable = restrictionId === UNAVAILABLE_RESTRICTION_ID

    const isDisabled = ({ okrElement }) => {
      if (objectiveIsJiraTask(okrElement)) {
        return true
      }

      const isElementInClosedStatus = isOkrElementClosed(okrElement)

      const isAvailableByChildren = Boolean(okrElement.childCount)

      // okr weights available only if element is not closed and has more than 0 children
      const baseCondition = isElementInClosedStatus || !isAvailableByChildren

      const [isDisabled] = [
        isJiraApp && baseCondition,
        // if is jira connected to web or cross-platform app we can use okr weights according to the base condition
        isWebApp && isJiraConnected.value && baseCondition,
        isCrossPlatformApp && isJiraConnected.value && baseCondition,
        // if is jira not connected to web or cross-platform app we can use okr weights only if we have not essential plan
        isWebApp && !isJiraConnected.value && !isUnavailable && baseCondition,
        isCrossPlatformApp && !isJiraConnected.value && !isUnavailable && baseCondition,
        // if is jira not connected to web or cross-platform app we cant use okr weights if we have essential plan
        isWebApp && !isJiraConnected.value && isUnavailable,
        isCrossPlatformApp && !isJiraConnected.value && isUnavailable
      ].filter(Boolean)

      return Boolean(isDisabled)
    }

    const getTooltip = ({ okrElement }) => {
      if (objectiveIsJiraTask(okrElement)) {
        return null
      }

      const isElementInClosedStatus = isOkrElementClosed(okrElement)

      const isAvailableByChildren = Boolean(okrElement.childCount)

      const getClosedStatusName = () => {
        if (isElementInClosedStatus) {
          const statusName =
            okrElement.confidenceLevelId === OKR_STATUSES.CLOSED
              ? t('dashboard.closed')
              : t('dashboard.abandoned')
          return statusName.toLowerCase()
        }

        return ''
      }

      const tooltipByChildCount = t('weights.objective.cant_change_weight_by_nested_items')
      const tooltipByClosedStatus = t('weights.objective.cant_change_weight_desc', {
        status: getClosedStatusName()
      })

      const conditions = [
        !isAvailableByChildren && tooltipByChildCount,
        isElementInClosedStatus && tooltipByClosedStatus
      ]

      if (isJiraApp || ((isWebApp || isCrossPlatformApp) && isJiraConnected.value)) {
        const [tooltip] = conditions.filter(Boolean)

        return tooltip || null
      }

      if ((isWebApp || isCrossPlatformApp) && !isJiraConnected.value) {
        const [tooltip] = [isUnavailable && UNAVAILABLE_BY_PLAN_TOOLTIP, ...conditions].filter(
          Boolean
        )

        return tooltip || null
      }

      return null
    }

    const essentialPlanLimitations = {
      UNAVAILABLE_BY_PLAN_TOOLTIP,
      isDisabledByPlan:
        (isWebApp || isCrossPlatformApp) &&
        !isJiraConnected.value &&
        restrictionId === UNAVAILABLE_RESTRICTION_ID
    }

    return {
      isDisabled,
      getTooltip,
      essentialPlanLimitations
    }
  })

  const customDashboardPublicLimitations = computed(() => {
    const restrictionId = getRestrictionId({ key: RESTRICTIONS_KEYS.CUSTOM_DASHBOARD_PUBLIC })
    const isNeedLimitation = checkIsNeedLimitation({ restrictionId })

    const limit = isNeedLimitation
      ? LIMITATIONS.CUSTOM_DASHBOARD_PUBLIC[restrictionId]
      : MAX_PUBLIC_CUSTOM_DASHBOARDS_COUNT

    const getDisabledButtonTooltip = () => {
      if (isNeedLimitation) {
        return 'limitations.create_button.tooltip'
      } else {
        return null
      }
    }

    return {
      limit,
      getDisabledButtonTooltip,
      isLimitExceeded: ({ count }) => isLimitExceeded({ count, limit })
    }
  })

  return {
    customFieldsLimitations,
    okrLevelsLimitations,
    okrWeightsLimitations,
    customDashboardPublicLimitations
  }
}
