<template>
  <div class="aup-Layout">
    <div class="aup-Layout_Sidebar">
      <AppLogo height="40" type="short" width="40" />
      <div class="aup-SidebarActions">
        <SupportModal hide-debug-button with-local-modal-trigger />

        <AppMenuUserActions
          :disabled-actions="[ACTIONS_KEYS.GO_TO_PROFILE]"
          append-to="parent"
          @create-organization="isShowCreateOrganizationModal = true"
        />
      </div>
    </div>
    <div class="aup-Layout_Content">
      <slot />
    </div>
  </div>
  <portal to="modal-windows">
    <CreateOrganizationModal
      :show="isShowCreateOrganizationModal"
      @close-create-organization="isShowCreateOrganizationModal = false"
    />
  </portal>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { useSetAppLocale } from '@/utils/general'
import CreateOrganizationModal from '@shared-modules/components/modals/CreateOrganizationModal'

import AppLogo from '@/components/AppLogo'
import AppMenuUserActions from '@/components/AppMenu/AppMenuUserActions'
import SupportModal from '@/components/support/SupportModal'

defineOptions({
  name: 'AppUnavailablePageLayout'
})

const store = useStore()
const appLocale = computed(() => store.getters['system/appLocale'])

onMounted(async () => {
  await store.dispatch('system/fetchUserData')

  useSetAppLocale(appLocale.value)
})

const isShowCreateOrganizationModal = ref(false)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';

.aup-Layout {
  height: $default-canvas-height-non-objectives-page;
  display: grid;
  grid-template-columns: 80px 1fr 80px;
}

.aup-Layout_Sidebar {
  width: 100%;
  height: 100%;
  padding: 20px 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  box-shadow: 0 0 1px 0 rgba(22, 22, 29, 0.31), 0 3px 5px 0 rgba(22, 22, 29, 0.2),
    0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.aup-Layout_Content {
  width: 100%;
}

.aup-SidebarActions {
  position: sticky;
  bottom: 20px;
  display: grid;
  gap: 17px;

  > :not(:last-child) {
    &:after {
      pointer-events: none;
      content: '';
      width: 100%;
      background-color: $grey-2-next;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -9px;
    }
  }
}
</style>
