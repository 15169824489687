<template>
  <div class="bi-Wrapper">
    <div
      v-for="box in options"
      :key="box.id"
      :style="{ '--color': box.hex, '--color-rgb': box.rgb }"
      class="bi-Item"
    >
      <div class="bi-ItemHeader">
        <div class="bi-ItemHeader_Content">
          <div
            v-if="box.hero"
            :class="{ 'bi-HeroWrapper-bg': box.heroTitle }"
            class="bi-HeroWrapper"
          >
            <AppIcon
              :height="box.heroSize || DEFAULT_IMG_SIZE"
              :icon-name="box.hero"
              :width="box.heroSize || DEFAULT_IMG_SIZE"
              class="bi-ItemHero"
            />
            <div v-if="box.heroTitle" class="bi-HeroTitle">
              {{ box.heroTitle }}
            </div>
          </div>

          <AppImage
            v-else-if="box.image"
            :height="DEFAULT_IMG_SIZE"
            :name="box.image"
            :width="box.imageWidth"
            format="png"
          />
        </div>

        <AppImage v-if="box.person" :height="DEFAULT_IMG_SIZE" :name="box.person" format="png" />
      </div>

      <AppTitle :level="3" class="bi-BoxTitle" disable-margin style="--white-space: break-spaces">
        {{ box.title }}
      </AppTitle>

      <div class="bi-ItemContent">
        <i18n-t :keypath="box.subtitle" class="bi-ItemSubtitle" scope="global" tag="div">
          <template #emailAddress>
            <a href="mailto:support@oboard.io">support@oboard.io</a>
          </template>
        </i18n-t>

        <a :href="box.link" class="bi-ItemLink" target="_blank" @click="onLinkClick(box.tracking)">
          <AppIcon v-if="box.linkIcon" :icon-name="box.linkIcon" height="24" width="24" />
          {{ box.linkText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppImage from '@/components/ui/AppImage'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'BoxItems'
})

const DEFAULT_IMG_SIZE = 32

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },

  itemsPerRow: {
    type: Number,
    default: -1,
    validator: value => Number.isFinite(value) && Number.isInteger(value) && value > 0
  }
})

const elementLength = computed(() => {
  return props.itemsPerRow > 0 ? props.itemsPerRow : props.options.length
})

const onLinkClick = eventValue => {
  tracker.logEvent('opened external resource', {
    category: EVENT_CATEGORIES.EXTERNAL_RESOURCES,
    source: EVENT_SOURCES.SUPPORT,
    value: eventValue
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/marketing-boxes';

.bi-Wrapper {
  display: grid;
  grid-template-columns: repeat(v-bind(elementLength), 1fr);
  gap: 20px;
}

.bi-Item {
  @extend %box-style;
  padding: 20px;
  gap: var(--box-item-gap, 14px);
  flex-direction: column;
}

.bi-ItemHero {
  @extend %box-hero-style;
}

.bi-BoxTitle {
  @extend %box-title-style;
}

.bi-ItemSubtitle {
  @extend %box-subtitle-style;
}

.bi-ItemLink {
  @extend %box-link-style;
  width: var(--button-width, 100%);
  min-width: var(--button-min-width, 100%);
  margin: auto 0 0;
  padding: 6px 20px;
}

.bi-ItemContent {
  @extend %box-content-style;
}

.bi-ItemHeader {
  display: flex;
  gap: var(--box-item-header-gap, 20px);
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.bi-ItemHeader_Content {
  display: grid;
  width: 100%;
  gap: inherit;
}

.bi-HeroWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  &-bg {
    padding: 0 7px 0 0;
    &:before {
      display: block;
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--color) 50.54%);
      opacity: 0.1;
      border-radius: $border-radius-sm-next;
    }
  }
}

.bi-HeroTitle {
  color: var(--color);
  font-size: $fs-12;
  line-height: 20px;
  font-weight: fw('bold');
}
</style>
