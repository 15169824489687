import ApiHandler from '@/api/base'

class BinApiHandler extends ApiHandler {
  async getBinData({
    accountIds = null,
    searchString = null,
    deletedDateFrom = null,
    deletedDateTo = null,
    limit = 20,
    offset = 0
  }) {
    const { data } = await this.http.get('/trash-bucket', {
      params: {
        accountIds,
        deletedDateTo,
        deletedDateFrom,
        searchString,
        offset,
        limit
      }
    })
    return data
  }

  async restoreDataFromBin({
    elementIds = null,
    intervalId = null,
    parentId = null,
    workspaceId = null
  }) {
    const { data } = await this.http.post('/trash-bucket', {
      elementIds,
      intervalId,
      parentId,
      workspaceId
    })
    return data
  }
}

export default BinApiHandler
