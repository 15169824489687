import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import i18n from '@/i18n'
import { LOCALE } from '@/util'
import { readFromStorageByKey } from '@/utils/persist'
import { JIRA_CLOUD_API, setLocaleFromJira, transformLocaleToDayjsFormat } from '@jira/util'

export const checkSomeValueIsTruthy = (...args) => [...args].some(value => !!value)

export const createGroupNameShortcut = groupName => groupName.trim().substring(0, 2).toUpperCase()

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const isFirefox = () => /firefox/i.test(navigator.userAgent)

export const isMacOs = () => /Mac/i.test(navigator.platform)
export const isWindowsOs = () => /Win/i.test(navigator.platform)

export const copyToClipboard = text => {
  const dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.value = text
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

export const CUSTOM_SCROLLBAR_CLASS = 'oboard-custom-scrollbar'
export const STYLED_SCROLLBAR_CLASS = 'oboard-styled-scrollbar'
export const IS_SAFARI_CLASS = 'is-safari'

export const getPluginVersion = backendVersion =>
  `[f.${process.env.VUE_APP_VERSION}, b.${backendVersion}]`

export const useSetAppLocale = appLocale => {
  const { locale, fallbackLocale, availableLocales } = i18n.global // https://vue-i18n.intlify.dev/guide/essentials/scope#local-scope-1
  const storageLocale = readFromStorageByKey(LOCALE)

  if (appLocale) {
    locale.value = appLocale
    dayjs.locale(transformLocaleToDayjsFormat(appLocale))
  } else if (storageLocale) {
    locale.value = storageLocale
    dayjs.locale(transformLocaleToDayjsFormat(storageLocale))
  } else if (JIRA_CLOUD_API) {
    setLocaleFromJira({
      locale,
      fallbackLocale,
      availableLocales
    })
  } else {
    dayjs.locale(transformLocaleToDayjsFormat(locale.value))
  }
}

export const logAttentionPhrase = (phrase = '') => {
  console.log(
    `%c ${phrase}`,
    `font-weight: 700;
     font-size: 40px;
     color: #F64963;
     text-transform: uppercase;
     font-family: Gilroy, sans-serif;`
  )
}

export const deferExecutionByCondition = async ({ condition = () => false, delay = 100 }) => {
  while (condition()) {
    await new Promise(resolve => setTimeout(resolve, delay))
  }
}

export const isStringEmpty = (string = '') => {
  return isEmpty(string.trim()) || string === '‎ '
}

export const isUrl = (string = '') => {
  const reg = /^(https?:\/\/)?([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/
  return new RegExp(reg).test(string)
}

const JWTRegex = /jwt=([^&#]*)/
export const replaceJwt = (str = 'jwt=', replaceByRegex = true) => {
  const cipher = '******'
  return replaceByRegex ? str.replace(JWTRegex, `jwt=${cipher}`) : cipher
}
