<template>
  <div class="dil-Wrapper">
    <div class="dil-Header">
      <SkeletonItem size="sm" width="24px" />
      <div class="dil-Header_RightSide">
        <SkeletonItem size="sm" width="64px" />
        <SkeletonItem size="sm" width="24px" />
        <SkeletonItem size="sm" width="24px" />
        <SkeletonItem size="sm" width="1px" />
        <SkeletonItem size="sm" width="24px" />
        <SkeletonItem size="sm" width="24px" />
        <SkeletonItem size="sm" width="24px" />
      </div>
    </div>
    <div class="dil-TitleWrapper">
      <SkeletonItem height="128px" width="640px" />
      <div class="dil-Chart">
        <SkeletonItem height="165px" width="273px" />
      </div>
    </div>
    <div class="dil-ProgressBar">
      <SkeletonItem border-radius="16px" height="32px" width="100%" />
    </div>
    <div class="dil-SliderWrapper">
      <SkeletonItem
        v-for="i in 5"
        :key="i"
        border-radius="24px"
        height="213px"
        width="calc(100% / 3)"
      />
    </div>
  </div>
</template>

<script setup>
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'DashboardItemLoader'
})
</script>

<style lang="scss" scoped>
.dil-Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.dil-Header_RightSide {
  display: flex;
  gap: 16px;
}
.dil-TitleWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  align-items: flex-end;
}
.dil-ProgressBar {
  margin: 0 0 20px 0;
}
.dil-SliderWrapper {
  display: flex;
  gap: 20px;
  margin: 0 0 20px 0;
}
.dil-Chart {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dil-Wrapper {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}
</style>
