<script>
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'AppBanner',

  props: {
    type: {
      type: String,
      default: 'warning',
      validator: value => ['warning'].includes(value)
    }
  },

  computed: {
    classes() {
      return {
        'ab-Banner': true,
        [`ab-Banner-${this.type}`]: true
      }
    }
  },

  render() {
    return h(
      'div',
      { class: this.classes },
      h('div', { class: 'ab-Banner_Content' }, this.$slots.default())
    )
  }
})
</script>

<style lang="scss" scoped>
.ab-Banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;

  &-warning {
    background-color: $grade-medium-color-next;
  }
}

.ab-Banner_Content {
  padding: 0 15px;
  font-size: $fs-15;
  text-align: center;
  min-width: 480px;
  max-width: 840px;
  width: 100%;
}
</style>
