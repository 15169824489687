import { isEmpty } from 'lodash'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import DashboardApiHandler from '@/api/dashboard'
import { handleError } from '@/utils/error-handling'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OKR_STATUSES } from '@/utils/objectives'
import { FILTER_DEFAULT_VALUES, FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { getSelectWithSelectAllApiParameter } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

export const CARD_STYLES = {
  AVG_PROGRESS: {
    '--background-color': 'var(--grade-default-color)',
    '--title-color': 'var(--white-color)',
    '--subtitle-color': 'var(--grey-1-next)'
  },

  ON_TRACK: {
    '--background-color': 'rgba(var(--grade-high-color-rgb-next), 0.1)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--grade-high-color-next)'
  },

  BEHIND: {
    '--background-color': 'rgba(var(--grade-medium-color-rgb-next), 0.1)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--grade-medium-color-next)'
  },

  AT_RISK: {
    '--background-color': 'rgba(var(--grade-low-color-rgb-next), 0.1)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--grade-low-color-next)'
  },

  CYCLE_PASSED: {
    '--background-color': 'var(--primary-color-next)',
    '--title-color': 'var(--white-color)',
    '--subtitle-color': 'var(--grey-1-next)',
    '--data-color': 'var(--white-color)'
  },

  JIRA_ISSUE_LINKED: {
    '--background-color': 'var(--dark-3)',
    '--title-color': 'var(--white-color)',
    '--subtitle-color': 'var(--grey-1-next)',
    '--data-color': 'var(--white-color)'
  },

  OBJECTIVES_WITHOUT_CHILD: {
    '--background-color': 'var(--dark-3)',
    '--title-color': 'var(--grey-1-next)',
    '--subtitle-color': 'var(--white-color)',
    '--data-color': 'var(--white-color)',
    '--subtitle-weight': '600',
    '--title-weight': '400'
  },
  PEOPLE_WITH_OKR: {
    '--background-color': 'var(--primary-color-next)',
    '--title-color': 'var(--white-color)',
    '--subtitle-color': 'var(--grey-1-next)',
    '--data-color': 'var(--white-color)'
  },
  PEOPLE_WITHOUT_OKR: {
    '--background-color': 'var(--dark-3)',
    '--title-color': 'var(--white-color)',
    '--subtitle-color': 'var(--grey-1-next)',
    '--data-color': 'var(--white-color)'
  },
  NOT_STARTED: {
    '--background-color': 'var(--grey-3-next)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--dark-3)'
  },
  CLOSED: {
    '--background-color': 'var(--grey-3-next)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--dark-3)'
  },
  ABANDONED: {
    '--background-color': 'var(--grey-3-next)',
    '--title-color': 'var(--grade-default-color)',
    '--subtitle-color': 'var(--dark-3)',
    '--data-color': 'var(--dark-3)'
  }
}

const getItemQueryParameter = ({ item }) => {
  if (item.ownerId) {
    return { [FILTERS_KEYS.ASSIGNEE_IDS]: JSON.stringify([item.ownerId]) }
  }
  return { [FILTERS_KEYS.GROUP_IDS]: JSON.stringify([item.groupId]) }
}

export const getRouteFromDashboardTableToAlignment = ({
  workspaceId,
  gradeType,
  intervalId,
  OKRTypeIds,
  item = {}
}) => {
  if (!workspaceId || !gradeType || !intervalId || isEmpty(item)) {
    throw new Error('You should pass all required params')
  }

  if (!Object.values(OKR_STATUSES).includes(gradeType)) {
    throw new Error('Grade type is not valid')
  }

  const path = `/workspaces/${workspaceId}/objectives/alignment`

  const query = {
    ...getItemQueryParameter({ item }),
    [FILTERS_KEYS.GRADE_TYPES]: JSON.stringify([gradeType]),
    [FILTERS_KEYS.INTERVAL_IDS]: JSON.stringify([intervalId]),
    [FILTERS_KEYS.OKR_TYPE_IDS]: JSON.stringify(OKRTypeIds)
  }

  const excludedQueryParams = Object.keys(query)

  const otherQueryParams = Object.fromEntries(
    Object.entries(FILTER_DEFAULT_VALUES)
      .filter(([key]) => !excludedQueryParams.includes(key))
      .map(([key, value]) => {
        return [key, Array.isArray(value) ? JSON.stringify(value) : value]
      })
  )

  return {
    path,
    query: {
      ...query,
      ...otherQueryParams
    }
  }
}

export const getIndicatorPanelStyles = ({ progressWidth, contentWidth }) => {
  if (contentWidth > progressWidth) {
    // Transform use because position not working in PDF export
    return {
      color: '--primary-color-next',
      transform: 'translate3d(5%, 0, 0)',
      left: progressWidth + 10 + 'px'
    }
  } else {
    return {
      color: '--white-color',
      transform: 'translate3d(-105%, 0, 0)',
      right: '10px'
    }
  }
}

export const GRADE_TABLE_VIEW = 'GRADE_TABLE_VIEW'
export const STATUS_TABLE_VIEW = 'STATUS_TABLE_VIEW'

export const getTableViewOptions = [
  {
    label: 'objective.by_status',
    value: STATUS_TABLE_VIEW,
    icon: 'status',
    trackingGroup: 'by status'
  },
  {
    label: 'objectives.table_header_grade',
    value: GRADE_TABLE_VIEW,
    icon: 'result-type-percent',
    trackingGroup: 'by grade'
  }
]

export const useGetPerformanceDashboardColumns = ({ selectedTableView, systemSettings }) => {
  const { t } = useI18n()
  const {
    LABEL,
    ON_TRACK_COUNT,
    BEHIND_COUNT,
    AT_RISK_COUNT,
    AVERAGE_TOTAL,
    OKR_COUNT,
    NOT_STARTED_COUNT,
    CLOSED_COUNT,
    ABANDONED_COUNT,
    ZERO_GRADE_COUNT,
    MORE_THAN_100_GRADE_COUNT
  } = TABLE_COLUMNS_KEYS

  const byStatusColumns = [
    {
      title: t('dashboard.not_started'),
      key: NOT_STARTED_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: t('dashboard.on_track'),
      key: ON_TRACK_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: t('dashboard.behind'),
      key: BEHIND_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: t('dashboard.at_risk'),
      key: AT_RISK_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: t('dashboard.closed'),
      key: CLOSED_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: t('dashboard.abandoned'),
      key: ABANDONED_COUNT,
      width: 80,
      align: 'right'
    }
  ]
  const { thresholdBehind, thresholdOnTrack } = systemSettings
  const byGradeColumns = [
    {
      title: '0',
      key: ZERO_GRADE_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: `${0} — ${thresholdBehind - 1}`,
      key: GRADE_AT_RISK_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: `${thresholdBehind} — ${thresholdOnTrack - 1}`,
      key: GRADE_BEHIND_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: `${thresholdOnTrack} — 100`,
      key: GRADE_ON_TRACK_COUNT,
      width: 80,
      align: 'right'
    },
    {
      title: '>100',
      key: MORE_THAN_100_GRADE_COUNT,
      width: 80,
      align: 'right'
    }
  ]

  return {
    data: computed(() =>
      [
        {
          title: t('field.group.title'),
          key: LABEL,
          width: 'auto',
          sortable: true
        },
        {
          title: '',
          key: OKR_COUNT,
          width: 140,
          sortable: false
        },
        selectedTableView === GRADE_TABLE_VIEW ? byGradeColumns : byStatusColumns,
        {
          title: t('dashboard.average_total'),
          key: AVERAGE_TOTAL,
          width: 100,
          align: 'right',
          sortable: true
        }
      ].flat()
    )
  }
}

export const useGetReportByUser = async ({
  groupIds,
  selectedAssigneeIds,
  intervalId,
  workspaceId
}) => {
  const dashboardApi = new DashboardApiHandler()

  try {
    const { users } = await dashboardApi.getReportByUser({
      groupIds: getSelectWithSelectAllApiParameter(groupIds),
      userIds: getSelectWithSelectAllApiParameter(selectedAssigneeIds),
      intervalId,
      workspaceId
    })
    return users
  } catch (error) {
    handleError({ error })
  }
}

export const getRouteTo = ({ item = {}, gradeType, OKRTypeIds, workspaceId, intervalId }) => {
  return getRouteFromDashboardTableToAlignment({
    item,
    gradeType,
    workspaceId,
    intervalId,
    OKRTypeIds
  })
}

export const getRowsTableLoader = count => {
  return Array(count)
    .fill(count)
    .map((_, index) => ({
      id: index,
      name: '220px',
      total: '46px'
    }))
}

export const OBJ_KEY = 'obj'
export const KR_KEY = 'kr'
export const columnKeys = [OBJ_KEY, KR_KEY]

const {
  ON_TRACK_COUNT,
  BEHIND_COUNT,
  AT_RISK_COUNT,
  CLOSED_COUNT,
  ABANDONED_COUNT,
  ZERO_GRADE_COUNT,
  MORE_THAN_100_GRADE_COUNT,
  NOT_STARTED_COUNT,
  GRADE_BEHIND_COUNT,
  GRADE_ON_TRACK_COUNT,
  GRADE_AT_RISK_COUNT
} = TABLE_COLUMNS_KEYS

export const keysForSummary = {
  [STATUS_TABLE_VIEW]: [
    ABANDONED_COUNT,
    CLOSED_COUNT,
    NOT_STARTED_COUNT,
    ON_TRACK_COUNT,
    AT_RISK_COUNT,
    BEHIND_COUNT
  ],
  [GRADE_TABLE_VIEW]: [
    ZERO_GRADE_COUNT,
    MORE_THAN_100_GRADE_COUNT,
    GRADE_BEHIND_COUNT,
    GRADE_AT_RISK_COUNT,
    GRADE_ON_TRACK_COUNT
  ]
}
export const getSummary = ({ data, okrKey = OBJ_KEY, filteredBy = STATUS_TABLE_VIEW }) => {
  return keysForSummary[filteredBy].reduce((acc, key) => {
    acc += data[okrKey]?.[key] || 0
    return acc
  }, 0)
}

export const getTableCellValue = ({ item, type, valueKey }) => {
  return item[type]?.[valueKey] || 0
}

export const useLinkOnProfileAvailability = () => {
  const store = useStore()
  const isJiraApp = inject(isJiraAppInjectionKey, false)
  const isWebApp = inject(isWebAppInjectionKey)
  const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)
  const isJiraConnected = computed(() => {
    return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
  })
  const hasAccessToJira = computed(() => store.state.system.userData.hasAccessToJira)

  const isLinkOnJiraProfileClickable = computed(() => {
    if (isJiraApp) {
      return true
    }

    if (isWebApp || isCrossPlatformApp) {
      return isJiraConnected.value && hasAccessToJira.value
    }

    return false
  })

  return {
    isLinkOnJiraProfileClickable
  }
}
