import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { APP_MODULE_IDS } from '@root/disabled-moduels'

import SynchronizationSettings from '@jira/views/workspaces/settings/synchronizationSettings/SynchronizationSettings'

export const JIRA_APP_SETTINGS_ROUTES = [
  {
    path: ROUTE_PATHS.SETTINGS_SYNCHRONIZATION,
    name: ROUTE_NAMES.SETTINGS_SYNCHRONIZATION,
    component: SynchronizationSettings,
    meta: {
      appModuleId: APP_MODULE_IDS.SETTINGS_SYNCHRONIZATION
    }
  }
]
