<template>
  <div class="ud-UserDetailsPage">
    <div class="ud-UserDetailsPage_Content">
      <UserDetailsHeadLoader v-if="isLoading" :is-jira-app="isJiraApp" />
      <div v-else class="ud-Header">
        <div class="ud-Header_Top">
          <AppButton
            v-tippy="{
              content: $t('menu.tooltip.go_back'),
              placement: 'right'
            }"
            icon="arrow-left-next"
            size="sm"
            type="subtle"
            @click="goToPreviousPage"
          />

          <div class="ud-Header_Actions">
            <DropdownMenu
              :items="DROPDOWN_ACTIONS"
              :offset="[0, 0]"
              :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
              position="bottom-end"
              @item-click="onMenuActionsClick"
            >
              <template #activator>
                <AppButton icon="more-next" size="sm" type="subtle" />
              </template>
            </DropdownMenu>
          </div>
        </div>

        <div class="ud-Header_Bottom">
          <AppAvatar :avatar-url="userData.avatarUrl" :size="AVATAR_OPTIONS.XL.name" no-margins />

          <AppTitle :level="3" class="ud-HeaderName" disable-margin>
            {{ userData.name }}
          </AppTitle>

          <a
            v-if="isShowLinkOnProfile"
            :href="userData.platformLink"
            class="ud-HeaderLink"
            target="_blank"
          >
            {{ $t('user_details.platform_profile', { platform: $t('app.platform.jira') }) }}
            <AppIcon height="24" icon-name="shortcut-next" width="24" />
          </a>
        </div>
      </div>

      <div v-if="!isNoWorkspaces" class="ud-Body" data-testid="workspaces-table-wrapper">
        <AppTable
          :columns="TABLE_COLUMNS"
          :data="userWorkspaces"
          :disable-user-select="false"
          :hover-row="tableHoverRow"
          :loading="isWorkspacesLoading"
          border-on-last-row
          no-border
          offset-left="var(--page-left-padding)"
          offset-right="var(--page-right-padding)"
          sticky-header
          type="primary-next"
        >
          <template #header-cell="{ column }">
            <div
              v-if="column.title"
              :class="{ [`ud-HeadCell-${column.key}`]: true }"
              class="ud-HeadCell"
            >
              {{ $t(column.title) }}

              <AppIcon
                v-if="column.key === TABLE_COLUMNS_KEYS.GROUPS"
                v-tippy="{
                  content: $t('groups.users_tab.tooltip'),
                  placement: 'top'
                }"
                height="24"
                icon-name="info-next"
                width="24"
              />
            </div>
          </template>

          <template #cell="{ columnKey, item, index }">
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.WORKSPACE_NAME">
              <WorkspaceNameCell :workspace="item" @on-name-click="goToWorkspace(item)" />
            </template>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.SYNC">
              <div v-if="isShowSyncCell({ groups: item.groups })" class="ud-SyncCell">
                <AppIcon height="24" icon-name="link-task" width="24" />
              </div>
            </template>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.GROUPS">
              <div class="ud-GroupsCell">
                <LimitTagList
                  :entity="LIMIT_TAG_LIST_ENTITIES.GROUP"
                  :items="item.groups"
                  :limit="10"
                  style="--list-gap: 4px; --count-offset: 0"
                  tooltip-value="name"
                >
                  <template #item="{ item: group }">
                    <GroupIcon
                      :clickable="hasAccessToGlobalGroupsPage"
                      :color="group.color"
                      :icon-name="group.icon"
                      class="us-GroupIcon"
                      @click="onGroupClick(group.id)"
                    />
                  </template>
                </LimitTagList>
              </div>
            </template>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.ROLE">
              <UserRoleCell
                :account-id="userData.accountId"
                :role-id="item.userRoleId"
                :roles="roles"
                :tracking-source="EVENT_SOURCES.USER_PROFILE_PAGE"
                :user-workspaces-data="createUserWorkspacesData(item)"
                :workspace-id="item.id"
                append-to=".ud-Body"
                @update-role-id="onUpdateRole(item.id, $event)"
              />
            </template>

            <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
              <DropdownMenu
                :data-testid="`user-dropdown-menu-${item.id}`"
                :items="menuItemActions(item)"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                position="bottom-end"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onRowActionsClick($event, item)"
              >
                <template #activator="{ isOpened }">
                  <AppButton
                    :class="{ 'ud-ActionsButton-active': isOpened }"
                    class="ud-ActionsButton"
                    icon="more-next"
                    size="sm"
                    type="tertiary-next"
                  />
                </template>
              </DropdownMenu>
            </template>
          </template>

          <template #loading>
            <UserDetailsTableLoader />
          </template>

          <template #footer>
            <AppTableCreateButton
              data-testid="add-user-to-workspace-button"
              full-width
              icon-name="plus-next"
              @click="showAddUserModal"
            >
              {{ $t('user_details.add_user') }}
            </AppTableCreateButton>
          </template>
        </AppTable>
      </div>

      <div v-else class="ud-WorkspacesEmpty" data-testid="workspaces-empty-state">
        <NoSearchResults
          offset-left="var(--page-left-padding)"
          offset-right="var(--page-right-padding)"
        >
          {{ $t('user_details.no_workspaces') }}
        </NoSearchResults>
        <AppTableCreateButton full-width icon-name="plus-next" @click="showAddUserModal">
          {{ $t('user_details.add_user') }}
        </AppTableCreateButton>
      </div>
    </div>

    <UserDeleteModal
      :show="isShowUserDeleteModal"
      :title="deleteUserConfig.title"
      data-testid="user-delete-modal"
      @on-close="closeUserDeleteModal"
      @on-confirm="onDeleteUser"
    >
      <i18n-t :keypath="deleteUserConfig.description" scope="global">
        <template #userName>
          <span class="ud-DangerText">{{ deleteUserConfig.userName }}</span>
        </template>

        <template #appName>
          <span class="ud-DangerText">{{ $t('app_name') }}</span>
        </template>

        <template #workspaceName>
          <span class="ud-DangerText">{{ deleteUserConfig.workspaceName }}</span>
        </template>
      </i18n-t>
    </UserDeleteModal>

    <AddUserToWorkspaceModal
      v-model:show="isShowAddUserToWorkspaceModal"
      :existed-workspaces-ids="existedWorkspacesIds"
      :roles="roles"
      :user-data="userData"
      data-testid="add-user-to-workspace-modal"
      @reload-workspaces="reloadWorkspaces"
    />
    <RemoveUserFromGroupModal
      v-model:show="isShowUserRemoveFromGroupModal"
      :predefined-groups="groupsToDelete"
      :tracking-source="EVENT_SOURCES.USER_PROFILE_PAGE"
      :user="userData"
      data-testid="remove-user-from-group-modal"
      @reload-data="reloadWorkspaces"
    />
  </div>
</template>

<script setup>
import { has, isEmpty, isNull, uniq } from 'lodash'
import { computed, onMounted, ref, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import UserApiHandler from '@/api/user-details'
import { useGoToEditGroup } from '@/composables/global-groups'
import { useFetchRoles } from '@/composables/plugin-users'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackOpenAddUserModalEvent,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { AVATAR_OPTIONS } from '@/utils/components-configurations/app-avatar'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { LIMIT_TAG_LIST_ENTITIES } from '@/utils/components-configurations/limit-tag-list'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { WEB_APP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { useExternalLinkHelper } from '@/utils/external-link-composables'
import { EXTERNAL_LINK_HANDLER_SOURCES } from '@/utils/external-link-helper'
import {
  appPlatformInjectionKey,
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { JIRA_PLATFORM_ID } from '@/utils/platforms-helpers'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { ACCOUNT_ID } from '@/utils/workspace-configuration-modal-helpers'
import { getAtlassianUserUrl } from '@jira/util'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import LimitTagList from '@/components/form/LimitTagList'
import GroupIcon from '@/components/global-groups/GroupIcon'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import UserDetailsHeadLoader from '@/components/ui/SkeletonLoaders/UserDetailsHeadLoader'
import UserDetailsTableLoader from '@/components/ui/SkeletonLoaders/UserDetailsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import UserDeleteModal from '@/components/workspaces/UserDeleteModal'
import WorkspaceNameCell from '@/components/workspaces/WorkspaceNameCell'
import AddUserToWorkspaceModal from '@/views/workspaces/settings/plugin-users/AddUserToWorkspaceModal'
import UserRoleCell from '@/views/workspaces/settings/plugin-users/UserRoleCell'
import RemoveUserFromGroupModal from '@/views/workspaces/settings/workspace-users/RemoveUserFromGroupModal'

defineOptions({
  name: 'UserDetailsPage'
})

const { REMOVE } = ACTIONS_KEYS

const route = useRoute()
const router = useRouter()

const tableHoverRow = ref(-1)
const TABLE_COLUMNS = [
  {
    title: 'workspaces.name',
    key: TABLE_COLUMNS_KEYS.WORKSPACE_NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.SYNC,
    width: 32
  },
  {
    title: 'field.groups.title',
    key: TABLE_COLUMNS_KEYS.GROUPS,
    width: 308
  },
  {
    title: 'workspaces.role',
    key: TABLE_COLUMNS_KEYS.ROLE,
    width: 168
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const menuItemActions = item => {
  const result = []

  if (isEmpty(item.groups)) {
    result.push({
      name: ACTIONS_KEYS.REMOVE,
      title: 'action.remove_from_ws',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: MENU_ITEMS_GROUPS.REMOVING,
      disabled: !item.canBeDeleted,
      tooltipContent: item.canBeDeleted
        ? null
        : t('user_details.delete_user_from_workspace', {
            workspaceName: item.name,
            platform: t('app.platform.jira')
          })
    })
  } else {
    result.push({
      name: ACTIONS_KEYS.REMOVE,
      title: 'action.remove_from_group',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: MENU_ITEMS_GROUPS.REMOVING
    })
  }
  return result
}

const onRowActionsClick = (name, item) => {
  if (name === ACTIONS_KEYS.REMOVE) {
    if (!isEmpty(item.groups)) {
      showRemoveUserFromGroupModal(item)
    } else {
      showUserDeleteModal({ workspaceId: item.id })
    }
  }
}

const DROPDOWN_ACTIONS = [
  {
    name: REMOVE,
    title: 'action.delete',
    icon: 'delete-next',
    group: MENU_ITEMS_GROUPS.REMOVING,
    color: 'var(--grade-low-color-next)'
  }
]

const { t } = useI18n()
const onMenuActionsClick = name => {
  if (name === REMOVE) {
    if (userData.value.canBeDeleted) {
      showUserDeleteModal()
    } else {
      showNotify({
        type: NOTIFICATION_TYPES.WARNING,
        content: t('user_details.delete_user_from_workspace', {
          workspaceName: t('app_name'),
          platform: t('app.platform.jira')
        }),
        expanded: true
      })
    }
  }
}

const redirectToAllUsersPage = () => router.push({ name: ROUTE_NAMES.ORGANIZATION_USERS })

const isLoading = ref(false)
const isWorkspacesLoading = ref(false)

const userData = ref({})
const userWorkspaces = ref([])
const roles = ref([])

const userApi = new UserApiHandler()

const fetchUserWorkspaces = async accountId => {
  isWorkspacesLoading.value = true

  if (!isEmpty(userWorkspaces.value)) {
    userWorkspaces.value = []
  }
  try {
    return await userApi.getUserWorkspaces(accountId)
  } catch (error) {
    handleError({ error })
  } finally {
    isWorkspacesLoading.value = false
  }
}

const reloadWorkspaces = async () => {
  if (userData.value.accountId) {
    userWorkspaces.value = await fetchUserWorkspaces(userData.value.accountId)
  }
}

const fetchUserData = async accountId => {
  if (!isEmpty(userData.value)) {
    userData.value = {}
  }

  isLoading.value = true

  try {
    const [userInfo, userWorkspacesInfo, rolesInfo] = await Promise.all([
      userApi.getUserInfo(accountId),
      fetchUserWorkspaces(accountId),
      useFetchRoles()
    ])

    if (isEmpty(userInfo.users)) {
      router.push({ name: ROUTE_NAMES.MISSING_PAGE })
    } else {
      const { accountId, ...rest } = userInfo.users[0]
      userData.value = {
        ...rest,
        accountId,
        platformLink: getAtlassianUserUrl(accountId)
      }
      userWorkspaces.value = userWorkspacesInfo
      roles.value = rolesInfo

      const allGroups = userWorkspacesInfo.reduce((acc, val) => {
        const { groups } = val
        const groupIds = groups.map(group => group.id)
        return [...acc, ...groupIds]
      }, [])

      tracker.logEvent('User profile opened', {
        category: EVENT_CATEGORIES.USER_MANAGEMENT,
        workspaces: userWorkspacesInfo.length,
        groups: uniq(allGroups).length
      })
    }
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const store = useStore()

const hasAccessToGlobalGroupsPage = computed(
  () => store.state.system.userData.hasAccessToGlobalGroupsPage
)

onMounted(() => {
  if (has(route.params, ACCOUNT_ID) && hasAccessToGlobalGroupsPage.value) {
    fetchUserData(route.params.accountId)
  } else {
    redirectToAllUsersPage()
  }
})

const appPlatform = inject(appPlatformInjectionKey)

const { handleExternalLink } = useExternalLinkHelper()

const goToWorkspace = workspace => {
  handleExternalLink({
    workspaceId: workspace.id,
    source: EXTERNAL_LINK_HANDLER_SOURCES.USER_DETAILS_PAGE,
    appPlatform,
    navigateByRouter: isCrossPlatformApp
  })
}

const findWorkspaceForUpdate = workspaceId =>
  userWorkspaces.value.find(workspace => workspace.id === workspaceId)

const createUserWorkspacesData = workspace => {
  return [
    {
      workspaceId: workspace.id,
      defaultGroupId: workspace.groups.find(group => group.default)?.id || null
    }
  ]
}

const onUpdateRole = (workspaceId, roleId) => {
  const workspaceToUpdate = findWorkspaceForUpdate(workspaceId)

  if (workspaceToUpdate) {
    workspaceToUpdate.userRoleId = roleId
  }
}

const isShowUserDeleteModal = ref(false)
const workspaceIdToDeleteUser = ref(null)
const showUserDeleteModal = ({ workspaceId = null } = {}) => {
  isShowUserDeleteModal.value = true
  if (workspaceId) {
    workspaceIdToDeleteUser.value = workspaceId
  }
}

const closeUserDeleteModal = () => {
  isShowUserDeleteModal.value = false
  workspaceIdToDeleteUser.value = null
}

const goToPreviousPage = () => {
  if (router.options.history.state.back) {
    router.push(router.options.history.state.back)
    // router.back()
  } else {
    redirectToAllUsersPage()
  }
}

const onDeleteUser = async () => {
  const isDeleteUserFromWorkspace = !isNull(workspaceIdToDeleteUser.value)

  const payload = {
    accountId: userData.value.accountId
  }

  if (isDeleteUserFromWorkspace) {
    payload.workspaceId = workspaceIdToDeleteUser.value
  }

  const requestMethod = isDeleteUserFromWorkspace
    ? () => userApi.removeUserFromWorkspace(payload)
    : () => userApi.removeUserFromPlugin(payload)

  try {
    await requestMethod()

    const trackingPayload = {
      mode: MODE_NAMES_FOR_TRACKING.PLUGIN,
      source: EVENT_SOURCES.USER_PROFILE_PAGE
    }

    if (isDeleteUserFromWorkspace) {
      trackingPayload.mode = MODE_NAMES_FOR_TRACKING.WORKSPACE
    }

    trackRemoveUserEvent(trackingPayload)

    if (isDeleteUserFromWorkspace) {
      userWorkspaces.value = userWorkspaces.value.filter(
        workspace => workspace.id !== workspaceIdToDeleteUser.value
      )
      closeUserDeleteModal()

      if (isEmpty(userWorkspaces.value)) {
        goToPreviousPage()
      }
    } else {
      closeUserDeleteModal()

      goToPreviousPage()
    }
  } catch (error) {
    handleError({ error })
  }
}

const isNoWorkspaces = computed(() => {
  return !isWorkspacesLoading.value && isEmpty(userWorkspaces.value)
})

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const isShowUserRemoveFromGroupModal = ref(false)
const isShowAddUserToWorkspaceModal = ref(false)
const showAddUserModal = () => {
  isShowAddUserToWorkspaceModal.value = true

  trackOpenAddUserModalEvent({
    source: EVENT_SOURCES.USER_PROFILE_PAGE,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
    role: userRoleForTracking.value
  })
}

const groupsToDelete = ref(null)
const showRemoveUserFromGroupModal = item => {
  isShowUserRemoveFromGroupModal.value = true
  groupsToDelete.value = item.groups
}

watch(
  () => isShowUserRemoveFromGroupModal.value,
  newValue => {
    if (!newValue) {
      groupsToDelete.value = null
    }
  }
)

const existedWorkspacesIds = computed(() => {
  return userWorkspaces.value.map(workspace => workspace.id)
})

const deleteUserConfig = computed(() => {
  const userName = userData.value.name

  let description = 'delete_user_from_app'
  let workspaceName = 'this workspace'
  if (workspaceIdToDeleteUser.value) {
    const workspace = userWorkspaces.value.find(
      workspace => workspace.id === workspaceIdToDeleteUser.value
    )

    workspaceName = workspace?.name

    description = 'delete_user_from_workspace'
  }

  return {
    title: t('delete_user.title', {
      userName
    }),
    userName,
    workspaceName,
    description
  }
})

const isJiraApp = inject(isJiraAppInjectionKey, false)
const isWebApp = inject(isWebAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const isJiraConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
})

const hasAccessToJira = computed(() => store.state.system.userData.hasAccessToJira)

const isShowLinkOnProfile = computed(() => {
  let isShow = userData.value[WEB_APP_ENTITY_KEYS.PLATFORM_IDS]?.includes(JIRA_PLATFORM_ID)

  if (isWebApp || isCrossPlatformApp) {
    isShow = isShow && isJiraConnected.value && hasAccessToJira.value
  }

  return isShow
})

const isShowSyncCell = ({ groups = [] } = {}) => {
  return groups.some(group => group.userSynced)
}

/*
  let description = t('delete_user_from_app', {
    userName
  })

  if (workspaceIdToDeleteUser.value) {
    const workspace = userWorkspaces.value.find(
      workspace => workspace.id === workspaceIdToDeleteUser.value
    )

    workspaceName = workspace.name

    description = 'delete_user_from_workspace'
  }

  return {
    title: t('delete_user.title', {
      userName
    }),
    userName,
    workspaceName,
    description
  }
})
 */

const { goToEditGroup } = useGoToEditGroup()

const onGroupClick = groupId => {
  goToEditGroup({ groupId })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/modal-danger-text';
@import '~@/assets/styles/mixins';

.ud-UserDetailsPage {
  padding: 40px 0;
  font-family: $system-ui;
}

.ud-Header {
  width: 100%;
  max-width: $page-width-md-next;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: grid;
  gap: 29px;
}

.ud-HeaderName {
  line-height: 28px;
}

.ud-HeaderLink {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  margin-left: auto;
  font-weight: 400;
  font-size: $fs-14;
  line-height: 20px;
  color: $primary-color-next;
  flex: 0 0 auto;
}

.ud-Header_Top {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;
}

.ud-Header_Bottom {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.ud-Body {
  margin-top: 40px;
  width: 100%;
  max-width: $page-width-md-next;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
  --head-padding-top: 4px;
  --head-padding-bottom: 4px;
}

.ud-WorkspacesEmpty {
  margin-top: 40px;
  width: 100%;
  max-width: $page-width-md-next;
}

.ud-HeadCell {
  min-height: 24px;
  display: flex;
  align-items: center;

  &-role {
    padding-left: 6px;
  }
}

.ud-SyncCell {
  display: flex;
  align-items: center;
}

.ud-GroupsCell {
  display: flex;
  padding-right: 8px;
}

.ud-DangerText {
  @extend %modal-danger-text;
}
.ud-GroupsCell {
  display: flex;
}
.ud-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }
  @extend %app-table-hidden-items;
}
</style>
