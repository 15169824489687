<template>
  <div ref="toolbar" class="ot-OkrToolbar">
    <portal to="okr-sticky-header">
      <OkrFilters :view="view">
        <div v-if="areAllInitialFiltersDataLoaded" class="ot-Navigation_Filters">
          <div class="ot-NavigationFilter ot-NavigationFilter-intervals">
            <IntervalSelectWithCustomRange
              v-model:interval-custom-date="intervalCustomDate"
              v-model:interval-custom-range="intervalCustomRange"
              v-model:selected-dates="selectedDates"
              v-model:selected-interval-custom-range-mode="selectedIntervalCustomRangeMode"
              v-model:selected-intervals="selectedIntervals"
              v-model:show-interval-custom-range-picker="showIntervalCustomRangePicker"
              :custom-range-options-based-on-filters="customRangeOptionsBasedOnFilters"
              :hidden-intervals="hiddenIntervals"
              :interval-fixed-items="INTERVALS_FIXED_ITEMS"
              :intervals="intervals"
              :intervals-are-loading="intervalsAreLoading"
              :is-between-mode-selected="isBetweenModeSelected"
              :is-time-range-selected="isTimeRangeSelected"
              :tracker-event-source="EVENT_SOURCES.GRID"
              data-testid="intervals-filter"
              @select-intervals="selectIntervals($event, true)"
              @on-bottom-fixed-item-click="onBottomFixedItemClick"
            />
          </div>

          <div class="ot-NavigationFilter">
            <OkrFilterSelect
              :bottom-fixed-items="
                getBottomFixedItemsClearSelection($options.FILTERS_KEYS.ASSIGNEE_IDS)
              "
              :model-value="filtersValues[$options.FILTERS_KEYS.ASSIGNEE_IDS]"
              :options="assigneesOptions"
              :search-function="getAssignees"
              data-testid="owners-filter"
              has-only-this-button
              item-label="name"
              item-value="accountId"
              n-selected-label="filter.owners"
              prepend-icon="user-next"
              @update:options="onAssigneesUpdate"
              @update:model-value="
                selectListFilterValue($options.FILTERS_KEYS.ASSIGNEE_IDS, $event)
              "
            >
              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
              <template #bottom-fixed-items="{ bottomFixedItems }">
                <div v-for="item in bottomFixedItems" :key="item.id">
                  <BottomFixedSelectItem
                    v-if="isClearSelectionAction(item.action)"
                    @click="bottomFixedItemsHandle(item.action, $options.FILTERS_KEYS.ASSIGNEE_IDS)"
                  >
                    {{ $t(item.text) }}
                  </BottomFixedSelectItem>
                </div>
              </template>
            </OkrFilterSelect>
          </div>

          <div class="ot-NavigationFilter">
            <OkrFilterSelect
              :bottom-fixed-items="
                getBottomFixedItemsClearSelection($options.FILTERS_KEYS.GROUP_IDS)
              "
              :dropdown-min-width="300"
              :model-value="filtersValues[$options.FILTERS_KEYS.GROUP_IDS]"
              :options="groupsOptions"
              :search-function="getGroups"
              data-testid="groups-filter"
              has-only-this-button
              n-selected-label="filter.groups"
              prepend-icon="team-next"
              @update:options="onGroupsUpdate"
              @update:model-value="selectListFilterValue($options.FILTERS_KEYS.GROUP_IDS, $event)"
            >
              <template #option-label="{ option }">
                <GlobalGroupsSelectOption v-if="option" :group="option" />
              </template>
              <template #bottom-fixed-items="{ bottomFixedItems }">
                <div v-for="item in bottomFixedItems" :key="item.id">
                  <BottomFixedSelectItem
                    v-if="isClearSelectionAction(item.action)"
                    @click="bottomFixedItemsHandle(item.action, $options.FILTERS_KEYS.GROUP_IDS)"
                  >
                    {{ $t(item.text) }}
                  </BottomFixedSelectItem>
                </div>
              </template>
            </OkrFilterSelect>
          </div>

          <div class="ot-NavigationFilter">
            <div
              :class="{
                'ot-AdditionalFiltersButton-highlight':
                  showAdditionalFilterSelectedCount || showAdditionalFilters
              }"
              class="ot-AdditionalFiltersButton"
              data-testid="additional-filters-trigger"
              @click.capture="showAdditionalFilters = !showAdditionalFilters"
            >
              <AppIcon
                class="ot-AdditionalFiltersButton_Icon"
                height="24"
                icon-name="plus-next"
                width="24"
              />

              {{ $t('filter.more') }}
              <SelectedFiltersCount
                v-if="showAdditionalFilterSelectedCount"
                class="ot-AdditionalFilter_SelectedCount"
                data-testid="additional-filters-selected-count"
              >
                {{ additionalFiltersSelectedCounts.totalCount }}
              </SelectedFiltersCount>
            </div>
            <portal to="modal-windows">
              <Modal
                id="additional-filters-modal"
                key="additional-filters-modal"
                :scrollable-wrapper="false"
                :show="showAdditionalFilters"
                data-auto-testid="additional-filters-modal"
                data-testid="additional-filters-modal"
                manual-close
                scrollable-content
                size="xs"
                style="--modal-header-padding: 18px 20px 18px 20px"
                use-v-show-directive
                @close="showAdditionalFilters = false"
              >
                <template #header>
                  <div class="ot-AdditionalFiltersModalHeader">
                    <AppTitle :level="3" disable-margin> {{ $t('filter.more_filters') }}</AppTitle>
                    <SelectedFiltersCount
                      v-if="showAdditionalFilterSelectedCount"
                      class="ot-AdditionalFilter_SelectedCount ot-AdditionalFilter_SelectedCount-light"
                    >
                      {{ additionalFiltersSelectedCounts.totalCount }}
                    </SelectedFiltersCount>
                  </div>
                </template>
                <template #modal-body>
                  <div class="ot-AdditionalFiltersContent">
                    <div class="ot-AdditionalFiltersContent_Filters">
                      <FormItemsGroup class="ot-AdditionalFilters" no-border>
                        <OkrFilterSelect
                          :bottom-fixed-items="
                            getBottomFixedItemsClearSelection($options.FILTERS_KEYS.OKR_TYPE_IDS)
                          "
                          :model-value="filtersValues[$options.FILTERS_KEYS.OKR_TYPE_IDS]"
                          :options="getOKRsOptions"
                          :search-function="
                            value => localSearch({ value, options: getOKRsOptions })
                          "
                          :type="SELECT_TYPES.DEFAULT_NEXT"
                          additional
                          append-to=".ot-AdditionalFiltersContent_Filters"
                          class="ot-AdditionalFilter_SelectButton"
                          custom-button
                          dropdown-search
                          has-only-this-button
                          multi
                          n-selected-label="filter.levels"
                          simplified-selected
                          @update:model-value="
                            selectListFilterValue($options.FILTERS_KEYS.OKR_TYPE_IDS, $event)
                          "
                        >
                          <template #option-label="{ option }">
                            <OkrTypeFieldOption :option="option" />
                          </template>
                          <template #bottom-fixed-items="{ bottomFixedItems }">
                            <div v-for="item in bottomFixedItems" :key="item.id">
                              <BottomFixedSelectItem
                                v-if="isClearSelectionAction(item.action)"
                                @click="
                                  bottomFixedItemsHandle(
                                    item.action,
                                    $options.FILTERS_KEYS.OKR_TYPE_IDS
                                  )
                                "
                              >
                                {{ $t(item.text) }}
                              </BottomFixedSelectItem>
                            </div>
                          </template>
                          <template #button="{ active, fullDataOption, option }">
                            <OkrFormFieldSelectTriggerNext
                              :label="$t('filter.okr_level')"
                              :opened="active"
                              :selected-options="option"
                              type="secondary"
                            >
                              <template v-if="isShowAllContain(fullDataOption)" #values>
                                <span class="ot-SelectButton_Value">
                                  {{ $t('filter.show_all') }}
                                </span>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                        </OkrFilterSelect>

                        <OkrFilterSelect
                          :model-value="filtersValues[$options.FILTERS_KEYS.GRADE_TYPES]"
                          :options="gradeTypesOptions"
                          :search="false"
                          :type="SELECT_TYPES.DEFAULT_NEXT"
                          additional
                          append-to=".ot-AdditionalFiltersContent_Filters"
                          class="ot-AdditionalFilter_SelectButton"
                          has-only-this-button
                          item-label="label"
                          item-value="value"
                          n-selected-label="filter.statuses"
                          @update:model-value="
                            selectListFilterValue($options.FILTERS_KEYS.GRADE_TYPES, $event)
                          "
                        >
                          <template #button="{ active, option, fullDataOption }">
                            <OkrFormFieldSelectTriggerNext
                              :label="$t('filter.status')"
                              :opened="active"
                              :selected-options="option"
                              type="secondary"
                            >
                              <template v-if="isShowAllContain(fullDataOption)" #values>
                                <span class="ot-SelectButton_Value">
                                  {{ $t('filter.show_all') }}
                                </span>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                        </OkrFilterSelect>
                        <div
                          :class="{
                            'ot-AdditionalFilters_Dates-locked': showLockedDatesMessage
                          }"
                          class="ot-AdditionalFilters_Dates"
                        >
                          <FormItemsGroup no-border>
                            <DateFilter
                              :date-can-be-null="isTimeRangeSelected"
                              :disabled="isTimeRangeSelected"
                              :model-value="filtersValues[$options.FILTERS_KEYS.START_DATES]"
                              :popup-style="DATE_FILTER_POPUP_STYLE"
                              append-icon-height="20"
                              append-icon-width="20"
                              append-to=".ot-AdditionalFiltersContent_Filters"
                              no-left-padding
                              @update:model-value="onDateSelect($event, filtersKeys.START_DATES)"
                            >
                              <template #button="{ active, option, displayedDates }">
                                <OkrFormFieldSelectTriggerNext
                                  :empty-state-label="$t('objectives.table_header_startDate')"
                                  :label="$t('filter.start_date')"
                                  :opened="active"
                                  :selected-options="option.label"
                                  separated-label
                                  type="secondary"
                                >
                                  <template #values>
                                    <div class="ot-SelectButton_Value">
                                      <template v-if="isTimeRangeSelected">
                                        <div class="ot-SelectButton_ValueText">
                                          {{ displayedLockedDates.start }}
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div
                                          v-if="displayedDates"
                                          class="ot-SelectButton_ValueText"
                                        >
                                          <i18n-t
                                            keypath="date.date_with_word_break"
                                            scope="global"
                                          >
                                            <template #start>{{ displayedDates.start }}</template>
                                            <template #end>{{ displayedDates.end }}</template>
                                            <template #break><br /></template>
                                          </i18n-t>
                                        </div>
                                        <span v-else>{{ $t('filter.show_all') }}</span>
                                      </template>
                                    </div>
                                  </template>
                                </OkrFormFieldSelectTriggerNext>
                              </template>
                            </DateFilter>

                            <DateFilter
                              :date-can-be-null="isTimeRangeSelected"
                              :disabled="isTimeRangeSelected"
                              :model-value="filtersValues[$options.FILTERS_KEYS.DUE_DATES]"
                              :popup-style="DATE_FILTER_POPUP_STYLE"
                              append-icon-height="20"
                              append-icon-width="20"
                              append-to=".ot-AdditionalFiltersContent_Filters"
                              no-left-padding
                              @update:model-value="onDateSelect($event, filtersKeys.DUE_DATES)"
                            >
                              <template #button="{ active, option, displayedDates }">
                                <OkrFormFieldSelectTriggerNext
                                  :empty-state-label="$t('filter.due_date')"
                                  :label="$t('filter.due_date')"
                                  :opened="active"
                                  :selected-options="option.label"
                                  separated-label
                                  type="secondary"
                                >
                                  <template #values>
                                    <div class="ot-SelectButton_Value">
                                      <template v-if="isTimeRangeSelected">
                                        <div class="ot-SelectButton_ValueText">
                                          {{ displayedLockedDates.end }}
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div
                                          v-if="displayedDates"
                                          class="ot-SelectButton_ValueText"
                                        >
                                          <i18n-t
                                            keypath="date.date_with_word_break"
                                            scope="global"
                                          >
                                            <template #start>{{ displayedDates.start }}</template>
                                            <template #end>{{ displayedDates.end }}</template>
                                            <template #break><br /></template>
                                          </i18n-t>
                                        </div>
                                        <span v-else>{{ $t('filter.show_all') }}</span>
                                      </template>
                                    </div>
                                  </template>
                                </OkrFormFieldSelectTriggerNext>
                              </template>
                            </DateFilter>
                          </FormItemsGroup>
                          <transition name="flip">
                            <div v-if="showLockedDatesMessage" class="ot-LockedDatesDescription">
                              <div class="ot-LockedDatesDescription_Content">
                                <AppIcon height="24" icon-name="calendar-range" width="24" />

                                <div class="ot-LockedDates_Title">
                                  {{ $t('filter.custom_range') }} {{ $t('filter.limitation') }}
                                </div>
                                <div class="ot-LockedDates_Range">
                                  {{ displayedLockedDates.full }}
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div>
                        <DateFilter
                          :model-value="
                            filtersValues[$options.FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]
                          "
                          :popup-style="DATE_FILTER_POPUP_STYLE"
                          append-icon-height="20"
                          append-icon-width="20"
                          append-to=".ot-AdditionalFiltersContent_Filters"
                          no-left-padding
                          @update:model-value="
                            onDateSelect($event, filtersKeys.LAST_GRADE_UPDATE_DATES)
                          "
                        >
                          <template #button="{ active, option, displayedDates }">
                            <OkrFormFieldSelectTriggerNext
                              :empty-state-label="$t('filter.last_grade_update_date')"
                              :label="$t('filter.last_grade_update_date')"
                              :opened="active"
                              :selected-options="option.label"
                              separated-label
                              type="secondary"
                            >
                              <template #values>
                                <div class="ot-SelectButton_Value">
                                  <div v-if="displayedDates" class="ot-SelectButton_ValueText">
                                    <i18n-t keypath="date.date_with_word_break" scope="global">
                                      <template #start>{{ displayedDates.start }}</template>
                                      <template #end>{{ displayedDates.end }}</template>
                                      <template #break><br /></template>
                                    </i18n-t>
                                  </div>
                                  <span v-else>{{ $t('filter.show_all') }}</span>
                                </div>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                        </DateFilter>

                        <OkrFilterSelect
                          :bottom-fixed-items="
                            getBottomFixedItemsClearSelection($options.FILTERS_KEYS.LABEL_IDS)
                          "
                          :loading="areLabelsLoading"
                          :model-value="filtersValues[$options.FILTERS_KEYS.LABEL_IDS]"
                          :options="labelsOptions"
                          :search-function="getLabels"
                          :type="SELECT_TYPES.DEFAULT_NEXT"
                          additional
                          append-to=".ot-AdditionalFiltersContent_Filters"
                          class="ot-AdditionalFilter_SelectButton"
                          has-only-this-button
                          multi
                          n-selected-label="field.labels.title"
                          simplified-selected
                          @update:model-value="
                            selectListFilterValue($options.FILTERS_KEYS.LABEL_IDS, $event)
                          "
                        >
                          <template #bottom-fixed-items="{ bottomFixedItems }">
                            <div v-for="item in bottomFixedItems" :key="item.id">
                              <BottomFixedSelectItem
                                v-if="isClearSelectionAction(item.action)"
                                @click="
                                  bottomFixedItemsHandle(
                                    item.action,
                                    $options.FILTERS_KEYS.LABEL_IDS
                                  )
                                "
                              >
                                {{ $t(item.text) }}
                              </BottomFixedSelectItem>
                            </div>
                          </template>
                          <template #button="{ active, fullDataOption, option }">
                            <OkrFormFieldSelectTriggerNext
                              :label="$t('field.labels.title')"
                              :opened="active"
                              :selected-options="option"
                              type="secondary"
                            >
                              <template v-if="isShowAllContain(fullDataOption)" #values>
                                <span class="ot-SelectButton_Value">
                                  {{ $t('filter.show_all') }}
                                </span>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                        </OkrFilterSelect>

                        <OkrFilterSelect
                          :bottom-fixed-items="
                            getBottomFixedItemsClearSelection($options.FILTERS_KEYS.STAKEHOLDER_IDS)
                          "
                          :model-value="filtersValues[$options.FILTERS_KEYS.STAKEHOLDER_IDS]"
                          :options="stakeholdersOptions"
                          :search-function="getStakeholders"
                          :type="SELECT_TYPES.DEFAULT_NEXT"
                          append-to=".ot-AdditionalFiltersContent_Filters"
                          class="ot-AdditionalFilter_SelectButton"
                          dropdown-search
                          has-only-this-button
                          item-label="displayName"
                          item-value="accountId"
                          multi
                          n-selected-label="field.assignee.stakeholders"
                          skeleton-loader
                          @update:model-value="
                            selectListFilterValue($options.FILTERS_KEYS.STAKEHOLDER_IDS, $event)
                          "
                        >
                          <template #bottom-fixed-items="{ bottomFixedItems }">
                            <div v-for="item in bottomFixedItems" :key="item.id">
                              <BottomFixedSelectItem
                                v-if="isClearSelectionAction(item.action)"
                                @click="
                                  bottomFixedItemsHandle(
                                    item.action,
                                    $options.FILTERS_KEYS.STAKEHOLDER_IDS
                                  )
                                "
                              >
                                {{ $t(item.text) }}
                              </BottomFixedSelectItem>
                            </div>
                          </template>
                          <template #button="{ active, fullDataOption, option }">
                            <OkrFormFieldSelectTriggerNext
                              :label="$t('field.assignee.stakeholders')"
                              :opened="active"
                              :selected-options="option"
                              half-width-items
                              item-label="displayName"
                              item-value="accountId"
                              separated-label
                              type="secondary"
                            >
                              <template #values>
                                <SelectedUsersAvatarsList
                                  v-if="getFilteredStakeholders(fullDataOption).length"
                                  :users="getFilteredStakeholders(fullDataOption)"
                                />
                                <template v-else-if="isShowAllContain(fullDataOption)">
                                  <span class="ot-SelectButton_Value">
                                    {{ $t('filter.show_all') }}
                                  </span>
                                </template>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                          <template #option-label="{ option }">
                            <OwnerFieldOption :option="option" />
                          </template>
                        </OkrFilterSelect>

                        <OkrFilterRelativeValues
                          :model-value="filtersValues[$options.FILTERS_KEYS.LAST_COMMENT]"
                          :options="getLastCommentOptions"
                          class="ot-AdditionalFilter_SelectButton"
                          @update:model-value="
                            onSelectLastCommentFilterValue($event, filtersKeys.LAST_COMMENT)
                          "
                        >
                          <template #button="{ active, option, modelValue }">
                            <OkrFormFieldSelectTriggerNext
                              :empty-state-label="$t('filter.last_comment')"
                              :label="$t('filter.last_comment')"
                              :opened="active"
                              :selected-options="option.label"
                              separated-label
                              type="secondary"
                            >
                              <template #values>
                                <div class="ot-SelectButton_Value">
                                  <div v-if="option.label" class="ot-SelectButton_ValueText">
                                    {{ option.label }}
                                    <span v-if="modelValue[1]">
                                      <br />
                                      {{ modelValue[1] }}
                                      {{ $t('objectives.days_ago') }}
                                    </span>
                                  </div>
                                  <span v-else>{{ $t('filter.show_all') }}</span>
                                </div>
                              </template>
                            </OkrFormFieldSelectTriggerNext>
                          </template>
                        </OkrFilterRelativeValues>

                        <OkrElementsFiltersCustomFieldsHub
                          ref="customFieldFiltersHub"
                          v-model:custom-field-filters-state="customFieldFiltersState"
                          :filters-values="customFieldsFiltersValues"
                          :workspace-id="workspaceId"
                          @update-filter-value="onUpdateCustomFieldFilterValue"
                          @initial-data-loaded="$emit('custom-fields-filters-initial-data-loaded')"
                        />
                      </FormItemsGroup>
                    </div>
                  </div>
                </template>
              </Modal>
            </portal>
          </div>

          <div v-if="showFilterActions" class="ot-NavigationFilterActions">
            <ResetFilterButton data-testid="toolbar-reset-filters-button" @click="onResetFilters" />
            <OkrFilterSaver
              :custom-fields-filters-values="customFieldsFiltersValues"
              :filters-values="filtersValues"
              :have-custom-field-filters-default-values="
                customFieldFiltersState.isAllFiltersInDefaultValues
              "
            />
          </div>
        </div>
      </OkrFilters>
    </portal>

    <div class="ot-Navigation">
      <template v-if="!fullScreen">
        <div class="ot-Navigation_ViewSelect">
          <ViewSelect ref="viewSelect" />
        </div>

        <AppRadioGroup
          v-if="showToggleRoadmapButton"
          :model-value="view"
          :options="switchOptions"
          name="roadmap-switch"
          style="--option-padding: 0 8px 0 0"
          type="primary-next"
          @update:model-value="onRoadmapToggleClick"
        >
          <template #item-label="{ item }">
            <span class="ss-AutoSwitchOption">
              <AppRadioGroupNextOptionWithIcon :option="item" />
            </span>
          </template>
        </AppRadioGroup>

        <portal-target v-if="isMindMapView || isExplorerView" name="ot-navigation-after" />

        <ObjectiveSorting
          :current-tab="tabForTracker"
          :model-value="filtersValues[$options.FILTERS_KEYS.SORT_ORDER]"
          :sort-children="filtersValues[$options.FILTERS_KEYS.SORT_CHILDREN]"
          @update:model-value="
            onUpdateFiltersValue({ key: $options.FILTERS_KEYS.SORT_ORDER, value: $event })
          "
          @update:sort-children="
            onUpdateFiltersValue({
              key: $options.FILTERS_KEYS.SORT_CHILDREN,
              value: $event
            })
          "
        />

        <portal-target name="ot-navigation-end" />
      </template>

      <div v-if="!fullScreen" class="ot-Navigation_Actions">
        <portal-target name="okr-toolbar-right-before" />

        <div class="ot-Actions_Left">
          <portal-target name="okr-toolbar-actions-left" />

          <UseAppPlatform v-slot="{ isCrossPlatformApp }">
            <AppButton
              v-if="!isCrossPlatformApp"
              class="ot-ActionsButton"
              height="24"
              remove-padding
              type="ghost-next"
              width="24"
              @click="onCopyClick"
            >
              <LoadingCircle v-if="shareLinkLoading" min-height="32" size="xsmall" />
              <AppIcon v-else height="24" icon-name="share" width="24" />
              {{ $t('action.share') }}
            </AppButton>
          </UseAppPlatform>

          <AppButton
            class="ot-ExportBtn"
            height="24"
            remove-padding
            type="ghost-next"
            width="24"
            @click="onExportToExcel"
          >
            <LoadingCircle v-if="excelExportProceed" min-height="32" size="xsmall" />
            <AppIcon v-else height="24" icon-name="xlsx" width="24" />
            {{ $t('objective.chart.download_xlsx') }}
          </AppButton>
        </div>

        <div v-if="isToolbarActionsPortalHasContent" class="ot-Actions_Right">
          <portal-target name="toolbar-actions" />
        </div>
      </div>
    </div>

    <UseAppPlatform v-slot="{ isCrossPlatformApp }">
      <CopySharedLinkModal
        v-if="!isCrossPlatformApp"
        v-model:show="showCopyShareLinkModal"
        :token="shareToken"
      />
    </UseAppPlatform>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isEqual, isNull } from 'lodash'
import { Wormhole } from 'portal-vue'
import { defineComponent } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import GlobalGroupsApiHandler from '@/api/global-groups'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import LabelsApiHandler from '@/api/labels'
import LevelApiHandler from '@/api/level'
import {
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { useBackSettingsBackButton } from '@/composables/settings-back-button'
import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES, TRACKING_SHOW_ALL } from '@/tracking/amplitude-helpers'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  getResolvedCustomFieldFiltersValues
} from '@/utils/custom-fields/helpers'
import { compensateTimeZone, DATE_FILTER_POPUP_STYLE, getDisplayedDates } from '@/utils/date'
import { ASSIGNEE_ENTITY_KEYS, GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  DEFAULT_DATES_RANGE,
  DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT,
  displayedLockedDates,
  filterOnBacklogInterval,
  getArchivedIntervals,
  getSuitableInterval,
  sortIntervals
} from '@/utils/interval'
import { NOTIFICATION_DURATIONS, NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import {
  ALL_STATUS_OPTIONS,
  localSearch,
  OKR_VIEW_PAGES,
  OKR_VIEW_PAGES_IDS
} from '@/utils/objectives'
import { prepareFiltersPayload } from '@/utils/okr-custom-filters'
import {
  FILTERS_KEYS,
  FILTER_TRACKING_KEY,
  sendFilterTrackerEvent,
  FILTER_DEFAULT_VALUES,
  FILTER_PRESETS,
  RESET_FILTER_TYPES,
  SELECT_ALL_VALUE,
  isTimeRangeSelectedInsteadOfInterval,
  INTERVAL_TIME_RANGE_OPTIONS,
  DEFAULT_SELECTED_FILTERS_COUNT,
  calculateSelectedFiltersCount,
  VALUES_DATA_TYPES,
  isShowAllContain,
  sendIntervalFilterTrackerEvent,
  getValueForTrackingLstCommentFilter,
  sendCustomFieldFilterTrackerEvent,
  getLastCommentOptions
} from '@/utils/okr-elements/filters'
import {
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY
} from '@/utils/query-parameters'
import {
  handleSelectInputWithSelectAll,
  selectAllIsSelected,
  getSelectWithSelectAllApiParameter
} from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { ACCOUNT_ID } from '@/utils/workspace-configuration-modal-helpers'

import OkrElementsFiltersCustomFieldsHub from '@/components/custom-fields/okr-elements-filters/OkrElementsFiltersCustomFieldsHub'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import DateFilter from '@/components/objectives/filter-field/DateFilter'
import ObjectiveSorting from '@/components/objectives/ObjectiveSorting'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterRelativeValues from '@/components/objectives/toolbar/OkrFilterRelativeValues'
import OkrFilterSaver from '@/components/objectives/toolbar/OkrFilterSaver'
import SelectedFiltersCount from '@/components/objectives/toolbar/SelectedFiltersCount'
import ViewSelect from '@/components/objectives/ViewSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import { useCopySharedLinkModal } from '@/components/ui/CopySharedLinkModal/composables'
import CopySharedLinkModal from '@/components/ui/CopySharedLinkModal/CopySharedLinkModal'
import {
  CUSTOM_RANGE_ITEM,
  isSelectedIntervalCustomRangeMode
} from '@/components/ui/IntervalSelectWithCustomRange/interval-select-with-custom-range-utils'
import IntervalSelectWithCustomRange from '@/components/ui/IntervalSelectWithCustomRange/IntervalSelectWithCustomRange'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import Modal from '@/components/ui/Modal/Modal'
import ResetFilterButton from '@/components/ui/ResetFilterButton'
import UseAppPlatform from '@/components/UseAppPlatform'

import OkrFilters from './OkrFilters'
import OkrFilterSelect from './OkrFilterSelect'

const MANAGE_INTERVALS_ACTION_ID = 1

export default defineComponent({
  name: 'OkrToolbar',

  components: {
    UseAppPlatform,
    CopySharedLinkModal,
    IntervalSelectWithCustomRange,
    OkrTypeFieldOption,
    AppTitle,
    Modal,
    OkrFilterRelativeValues,
    OkrElementsFiltersCustomFieldsHub,
    GlobalGroupsSelectOption,
    ResetFilterButton,
    AppRadioGroupNextOptionWithIcon,
    AppRadioGroup,
    DateFilter,
    OkrFilterSaver,
    SelectedFiltersCount,
    AppIcon,
    ViewSelect,
    AppButton,
    LoadingCircle,
    ObjectiveSorting,
    OkrFilters,
    OkrFilterSelect,
    OwnerFieldOption,
    FormItemsGroup,
    OkrFormFieldSelectTriggerNext,
    SelectedUsersAvatarsList,
    BottomFixedSelectItem
  },

  props: {
    intervals: {
      type: Array,
      default: () => []
    },

    fullScreen: {
      type: Boolean
    },

    excelExportProceed: {
      type: Boolean
    },

    filtersValues: {
      type: Object,
      required: true
    },

    customFieldsFiltersValues: {
      type: Object,
      required: true
    },

    workspaceId: {
      type: Number,
      required: true
    },

    tabForTracker: {
      type: String,
      default: ''
    },

    filterPreset: {
      type: String,
      default: FILTER_PRESETS.NONE
    },

    view: {
      type: String,
      default: OKR_VIEW_PAGES.ALIGNMENT
    }
  },

  emits: {
    'export-to-excel': null,
    'initial-data-loaded': null,
    'update:intervals': null,
    'update:my-group': null,
    'change-view': null,
    'export-excel': null,
    'toolbar-scrolled-above': null,
    'update-filter-value': null,
    'update-custom-field-filter-value': null,
    'reset-filter-value': null,
    'reset-custom-filter': null,
    'custom-fields-filters-initial-data-loaded': null,
    'reset-custom-field-filters': null,
    'toggle-roadmap': null
  },

  FILTERS_KEYS,

  setup() {
    const { saveSettingsReferer } = useBackSettingsBackButton()
    const { onCopyClick, shareToken, shareLinkLoading, showCopyShareLinkModal } =
      useCopySharedLinkModal()

    return {
      saveSettingsReferer,
      onCopyClick,
      shareToken,
      shareLinkLoading,
      showCopyShareLinkModal
    }
  },

  data() {
    return {
      areAllInitialFiltersDataLoaded: false,

      assignees: [],
      stakeholders: [],
      labels: [],
      areLabelsLoading: false,
      groups: [],

      showAdditionalFilters: false, // false,
      scrollObserver: null,
      intervalsAreLoading: true,

      areFiltersValidated: false,

      showLockedDatesMessage: false,
      showIntervalCustomRangePicker: false,
      selectedIntervalCustomRangeMode: INTERVAL_TIME_RANGE_OPTIONS.BETWEEN.value,
      intervalCustomRange: cloneDeep(DEFAULT_DATES_RANGE),
      intervalCustomDate: cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT),

      customFieldFiltersState: {
        selectedFiltersCount: DEFAULT_SELECTED_FILTERS_COUNT,
        isAllFiltersInDefaultValues: true
      }
    }
  },

  computed: {
    SELECT_TYPES: () => SELECT_TYPES,

    EVENT_SOURCES: () => EVENT_SOURCES,

    ...mapGetters('okrFilters', {
      currentSavedFilter: 'currentSavedFilter'
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapGetters('system', {
      userGroupsByWorkspace: 'userGroupsByWorkspace'
    }),

    ...mapState('objectives', {
      allLevels: state => state.levels
    }),

    ...mapState('okrFilters', {
      savedFiltersLoaded: state => state.savedFiltersLoaded
    }),

    ...mapState('system', {
      hasAccessToWorkspaceSettingsPage: state => state.userData?.hasAccessToWorkspaceSettingsPage
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    DATE_FILTER_POPUP_STYLE: () => DATE_FILTER_POPUP_STYLE,

    getLastCommentOptions() {
      return getLastCommentOptions()
    },

    hiddenIntervals() {
      const allArchivedIntervalsIds = getArchivedIntervals(this.intervals).map(({ id }) => id)
      const resolvedSelectedIntervals = isNull(this.selectedIntervals)
        ? FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS]
        : this.selectedIntervals
      // hide all archived intervals except selected
      return allArchivedIntervalsIds.filter(id => !resolvedSelectedIntervals.includes(id))
    },

    isBetweenModeSelected() {
      return isSelectedIntervalCustomRangeMode(this.selectedIntervalCustomRangeMode)
        .isBetweenModeSelected
    },

    displayedLockedDates() {
      return displayedLockedDates({
        isTimeRangeSelected: this.isTimeRangeSelected,
        selectedDates: this.selectedDates,
        customRangeOptionsBasedOnFilters: this.customRangeOptionsBasedOnFilters
      })
    },

    selectedIntervals: {
      get() {
        return this.filtersValues[FILTERS_KEYS.INTERVAL_IDS]
      },

      set(newValue) {
        this.$emit('update-filter-value', {
          key: FILTERS_KEYS.INTERVAL_IDS,
          value: newValue
        })
      }
    },

    selectedDates: {
      get() {
        const startDates = this.filtersValues[FILTERS_KEYS.START_DATES]
        const dueDates = this.filtersValues[FILTERS_KEYS.DUE_DATES]

        return {
          startDates,
          dueDates
        }
      },

      set(newValue) {
        if (newValue[FILTERS_KEYS.START_DATES]) {
          this.$emit('update-filter-value', {
            key: FILTERS_KEYS.START_DATES,
            value: newValue[FILTERS_KEYS.START_DATES]
          })
        }

        if (newValue[FILTERS_KEYS.DUE_DATES]) {
          this.$emit('update-filter-value', {
            key: FILTERS_KEYS.DUE_DATES,
            value: newValue[FILTERS_KEYS.DUE_DATES]
          })
        }
      }
    },

    customRangeOptionsBasedOnFilters() {
      const { startDates, dueDates } = this.selectedDates

      return isTimeRangeSelectedInsteadOfInterval({
        startDates,
        dueDates,
        intervals: this.selectedIntervals
      })
    },

    isTimeRangeSelected() {
      return this.customRangeOptionsBasedOnFilters.isTimeRangeSelected
    },

    INTERVALS_FIXED_ITEMS() {
      const isWorkspaceAdmin = this.selectedWorkspace?.workspaceAdmin
      if (isWorkspaceAdmin) {
        const intervalsQueryParams = new URLSearchParams({
          [EDIT_WORKSPACE_QUERY_KEY]: this.workspaceId,
          [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: TABLE_COLUMNS_KEYS.INTERVALS
        }).toString()
        return [
          {
            ...CUSTOM_RANGE_ITEM
          },
          {
            id: MANAGE_INTERVALS_ACTION_ID,
            path: `${ROUTE_PATHS.WORKSPACES}${ROUTE_PATHS.SETTINGS}/${ROUTE_PATHS.SETTINGS_WORKSPACES}?${intervalsQueryParams}`,
            text: 'filter.manage_intervals'
          }
        ]
      }

      return [{ ...CUSTOM_RANGE_ITEM }]
    },

    switchOptions() {
      return [
        {
          label: 'view_select.list',
          value: OKR_VIEW_PAGES.ALIGNMENT,
          icon: 'roadmap-switch-list'
        },
        {
          label: 'view_select.roadmap',
          value: OKR_VIEW_PAGES.ROADMAP,
          icon: 'view-select-roadmap'
        }
      ]
    },

    isToolbarActionsPortalHasContent() {
      return !isEmpty(Wormhole.getContentForTarget('toolbar-actions'))
    },

    filtersKeys: () => FILTERS_KEYS,

    intervalsTriggers() {
      return [this.selectedIntervals, this.intervals]
    },

    showToggleRoadmapButton() {
      return [OKR_VIEW_PAGES.ROADMAP, OKR_VIEW_PAGES.ALIGNMENT].some(view => view === this.view)
    },

    isMindMapView() {
      return this.view === OKR_VIEW_PAGES.MINDMAP
    },

    isExplorerView() {
      return this.view === OKR_VIEW_PAGES.OKREXPLORER
    },

    isRoadmapView() {
      return this.view === OKR_VIEW_PAGES.ROADMAP
    },

    getOKRsOptions() {
      return [
        {
          name: this.$t('filter.show_all'),
          id: 0
        },
        ...this.allLevels
      ]
    },

    haveFiltersDefaultValues() {
      return (
        this.haveFiltersDefaultValuesWithoutGroupsAndOwners &&
        selectAllIsSelected(this.filtersValues[FILTERS_KEYS.ASSIGNEE_IDS]) &&
        selectAllIsSelected(this.filtersValues[FILTERS_KEYS.GROUP_IDS])
      )
    },

    haveFiltersDefaultValuesWithoutGroupsAndOwners() {
      return (
        this.customFieldFiltersState.isAllFiltersInDefaultValues &&
        selectAllIsSelected(this.filtersValues[FILTERS_KEYS.GRADE_TYPES]) &&
        selectAllIsSelected(this.filtersValues[FILTERS_KEYS.LABEL_IDS]) &&
        selectAllIsSelected(this.filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS]) &&
        this.allTypesAreSelected &&
        this.filtersValues[FILTERS_KEYS.DUE_DATES] ===
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES] &&
        this.filtersValues[FILTERS_KEYS.START_DATES] ===
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES] &&
        this.filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] ===
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] &&
        this.filtersValues[FILTERS_KEYS.LAST_COMMENT][0] === SELECT_ALL_VALUE
      )
    },

    filterPresetConditions() {
      const { filterPreset } = this

      return {
        isNone: filterPreset === FILTER_PRESETS.NONE,
        isMyGroup: filterPreset === FILTER_PRESETS.MY_GROUP,
        isMyOkr: filterPreset === FILTER_PRESETS.MY_OKR
      }
    },

    showFilterActions() {
      const { haveFiltersDefaultValuesWithoutGroupsAndOwners, filtersValues } = this
      const { isNone, isMyOkr, isMyGroup } = this.filterPresetConditions

      if (isNone) {
        return !this.haveFiltersDefaultValues
      } else if (isMyGroup) {
        // const [myGroup] = this.groups
        const userGroupsByWorkspace = this.userGroupsByWorkspace(this.workspaceId)
        const resolvedUserGroups = isEmpty(userGroupsByWorkspace)
          ? FILTER_DEFAULT_VALUES[FILTERS_KEYS.GROUP_IDS]
          : userGroupsByWorkspace

        // const resolvedMyGroupId = myGroup?.id || SELECT_ALL_VALUE

        return !(
          haveFiltersDefaultValuesWithoutGroupsAndOwners &&
          selectAllIsSelected(filtersValues[FILTERS_KEYS.ASSIGNEE_IDS]) &&
          // isEqual(filtersValues[FILTERS_KEYS.GROUP_IDS], [resolvedMyGroupId])
          isEqual(filtersValues[FILTERS_KEYS.GROUP_IDS], resolvedUserGroups)
        )
      } else if (isMyOkr) {
        const currentUserId = this.$store.state.system.userData?.userAccountId
        return !(
          haveFiltersDefaultValuesWithoutGroupsAndOwners &&
          selectAllIsSelected(filtersValues[FILTERS_KEYS.GROUP_IDS]) &&
          isEqual(filtersValues[FILTERS_KEYS.ASSIGNEE_IDS], [currentUserId])
        )
      } else if (this.savedFiltersLoaded) {
        const currentSavedFilter = this.currentSavedFilter(this.filterPreset)

        if (currentSavedFilter) {
          const preparedFilters = prepareFiltersPayload(
            {
              ...filtersValues,
              [CUSTOM_FIELDS_FILTERS_QUERY_KEY]: getResolvedCustomFieldFiltersValues({
                customFieldsFiltersValues: this.customFieldsFiltersValues,
                haveCustomFieldFiltersDefaultValues:
                  this.customFieldFiltersState.isAllFiltersInDefaultValues
              })
            },
            this.$store.state.workspaces.workspaceId
          )

          return !isEqual(currentSavedFilter.filters, preparedFilters)
        }
        return true
      } else {
        return false
      }
    },

    assigneesOptions() {
      return [
        {
          accountId: 0,
          name: this.$t('filter.all_owners'),
          iconName: 'team-next'
        },
        ...this.assignees
      ]
    },

    groupsOptions() {
      return [
        {
          id: 0,
          name: this.$t('filter.all_groups')
        },
        ...this.groups
      ]
    },

    labelsOptions() {
      return [
        {
          id: 0,
          name: this.$t('filter.show_all')
        },
        ...this.labels
      ]
    },

    stakeholdersOptions() {
      return [
        {
          [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: SELECT_ALL_VALUE,
          displayName: this.$t('filter.show_all')
        },
        ...this.stakeholders
      ]
    },

    gradeTypesOptions() {
      return [
        {
          value: 0,
          label: this.$t('filter.show_all')
        },
        ...ALL_STATUS_OPTIONS.map(option => ({ ...option, label: this.$t(option.label) }))
      ]
    },

    allTypesAreSelected() {
      return selectAllIsSelected(this.filtersValues[FILTERS_KEYS.OKR_TYPE_IDS])
    },

    additionalFiltersSelectedCounts() {
      const selectedStatusesCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.GRADE_TYPES],
        filterValueType: VALUES_DATA_TYPES.ARRAY
      })

      const selectedOkrTypesCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.OKR_TYPE_IDS],
        filterValueType: VALUES_DATA_TYPES.ARRAY
      })

      const selectedDueDatesCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.DUE_DATES],
        filterValueType: VALUES_DATA_TYPES.DATES
      })

      const selectedStartDatesCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.START_DATES],
        filterValueType: VALUES_DATA_TYPES.DATES
      })

      const selectedLastGradeUpdateDatesCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES],
        filterValueType: VALUES_DATA_TYPES.DATES
      })

      const selectedLabelsCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.LABEL_IDS],
        filterValueType: VALUES_DATA_TYPES.ARRAY
      })

      const lastComment = this.filtersValues[FILTERS_KEYS.LAST_COMMENT]
      const selectedLastCommentUpdateDatesCount = lastComment[0] === SELECT_ALL_VALUE ? 0 : 1

      const selectedStakeholdersCount = calculateSelectedFiltersCount({
        filterValue: this.filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS],
        filterValueType: VALUES_DATA_TYPES.ARRAY
      })

      const { selectedFiltersCount } = this.customFieldFiltersState

      const totalCount = [
        selectedOkrTypesCount,
        selectedStatusesCount,
        selectedDueDatesCount,
        selectedStartDatesCount,
        selectedLastGradeUpdateDatesCount,
        selectedLastCommentUpdateDatesCount,
        selectedLabelsCount,
        selectedStakeholdersCount,
        selectedFiltersCount
      ].reduce((acc, val) => acc + val, DEFAULT_SELECTED_FILTERS_COUNT)
      return {
        totalCount,
        selectedStatusesCount,
        selectedOkrTypesCount,
        selectedDueDatesCount,
        selectedStartDatesCount,
        selectedLastGradeUpdateDatesCount,
        selectedLastCommentUpdateDatesCount,
        selectedLabelsCount,
        selectedStakeholdersCount,
        customFieldFiltersSelectedCount: selectedFiltersCount
      }
    },

    showAdditionalFilterSelectedCount() {
      return this.additionalFiltersSelectedCounts.totalCount > DEFAULT_SELECTED_FILTERS_COUNT
    }
  },

  watch: {
    workspaceId: {
      handler(newValue, oldValue) {
        if (newValue !== null) {
          if (oldValue) {
            // this is a hotfix
            // find a better way to do this
            setTimeout(() => {
              this.resetFiltersAndIntervals()
              this.resetCustomFieldFilters()
            })
          }
          this.fetchAll()
          this.$refs.customFieldFiltersHub?.renewFilters()
        }
      },

      immediate: true
    },

    intervalsTriggers: {
      handler(newValue) {
        const [selectedIntervalsIds, intervals] = newValue
        if (!isEmpty(selectedIntervalsIds) && !isEmpty(intervals)) {
          const selectedIntervalsNames = intervals
            .filter(({ id }) => selectedIntervalsIds.includes(id))
            .map(({ name }) => name)
            .join(', ')
          this.setSelectedIntervalsNames(selectedIntervalsNames)
        }
      },

      immediate: true,
      deep: true
    },

    showAdditionalFilters: {
      handler(newValue) {
        // need for css animation
        if (newValue) {
          this.$nextTick(() => {
            this.showLockedDatesMessage = this.isTimeRangeSelected
          })
        } else {
          this.showLockedDatesMessage = false
        }
      }
    }
  },

  mounted() {
    this.handleObjectiveToolbarIntersecting()
  },

  beforeUnmount() {
    this.disconnectObserver()
  },

  methods: {
    localSearch,
    isClearSelectionAction,
    ...mapActions('objectives', {
      setLevels: 'setLevels'
    }),

    ...mapActions('intervals', {
      setSelectedIntervalsNames: 'setSelectedIntervalsNames'
    }),

    onBottomFixedItemClick({ handler, item }) {
      if (handler && item && item.id === MANAGE_INTERVALS_ACTION_ID) {
        this.saveSettingsReferer()
      }

      if (handler) {
        handler()
      }
    },

    bottomFixedItemsHandle(action, key) {
      if (isClearSelectionAction(action)) {
        this.clearSelection(key)
      }
    },

    getBottomFixedItemsClearSelection(key) {
      return !this.filtersValues[key].includes(SELECT_ALL_VALUE) ? SELECTS_WITH_CHECKBOX_ITEMS : []
    },

    clearSelection(key) {
      this.$emit('reset-filter-value', key)
    },

    onUpdateFiltersValue(filtersValues) {
      this.$emit('update-filter-value', filtersValues)
    },

    isShowAllContain,

    getFilteredStakeholders(items) {
      return items.filter(item => item.accountId)
    },

    onRoadmapToggleClick() {
      const { workspaceId, $route, isRoadmapView } = this
      const basePath = `${ROUTE_PATHS.WORKSPACES}${workspaceId}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}`
      const pathPostfix = isRoadmapView
        ? ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE
        : ROUTE_PATHS.OKR_ELEMENTS_ROADMAP
      this.$router.push({ path: `${basePath}/${pathPostfix}`, query: $route.query })
      this.$emit('toggle-roadmap')

      const eventName = isRoadmapView ? 'closed roadmap' : 'opened roadmap'
      const viewType = isRoadmapView ? OKR_VIEW_PAGES_IDS.ROADMAP : OKR_VIEW_PAGES_IDS.ALIGNMENT
      tracker.logEvent(eventName, {
        category: EVENT_CATEGORIES.ROADMAP,
        source: `${EVENT_SOURCES.VIEW_TYPE} ${viewType}`
      })
    },

    async fetchIntervals() {
      this.intervalsAreLoading = true
      if (!isEmpty(this.intervals)) {
        this.$emit('update:intervals', cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS]))
      }
      const intervalsInfoApi = new IntervalsInfoApiHandler()
      try {
        const intervals = await intervalsInfoApi.getAllIntervalsInfo({
          workspaceId: this.workspaceId
        })
        this.$emit('update:intervals', sortIntervals(intervals))
        // await while new intervals applied
        await this.$nextTick()
        const selectedIntervalsValid = this.selectedIntervals.every(interval => {
          return this.intervals.map(item => item.id).includes(interval)
        })
        if (isEmpty(this.selectedIntervals) || !selectedIntervalsValid) {
          this.updateSelectedIntervals()
        }
      } catch (error) {
        handleError({ error })
      }
      this.intervalsAreLoading = false
    },

    async handleObjectiveToolbarIntersecting() {
      await this.$nextTick()

      this.scrollObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!entry.isIntersecting) {
              const { viewSelect } = this.$refs
              if (viewSelect) {
                viewSelect.hideDropdown()
              }
              this.$emit('toolbar-scrolled-above')
            }
          })
        },
        { rootMargin: '-80px' } // 72px header 8px toolbar padding top
      )

      this.scrollObserver.observe(this.$refs.toolbar)
    },

    disconnectObserver() {
      if (this.scrollObserver) {
        this.scrollObserver.unobserve(this.$refs.toolbar)
        this.scrollObserver = null
      }
    },

    async fetchAll() {
      try {
        const fetchAssignees = async () => {
          this.assignees = await this.getAssignees()
        }

        const fetchStakeholders = async () => {
          this.stakeholders = await this.getStakeholders()
        }
        const fetchLabels = async () => {
          this.labels = await this.getLabels()
        }

        await Promise.all([
          fetchAssignees(),
          fetchStakeholders(),
          this.fetchGroups(),
          this.fetchIntervals(),
          this.getLevelsForFilter(),
          fetchLabels()
        ])
        if (!this.areFiltersValidated) {
          this.validateAndSelectFilters()
        }
        this.areAllInitialFiltersDataLoaded = true
        this.$emit('initial-data-loaded')
      } catch (error) {
        handleError({ error })
      }
    },

    validateAndSelectFilters() {
      const filtersForValidate = [
        {
          key: FILTERS_KEYS.ASSIGNEE_IDS,
          allValues: this.assignees,
          resolvedKey: ACCOUNT_ID
        },
        {
          key: FILTERS_KEYS.GROUP_IDS,
          allValues: this.groups,
          resolvedKey: 'id'
        },
        {
          key: FILTERS_KEYS.OKR_TYPE_IDS,
          allValues: this.getOKRsOptions,
          resolvedKey: 'id'
        },
        {
          key: FILTERS_KEYS.LABEL_IDS,
          allValues: this.labels,
          resolvedKey: 'id'
        },
        {
          key: FILTERS_KEYS.STAKEHOLDER_IDS,
          allValues: this.stakeholders,
          resolvedKey: ACCOUNT_ID
        },
        {
          key: FILTERS_KEYS.GRADE_TYPES,
          allValues: this.gradeTypesOptions,
          resolvedKey: 'value'
        }
      ]

      filtersForValidate.forEach(filter => {
        const { key, allValues, resolvedKey } = filter
        const allValuesIds = allValues.map(value => value[resolvedKey])
        const validSelectedValues = this.filtersValues[key].filter(selectedValue =>
          allValuesIds.includes(selectedValue)
        )
        const payload = validSelectedValues.length
          ? validSelectedValues
          : cloneDeep(FILTER_DEFAULT_VALUES[key])
        this.selectListFilterValue(key, payload)
      })

      this.areFiltersValidated = true
    },

    async fetchGroups(includeMyGroups = false) {
      this.groups = await this.getGroups(null, includeMyGroups)

      // const [myGroup] = this.groups
      // if (myGroup?.id) {
      //   this.$emit('update:my-group', myGroup.id)
      // }

      if (includeMyGroups) {
        const myGroups = this.userGroupsByWorkspace(this.workspaceId).filter(groupId =>
          this.groups.some(group => group.id === groupId)
        )
        if (!isEmpty(myGroups)) {
          this.$emit('update:my-group', myGroups)
        } else {
          this.$emit('update:my-group', FILTER_DEFAULT_VALUES[FILTERS_KEYS.GROUP_IDS])
        }
      }

      if (isEmpty(this.groups)) {
        const isWorkspaceAdmin = this.selectedWorkspace?.workspaceAdmin

        const isShowNotificationAction =
          (isWorkspaceAdmin && this.hasAccessToWorkspaceSettingsPage) || this.isPluginAdmin

        const actions = isShowNotificationAction
          ? [
              {
                id: 0,
                label: 'global_groups.add_groups_to_workspace',
                closeOnHandle: true,
                handler: () => {
                  this.saveSettingsReferer()
                  this.$router.push({
                    name: ROUTE_NAMES.SETTINGS_WORKSPACES,
                    query: { [EDIT_WORKSPACE_QUERY_KEY]: this.workspaceId }
                  })
                }
              }
            ]
          : null

        const notificationText = this.$t('groups.no_groups_notification')

        const content = isShowNotificationAction
          ? notificationText
          : `${notificationText} ${this.$t('notification.contact_ws_admin')}`

        showNotify({
          content,
          duration: NOTIFICATION_DURATIONS.LONG,
          expanded: true,
          type: NOTIFICATION_TYPES.WARNING,
          actions,
          withButtonClose: true
        })
      }
    },

    async getAssignees(searchString = null) {
      const api = new AssigneesInfoApiHandler()
      let result = []
      try {
        result = await api.getUsers({
          searchString,
          requiredUserAccountIds: getSelectWithSelectAllApiParameter(
            this.filtersValues[FILTERS_KEYS.ASSIGNEE_IDS]
          ),
          workspaceId: this.workspaceId
        })
      } catch (error) {
        handleError({ error })
      }
      return result
    },

    async getStakeholders(searchString = null) {
      const api = new AssigneesInfoApiHandler()
      let result = []
      try {
        result = await api.getUsers({
          searchString,
          requiredUserAccountIds: getSelectWithSelectAllApiParameter(
            this.filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS]
          ),
          workspaceId: this.workspaceId
        })
      } catch (error) {
        handleError({ error })
      }
      return result
    },

    async getGroups(searchString = null, includeMyGroups = false) {
      const api = new GlobalGroupsApiHandler()
      try {
        const selectedGroups = getSelectWithSelectAllApiParameter(
          this.filtersValues[FILTERS_KEYS.GROUP_IDS]
        )
        const resolvedSelectedGroups = isNull(selectedGroups) ? [selectedGroups] : selectedGroups
        const resolvedMyGroups = includeMyGroups ? this.userGroupsByWorkspace(this.workspaceId) : []
        const groupIds = isNull(searchString)
          ? [...resolvedSelectedGroups, ...resolvedMyGroups]
          : null
        return await api.getGroupsForFilter({
          searchString,
          groupIds,
          workspaceIds: [this.workspaceId]
        })
      } catch (error) {
        handleError({ error })
      }
      return []
    },

    async getLabels(searchString = null) {
      const api = new LabelsApiHandler()
      let result = []
      this.areLabelsLoading = true
      try {
        result = await api.getLabels({ name: searchString })
      } catch (error) {
        handleError({ error })
      } finally {
        this.areLabelsLoading = false
      }
      return result
    },

    resetFiltersAndIntervals() {
      this.$emit('reset-filter-value', FILTERS_KEYS.INTERVAL_IDS)
      this.resetFilters()
    },

    resetCustomFieldFilters({ updateElements = false } = {}) {
      this.$emit('reset-custom-field-filters', { updateElements })
    },

    /** @public */
    onResetFilters() {
      const { isNone, isMyOkr, isMyGroup } = this.filterPresetConditions
      const currentSavedFilter = this.currentSavedFilter(this.filterPreset)
      const savedFilterExist = !!currentSavedFilter
      const isDefaultFilterPreset = Object.values(FILTER_PRESETS).includes(this.filterPreset)

      if (isNone || (!savedFilterExist && !isDefaultFilterPreset)) {
        this.resetFilters()
      } else {
        const [emitsPayload] = [
          isMyGroup && { type: RESET_FILTER_TYPES.MY_GROUP },
          isMyOkr && { type: RESET_FILTER_TYPES.MY_OKR },
          !isMyOkr &&
            !isMyGroup && { type: RESET_FILTER_TYPES.CUSTOM, query: currentSavedFilter.query }
        ].filter(Boolean)

        this.$emit('reset-custom-filter', { ...emitsPayload })
      }
    },

    onExportToExcel() {
      this.$emit('export-excel')
    },

    logFilterEvent(label, value) {
      sendFilterTrackerEvent({
        label,
        value,
        section: EVENT_SOURCES.GRID,
        tab: this.tabForTracker
      })
    },

    onAssigneesUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.assignees = newValue.filter(
        assignee => assignee[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID] !== SELECT_ALL_VALUE
      )
    },

    onGroupsUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.groups = newValue.filter(group => group[GROUP_ENTITY_KEYS.ID] !== SELECT_ALL_VALUE)
    },

    updateSelectedIntervals() {
      if (!this.isTimeRangeSelected) {
        const suitableInterval = getSuitableInterval(this.intervals, this.selectedIntervals, true)

        this.selectIntervals([suitableInterval])
      }
    },

    selectIntervals(value, sendTrackerEvent = false) {
      if (this.isTimeRangeSelected) {
        this.$emit('reset-filter-value', FILTERS_KEYS.DUE_DATES, false)
        this.$emit('reset-filter-value', FILTERS_KEYS.START_DATES, false)
      }
      this.showIntervalCustomRangePicker = false
      const newValue = filterOnBacklogInterval({
        intervals: this.intervals,
        value,
        selectedIntervals: this.selectedIntervals
      })

      if (sendTrackerEvent) {
        sendIntervalFilterTrackerEvent({
          section: EVENT_SOURCES.GRID,
          newValue,
          intervals: this.intervals,
          tab: this.tabForTracker
        })
      }

      this.$emit('update-filter-value', { key: FILTERS_KEYS.INTERVAL_IDS, value: newValue })
    },

    onUpdateCustomFieldFilterValue({ fieldId, value, filterName }) {
      this.$emit('update-custom-field-filter-value', { fieldId, value })
      sendCustomFieldFilterTrackerEvent({
        label: filterName,
        section: EVENT_SOURCES.GRID,
        tab: this.tabForTracker
      })
    },

    selectListFilterValue(filterKey, newValue) {
      const oldValue = this.filtersValues[filterKey]
      // eslint-disable-next-line prefer-const
      let { valueChanged, result } = handleSelectInputWithSelectAll(newValue, oldValue)
      valueChanged = valueChanged || !isEqual(oldValue, result)

      if (valueChanged) {
        // const oldValueCopy = [...oldValue]
        this.$emit('update-filter-value', { key: filterKey, value: result })

        // const newItemIndex = result.findIndex(item => oldValueCopy.indexOf(item) === -1)

        this.logFilterEvent(FILTER_TRACKING_KEY[filterKey], result)

        // if (
        //   Array.isArray(result) &&
        //   result.length > 0 &&
        //   !selectAllIsSelected(result) &&
        //   newItemIndex !== -1
        // ) {
        //   if (filterKey === FILTERS_KEYS.GRADE_TYPES) {
        //     const newItem = this.gradeTypesOptions.find(option => newItemIndex === option.value)
        //     this.logFilterEvent(FILTER_TRACKING_KEY[filterKey], newItem?.label)
        //   } else if (filterKey === FILTERS_KEYS.OKR_TYPE_IDS) {
        //     const newItem = this.getOKRsOptions.find(option => newItemIndex === option.id)
        //     this.logFilterEvent(FILTER_TRACKING_KEY[filterKey], newItem?.name)
        //   } else {
        //     this.logFilterEvent(FILTER_TRACKING_KEY[filterKey])
        //   }
        // }
      }
    },

    onSelectLastCommentFilterValue(value, filterKey = FILTERS_KEYS.LAST_COMMENT) {
      // necessary to prevent multiple requests of getElements
      if (this.showAdditionalFilters) {
        let emitKey = FILTERS_KEYS.LAST_COMMENT
        const eventName = FILTER_TRACKING_KEY[filterKey]

        if (filterKey === FILTERS_KEYS.LAST_COMMENT) {
          emitKey = FILTERS_KEYS.LAST_COMMENT
        }

        this.$emit('update-filter-value', { key: emitKey, value })

        const trackingValue = getValueForTrackingLstCommentFilter({ value })

        this.logFilterEvent(eventName, trackingValue)
      }
    },

    onDateSelect(value, filterKey = FILTERS_KEYS.DUE_DATES) {
      // necessary to prevent multiple requests of getElements
      if (this.showAdditionalFilters) {
        let emitKey = FILTERS_KEYS.DUE_DATES

        if (filterKey === FILTERS_KEYS.START_DATES) {
          emitKey = FILTERS_KEYS.START_DATES
        }

        if (filterKey === FILTERS_KEYS.LAST_GRADE_UPDATE_DATES) {
          emitKey = FILTERS_KEYS.LAST_GRADE_UPDATE_DATES
        }

        this.$emit('update-filter-value', { key: emitKey, value })

        let valueForTracking = ''

        if (isNull(value)) {
          valueForTracking = TRACKING_SHOW_ALL
        }

        if (Array.isArray(value) && value.length > 0) {
          valueForTracking = getDisplayedDates({
            selectedDates: value.map(date => compensateTimeZone(date))
          }).full
        }

        this.logFilterEvent(FILTER_TRACKING_KEY[filterKey], valueForTracking)

        // if (Array.isArray(value) && value.length > 0) {
        //   this.logFilterEvent(eventName)
        // }
      }
    },

    async getLevelsForFilter() {
      const levelApi = new LevelApiHandler()
      try {
        const levels = await levelApi.getLevelsForFilter({
          workspaceId: this.workspaceId
        })
        this.setLevels({
          levels
        })
      } catch (error) {
        handleError({ error })
      }
    },

    // onClickOutside(e) {
    //   if (this.showAdditionalFilters) {
    //     const datePickerEl = document.querySelector('.mx-datepicker-popup')
    //     const triggerButton = this.$refs.additionalFiltersTrigger
    //     const isTargetIgnore = [datePickerEl, triggerButton].some(item =>
    //       e.composedPath().includes(item)
    //     )
    //     if (isTargetIgnore) {
    //       return
    //     }
    //     this.showAdditionalFilters = false
    //   }
    // },

    resetFilters() {
      if (this.isTimeRangeSelected) {
        const suitableInterval = getSuitableInterval(this.intervals, [], true)
        this.selectIntervals([suitableInterval])
      }
      this.$emit('reset-filter-value', FILTERS_KEYS.ASSIGNEE_IDS)
      this.$emit('reset-filter-value', FILTERS_KEYS.GROUP_IDS)
      this.resetAdditionalFilters()
      this.resetCustomFieldFilters({ updateElements: true })
    },

    resetAdditionalFilters() {
      // because this filters resets in selectIntervals method when custom range selected
      if (!this.isTimeRangeSelected) {
        this.$emit('reset-filter-value', FILTERS_KEYS.DUE_DATES)
        this.$emit('reset-filter-value', FILTERS_KEYS.START_DATES)
      }
      this.$emit('reset-filter-value', FILTERS_KEYS.LAST_GRADE_UPDATE_DATES)
      this.$emit('reset-filter-value', FILTERS_KEYS.LAST_COMMENT)
      this.resetSelectedOkrTypes()
      this.resetSelectedStatuses()
      this.resetSelectedLabels()
      this.resetSelectedStakeholders()
    },

    resetSelectedOkrTypes() {
      this.$emit('reset-filter-value', FILTERS_KEYS.OKR_TYPE_IDS)
    },

    resetSelectedStatuses() {
      this.$emit('reset-filter-value', FILTERS_KEYS.GRADE_TYPES)
    },

    resetSelectedLabels() {
      this.$emit('reset-filter-value', FILTERS_KEYS.LABEL_IDS)
    },

    resetSelectedStakeholders() {
      this.$emit('reset-filter-value', FILTERS_KEYS.STAKEHOLDER_IDS)
    }
  }
})
</script>
<!--<script setup>-->
<!--import { vOnClickOutside } from '@vueuse/components'-->
<!--</script>-->
<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/table-modals-helpers';
@import '~@/assets/styles/filters';

.ot-OkrToolbar {
  // position: relative;
  background-color: $white;
}
.ot-Navigation {
  display: flex;
  align-items: center;
  gap: 0 8px;
  flex-wrap: wrap;
}

.ot-Navigation_Filters {
  flex: 0 1 auto;
  min-width: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.ot-Navigation_Actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.ot-NavigationFilter {
  flex: 0 1 auto;
  max-width: 230px;

  --select-skeleton-left: 0px;
  --select-skeleton-top: 0px;

  &-intervals {
    position: relative;
    padding-right: 12px;
    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      background: $lines-grey;
      height: 24px;
    }
  }
}

.ot-NavigationFilterActions {
  flex: 0 1 auto;
  display: flex;
  gap: 8px;
}

.ot-ExportBtn,
.ot-ActionsButton {
  color: $dark-2;
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('bold');
  @include activityStates($dark-2, 10%, 'color');
  background: transparent;
}

.ot-ExportBtn {
  min-width: 32px;
}
/* eslint-disable-next-line */
%actions-element {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ot-Actions_Left {
  @extend %actions-element;
}

.ot-AdditionalFiltersButton {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  cursor: pointer;
  padding: 4px 12px 4px 4px;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  font-family: $system-ui;
  .ot-AdditionalFiltersButton_Icon {
    color: $dark-2;
  }
  @media (any-hover: hover) {
    &:hover {
      .ot-AdditionalFiltersButton_Icon {
        color: $dark-2;
      }
    }

    &:active {
      background: $dark-2;
      color: $white;
      .ot-AdditionalFiltersButton_Icon {
        color: $white;
      }
    }
  }
  &-highlight {
    background: $dark-2;
    color: $white;
    .ot-AdditionalFiltersButton_Icon {
      color: $white;
    }
    @media (any-hover: hover) {
      &:hover {
        background: lighten($dark-2, 10%);
        .ot-AdditionalFiltersButton_Icon {
          color: $white;
        }
      }

      &:active {
        color: $white;
        background: darken($dark-2, 10%);
        .ot-AdditionalFiltersButton_Icon {
          color: $white;
        }
      }
    }
  }
}

.ot-AdditionalFiltersButton_Icon {
  margin-right: 4px;
  color: $grey-8;
}

$table-modals-gap: 10px;

.ot-AdditionalFiltersModalHeader {
  display: flex;
  gap: 4px;
  align-items: center;
  overflow: hidden;
}

.ot-AdditionalFiltersContent {
  // padding-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  height: $modal-header-height;

  &:before {
    @extend %divider-style;
  }
}

.ot-AdditionalFiltersContent_Filters {
  // padding: 0 0 20px;
  overflow-y: auto;
  @include styled-native-scrollbar();
  position: relative;
}

/*
.ot-AdditionalFiltersContent_Footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: 20px;
  padding: 20px 20px 0;

  &:before {
    @extend %divider-style;
  }
}
*/

.ot-AdditionalFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  // width: $dropdown-width;
  width: 100%;
  --group-padding: 10px;
}

.ot-AdditionalFilters_Dates {
  width: 100%;
  position: relative;
  --group-padding: 0;
  perspective: 1000px;

  &-locked {
    position: relative;
    padding-top: 10px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-color-next;
      border-radius: $border-radius-sm-next;
      pointer-events: none;
    }
  }
}

.ot-AdditionalFilter_SelectButton {
  width: 100%;
}

.flip-enter-active {
  animation: flip $transition-normal;
}
.flip-leave-active {
  animation: flip $transition-normal reverse;
}
@keyframes flip {
  0% {
    transform: rotateX(50deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.ot-LockedDatesDescription {
  background-color: $primary-color-next;
  border-radius: 0 0 $border-radius-sm-next $border-radius-sm-next;
  color: $white;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  overflow: hidden;
  transform-style: preserve-3d;
  transform-origin: center 0;
  margin-top: 10px;
}

$dropdown-width: 300px;

/*
.ot-LockedDatesDescription {
  background: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    $primary-color-next 50%,
    $primary-color-next
  );
  color: $white;
  position: absolute;
  !* right: 0; *!
  top: -12px;
  height: calc(100% + 22px);
  width: calc(#{$dropdown-width} * 2);
  left: -10px;
  border-radius: $border-radius-sm-next;
  border: 2px solid $primary-color-next;
  box-sizing: border-box;
  padding: 20px 20px 20px calc(#{$dropdown-width} + 20px);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  z-index: 1;
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  overflow: hidden;
  transform-style: preserve-3d;
  transform-origin: center 0;
}
*/

.ot-LockedDates_Title {
  margin-top: 15px;
  font-weight: fw('regular');
}

.ot-LockedDates_Range {
  font-weight: fw('semi-bold');
}

.ot-Navigation_ViewSelect {
  max-width: 172px;
}

.ot-SelectButton_Value {
  @extend %select-button-value;
}

.ot-SelectButton_ValueText {
  @extend %select-button-value-text;
}

.ot-AdditionalFilter_SelectedCount {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  font-family: $system-ui;

  &:not(&-light) {
    background: $dark-3;
    color: $white;
  }

  &-light {
    color: $dark-3;
  }
}
</style>
