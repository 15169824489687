import { cloneDeep, isEqual } from 'lodash'
import { computed, nextTick, ref, toValue, watch } from 'vue'

import { compensateTimeZone } from '@/utils/date'
import {
  actualizeSelectedCustomRangeMode,
  DEFAULT_DATES_RANGE,
  DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT,
  filterOnBacklogInterval
} from '@/utils/interval'
import {
  INTERVAL_TIME_RANGE_OPTIONS,
  isTimeRangeSelectedInsteadOfInterval
} from '@/utils/okr-elements/filters'

export const isSelectedIntervalCustomRangeMode = selectedIntervalCustomRangeMode => {
  return {
    isBetweenModeSelected:
      selectedIntervalCustomRangeMode === INTERVAL_TIME_RANGE_OPTIONS.BETWEEN.value,
    isBeforeModeSelected:
      selectedIntervalCustomRangeMode === INTERVAL_TIME_RANGE_OPTIONS.BEFORE.value,
    isSinceModeSelected: selectedIntervalCustomRangeMode === INTERVAL_TIME_RANGE_OPTIONS.SINCE.value
  }
}

export const useSelectWithCustomRange = ({ intervals = [], selectedDates }) => {
  const intervalList = toValue(intervals)
  const selectedIntervals = ref([])
  const intervalCustomDate = ref(cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT))
  const intervalCustomRange = ref(cloneDeep(DEFAULT_DATES_RANGE))
  const selectedIntervalCustomRangeMode = ref(INTERVAL_TIME_RANGE_OPTIONS.BETWEEN.value)
  const showIntervalCustomRangePicker = ref(false)
  const { BEFORE, SINCE } = INTERVAL_TIME_RANGE_OPTIONS

  const isBetweenModeSelected = computed(() => {
    return isSelectedIntervalCustomRangeMode(selectedIntervalCustomRangeMode.value)
      .isBetweenModeSelected
  })

  const customRangeOptionsBasedOnFilters = computed(() => {
    const { startDates, dueDates } = selectedDates.value

    return isTimeRangeSelectedInsteadOfInterval({
      startDates,
      dueDates,
      intervals: selectedIntervals.value
    })
  })

  const isTimeRangeSelected = computed(() => {
    return customRangeOptionsBasedOnFilters.value.isTimeRangeSelected
  })

  const selectIntervals = value => {
    showIntervalCustomRangePicker.value = false

    selectedIntervals.value = filterOnBacklogInterval({
      intervals: intervalList,
      value,
      selectedIntervals: selectedIntervals.value
    })
  }

  const onActualizeSelectedCustomRangeMode = () => {
    const modeBySelectedDates = actualizeSelectedCustomRangeMode({
      customRangeOptionsBasedOnFilters: customRangeOptionsBasedOnFilters.value,
      selectedIntervalCustomRangeMode: selectedIntervalCustomRangeMode.value
    })

    if (modeBySelectedDates) {
      selectedIntervalCustomRangeMode.value = modeBySelectedDates
    }
  }

  watch(
    () => isTimeRangeSelected.value,
    async newValue => {
      if (newValue) {
        const { startDates, dueDates } = selectedDates.value

        onActualizeSelectedCustomRangeMode()

        await nextTick()

        const { isBetweenRangeSelected, isBeforeRangeSelected, isSinceRangeSelected } =
          customRangeOptionsBasedOnFilters.value

        const [startDateFrom] = startDates
        const [, dueDateTo] = dueDates

        if (
          isBetweenRangeSelected &&
          !isEqual(intervalCustomRange.value, [startDateFrom, dueDateTo])
        ) {
          intervalCustomRange.value = [
            compensateTimeZone(startDateFrom),
            compensateTimeZone(dueDateTo)
          ]
        }

        if (isBeforeRangeSelected && !isEqual(intervalCustomDate.value[BEFORE.value], dueDateTo)) {
          intervalCustomDate.value[BEFORE.value] = compensateTimeZone(dueDateTo)
        }

        if (
          isSinceRangeSelected &&
          !isEqual(intervalCustomDate.value[SINCE.value], startDateFrom)
        ) {
          intervalCustomDate.value[SINCE.value] = compensateTimeZone(startDateFrom)
        }
      } else {
        if (!isEqual(intervalCustomRange.value, DEFAULT_DATES_RANGE)) {
          intervalCustomRange.value = cloneDeep(DEFAULT_DATES_RANGE)
        }

        if (!isEqual(intervalCustomDate.value, DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT)) {
          intervalCustomDate.value = cloneDeep(DEFAULT_INTERVAL_CUSTOM_DATE_OBJECT)
        }
      }
    },

    {
      immediate: true
    }
  )

  return {
    selectIntervals,
    selectedIntervals,
    isTimeRangeSelected,
    intervalCustomDate,
    intervalCustomRange,
    selectedIntervalCustomRangeMode,
    showIntervalCustomRangePicker,
    customRangeOptionsBasedOnFilters,
    isBetweenModeSelected
  }
}

export const CUSTOM_RANGE_ITEM = {
  id: 0,
  text: 'filter.custom_range',
  isRange: true
}
