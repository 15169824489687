<template>
  <AppDialog
    :disable-confirm-button="isLoading"
    :show="showConfirmDeleteDialog"
    :title="`${$t('action.delete_entity', { entity: dashboardForDelete.name })}?`"
    :type="DIALOG_TYPES.DELETE"
    @on-confirm="onConfirmDeleteDashboard"
    @on-close="closeConfirmDeleteDialog"
  >
    <template #confirm-btn-text>
      {{ $t('action.delete') }}
    </template>
  </AppDialog>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { trackDeleteCustomDashboardEvent } from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'

const props = defineProps({
  showConfirmDeleteDialog: {
    type: Boolean
  },
  dashboardForDelete: {
    type: Object,
    default: () => ({})
  }
})
const isLoading = ref(false)

const emit = defineEmits(['close', 'confirm', 'on-success'])

const closeConfirmDeleteDialog = () => {
  emit('close')
}
const store = useStore()
const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)
const onConfirmDeleteDashboard = async () => {
  const customDashboardApi = new CustomDashboardApiHandler()
  try {
    const { id } = props.dashboardForDelete
    isLoading.value = true
    await customDashboardApi.deleteDashboard({ id })
    closeConfirmDeleteDialog()
    trackDeleteCustomDashboardEvent({
      id: props.dashboardForDelete.id,
      isPluginAdmin: isPluginAdmin.value
    })
    emit('on-success')
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped></style>
