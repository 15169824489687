<template>
  <AppButton
    :id="`tdm-${entityId}`"
    :class="{ 'tdm-Trigger-active': isActionsShow }"
    data-testid="row-dropdown-trigger"
    icon="more-next"
    size="sm"
    type="tertiary-next"
    @click="onTriggerClick"
  />
  <DropdownMenu
    v-if="actionsMenuInitialized"
    :items="items"
    :offset="[0, 0]"
    :show="isActionsShow"
    :to-selector="`#tdm-${entityId}`"
    :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
    data-testid="row-dropdown-menu"
    preferred-position="bottom-end"
    show-on-init
    @close="onClose"
    @open="onOpen"
    @update:show="onUpdateShow"
    @item-click="onItemClick"
  />
</template>

<script setup>
import { ref } from 'vue'

import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'

import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import { getRowId } from '@/components/ui/InfiniteTableExpandable/infinite-table-expandable-utils'

defineOptions({
  name: 'TableDropdownMenu',
  inheritAttrs: false
})

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  entityId: {
    type: [String, Number],
    required: true
  }
})

const isActionsShow = ref(false)
const actionsMenuInitialized = ref(false)

const onTriggerClick = () => {
  if (!actionsMenuInitialized.value) {
    actionsMenuInitialized.value = true
  }
}

const onUpdateShow = newValue => {
  isActionsShow.value = newValue
}

const emit = defineEmits({
  'item-click': item => item,
  'update:table-hover-row': value => value,
  open: null,
  close: null
})

const onItemClick = item => {
  emit('item-click', item)
}

const onToggleTableHoverRow = (resetValue = false) => {
  const newValue = resetValue ? null : getRowId(props.entityId)
  emit('update:table-hover-row', newValue)
}

const onOpen = () => {
  emit('open')
  onToggleTableHoverRow(false)
}

const onClose = () => {
  emit('close')
  onToggleTableHoverRow(true)
}
</script>

<style lang="scss" scoped>
.tdm-Trigger-active {
  background-color: $dark-2;
  color: $white;
}
</style>
