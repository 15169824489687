<template>
  <template v-if="!isFullAppLoader">
    <AppDialog
      v-if="!canMove.canMoveWithoutNested"
      :show="show"
      :title="$t('objective.cant_move')"
      @on-close="hideModal"
      @on-confirm="hideModal"
    >
      <div>
        <p class="smw-Subtitle smw-Subtitle_ObjectiveName">
          {{ objectiveData.displayId }} {{ objectiveData.name }}
        </p>

        <div class="smw-Subtitle">
          {{
            t('objective.obj_cant_move_because_kr', {
              closedStatus: t('dashboard.closed'),
              abandonedStatus: t('dashboard.abandoned')
            })
          }}
        </div>
      </div>
      <template #confirm-btn-text>{{ t('tour.got_it') }}</template>
    </AppDialog>
    <ObjectiveMoveModal
      v-else
      :disable="!isProceed || isAnyChildClosed"
      :loading="loading"
      :show="show"
      :title="t('objective.move_objective', { displayId: objectiveData?.displayId })"
      :with-back-button="false"
      @close="hideModal"
      @proceed="proceed"
    >
      <div>
        <p class="smw-Subtitle">
          {{ t('objective.move_objective_subtitle') }}
        </p>
        <p class="smw-Subtitle smw-Subtitle_ObjectiveName">
          {{ objectiveData.displayId }} {{ objectiveData.name }}
        </p>
      </div>
      <WorkspaceIntervalParentSelector
        v-model="formModel"
        class="smw-MainContent"
        with-no-parent-option
      />
      <FormField type="default-next">
        <template #label>
          <span class="smw-FieldTitle">{{ t('objective.move_nested_items') }}</span>
        </template>
        <div class="smw-SwitcherWrapper">
          <AppCheckbox v-model="formModel.moveNested">
            <span class="smw-SwitcherWrapper_Text">{{ t('kr.move_nested_items') }}</span>
          </AppCheckbox>
          <AppInfoMessage v-if="isAnyChildClosed" :type="INFO_MESSAGE_TYPES.ERROR">
            <span class="smw-ErrorMsg">
              {{
                $t('objective.obj_cant_move', {
                  closedStatus: $t('dashboard.closed'),
                  abandonedStatus: $t('dashboard.abandoned')
                })
              }}
            </span>
          </AppInfoMessage>
          <AppCheckbox v-model="formModel.moveCrossWorkspace" :disabled="!formModel.moveNested">
            <span class="smw-SwitcherWrapper_Text">{{ t('kr.move_from_all_workspaces') }}</span>
          </AppCheckbox>
        </div>
      </FormField>
    </ObjectiveMoveModal>
  </template>
</template>

<script setup>
import { ref, computed, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'
import FormField from '@/components/form/FormField'
import ObjectiveMoveModal from '@/components/objectives/objective-move/ObjectiveMoveModal'
import WorkspaceIntervalParentSelector from '@/components/objectives/objective-move/WorkspaceIntervalParentSelector'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppInfoMessage from '@/components/ui/AppInfoMessage'

defineOptions({
  name: 'SelectWorkspaceModal',
  inheritAttrs: false
})

const { t } = useI18n()
const props = defineProps({
  show: {
    type: Boolean
  },

  data: {
    type: Object,
    default: () => ({})
  },

  loading: {
    type: Boolean
  },

  objectiveData: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits({
  'update:show': null,
  proceed: null
})

const store = useStore()

const formModel = ref(props.data)

const objectiveInfoApi = new ObjectivesInfoApiHandler()

const canMove = ref({
  canMoveWithoutNested: true,
  canMoveWithNested: true
})

const isFullAppLoader = computed(() => store.state.system.fullAppLoading.flag)
const toggleFullAppLoader = () => store.dispatch('system/toggleFullAppLoader')
const checkMovingAvailability = async () => {
  try {
    await toggleFullAppLoader()
    canMove.value = await objectiveInfoApi.checkMovingAvailability({
      elementId: props.objectiveData.id
    })
  } catch (error) {
    handleError({ error })
  } finally {
    await toggleFullAppLoader()
  }
}

watch(
  () => props.show,
  async value => {
    if (value) {
      await checkMovingAvailability()
    }
  },
  { immediate: true }
)

const hideModal = async () => {
  emit('update:show', false)

  await nextTick()
}
const isProceed = computed(() => formModel.value.workspaceId && formModel.value.intervalId)

const proceed = () => {
  emit('proceed', formModel.value)
}

const isAnyChildClosed = computed(() => {
  return !canMove.value.canMoveWithNested && formModel.value.moveNested
})
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectMoveWorkspace'
})
</script>

<style lang="scss" scoped>
.smw-Subtitle {
  margin-bottom: 0;
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  color: $dark-2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &_ObjectiveName {
    font-weight: fw('bold');
    margin-bottom: 23px;
  }
}

.smw-MainContent {
  margin-bottom: 20px;
}

.smw-FieldTitle {
  font-family: $system-ui;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
.smw-SwitcherWrapper_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
}
.smw-SwitcherWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  margin-top: 12px;
  --checkmark-margin: 0 4px;
}
.smw-Subtitle {
  margin-bottom: 0;
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  color: $dark-2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &_ObjectiveName {
    font-weight: fw('bold');
    margin-bottom: 23px;
  }
}

.smw-ErrorMsg {
  color: $grade-low-color-next;
  font-size: $fs-14;
  line-height: 20px;
}
</style>
