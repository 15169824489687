<template>
  <portal to="modal-windows">
    <AppModalWithConfirmation
      ref="confirmReference"
      :confirm-close="areDataChanged"
      :show="show"
      :size="tab === ADD_USER_TAB ? 500 : 680"
      :title="t('users.create')"
      class="autw-Modal"
      data-auto-testid="add-users-to-workspace-modal"
      hide-hero
      @on-close="onClose(false)"
    >
      <AppRadioGroup
        v-if="needShowSwitcher"
        v-model="tab"
        :options="TAB_OPTIONS"
        class="autw-RadioGroup"
        name="add-users-tab"
        style="--option-padding: 0 8px 0 0"
        type="primary-next"
        @update:model-value="onTabChange"
      >
        <template #item-label="{ item }">
          <AppRadioGroupNextOptionWithIcon :option="item" />
        </template>
      </AppRadioGroup>
      <div v-show="tab === ADD_USER_TAB" class="autw-Head">
        <div class="autw-Head_Cell">
          {{ userSelectLabel }}
        </div>
        <div class="autw-Head_Cell">
          {{ $t('workspaces.role') }}
        </div>
      </div>

      <div ref="fieldSetsReference" class="autw-ModalBody">
        <div v-show="tab === ADD_USER_TAB" class="autw-Fields">
          <AddUserWithGroupFieldGroup
            v-for="item in accountsToAdd"
            :key="item.uid"
            v-model:accounts-to-add="accountsToAdd"
            :item="item"
            :show-clear-button="isShowClearButton"
            :workspace-id="workspaceId"
            @remove-item="onRemoveItem"
          />
        </div>
        <AddLinkedUserFieldGroup
          v-show="tab === BULK_ADD_TAB"
          v-model:groups-to-add="groupsToAdd"
          :workspace-id="workspaceId"
          class="autw-Fields"
        />

        <AppTableCreateButton
          v-if="isShowAddButton"
          icon-name="plus-next"
          no-hover
          no-padding
          @click="onAddItemClick"
        >
          {{ $t('users.add_another') }}
        </AppTableCreateButton>
      </div>

      <template #footer-actions>
        <AppButton type="ghost-next" @click="close">
          {{ $t('action.cancel') }}
        </AppButton>
        <AppButton
          :disable="isLoading || isDisableSaving"
          :loading="isLoading"
          type="primary-next"
          @click="save"
        >
          {{ $t('action.add_user_qty', accountsToAdd.length) }}
        </AppButton>
      </template>
    </AppModalWithConfirmation>
  </portal>
</template>

<script setup>
import { isBoolean } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import WorkspaceGroupsApiHandler from '@/api/workspace-groups'
import UsersApi from '@/api/workspace-users'
import { scrollSmooth } from '@/composables/visualEffects'
import {
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent,
  trackAddUserEventBulk,
  trackOpenAddUserModalEvent
} from '@/tracking/amplitude-helpers'
import {
  ADD_USER_TAB,
  BULK_ADD_TAB,
  useBulkOperationSwitcher
} from '@/utils/bulk-operation-switcher'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { GLOBAL_GROUPS_PERMISSIONS } from '@/utils/global-groups'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { uid } from '@/utils/uid'
import {
  createPayloadForAccountIdsAndEmails,
  useEmailsInSelect,
  USER_IDENTIFIER_VALID_KEY
} from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AddLinkedUserFieldGroup from '@/views/workspaces/settings/workspace-users/AddLinkedUserFieldGroup'
import AddUserWithGroupFieldGroup from '@/views/workspaces/settings/workspace-users/AddUserWithGroupFieldGroup'

defineOptions({
  name: 'AddUserToWorkspaceWithGroupModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  workspaceId: {
    type: Number,
    required: true
  }
})

const { userSelectLabel } = useEmailsInSelect()

const tab = ref(ADD_USER_TAB)

const { resolvedOptions: TAB_OPTIONS, needShowSwitcher } = useBulkOperationSwitcher()

const emit = defineEmits(['update:show', 'reload-data'])
const { t } = useI18n()

const areDataChanged = computed(() => {
  if (tab.value === ADD_USER_TAB) {
    return accountsToAdd.value.some(item => item[REQUEST_ENTITY_KEYS.IDENTIFIER])
  } else {
    return true
  }
})

const onClose = (reloadData = false) => {
  tab.value = ADD_USER_TAB
  accountsToAdd.value = [{ ...DEFAULT_ITEM, uid: uid() }]
  groupsToAdd.value = {
    ...DEFAULT_GROUPS_TO_ADD
  }
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const confirmReference = ref(null)
const close = () => {
  confirmReference.value.close()
}

const isLoading = ref(false)

const isDisableSaving = computed(() => {
  const keepSyncedNotChecked = !isBoolean(groupsToAdd.value.keepSynced)
  const targetGroupNotSelected = !groupsToAdd.value.targetPluginGroupId
  const sourceGroupsNotSelected = !groupsToAdd.value.sourcePlatformGroupIds.length

  return tab.value === BULK_ADD_TAB
    ? sourceGroupsNotSelected || targetGroupNotSelected || keepSyncedNotChecked
    : false
})

const DEFAULT_ITEM = {
  [REQUEST_ENTITY_KEYS.IDENTIFIER]: null,
  [USER_IDENTIFIER_VALID_KEY]: true,
  roleId: WORKSPACE_USER_ROLES.USER
}
const accountsToAdd = ref([{ ...DEFAULT_ITEM, uid: uid() }])

const save = async () => {
  if (tab.value === ADD_USER_TAB) {
    let isValid = true
    accountsToAdd.value.forEach(item => {
      item[USER_IDENTIFIER_VALID_KEY] = !!item[REQUEST_ENTITY_KEYS.IDENTIFIER]
      if (!item[USER_IDENTIFIER_VALID_KEY]) {
        isValid = false
      }
    })
    if (isValid) {
      isLoading.value = true
      const existedAccounts = accountsToAdd.value.filter(account => account.oboardUser)
      const usersApi = new UsersApi()
      const notExistedAccounts = accountsToAdd.value.filter(account => !account.oboardUser)

      try {
        const notExistedAccountsPayload = notExistedAccounts.map(account => {
          return {
            roleId: account.roleId,
            [REQUEST_ENTITY_KEYS.IDENTIFIER]: account[REQUEST_ENTITY_KEYS.IDENTIFIER],
            groupIds: []
          }
        })
        const existedAccountsPayload = existedAccounts.map(account => {
          return {
            roleId: account.roleId,
            defaultGroupId: account.defaultGroupId || null,
            [REQUEST_ENTITY_KEYS.IDENTIFIER]: account[REQUEST_ENTITY_KEYS.IDENTIFIER],
            groupIds: [...account.groups.map(group => group.id)]
          }
        })
        const usersToCreate = [...notExistedAccountsPayload, ...existedAccountsPayload]

        isLoading.value = false

        const { emails, accountIds } = createPayloadForAccountIdsAndEmails({
          selectedItems: usersToCreate
        })

        await usersApi.addUsersToWorkspace({
          workspaceId: props.workspaceId,
          usersToCreate: [...accountIds, ...emails]
        })

        trackAddUserEvent({
          mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
          selectedUsers: usersToCreate,
          source: EVENT_SOURCES.WS_USERS_TAB
        })

        onClose(true)
      } catch (error) {
        handleError({ error })
      } finally {
        isLoading.value = false
      }
    }
  } else {
    isLoading.value = true
    try {
      const api = new WorkspaceGroupsApiHandler()
      const { sourcePlatformGroupIds, targetPluginGroupId, keepSynced } = groupsToAdd.value
      await api.bulkAddUserToGroup({
        workspaceId: props.workspaceId,
        sourcePlatformGroupIds,
        targetPluginGroupId,
        keepSynced
      })

      trackAddUserEventBulk({
        mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
        source: EVENT_SOURCES.WS_USERS_TAB,
        synced: keepSynced
      })

      onClose(true)
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
  }
}

const users = ref({})
const areUsersLoading = ref(false)
const getUsers = async (searchString = null) => {
  const api = new WorkspaceGroupsApiHandler()

  areUsersLoading.value = true

  try {
    return await api.getInnerUsers({
      searchString,
      workspaceId: props.workspaceId
    })
  } catch (error) {
    handleError({ error })
  } finally {
    areUsersLoading.value = false
  }
}

const groups = ref({})
const areGroupsLoading = ref(false)
const getGroupsList = async (searchString = null) => {
  areGroupsLoading.value = true
  const groupsApi = new GlobalGroupsApiHandler()

  try {
    const data = await groupsApi.getGlobalGroups({
      workspaceIds: [props.workspaceId],
      searchString
    })
    return data.filter(group => group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.ADD_USERS))
  } catch (error) {
    handleError({ error })
  } finally {
    areGroupsLoading.value = false
  }
}
watch(
  () => props.show,
  async value => {
    if (value) {
      users.value = await getUsers()
      groups.value = await getGroupsList()
    }
  }
)
const MAX_COUNT = 10
const MIN_COUNT = 1

const isShowAddButton = computed(() => {
  return accountsToAdd.value.length < MAX_COUNT && tab.value === ADD_USER_TAB
})

const isShowClearButton = computed(() => {
  return accountsToAdd.value.length > MIN_COUNT
})
const fieldSetsReference = ref(null)
const onAddItemClick = () => {
  accountsToAdd.value.push({
    uid: uid(),
    ...DEFAULT_ITEM
  })
  scrollSmooth(fieldSetsReference)
}
const onRemoveItem = uid => {
  accountsToAdd.value = accountsToAdd.value.filter(item => item.uid !== uid)
}

const DEFAULT_GROUPS_TO_ADD = {
  keepSynced: null,
  sourcePlatformGroupIds: [],
  targetPluginGroupId: null
}
const groupsToAdd = ref({
  ...DEFAULT_GROUPS_TO_ADD
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const onTabChange = value => {
  const tab = TAB_OPTIONS.find(item => item.value === value).trackingName
  trackOpenAddUserModalEvent({
    tab,
    source: EVENT_SOURCES.WS_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
    role: userRoleForTracking.value
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_mixins.scss';

.autw-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px - 32px - 20px
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height; 32px is radioGroup height
  padding: 0 40px;
  @include styled-native-scrollbar();
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.autw-Fields {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.autw-Head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 40px 6px;
}
.autw-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
.autw-RadioGroup {
  margin: 0 40px 20px;
}
</style>
<style lang="scss">
.autw-Modal {
  .ad-Content_Title {
    padding: 0 40px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }
  // --footer-padding-top: 0;
  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
</style>
